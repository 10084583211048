<form [formGroup]="competitorForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>    
    <h2 mat-dialog-title class="ta-center">Concorrente</h2>
    <div class="row">
      <mat-form-field appearance="standard" class="col-6">
        <mat-label for="companyName">
          {{ 'IR_FORM.BusinessName' | translate }}
        </mat-label>
        <input 
          matInput
          formControlName="companyName"
          type="text"
          name="companyName"
          autocomplete="off"
        >
        <mat-error *ngIf="competitorForm.get('companyName').invalid">
          {{ competitorForm.get('companyName').errors | getErrorMessage:translations.BusinessName }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard" class="col-6">
        <mat-label for="valueRevenueYear">{{ 'CRM_DETAIL.ValueRevenueYear' | translate }}</mat-label>
        <input
          matInput
          name="valueRevenueYear"
          formControlName="valueRevenueYear"
          currencyMask
          autocomplete="off"
          [options]="currencyDefaultConfig"
        >
        <mat-error *ngIf="competitorForm.get('valueRevenueYear').invalid">
          {{ competitorForm.get('valueRevenueYear').errors | getErrorMessage:translations.ValueRevenueYear }}
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button type="submit" mat-raised-button color="primary" [disabled]="savingCompetitorLoader || isReadonly">
      <mat-spinner *ngIf="savingCompetitorLoader" class="d-inline-block mr-10" diameter="20"></mat-spinner>
      <mat-icon *ngIf="!savingCompetitorLoader" class="mr-10">save</mat-icon>
      {{ 'IR_FORM.Save' | translate }}
    </button>
    <button mat-raised-button color="warn" mat-dialog-close>
      <mat-icon>clear</mat-icon>
      {{ 'CRM_DETAIL.Cancel' | translate }}
    </button>    
  </mat-dialog-actions>
</form>
