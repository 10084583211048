import { MatDateFormats } from '@angular/material/core';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
// import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { environment } from 'src/environments/environment';
import {
  faFileAlt,
  faFilePdf,
  faFileWord,
} from '@fortawesome/free-solid-svg-icons';
import { Storage } from './utility/storage';

// const { DOMAIN, PROTOCOL, API_CONTEXT } = environment;
export const protocol = Storage.get("protocol");
export const domain = Storage.get("domain");
export const apiContext = Storage.get("apiContext");

export const headerName = 'Authorization';
// export const apiRootUrl = `${PROTOCOL}://${DOMAIN}${API_CONTEXT}`;
export const apiRootUrl = `${protocol}://${domain}/${apiContext}`;

// export const allowedDomains = [DOMAIN];
export const allowedDomains = [domain];
export const noAuthApiRoutes = [
  '/account/access_token',
  '/account/refresh_token',
  '/account/confirmsignup',
  '/account/passwordReset',
];
export const horizontalPosition: MatSnackBarHorizontalPosition = 'right';
export const verticalPosition: MatSnackBarVerticalPosition = 'top';

export const FILE_HEADER = 'x-file-name';

/**
 * WEB APP CONFIG
 */
export const APP_CONTEX = '';
export const storageKeys = {
  accessToken: 'access_token',
  tokenShema: 'auth_schema',
  tokenDate: 'issue_date',
  refreshToken: 'refresh_token',
  expireToken: 'expires_in',
  user: 'session_user',
  functionality: 'user_functionality',
};

export const currencyDefaultConfig: NgxCurrencyModule = {
  align: 'left',
  prefix: '€ ',
  precision: 2,
  thousands: '.',
  decimal: ',',
  allowNegative: false,
  suffix: '',
  inputMode: CurrencyMaskInputMode.NATURAL,
  nullable: true,
};

export const currencyDefaultConfigAllowNegative: NgxCurrencyModule = {
  align: 'left',
  prefix: ' € ',
  precision: 2,
  thousands: '.',
  decimal: ',',
  allowNegative: true,
  suffix: '',
  inputMode: CurrencyMaskInputMode.NATURAL,
  nullable: true,
};

export const currencyQuantityConfig: NgxCurrencyModule = {
  align: 'left',
  prefix: '',
  precision: 2,
  thousands: '.',
  decimal: ',',
  allowNegative: false,
  suffix: '',
  inputMode: CurrencyMaskInputMode.NATURAL,
  nullable: true,
};

export const currencyQuantityConfigWithoutDecimal: NgxCurrencyModule = {
  align: 'left',
  prefix: '',
  precision: 0,
  thousands: '.',
  decimal: ',',
  allowNegative: false,
  suffix: '',
  inputMode: CurrencyMaskInputMode.NATURAL,
  nullable: true,
};

export const DATE_PIKER_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMM YYYY',
  },
};
/**
 * / WEB APP CONFIG
 */

/**
 * ENUM
 */
export enum MARKET_TYPE {
  MarketPresence = 1,
  DesiredPresenceMarket = 2,
  HighPotentialMarket = 3,
  HighPotentialFutureMarket = 4,
}

export enum PS_TYPE {
  Standard = 0,
  Master = 1,
  Clone = 2,
}

export enum RI_STATUS {
  NotInterested = 0,
  RiWorking = 1,
  RiDelivered = 2,
  RiApproved = 3,
  NoContract = 4,
  IspWorking = 5,
  IspDelivered = 6,
  IspApproved = 7,
  Closed = 8,
  IspWorking2 = 9,
  IspWorking3 = 10,
  IspWorking4 = 11,
  IspWorking5 = 12,
  IspWorking6 = 13,
  IspDelivered2 = 15,
  IspDelivered3 = 16,
  IspDelivered4 = 17,
  IspDelivered5 = 18,
  IspDelivered6 = 19,
}

export enum ID_PROJECT_STATUS {
  PS2 = 9,
  PS3 = 10,
  PS4 = 11,
  PS5 = 12,
  PS6 = 13,
}

export enum RI_STATUS_DESCRIPTION {
  RiWorking = 'RI_WORKING',
  RiDelivered = 'RI_DELIVERED',
  RiApproved = 'RI_APPROVED',
  IspWorking = 'ISP_WORKING',
  IspDelivered = 'ISP_DELIVERED',
  IspApproved = 'ISP_APPROVED',
  NoContract = 'NO_CONTRACT',
}

export enum RI_STATUS_STRING {
  RI_STATUS_1 = 'RiWorking',
  RI_STATUS_2 = 'RiDelivered',
  RI_STATUS_3 = 'RiApproved',
  RI_STATUS_4 = 'NoContract',
  RI_STATUS_5 = 'IspWorking',
  RI_STATUS_6 = 'IspDelivered',
  RI_STATUS_7 = 'IspApproved',
  RI_STATUS_8 = 'Closed',
  RI_STATUS_9 = 'IspWorking2',
  RI_STATUS_10 = 'IspWorking3',
  RI_STATUS_11 = 'IspWorking4',
  RI_STATUS_12 = 'IspWorking5',
  RI_STATUS_13 = 'IspWorking6',
  RI_STATUS_15 = 'IspDelivered2',
  RI_STATUS_16 = 'IspDelivered3',
  RI_STATUS_17 = 'IspDelivered4',
  RI_STATUS_18 = 'IspDelivered5',
  RI_STATUS_19 = 'IspDelivered6',
}

export enum PS_VERSION {
  RI_WORKING = 'ASS',
  RI_DELIVERED = 'ASS',
  ISP_WORKING_1 = 'PS1',
  ISP_DELIVERED_1 = 'PS1',
  ISP_WORKING_2 = 'PS2',
  ISP_DELIVERED_2 = 'PS2',
  ISP_WORKING_3 = 'PS3',
  ISP_DELIVERED_3 = 'PS3',
  ISP_WORKING_4 = 'PS4',
  ISP_DELIVERED_4 = 'PS4',
  ISP_WORKING_5 = 'PS5',
  ISP_DELIVERED_5 = 'PS5',
  ISP_WORKING_6 = 'PS6',
  ISP_DELIVERED_6 = 'PS6',
}

export enum PR_STATUS_PS_VERSION {
  PR_STATUS_5 = 'PS1',
  PR_STATUS_6 = 'PS1',
  PR_STATUS_9 = 'PS2',
  PR_STATUS_10 = 'PS3',
  PR_STATUS_11 = 'PS4',
  PR_STATUS_12 = 'PS5',
  PR_STATUS_13 = 'PS6',
  PR_STATUS_15 = 'PS2',
  PR_STATUS_16 = 'PS3',
  PR_STATUS_17 = 'PS4',
  PR_STATUS_18 = 'PS5',
  PR_STATUS_19 = 'PS6',
}

export enum RI_TAB {
  Customer = 'Customer',
  CustomerInformation = 'CustomerInformation',
  Contract = 'Contract',
  Products = 'Products',
  Markets = 'Markets',
  Distribution = 'Distribution',
  ReferenceCompetitors = 'ReferenceCompetitors',
  LeadGeneration = 'LeadGeneration',
  Swot = 'Swot',
  MarketsStrategyAnalysis = 'MarketsStrategyAnalysis',
  Notes = 'Notes',
  Strategy = 'Strategy',
}

export enum PS_TAB {
  Swot = 'Swot',
  CompetitorPositioning = 'CompetitorPositioning',
  Strategy = 'Strategy',
}

export enum CUSTOMER_STATUS {
  CLIENT = 'Client',
  USER = 'User',
}

export enum PROJECT_TYPES {
  ME = 'ME',
  CSP = 'CSP',
}

export enum CHANNEL_USED {
  National = 'NAZIONALE',
  Foreign = 'ESTERO',
  NationalForeign = 'NAZIONALE_ESTERO',
}

export enum DOC_TYPE {
  CONTRACT = 'CONTRACT',
  RI = 'RI',
  ISP = 'ISP',
  Deal = 'DEAL',
}

export enum DOCUMENT_TYPE {
  CONTRACT = 'Contratto',
  VE = 'Visura commerciale',
  OTHER_DOC = 'Altro',
}

export enum NOTE_TYPE {
  ReferentNote = 1,
  CustomNote = 2,
  ProductNote = 3,
  ManagerNote = 4,
}

export enum PRODUCT_TYPE {
  sold = 1,
  bought = 2,
  related = 3,
  competition = 4,
}

export enum PRODUCT_TYPE_NEW {
  'Sold2' = 1,
  'Bought2' = 2,
  'RelComp2' = 3,
  'IndirectCompetition' = 4,
}

export enum PRODUCT_TYPOLOGY {
  SERVIZIO = 'Service',
  PRODOTTO_FINITO = 'Product',
  SUBFORNITURA = 'Subcontracting',
}

export enum SEARCH_PRODUCT_TYPE {
  sold = 1,
  bought = 2,
}

export enum PRODUCTION_TYPE {
  'P' = 'Production',
  'C' = 'Commercial',
  'S' = 'Services',
}

export enum CHANNEL_TYPE {
  Desired = 1,
  Actual = 2,
}

export enum CHANNELS_TYPE {
  ACTIVE = 'Active',
  SUSPECT = 'Suspect',
  PROSPECT = 'Prospect',
  NOT_INTERESTED = 'NotInterested',
  NOT_INTERESTED_NOW = 'NotInterestedForNow',
  NOT_INTEREST_FOR_CUSTOMER = 'NotInteresting',
  LOSED = 'Lost',
  ASK_BUT_NOT_BUY = 'AskButDontBuy',
}

export enum CONTACT_ORIGIN {
  'Sales guide' = 'SalesGuide',
  'Istitutional website' = 'InstitutionalWebsite',
  'External portals' = 'ExternalPortals',
  'E-Commerce' = 'ECommerce',
  'Marketplace' = 'Marketplace',
  'LinkedIn' = 'LinkedIn',
  'Facebook' = 'Facebook',
  'Instagram' = 'Instagram',
  'Traditional sale' = 'TraditionalSale',
  'Traditional shop' = 'TraditionalShop',
  'Traditional advertising' = 'TraditionalAdvertising',
  'Editorial on paper' = 'OnPaperEditorial',
  'Radio/Television' = 'RadioAndTelevision',
  'Telemarketing' = 'Telemarketing',
  'Event' = 'Event',
  'Exhibitions' = 'Exhibitions',
  'Word of mouth' = 'WordOfMouth',
  'SEO' = 'SEO',
  'SEA' = 'SEA',
  'DEM' = 'DEM',
  'SMM' = 'SMM',
  'Content Management' = 'ContentManagement',
  'Online editorial' = 'OnlineEditorial',
  'Influencer Marketing' = 'InfluencerMarketing',
  'Other' = 'Other',
}

export enum CUSTOM_INDEXES {
  'Experience DLManager' = 'ESExperience',
  'Prospect presence' = 'ProspectPresence',
  'Competitors presence' = 'CompetitorsPresence',
  'Difficulty in exporting(duties, quotas...)' = 'ExportingDifficulty',
  'Impact of transport costs' = 'ImpactOfTransportCosts',
  'Production specialisation/labour costs' = 'ProductionSpecialization/LabourCosts',
  'Complexity of approach to the market' = 'MarketApproachComplexity',
  'Cultural affinity of consumption' = 'ConsumptionCulturalAffinity',
  'Languages known in the company' = 'CompanyKnownLanguages',
  'Language known by the DLManager' = 'ESKnownLanguages',
  'Other' = 'Other',
}

export enum COMPETITOR_TYPE {
  National = 0,
  Foreign = 1,
  NationalAndForeign = 2,
}

export enum LEAD_GENERATION_ACTIVITY_TYPE {
  Online = 1,
  Offline = 2,
}

export enum CRM_ACTIVITIES_TYPE {
  NonMandatory = 0,
  Mandatory = 1,
}

export enum SINGULAR_DEAL_STATES_TYPE {
  'Offerte fatte' = 'DealMade',
}

export const ADMINISTRATOR_ROLE = 'ADMIN';
export const AGENT_ROLE = 'AGENT';
export const DIRECTION_ROLE = 'DIRECTION';
export const AGENT_SUPERVISOR = 'AGENT_SUPERVISOR';
export const LAB_MANAGER = 'LAB_MANAGER';
export const LAB_MANAGER_SUPERVISOR = 'LAB_MANAGER_SUPERVISOR';
export const LAB_MANAGER_INTERNAL = 'LAB_MANAGER_INTERNAL';
export const CUSTOMER = 'CUSTOMER';

export enum Roles {
  Admin = 'ADMIN',
  Agent = 'AGENT',
  Direction = 'DIRECTION',
  AgentSupervisor = 'AGENT_SUPERVISOR',
  LabManager = 'LAB_MANAGER',
  LabManagerSupervisor = 'LAB_MANAGER_SUPERVISOR',
  LabManagerInternal = 'LAB_MANAGER_INTERNAL',
  Customer = 'CUSTOMER',
}

export enum ROLES {
  ADMIN = 'Amministratore',
  AGENT = 'Agent',
  DIRECTION = 'Direzione',
  AGENT_SUPERVISOR = 'Supervisore Agente',
  LAB_MANAGER = 'Lab Manager',
  LAB_MANAGER_SUPERVISOR = 'Supervisore DLManager',
  LAB_MANAGER_INTERNAL = 'Lab Manager Interno',
  CUSTOMER = 'Customer',
}

export enum BUYER_STATUS_DESCRIPTION {
  BUYER_STATUS_1 = 'Cliente',
  BUYER_STATUS_2 = 'Suspect',
  BUYER_STATUS_3 = 'Prospect',
  BUYER_STATUS_4 = 'Non interessato',
  BUYER_STATUS_5 = 'Non interessato per ora',
  BUYER_STATUS_6 = 'Non interessante',
  BUYER_STATUS_7 = 'Perso',
  BUYER_STATUS_8 = 'Chiede ma non compra',
}

export enum BUYER_STATUS {
  Active = 1,
  Suspect = 2,
  Prospect = 3,
  NotInterested = 4,
  NotInterestedNow = 5,
  NotInterestForCustomer = 6,
  Losed = 7,
  AskButNotBuy = 8,
}

export enum CRM_DETAIL_TABS_INDEX {
  General = 0,
  Suspect = 1,
  Prospect = 2,
  Customers = 3,
  AskButNotBuy = 4,
  NotInterestedNow = 5,
  NotInterested = 6,
  NotInterestForCustomer = 7,
  Losed = 8,
}

export enum DEAL_STATE_ID {
  Created = 1,
  // Pending = 2,
  Losed = 3,
  OrderPlaced = 4,
}

export enum DEAL_STATE_NAME {
  'CREATED' = 'Created',
  'LOSED' = 'Losed',
  'ORDER PLACED' = 'OrderPlaced',
}

export enum DEAL_STATE_REASON {
  PRODUCT_FEATURES = 'ProductFeatures',
  CERTIFICATION = 'Certification',
  SELLING_CONDITIONS = 'SellingConditions',
  LOYAL_CURRENT_SUPPLIERS = 'LoyalCurrentSuppliers',
  OUT_TARGET_SUPPLIERS = 'OutTargetSuppliers',
  WAIT_NEW_PROJECTS = 'WaitNewProjects',
  WRONG_TIME_NEW_SUPPLIERS = 'WrongTimeNewSuppliers',
  QUALITY = 'Quality',
  PRICE = 'Price',
  PRE_SELLING_SERVICE = 'PreSellingService',
  POST_SELLING_SERVICE = 'PostSellingService',
  SPECIFIC_PROBLEM = 'SpecificIssue',
  OTHER = 'Other',
}

export enum ID_COMMERCE_SECTORS {
  Clothing = 1,
  Food = 2,
  Furniture = 3,
  Automotive = 4,
  Paper = 5,
  Household = 6,
  Chemistry = 7,
  Cosmetics = 8,
  Building = 9,
  Electronicos = 10,
  OfficeSupplies = 11,
  ThirdPartiesProcessing = 12,
  Wood = 13,
  MachinesAndSystems = 14,
  Mechanics = 15,
  Medical = 16,
  Plastic = 17,
  Services = 18,
  Textile = 19,
  Glass = 20,
}

// export enum COMMERCE_SECTORS {
//   Clothing = 'Clothing',
//   Food = 'Food',
//   Furniture = 'Furniture',
//   Automotive = 'Automotive',
//   Paper = 'Paper',
//   Household = 'Household',
//   Chemistry = 'Chemistry',
//   Cosmetics = 'Cosmetics',
//   Building = 'Building',
//   Electronicos = 'Electronicos',
//   OfficeSupplies = 'OfficeSupplies',
//   ThirdPartiesProcessing = 'ThirdPartiesProcessing',
//   Wood = 'Wood',
//   MachinesAndSystems = 'MachinesAndSystems',
//   Mechanics = 'Mechanics',
//   Medical = 'Medical',
//   Plastic = 'Plastic',
//   Services = 'Services',
//   Textile = 'Textile',
//   Glass = 'Glass',
// }

export enum TITLE_TYPE {
  Mister = 'Mister',
  Mistress = 'Mistress',
  Doctor = 'Doctor',
  FemaleDoctor = 'FemaleDoctor',
  // Engineer = 'Engineer',
}

export enum CONTACT_REFERENCE_TYPE {
  'AdministrativeReferent' = 1,
  'ProjectReferent' = 2,
  'BMPurchasingReferent' = 3,
  'BMSalesReferent' = 4,
  'OtherReferent' = 5,
}

/**
 * /ENUM
 */
const Config = {
  storageKeys,
  apiRootUrl,
  horizontalPosition,
  verticalPosition,
  ADMINISTRATOR_ROLE,
  AGENT_ROLE,
  DIRECTION_ROLE,
  ROLES,
};

export const IconTypes = {
  plain: faFileAlt,
  word: faFileWord,
  pdf: faFilePdf,
};

export enum APPOINTMENT_STATE {
  'Created' = 1,
  'Confirmed' = 2,
  'Deleted' = 3,
  'Managed' = 4,
}

export enum APPOINTMENT_TYPE {
  Telephonic = 'T',
  Personal = 'F',
}

export const Colors = [
  /* Standard HTML colors (14) */
  {
    name: 'blue',
    hex: '	#0000ff',
    rgb: 'rgba(0,0,255,0.7)',
  },
  {
    name: 'red',
    hex: '#ff0000',
    rgb: 'rgba(255,0,0,0.7)',
  },
  {
    name: 'green',
    hex: '#008000',
    rgb: 'rgba(0,128,0,0.7)',
  },
  {
    name: 'yellow',
    hex: '#ffff00',
    rgb: 'rgb(255,255,0)',
  },
  {
    name: 'maroon',
    hex: '#800000',
    rgb: 'rgb(128,0,0)',
  },
  {
    name: 'aqua',
    hex: '#00ffff',
    rgb: 'rgb(0,255,255)',
  },
  {
    name: 'fuchsia',
    hex: 'ff00ff',
    rgb: 'rgb(255,0,255)',
  },
  {
    name: 'lime',
    hex: '#00ff00',
    rgb: 'rgb(0,255,0)',
  },
  {
    name: 'gray',
    hex: '#808080',
    rgb: 'rgb(128,128,128)',
  },
  {
    name: 'navy',
    hex: '#000080',
    rgb: 'rgb(0,0,128)',
  },
  {
    name: 'purple',
    hex: '#800080',
    rgb: 'rgb(128,0,128)',
  },
  {
    name: 'olive',
    hex: '#808000',
    rgb: 'rgb(128,128,0)',
  },
  {
    name: 'silver',
    hex: '#c0c0c0',
    rgb: 'rgb(192,192,192)',
  },
  {
    name: 'teal',
    hex: '#008080',
    rgb: 'rgb(0,128,128)',
  },
  /* Standard X11 Colors (14) */
  {
    name: 'aquamarine',
    hex: '#7fffd4',
    rgb: 'rgb(127,255,212)',
  },
  {
    name: 'orange',
    hex: '#ffa500',
    rgb: 'rgb(255,165,0)',
  },
  {
    name: 'yellowgreen',
    hex: '#9acd32',
    rgb: 'rgb(154,205,50)',
  },
  {
    name: 'goldenrod',
    hex: '#daa520',
    rgb: 'rgb(218,165,32)',
  },
  {
    name: 'peru',
    hex: '#cd853f',
    rgb: 'rgb(205,133,63)',
  },
  {
    name: 'skyblue',
    hex: '#87ceeb',
    rgb: 'rgb(135,206,235)',
  },
  {
    name: 'salmon',
    hex: '#fa8072',
    rgb: 'rgb(250,128,114)',
  },
  {
    name: 'lightseagreen',
    hex: '#20b2aa',
    rgb: 'rgb(32,178,170)',
  },
  {
    name: 'moccasin',
    hex: '#ffe4b5',
    rgb: 'rgb(255,228,181)',
  },
  {
    name: 'chocolate',
    hex: '#d2691e',
    rgb: 'rgb(210,105,30)',
  },
  {
    name: 'dodgerblue',
    hex: '#1e90ff',
    rgb: 'rgb(30,144,255)',
  },
  {
    name: 'lightpink',
    hex: '#ffb6c1',
    rgb: 'rgb(255,182,193)',
  },
  {
    name: 'limegreen',
    hex: '#32cd32',
    rgb: 'rgb(50,205,50)',
  },
  {
    name: 'rosybrown',
    hex: '#bc8f8f',
    rgb: 'rgb(188,143,143)',
  },
];

export const FileLimit = {
  maxSize: 30, // 30MB
  fileTypes: [
    'pdf',
    'jpeg',
    'jpg',
    'png',
    'bmp',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'txt',
    'csv',
  ],
  fileTypesSplit: [
    'pdf',
    'jpeg',
    'png',
    'bmp',
    'msword',
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
    'vnd.ms-excel',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'plain',
    'csv',
  ],
};

export const FileLimitLogo = {
  maxSize: 5, // 5MB
  fileTypes: ['jpeg', 'jpg', 'png', 'bmp', 'svg', 'tiff'],
  fileTypesSplit: ['jpeg', 'jpg', 'png', 'bmp', 'svg', 'tiff'],
};

export enum Pages {
  Dashboard = 'dashboard',
  Meetings = 'meetings',
  Customers = 'customers',
  Customer = 'customer',
}

export enum DashboardStatisticsOutputType {
  MonthlyAggregation = 1,
  AnnualAggregation = 2,
  FromBegin = 3,
}

export enum VARIABLES_COLORS {
  Primary = '#73cc34',
  LightPrimary = '#a0ce7f',
  PrimaryFluo = '#9aff1c',
  PrimaryTransparent = '#73cc3480',
  DarkPrimary = '#3e6d1c',
  DarkPrimaryFluo = '#375c0a',
  Secondary = '#25b0db',
  DarkSecondary = '#008cb7',
  LightSecondary = '#ddf7ff',
  LightSecondaryLight = '#ddf7ff4f',
  Success = '#67b30a',
  DarkSuccess = '#2e4e06',
  Error = '#dd2156',
  DarkError = '#5a0e23',
  ErrorTransparent = '#dd215680',
  Problem = '#ffa54b',
  Text = '#424242',
  Chart = '#bf6ece',
  LightChart = '#c39bca',
  Black = '#242424',

  // Primary = '#67b30a',
  // LightPrimary = '#67b30a81',
  // PrimaryFluo = '#9aff1c',
  // PrimaryTransparent = '#99ff1c83',
  // DarkPrimary = '#2a4905',
  // DarkPrimaryFluo = '#375c0a',
  // Secondary = '#36d0ff',
  // DarkSecondary = '#008CB7',
  // LightSecondary = '#ddf7ff',
  // LightSecondaryLight = '#ddf7ff5e',
  // Success = '#67b30a',
  // DarkSuccess = '#2e4e06',
  // Error = '#ff034b',
  // DarkError = '#810025',
  // ErrorTransparent = '#ff034a83',
  // Problem = '#ff870f',
  // Text = '#424242',
  // Chart = '#d703ff',
  // LightChart = '#d503ff7c',
  // Black = '#242424',
}

export enum MARKET_AREA {
  'Europe' = 1,
  'NorthAmerica' = 2,
  'SouthAmerica' = 3,
  'Asia' = 4,
  'Africa' = 5,
  'Oceania' = 6,
  'CentralAmerica' = 7,
  'MiddleEast' = 8,
}

export const newChartColors = [
  '#25B0DB',
  '#73CC34',
  '#BF6ECE',
  '#FFA54B',
  '#41DCDC',
  '#F8E23F',
  '#7582EB',
  '#F79245',
];

// #6EB0B0 > #41DCDC
// #E4D354 > #F8E23F
// #A4A7BC > #7582EB
// #DA9662 > #F79245
// #4C82BF > #267EE3
// #B0A53D > #D2BF19
// #B394E4 > #B088F2
// #DA8580 > #F36F68
// #457B84 > #278EA0
// #CC984C > #EFA02A
// #DA80A1 > #F3689B
// #E4B484 > #FBB570
// #86BC9B > #69D894
// #846100 > #846100
// #7472BC > #5651DB
// #E48E64 > #F68851

export default Config;
