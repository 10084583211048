import { Component, OnInit, Input } from '@angular/core';
import { ChartDataSets, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.css']
})
export class RadarChartComponent implements OnInit {

  @Input() radarChartLabels: Label[];
  @Input() radarChartData: ChartDataSets[];
  @Input() radarChartType: ChartType;

  constructor() { }

  ngOnInit(): void {
  }

}
