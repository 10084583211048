import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  IContact,
  IContactBuyer,
  IDeal,
  IDealForTable,
  IDealState,
  IDealStateReason,
} from 'src/app/models/contact';
import { CommonService } from 'src/app/services/common/common.service';
import { CrmService } from 'src/app/services/crm/crm.service';
import { ISectors } from 'src/app/models/sectors';
import {
  currencyDefaultConfig,
  currencyQuantityConfig,
  DEAL_STATE_ID,
  DEAL_STATE_NAME,
  DEAL_STATE_REASON,
} from 'src/app/config';
import { NgxCurrencyModule } from 'ngx-currency';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import {
  IFileToHandle,
  ToastStatus,
  UnitsOfMeasure,
} from 'src/app/models/utility';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { ChannelsManagementDialogComponent } from '../channels-management-dialog/channels-management-dialog.component';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';

@Component({
  selector: 'app-contact-deal-dialog',
  templateUrl: './contact-deal-dialog.component.html',
  styleUrls: ['./contact-deal-dialog.component.scss'],
})
export class ContactDealDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  translations: any;
  deal: IDeal;
  dealJustSaved: IDeal;
  contact: IContact;
  dealForm: FormGroup;
  commerceSectors: ISectors[];
  loading: boolean = false;
  loadingFile: boolean;
  dealStates: IDealState[];
  dealStateNameEnum: typeof DEAL_STATE_NAME = DEAL_STATE_NAME;
  dealStateReasonEnum: typeof DEAL_STATE_REASON = DEAL_STATE_REASON;
  dealStateReasons: IDealStateReason[];
  filteredDealStateReasons: IDealStateReason[];
  selectedDealState: number;
  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;
  currencyQuantityConfig: NgxCurrencyModule = currencyQuantityConfig;
  showOpportunityFlag: boolean;
  showDealStateReason: boolean;
  // showOpportunityFields: boolean;
  uploadedDocument: IFileToHandle;
  uploadError: boolean;
  documentToUpload: File;
  documentRequired: boolean;
  isReadonly: boolean;
  idCommerceSector: number;
  unitsOfMeasure: UnitsOfMeasure[];
  unitsOfMeasureAreModified: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private crmService: CrmService,
    private common: CommonService,
    private dialogRef: MatDialogRef<ContactDealDialogComponent>,
    private dialog: MatDialog,
    private irFormService: IrFormService
  ) {
    this.translations = data.translations;
    this.unitsOfMeasure = data.unitsOfMeasure;
    this.deal = data.deal;
    this.contact = data.contact;
    this.dealStates = data.dealStates;
    this.dealStateReasons = data.dealStateReasons;
    this.filteredDealStateReasons = data.dealStateReason;
    this.idCommerceSector = data.idCommerceSector;
    this.commerceSectors = data.commerceSectors;
    this.isReadonly = data.isReadonly;
    this.dealForm = this.fb.group({
      idDeal: [null],
      contactReference: this.fb.group({
        idContactReference: [null, Validators.required],
      }),
      commerceSector: this.fb.group({
        idCommerceSector: [data?.idCommerceSector, Validators.required],
      }),
      dealState: this.fb.group({
        idDealState: [null, Validators.required],
      }),
      dealStateReason: this.fb.group({
        idDealStateReason: [null],
      }),
      uom: this.fb.group({
        idUom: [null, Validators.required],
        name: [null],
        description: [null],
      }),
      // dateDeal: [null, Validators.required],
      quantity: [null, Validators.required],
      revenue: [null, Validators.required],
      estimatedQuantityOrder: [null],
      estimatedQuantityYear: [null],
      estimatedValueYear: [null],
      note: [null],
      isOpportunity: [null],
      productName: [null, Validators.required],
      italianDescription: [null, Validators.required],
      englishDescription: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.deal) {
      if (this.deal.dealState.idDealState === DEAL_STATE_ID.Losed) {
        this.dealForm.get('dealState.idDealState').disable();
        this.showOpportunityFlag = true;
        this.showDealStateReason = true;
        // if (this.deal.isOpportunity) {
        //   this.showOpportunityFields = true;
        // }
      }
      if (this.deal.dealState.idDealState === DEAL_STATE_ID.OrderPlaced) {
        this.dealForm.get('dealState.idDealState').disable();
        this.showDealStateReason = true;
      }
      if (this.deal.dealDocuments?.length) {
        this.uploadedDocument =
          this.deal.dealDocuments[this.deal.dealDocuments.length - 1];
      }
      this.dealForm.patchValue({
        idDeal: this.deal?.idDeal,
        contactReference: {
          idContactReference: this.deal?.contactReference?.idContactReference,
        },
        commerceSector: {
          idCommerceSector: this.deal?.commerceSector?.idCommerceSector,
        },
        dealState: {
          idDealState: this.deal?.dealState?.idDealState,
        },
        dealStateReason: {
          idDealStateReason: this.deal?.dealStateReason?.idDealStateReason,
        },
        uom: {
          idUom: this.deal?.uom?.idUom,
        },
        dateDeal: this.deal?.dateDeal
          ? moment(this.deal.dateDeal, 'DD/MM/YYYY').toDate()
          : null,
        quantity: this.deal?.quantity,
        estimatedQuantityOrder: this.deal?.estimatedQuantityOrder,
        estimatedQuantityYear: this.deal?.estimatedQuantityYear,
        estimatedValueYear: this.deal?.estimatedValueYear,
        revenue:
          this.deal?.dealState?.idDealState === DEAL_STATE_ID.OrderPlaced
            ? this.deal?.revenue
            : this.deal?.offerValue,
        note: this.deal?.note,
        isOpportunity: this.deal?.isOpportunity,
        productName: this.deal?.productName,
        italianDescription: this.deal?.italianDescription,
        englishDescription: this.deal?.englishDescription,
      });
    }
    if (!this.deal?.idDeal) {
      if (this.contact.contactReference.length === 1) {
        this.dealForm
          .get('contactReference.idContactReference')
          .setValue(this.contact.contactReference[0].idContactReference);
        this.dealForm.get('contactReference.idContactReference').disable();
      }
    }
  }

  reinitializeDealFormByDealState(select?: MatSelectChange): void {
    this.resetDynamicFields();
    this.selectedDealState = select?.value;
    if (select?.value === DEAL_STATE_ID.Losed) {
      this.dealForm
        .get('dealStateReason.idDealStateReason')
        .setValidators(Validators.required);
      // this.dealForm
      //   .get('estimatedQuantityOrder')
      //   .setValidators(Validators.required);
      // this.dealForm
      //   .get('estimatedQuantityYear')
      //   .setValidators(Validators.required);
      // this.dealForm
      //   .get('estimatedValueYear')
      //   .setValidators(Validators.required);
      this.dealForm.updateValueAndValidity();
      this.dealForm.markAllAsTouched();
      this.showOpportunityFlag = true;
      this.showDealStateReason = true;
      // this.mustUploadDocument = true;
      // this.loadingFile = false;
      // this.firstStepAddDeal = false;
      this.filteredDealStateReasons = this.dealStateReasons.filter(reason => {
        return reason.idDealStateReason != 18
      })
    }
    if (select?.value !== DEAL_STATE_ID.Losed) {
      this.documentRequired = false;
      this.dealForm.get('dealStateReason.idDealStateReason').clearValidators();
      this.dealForm
        .get('dealStateReason.idDealStateReason')
        .updateValueAndValidity();
      // this.dealForm.get('productName').clearValidators();
      // this.dealForm.get('productName').updateValueAndValidity();
      // this.dealForm.get('italianDescription').clearValidators();
      // this.dealForm.get('italianDescription').updateValueAndValidity();
      // this.dealForm.get('englishDescription').clearValidators();
      // this.dealForm.get('englishDescription').updateValueAndValidity();
      this.showDealStateReason = false;
      this.showOpportunityFlag = false;
    }
    if (select?.value === DEAL_STATE_ID.OrderPlaced) {
      this.dealForm
        .get('dealStateReason.idDealStateReason')
        .setValidators(Validators.required);
      this.dealForm
        .get('dealStateReason.idDealStateReason')
        .updateValueAndValidity();
      this.dealForm.updateValueAndValidity(),
      this.dealForm.markAllAsTouched();
      // this.showOpportunityFields = false;
      this.showDealStateReason = true;
      // this.mustUploadDocument = true;
      // this.uploadingDealDocument = false;
      // this.firstStepAddDeal = false;
      this.filteredDealStateReasons = this.dealStateReasons.filter(reason => {
        return reason.idDealStateReason < 5 || reason.idDealStateReason > 8
      })
    }
    if (select?.value === DEAL_STATE_ID.Created) {
      this.dealForm.get('dealStateReason.idDealStateReason').clearValidators();
      this.dealForm
        .get('dealStateReason.idDealStateReason')
        .updateValueAndValidity();
      // this.dealForm.get('productName').clearValidators();
      // this.dealForm.get('productName').updateValueAndValidity();
      // this.dealForm.get('italianDescription').clearValidators();
      // this.dealForm.get('italianDescription').updateValueAndValidity();
      // this.dealForm.get('englishDescription').clearValidators();
      // this.dealForm.get('englishDescription').updateValueAndValidity();

      this.showDealStateReason = false;
      // this.showOpportunityFields = false;
      // this.mustUploadDocument = false;
    }
  }

  resetDynamicFields(): void {
    this.dealForm.get('isOpportunity').setValue(false);
    // this.dealForm.get('italianDescription').setValue(null);
    // this.dealForm.get('englishDescription').setValue(null);
    // this.dealForm.get('productName').setValue(null);
    this.dealForm.get('dealStateReason.idDealStateReason').setValue(null);
    this.dealForm.updateValueAndValidity();
    this.filteredDealStateReasons = this.dealStateReasons;
  }

  // checkOpportunityFlag(check: boolean): void {
  //   if (check) {
      // this.dealForm.get('productName').setValidators(Validators.required);
      // this.dealForm
      //   .get('italianDescription')
      //   .setValidators(Validators.required);
      // this.dealForm
      //   .get('englishDescription')
      //   .setValidators(Validators.required);
      // this.showOpportunityFields = true;
    // } else {
      // this.dealForm.get('productName').clearValidators();
      // this.dealForm.get('italianDescription').setValue(null);
      // this.dealForm.get('italianDescription').clearValidators();
      // this.dealForm.get('englishDescription').setValue(null);
      // this.dealForm.get('englishDescription').clearValidators();
      // this.showOpportunityFields = false;
    // }
    // this.dealForm.get('productName').updateValueAndValidity();
    // this.dealForm.get('italianDescription').updateValueAndValidity();
    // this.dealForm.get('englishDescription').updateValueAndValidity();
  // }

  uploadDealDocument(document: File): void {
    this.loadingFile = true;
    if (this.dealForm.get('idDeal').value) {
      if (this.uploadedDocument?.idDealDocument) {
        this.subscriptions.add(
          this.crmService
            .saveUpdateDealDocument(
              this.dealForm.get('idDeal').value,
              document,
              true,
              this.uploadedDocument.idDealDocument
            )
            .subscribe({
              next: (documentUploaded: any) => {
                this.uploadedDocument = documentUploaded;
              },
              complete: () => {
                this.completeAfterUploadFile();
                this.loadingFile = false;
              },
              error: () => {
                this.uploadError = true;
                this.showUploadError();
                this.loadingFile = false;
              },
            })
        );
      } else {
        this.subscriptions.add(
          this.crmService
            .saveUpdateDealDocument(this.deal.idDeal, document, true)
            .subscribe({
              next: (documentUploaded: any) => {
                this.uploadedDocument = documentUploaded;
              },
              complete: () => {
                this.completeAfterUploadFile();
                this.loadingFile = false;
              },
              error: () => {
                this.uploadError = true;
                this.showUploadError();
                this.loadingFile = false;
              },
            })
        );
      }
    }
    if (!this.dealForm.get('idDeal').value) {
      this.documentToUpload = document;
      this.loadingFile = false;
      this.documentRequired = false;
    }
  }

  completeAfterUploadFile(): void {
    this.dealForm.markAsDirty();
    this.dealForm.markAsTouched();
  }

  showUploadError(): void {
    this.common.showToast(
      this.translations.UnableToUploadDocument,
      ToastStatus.error,
      5000
    );
  }

  updateValueYear(deal: FormGroup): void {
    deal
      .get('estimatedValueYear')
      .setValue(
        (deal.get('revenue').value || 0) / 
        (deal.get('quantity').value || 0) *
        (deal.get('estimatedQuantityYear').value || 0)
      );
  }

  saveDeal(): void {
    const body: IDeal = this.dealForm.getRawValue();
    if (this.dealForm.valid && this.dealForm.dirty) {
      this.loading = true;
      this.dealForm.disable();
      if (
        body.dealState.idDealState === DEAL_STATE_ID.Created ||
        body.dealState.idDealState === DEAL_STATE_ID.OrderPlaced
      ) {
        this.documentRequired = false;
        this.subscriptions.add(
          this.crmService.saveDeal(this.contact.idContact, body).subscribe({
            next: (response: IDeal) => {
              this.dealJustSaved = response;
            },
            complete: () => {
              if (this.documentToUpload) {
                this.loadingFile = true;
                this.subscriptions.add(
                  this.crmService
                    .saveUpdateDealDocument(
                      this.dealJustSaved.idDeal,
                      this.documentToUpload
                    )
                    .subscribe({
                      complete: () => {
                        this.dealForm.enable();
                        this.loading = false;
                        this.loadingFile = false;
                        this.dialogRef.close(true);
                      },
                      error: () => {
                        this.showUploadError();
                        this.dealForm.enable();
                        this.loading = false;
                        this.loadingFile = false;
                      },
                    })
                );
              } else {
                this.dealForm.enable();
                this.loading = false;
                this.dialogRef.close(true);
              }
            },
            error: () => {
              this.dealForm.enable();
              this.loading = false;
            },
          })
        );
      } else {
        if (this.documentToUpload) {
          this.subscriptions.add(
            this.crmService.saveDeal(this.contact.idContact, body).subscribe({
              next: (response: IDeal) => {
                this.dealJustSaved = response;
              },
              complete: () => {
                this.loadingFile = true;
                this.subscriptions.add(
                  this.crmService
                    .saveUpdateDealDocument(
                      this.dealJustSaved.idDeal,
                      this.documentToUpload
                    )
                    .subscribe({
                      complete: () => {
                        this.dealForm.enable();
                        this.loading = false;
                        this.loadingFile = false;
                        this.dialogRef.close(true);
                      },
                      error: () => {
                        this.showUploadError();
                        this.dealForm.enable();
                        this.loading = false;
                        this.loadingFile = false;
                      },
                    })
                );
              },
              error: () => {
                this.dealForm.enable();
                this.loading = false;
              },
            })
          );
        } else {
          if (this.uploadedDocument) {
            this.subscriptions.add(
              this.crmService.saveDeal(this.contact.idContact, body).subscribe({
                complete: () => {
                  this.dealForm.enable();
                  this.loading = false;
                  this.dialogRef.close(true);
                },
                error: () => {
                  this.dealForm.enable();
                  this.loading = false;
                },
              })
            );
          } else {
            this.documentRequired = true;
            this.loading = false;
            this.dealForm.enable();
          }
        }
      }
    }
    if (this.dealForm.valid && !this.dealForm.dirty && !this.documentRequired) {
      this.dialogRef.close();
    }
  }

  downloadDealDocument(): void {
    this.subscriptions.add(
      this.crmService
        .downloadDealDocument(this.uploadedDocument.idDealDocument, true)
        .subscribe({
          error: () => {
            this.common.showToast(
              this.translations.ShowGeneralErrorToast,
              ToastStatus.error,
              5000
            );
          },
        })
    );
  }

  showUomManagementDialog(event: PointerEvent): void {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '900px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      managementType: 'uom',
      uom: this.unitsOfMeasure,
      idCustomer: this.contact.idCustomer,
    };
    const dialogRef = this.dialog.open(
      ChannelsManagementDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'refresh') {
        this.subscriptions.add(
          this.irFormService
            .getUnitsOfMeasure(false, false, this.contact.idCustomer)
            .subscribe({
              next: (uom: UnitsOfMeasure[]) => {
                this.unitsOfMeasure = uom;
              },
              complete: () => {
                this.unitsOfMeasureAreModified = true;
              },
            })
        );
      }
    });
  }

  checkWichContactReferencesToShow(): IContactBuyer[] {
    const references: IContactBuyer[] = [];
    if (!this.deal?.idDeal) {
      this.contact?.contactReference?.forEach((ref: IContactBuyer) => {
        if (!ref.deactivated) {
          references.push(ref);
        }
      });
    }
    if (this.deal?.idDeal) {
      this.contact?.contactReference?.forEach((ref: IContactBuyer) => {
        if (!ref.deactivated) {
          references.push(ref);
        }
        if (ref.deactivated) {
          if (
            ref.idContactReference ===
            this.deal.contactReference.idContactReference
          ) {
            references.push(ref);
          }
        }
      });
    }
    return references;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
