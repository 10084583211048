import { Component, OnInit, Inject, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IProductMatching, KeyMatching } from 'src/app/models/product-matching';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-product-matching-dialog',
  templateUrl: './product-matching-dialog.component.html',
  styleUrls: ['./product-matching-dialog.component.scss']
})
export class ProductMatchingDialogComponent implements OnInit {

  dataMatching: IProductMatching;
  productName: string;
  translations: any;
  productForm: FormGroup;
  isReadOnly: boolean;
  operationInProgress: boolean = false;

  // dialogProductMatchingEmitter: EventEmitter<IProductMatching> = new EventEmitter();
  dialogProductMatchingEmitter: EventEmitter<FormGroup> = new EventEmitter();
  deleteDialogProductMatchingEmitter: EventEmitter<IProductMatching> = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder
  ) {
    this.translations = data.translations;
    this.dataMatching = data.product;
    this.productForm = fb.group({
      idProduct : [this.dataMatching?.idProduct || null],
      idCommerceSector : [this.dataMatching?.idCommerceSector || null],
      idProductType : [this.dataMatching?.idProductType || null],
      productMatching: fb.array([])
    });
    this.productName = data.productName;
    this.isReadOnly = data.isReadOnly;
  }

  ngOnInit(): void {
    this.productMatching.clear();
    this.dataMatching?.productMatching?.forEach((keyMatching: KeyMatching) => {
      this.addProductMatching(keyMatching);
    });
    this.productMatching.markAsPristine();
    this.productMatching.markAsUntouched();
  }

  get productMatching(): FormArray {
    return this.productForm.get('productMatching') as FormArray;
  }

  addProductMatching(keyMatching?: KeyMatching): void {
    this.productMatching.push(this.fb.group({
      idProductMatching: [ keyMatching?.idProductMatching || null],
      wordKey: [
        {value: keyMatching?.wordKey, disabled: this.isReadOnly} || null, [Validators.required, Validators.maxLength(250)]
      ],
      englishWordKey: [
        {value: keyMatching?.englishWordKey, disabled: this.isReadOnly} || null, [Validators.required, Validators.maxLength(250)]
      ]
    }));
  }

  onSubmit(): void {
    if (this.productForm.valid) {
      // const body: IProductMatching = {
      //   ...this.productForm.getRawValue()
      // };
      this.dialogProductMatchingEmitter.emit(this.productForm);
      this.productForm.markAsPristine();
      this.productForm.markAsUntouched();
    }
  }

  removeProductMatching(i: number): void {
    const idProductMatching = this.productMatching.at(i).get('idProductMatching').value;
    if (idProductMatching) {
      this.deleteDialogProductMatchingEmitter.emit(idProductMatching);
    }
    this.productMatching.removeAt(i);
  }

}
