import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  currencyDefaultConfig,
  currencyDefaultConfigAllowNegative,
  ID_PROJECT_STATUS,
} from 'src/app/config';
import { Observable, Subscription } from 'rxjs';
import { Project } from 'src/app/models/project';
import {
  ICustomerStrategy,
  IMarketStrategy,
  IChannelStrategy,
} from 'src/app/models/strategy';
import { NgxCurrencyModule } from 'ngx-currency';
import {
  IChannelSpecification,
  IChannelsType,
} from 'src/app/models/channels-type';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastStatus } from 'src/app/models/utility';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChannelsManagementDialogComponent } from 'src/app/components/channels-management-dialog/channels-management-dialog.component';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { IPsStrategy } from 'src/app/models/project';
import { ICountries } from 'src/app/models/countries';
import { ItemFieldValueInArrayCannotBeSame } from 'src/app/utility/validators';

@Component({
  selector: 'app-ps-strategy',
  templateUrl: './ps-strategy.component.html',
  styleUrls: ['./ps-strategy.component.scss'],
})
export class PsStrategyComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  strategyForm: FormGroup;
  project: Project;
  countries: ICountries[];

  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;
  currencyDefaultConfigAllowNegative: NgxCurrencyModule =
    currencyDefaultConfigAllowNegative;

  channelsType: IChannelsType[];
  channelsSpecifications: IChannelSpecification[];
  idProjectStatus: any = ID_PROJECT_STATUS;

  initialMarketPriority: number = 1;
  initialChannelPrioriy: number = 1;

  startDataOnInit: any;

  @Input() translations: any;
  @Input() initialValues: Observable<Project>;
  @Input() isSupervisor: any;
  @Input() isIspDelivered: any;
  @Input() isReadOnly: any;
  @Input() isManagerAndSupervisorManagar: any;

  @Output() saveStrategyEmitter: EventEmitter<ICustomerStrategy> =
    new EventEmitter();
  @Output() rejectIspEmitter: EventEmitter<any> = new EventEmitter();
  @Output() approveIspEmitter: EventEmitter<any> = new EventEmitter();

  @ViewChild('channelsContainer') channelsContainer: ElementRef;

  constructor(
    private fb: FormBuilder,
    private el: ElementRef,
    private common: CommonService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private irFormService: IrFormService
  ) {
    this.strategyForm = this.fb.group({
      projectObjective: this.fb.group({
        idProjectObjective: [null],
        idCustomer: [null],
        idProduct: [null],
        idProject: [null],
        productName: [{ value: null, disabled: true }],
        idUser: [null],
        dateEnd: [null, [Validators.required]],
        revenue: [null, [Validators.required]],
      }),
      marketProjectObjective: this.fb.array([]),
      channelProjectObjective: this.fb.array(
        [],
        [Validators.required, ItemFieldValueInArrayCannotBeSame('priority')]
      ),
      idsMarketsSelected: [null, Validators.required],
      leadGeneration: [null],
      actionPlan: this.fb.array([]),
    });
  }

  get idProjectObjective(): number {
    return this.strategyForm.get('projectObjective').get('idProjectObjective')
      .value;
  }

  get idsMarketsSelected(): FormControl {
    return this.strategyForm.get('idsMarketsSelected') as FormControl;
  }

  get actionPlan(): FormArray {
    return this.strategyForm.get('actionPlan') as FormArray;
  }

  get productPlan(): FormGroup {
    return this.actionPlan.at(0) as FormGroup;
  }

  get distributionPlan(): FormGroup {
    return this.actionPlan.at(1) as FormGroup;
  }

  get priceSellingPlan(): FormGroup {
    return this.actionPlan.at(2) as FormGroup;
  }

  get communicationMktgLgPlan(): FormGroup {
    return this.actionPlan.at(3) as FormGroup;
  }

  get otherActionPlan(): FormGroup {
    return this.actionPlan.at(4) as FormGroup;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (project: Project) => {
          this.project = project;
          this.countries = [];
          if (project.countries) {
            project.countries.forEach((country) => {
              this.countries.push({
                idCountry: country?.idCountry,
                country: country?.country,
              });
            });
          }
          if (!this.channelsType?.length) {
            this.channelsType = project.channelsType;
          }
          if (!this.channelsSpecifications?.length) {
            this.channelsSpecifications = project.specificationsChannels;
          }
          this.initializeStrategy(project.psStrategy);
          if (this.isIspDelivered || this.isReadOnly) {
            this.strategyForm.disable();
          }
          this.startDataOnInit = this.strategyForm.getRawValue();
        },
      })
    );
  }

  initializeStrategy(strategy: IPsStrategy): void {
    this.strategyForm.reset();
    this.strategyForm.get('projectObjective').reset();
    this.marketProjectObjective.clear();
    this.channelProjectObjective.clear();
    this.idsMarketsSelected.setValue([]);
    if (strategy?.proposedStrategy?.projectObjective) {
      this.projectObjective.patchValue(
        strategy?.proposedStrategy?.projectObjective
      );
    }
    /** Patch product information from previous strategy */
    this.projectObjective
      .get('productName')
      .setValue(
        strategy.activeStrategy.projectObjectiveCustomDTO.projectObjective
          .productName
      );
    this.projectObjective
      .get('idProduct')
      .setValue(
        strategy.activeStrategy.projectObjectiveCustomDTO.projectObjective
          .idProduct
      );
    /** Patch selected markets and sort by priority */
    strategy.proposedStrategy.marketProjectObjective.forEach(
      (market: IMarketStrategy) => {
        this.addMarketForStrategy(market);
      }
    );
    this.idsMarketsSelected.setValue(
      this.marketProjectObjective
        .getRawValue()
        .map((market: IMarketStrategy): number => {
          return market.idCountry;
        })
    );
    this.marketProjectObjective.controls.sort(
      (a: FormGroup, b: FormGroup) =>
        a.controls.priority.value - b.controls.priority.value
    );
    this.initialMarketPriority = 1;
    /** Patch selected channels and sorty by priority */
    const filteredChannels: any[] = [];
    strategy?.proposedStrategy?.channelProjectObjective?.forEach(
      (channel: IChannelStrategy) => {
        filteredChannels.push(channel);
      }
    );
    if (!strategy?.proposedStrategy?.channelProjectObjective?.length) {
      strategy?.proposedStrategy?.distributionChannel?.forEach(
        (channel: IChannelStrategy) => {
          filteredChannels.push(channel);
        }
      );
    } else {
      strategy?.proposedStrategy?.distributionChannel?.forEach(
        (distribChannel: IChannelStrategy) => {
          if (
            !filteredChannels.some((item: any) => {
              return (
                distribChannel.idChannelType === item.idChannelType &&
                distribChannel.idSpecificationChannelType ===
                  item.idSpecificationChannelType
              );
            })
          ) {
            filteredChannels.push(distribChannel);
          }
        }
      );
    }
    const filteredMappedChannels: any[] = filteredChannels?.map(
      (channel: IChannelStrategy): IChannelStrategy => ({
        idProjectObjective: channel?.idProjectObjective,
        idChannelProjectObjective: channel?.idChannelProjectObjective,
        idChannelType: channel?.idChannelType,
        idSpecificationChannelType: channel?.idSpecificationChannelType,
        levelOfInterest: channel?.levelOfInterest,
        priority: channel?.priority,
        selectedForStrategy: channel?.idChannelProjectObjective ? true : false,
      })
    );
    const sortedChannels = [];
    filteredMappedChannels?.forEach((channel: IChannelStrategy) => {
      if (channel.selectedForStrategy) {
        this.addChannelDistributionStrategy(channel, false);
      } else {
        sortedChannels.push(channel);
      }
    });
    sortedChannels
      .sort((a: IChannelStrategy, b: IChannelStrategy) => {
        return b.levelOfInterest - a.levelOfInterest;
      })
      .forEach((channel: IChannelStrategy) => {
        this.addChannelDistributionStrategy(channel, false);
      });
    this.initialChannelPrioriy = 1;
    /** Patch action plan */    
    this.actionPlan.clear();
    let actionPlan = strategy?.proposedStrategy?.actionPlan;
    for (let i = 0; i < 5; i++) {
      this.actionPlan.push(
        this.fb.group({
          idCustomerStrategy: [actionPlan[i]?.idCustomerStrategy || null],
          idCustomer: [this.project.customer.idCustomer || null],
          idStrategyType: [i + 1],
          actionLm: [actionPlan[i]?.actionLm || this.translations.NoActionExpected],
          actionCustomer: [actionPlan[i]?.actionCustomer || this.translations.NoActionExpected],
        })
      );
    }
    /** Pristine form */
    this.strategyForm.markAsPristine();
    this.strategyForm.markAsUntouched();
  }

  checkExistingIdMarketProjectObjective(country: any): number {
    let id: number = null;
    this.project.psStrategy.proposedStrategy.marketProjectObjective.forEach(
      (market) => {
        if (country.idCountry === market.idCountry) {
          id = market.idMarketProjectObjective;
        }
      }
    );
    return id;
  }

  checkExistingPriority(country: any): number {
    let priority: number = null;
    this.project.psStrategy.proposedStrategy.marketProjectObjective.forEach(
      (market) => {
        if (country.idCountry === market.idCountry) {
          priority = market.priority;
        }
      }
    );
    return priority;
  }

  saveStrategy(): void {
    if (this.strategyForm.valid && this.strategyForm.dirty) {
      if (
        this.channelProjectObjective.getRawValue().some((item: any) => {
          return item.selectedForStrategy;
        })
      ) {
        // this.actionPlan.push(this.strategyProductPlan);
        // this.actionPlan.push(this.strategyDistributionPlan);
        // this.actionPlan.push(this.strategyPriceSellingPlan);
        // this.actionPlan.push(this.strategyCommunicationMktgLgPlan);
        // this.actionPlan.push(this.strategyOtherActionPlan);
        const channelProjObj: IChannelStrategy[] = [];
        this.channelProjectObjective
          .getRawValue()
          .forEach((channel: IChannelStrategy) => {
            if (channel?.selectedForStrategy) {
              delete channel?.selectedForStrategy;
              delete channel?.isCustom;
              channelProjObj.push(channel);
            }
          });
        const body: any = {
          projectObjective: this.projectObjective.getRawValue(),
          marketProjectObjective: this.marketProjectObjective.getRawValue(),
          channelProjectObjective: channelProjObj,
          actionPlan: this.actionPlan.getRawValue(),
        };
        this.saveStrategyEmitter.emit(body);
        this.strategyForm.markAsPristine();
        this.strategyForm.markAsUntouched();
      } else {
        this.common.showToast(
          this.translations.SelectAtLeastOneChannel,
          ToastStatus.warning,
          3000
        );
      }
    } else {
      this.strategyForm.markAllAsTouched();
      // this.activeStrategyForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
    }
  }

  saveAndSendStrategy(): void {
    if (this.strategyForm.valid) {
      if (this._checkStrategyForm()) {
        // this.actionPlan.push(this.strategyProductPlan);
        // this.actionPlan.push(this.strategyDistributionPlan);
        // this.actionPlan.push(this.strategyPriceSellingPlan);
        // this.actionPlan.push(this.strategyCommunicationMktgLgPlan);
        // this.actionPlan.push(this.strategyOtherActionPlan);
        const body: any = {
          sendToApprove: 1,
          projectObjective: this.projectObjective.getRawValue(),
          marketProjectObjective: this.marketProjectObjective.getRawValue(),
          channelProjectObjective: this.channelProjectObjective
            .getRawValue()
            .concat(this.customChannelDistributionStrategy.getRawValue()),
          actionPlan: this.actionPlan.getRawValue(),
        };
        this.saveStrategyEmitter.emit(body);
        this.strategyForm.markAsPristine();
        this.strategyForm.markAsUntouched();
      }
    } else {
      this.strategyForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
    }
  }

  get marketProjectObjective(): FormArray {
    return this.strategyForm.get('marketProjectObjective') as FormArray;
  }

  get projectObjective(): FormGroup {
    return this.strategyForm.get('projectObjective') as FormGroup;
  }

  addMarketForStrategy(market?: any): void {
    this.marketProjectObjective.push(
      this.fb.group({
        idMarketProjectObjective: [market?.idMarketProjectObjective || null],
        idCountry: [market?.idCountry || market?.value || null],
        country: market?.country,
        idProjectObjective: [this.idProjectObjective || null],
        priority: [
          market?.priority || this.initialMarketPriority++,
          Validators.required,
        ],
      })
    );
  }

  addActiveMarketToProposed(market?: any): void {
    this.marketProjectObjective.push(
      this.fb.group({
        idMarketProjectObjective: [null],
        idCountry: [market?.idCountry || market?.value || null],
        idProjectObjective: [null],
        priority: [market?.priority || null],
      })
    );
  }

  removeMarketForStrategy(market?: any): void {
    let marketIdProjectObjective;
    let marketIndex;
    this.marketProjectObjective.value.forEach((element, i) => {
      if (element.idCountry === market?.value) {
        marketIdProjectObjective = element.idMarketProjectObjective;
        marketIndex = i;
      }
    });
    if (marketIdProjectObjective) {
      // this.deleteMarketProjectObjectiveEmitter.emit(marketIdProjectObjective);
    }
    this.marketProjectObjective.removeAt(marketIndex);
  }

  handleMarketStrategy(option): void {
    if (option._selected) {
      this.addMarketForStrategy(option);
    }
    if (!option._selected) {
      this.removeMarketForStrategy(option);
    }
  }

  getCountryName(idCountry: number): string {
    return this.countries.find((country) => country.idCountry === idCountry)
      .country;
  }

  get channelProjectObjective(): FormArray {
    return this.strategyForm.get('channelProjectObjective') as FormArray;
  }

  get channelDistributionStrategy(): FormArray {
    return this.strategyForm.get('channelDistributionStrategy') as FormArray;
  }

  get customChannelDistributionStrategy(): FormArray {
    return this.strategyForm.get(
      'customChannelDistributionStrategy'
    ) as FormArray;
  }

  addInitialChannelStrategy(channel?: any): void {
    this.channelProjectObjective.push(
      this.fb.group({
        idChannelProjectObjective: [channel?.idChannelProjectObjective || null],
        idChannelType: [channel?.idChannelType || null, [Validators.required]],
        idProjectObjective: [this.idProjectObjective || null],
        idSpecificationChannelType: [
          channel?.idSpecificationChannelType || null,
          [Validators.required],
        ],
        levelOfInterest: [channel?.levelOfInterest || null],
        priority: [channel?.priority || null, [Validators.required]],
        // channelDescription: [
        //   channel?.channelDescription || null,
        //   [Validators.required],
        // ],
        // channelSpecification: [
        //   channel?.channelSpecification || null,
        //   [Validators.required],
        // ],
      })
    );
  }

  addActiveChannelToProposed(channel?: any): void {
    this.channelProjectObjective.push(
      this.fb.group({
        idChannelProjectObjective: [null],
        idChannelType: [channel?.idChannelType || null, [Validators.required]],
        idProjectObjective: [null],
        idSpecificationChannelType: [
          channel?.idSpecificationChannelType || null,
          [Validators.required],
        ],
        levelOfInterest: [channel?.levelOfInterest || null],
        priority: [channel?.priority || null, [Validators.required]],
        // channelDescription: [
        //   channel?.channelDescription || null,
        //   [Validators.required],
        // ],
        // channelSpecification: [
        //   channel?.channelSpecification || null,
        //   [Validators.required],
        // ],
      })
    );
  }

  addChannelDistributionStrategy(
    channel?: IChannelStrategy,
    isCustom?: boolean,
    selected?: boolean
  ): void {
    this.channelProjectObjective.push(
      this.fb.group({
        idChannelProjectObjective: [channel?.idChannelProjectObjective || null],
        idChannelType: [
          {
            value: channel?.idChannelType || null,
            disabled: this.isReadOnly || !isCustom ? true : false,
          },
          [Validators.required],
        ],
        idSpecificationChannelType: [
          {
            value: channel?.idSpecificationChannelType || null,
            disabled:
              this.isReadOnly || !isCustom || !channel?.idChannelType
                ? true
                : false,
          },
        ],
        idProjectObjective: [this.idProjectObjective || null],
        selectedForStrategy: [
          {
            value:
              channel?.selectedForStrategy ||
              (isCustom || selected ? true : false),
            disabled: this.isReadOnly || isCustom,
          },
        ],
        levelOfInterest: [
          {
            value: channel?.levelOfInterest || null,
            disabled: this.isReadOnly || !isCustom,
          }, [Validators.required]
        ],
        isCustom: [{ value: isCustom, disabled: isCustom }],
        priority: [
          channel?.idChannelProjectObjective ? channel?.priority : null,
          channel?.selectedForStrategy || isCustom
            ? [Validators.required]
            : null,
        ],
      })
    );
  }

  addCustomChannelDistributionStrategy(): void {
    this.customChannelDistributionStrategy.push(
      this.fb.group({
        idChannelType: [null, [Validators.required]],
        idProjectObjective: [this.idProjectObjective || null],
        idSpecificationChannelType: [
          { value: null, disabled: true },
          [Validators.required],
        ],
        levelOfInterest: [null, [Validators.required]],
        priority: [
          null,
          [Validators.required, ItemFieldValueInArrayCannotBeSame],
        ],
      })
    );
  }

  removeChannel(channelIndex?: any): void {
    this.channelProjectObjective.removeAt(channelIndex);
  }

  isChannelChecked(channel?: any): boolean {
    let isChecked: boolean = false;
    for (const i in this.channelProjectObjective.getRawValue()) {
      if (
        channel?.idSpecificationChannelType ===
          this.channelProjectObjective.getRawValue()[i]
            .idSpecificationChannelType &&
        channel?.idChannelType ===
          this.channelProjectObjective.getRawValue()[i].idChannelType
      ) {
        isChecked = true;
      }
    }
    return isChecked;
  }

  handleChannelForStrategy(
    event?: boolean,
    channel?: any,
    index?: number
  ): void {
    if (event) {
      this.channelProjectObjective.push(
        this.fb.group({
          idChannelType: [channel?.idChannelType],
          idProjectObjective: [this.idProjectObjective],
          idSpecificationChannelType: [channel?.idSpecificationChannelType],
          levelOfInterest: [channel?.levelOfInterest],
          priority: [channel?.priority, [Validators.required]],
          // channelDescription: [channel?.channelDescription],
          // channelSpecification: [channel?.channelSpecification],
        })
      );
    } else {
      this.channelProjectObjective.value.forEach((element, i) => {
        if (
          channel?.idSpecificationChannelType ===
            element.idSpecificationChannelType &&
          channel?.idChannelType === element.idChannelType
        ) {
          this.channelProjectObjective.removeAt(i);
        }
      });
    }
  }

  addPriorityToSelectedChannel(channel: any): void {
    this.channelProjectObjective.getRawValue().forEach((element, i) => {
      if (
        channel?.idSpecificationChannelType ===
          element.idSpecificationChannelType &&
        channel?.idChannelType === element.idChannelType
      ) {
        this.channelProjectObjective
          .at(i)
          .get('priority')
          .setValue(channel.priority);
      }
    });
  }

  // checkChannelsPriorityDuplicates(): void {
  //   let priorities: number[] = [];
  //   this.channelDistributionStrategy.getRawValue().forEach((item) => {
  //     priorities.push(item.priority);
  //   });
  //   this.customChannelDistributionStrategy.getRawValue().forEach((item) => {
  //     priorities.push(item.priority);
  //   });
  //   let isDupe: boolean = false;
  //   if (priorities.length > 1) {
  //     for (let i = 0; i < priorities.length; i++) {
  //       if (priorities[i]) {
  //         for (let j = 0; j < priorities.length; j++) {
  //           if (priorities[j]) {
  //             if (i < this.channelDistributionStrategy.getRawValue().length) {
  //               if (i !== j) {
  //                 if (priorities[i] === priorities[j]) {
  //                   this.channelDistributionStrategy
  //                     .at(i)
  //                     .get('priority')
  //                     .setErrors({ incorrect: true });
  //                   this.channelDistributionStrategy
  //                     .at(i)
  //                     .get('priority')
  //                     .markAsTouched();
  //                   isDupe = true;
  //                 }
  //               }
  //             } else {
  //               if (i !== j) {
  //                 if (priorities[i] === priorities[j]) {
  //                   this.customChannelDistributionStrategy
  //                     .at(
  //                       i -
  //                         this.channelDistributionStrategy.getRawValue().length
  //                     )
  //                     .get('priority')
  //                     .setErrors({ incorrect: true });
  //                   this.customChannelDistributionStrategy
  //                     .at(
  //                       i -
  //                         this.channelDistributionStrategy.getRawValue().length
  //                     )
  //                     .get('priority')
  //                     .markAsTouched();
  //                   isDupe = true;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //       if (!isDupe) {
  //         if (i < this.channelDistributionStrategy.getRawValue().length) {
  //           this.channelDistributionStrategy
  //             .at(i)
  //             .get('priority')
  //             .setErrors(null);
  //         } else {
  //           this.customChannelDistributionStrategy
  //             .at(i - this.channelDistributionStrategy.getRawValue().length)
  //             .get('priority')
  //             .setErrors(null);
  //         }
  //       }
  //       isDupe = false;
  //     }
  //   }
  // }

  toggleChannelPriority(isChecked: boolean, channel: AbstractControl) {
    if (!isChecked) {
      channel.get('priority').setValue(null);
      channel.get('priority').setValidators(null);
      channel.get('priority').updateValueAndValidity();
      // this.checkChannelsPriorityDuplicates();
    } else {
      channel.get('priority').setValidators(Validators.required);
      channel.get('priority').updateValueAndValidity();
    }
  }

  // handleChannelTypeSelect(
  //   channelItem: FormGroup,
  //   channelTypeOption: MatOption
  // ): void {
  //   if (channelItem.get('idChannelType').value === 1) {
  //     channelItem.get('channelDescription').setValue('');
  //     channelItem.get('channelDescription').updateValueAndValidity();
  //     channelItem.get('channelSpecification').setValue('');
  //     channelItem.get('channelSpecification').updateValueAndValidity();
  //   } else {
  //     channelItem
  //       .get('channelDescription')
  //       .setValue(channelTypeOption.viewValue);
  //     channelItem.get('channelDescription').updateValueAndValidity();
  //     channelItem
  //       .get('channelSpecification')
  //       .setValue(channelTypeOption.viewValue);
  //     channelItem.get('channelSpecification').updateValueAndValidity();
  //   }
  // }

  // handleChannelDescription(
  //   channelItem: FormGroup,
  //   channelDescription: MatInput
  // ): void {
  //   channelItem.get('channelSpecification').setValue(channelDescription.value);
  //   channelItem.get('channelSpecification').updateValueAndValidity();
  // }

  // initializeActionPlan(actionPlan: IActionPlan[], idCustomer: number): void {
  //   this.strategyProductPlan.get('idCustomer').setValue(idCustomer);
  //   this.strategyProductPlan.get('idStrategyType').setValue(1);
  //   this.strategyDistributionPlan.get('idCustomer').setValue(idCustomer);
  //   this.strategyDistributionPlan.get('idStrategyType').setValue(2);
  //   this.strategyPriceSellingPlan.get('idCustomer').setValue(idCustomer);
  //   this.strategyPriceSellingPlan.get('idStrategyType').setValue(3);
  //   this.strategyCommunicationMktgLgPlan.get('idCustomer').setValue(idCustomer);
  //   this.strategyCommunicationMktgLgPlan.get('idStrategyType').setValue(4);
  //   this.strategyOtherActionPlan.get('idCustomer').setValue(idCustomer);
  //   this.strategyOtherActionPlan.get('idStrategyType').setValue(5);
  //   actionPlan.forEach((plan) => {
  //     if (plan.idStrategyType === 1) {
  //       this.strategyProductPlan
  //         .get('idCustomerStrategy')
  //         .setValue(plan.idCustomerStrategy);
  //       this.strategyProductPlan.get('actionLm').setValue(plan.actionLm);
  //       this.strategyProductPlan
  //         .get('actionCustomer')
  //         .setValue(plan.actionCustomer);
  //     }
  //     if (plan.idStrategyType === 2) {
  //       this.strategyDistributionPlan
  //         .get('idCustomerStrategy')
  //         .setValue(plan.idCustomerStrategy);
  //       this.strategyDistributionPlan.get('actionLm').setValue(plan.actionLm);
  //       this.strategyDistributionPlan
  //         .get('actionCustomer')
  //         .setValue(plan.actionCustomer);
  //     }
  //     if (plan.idStrategyType === 3) {
  //       this.strategyPriceSellingPlan
  //         .get('idCustomerStrategy')
  //         .setValue(plan.idCustomerStrategy);
  //       this.strategyPriceSellingPlan.get('actionLm').setValue(plan.actionLm);
  //       this.strategyPriceSellingPlan
  //         .get('actionCustomer')
  //         .setValue(plan.actionCustomer);
  //     }
  //     if (plan.idStrategyType === 4) {
  //       this.strategyCommunicationMktgLgPlan
  //         .get('idCustomerStrategy')
  //         .setValue(plan.idCustomerStrategy);
  //       this.strategyCommunicationMktgLgPlan
  //         .get('actionLm')
  //         .setValue(plan.actionLm);
  //       this.strategyCommunicationMktgLgPlan
  //         .get('actionCustomer')
  //         .setValue(plan.actionCustomer);
  //     }
  //     if (plan.idStrategyType === 5) {
  //       this.strategyOtherActionPlan
  //         .get('idCustomerStrategy')
  //         .setValue(plan.idCustomerStrategy);
  //       this.strategyOtherActionPlan.get('actionLm').setValue(plan.actionLm);
  //       this.strategyOtherActionPlan
  //         .get('actionCustomer')
  //         .setValue(plan.actionCustomer);
  //     }
  //   });
  //   this.strategyProductPlan.updateValueAndValidity();
  //   this.strategyDistributionPlan.updateValueAndValidity();
  //   this.strategyPriceSellingPlan.updateValueAndValidity();
  //   this.strategyCommunicationMktgLgPlan.updateValueAndValidity();
  //   this.strategyOtherActionPlan.updateValueAndValidity();
  // }

  handleTrend(trend: any): string {
    let trendString: string = 'trending_flat';
    if (trend && trend > 0) {
      trendString = 'trending_up';
    }
    if (trend && trend < 0) {
      trendString = 'trending_down';
    }
    return trendString;
  }

  handleTrendClass(trend: any): string {
    let trendClass: string = 'trend-icon-no-color';
    if (trend && trend > 0) {
      trendClass = 'trend-icon-green';
    }
    if (trend && trend < 0) {
      trendClass = 'trend-icon-red';
    }
    return trendClass;
  }

  // handleProposedStrategyClass(): string {
  //   if (this.project?.psStrategy?.proposedStrategy?.projectObjective) {
  //     return 'col-12';
  //   }
  //   return 'col-8';
  // }

  isEmptyProposedStrategy(): boolean {
    if (this.project?.psStrategy?.proposedStrategy?.projectObjective) {
      return false;
    }
    return true;
  }

  setMarketsAndChannelsFromActiveStrategy(): void {
    this.project.psStrategy.activeStrategy.projectObjectiveCustomDTO.marketProjectObjective.forEach(
      (market1) => {
        if (
          !this.marketProjectObjective
            .getRawValue()
            .some((market2: IMarketStrategy) => {
              return market2.idCountry === market1.idCountry;
            })
        ) {
          this.addMarketForStrategy({
            ...market1,
            idMarketProjectObjective: null,
          });
        }
      }
    );
    this.idsMarketsSelected.setValue(
      this.marketProjectObjective
        .getRawValue()
        .map((market: IMarketStrategy): number => {
          return market.idCountry;
        })
    );
    this.project.psStrategy.activeStrategy.projectObjectiveCustomDTO.channelProjectObjective.forEach(
      (channel1) => {
        this.channelProjectObjective.controls.forEach((channel2: FormGroup) => {
          if (
            channel2.controls.idChannelType.value === channel1.idChannelType &&
            channel2.controls.idSpecificationChannelType.value ===
              channel1.idSpecificationChannelType
          ) {
            channel2.controls.selectedForStrategy.setValue(true);
            channel2.controls.priority.setValue(channel1.priority);
            this.toggleChannelPriority(true, channel2);
          }
        });
      }
    );
    this.channelProjectObjective.getRawValue().forEach((element, i) => {
      this.channelProjectObjective
        .at(i)
        .get('priority')
        .setValidators(Validators.required);
    });
    this.marketProjectObjective.updateValueAndValidity();
    this.idsMarketsSelected.updateValueAndValidity();
    this.channelProjectObjective.updateValueAndValidity();
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.idCountry === c2.idCountry : false;
  }

  private _checkStrategyForm(): boolean {
    if (
      this.channelProjectObjective
        .getRawValue()
        .concat(this.customChannelDistributionStrategy.getRawValue()).length ===
      0
    ) {
      this.common.showToast(
        this.translations.StrategyChannelError,
        ToastStatus.warning,
        3000
      );
      return false;
    }
    return true;
  }

  private async scrollToFirstInvalidControl() {
    let invalidControl: HTMLElement;
    setTimeout(() => {
      invalidControl = this.el.nativeElement.querySelector(
        '.mat-form-field-invalid'
      );
      if (invalidControl) {
        invalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else if (this.channelProjectObjective.invalid) {
        this.channelsContainer.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    });
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(
        this.strategyForm.getRawValue(),
        this.irFormService.replacer
      )
    );
  }

  checkInvalidForm(): boolean {
    this.strategyForm.markAllAsTouched();
    return (
      this.strategyForm?.invalid ||
      !this.channelProjectObjective
        .getRawValue()
        .some((channel: IChannelStrategy) => {
          return channel.selectedForStrategy;
        })
    );
  }

  rejectIsp(): void {
    this.rejectIspEmitter.emit();
  }

  approveIsp(): void {
    if (this.isManagerAndSupervisorManagar) {
      this.saveAndSendStrategy();
    } else {
      this.approveIspEmitter.emit();
    }
  }

  showChannelsManagementDialog(
    event: PointerEvent,
    type: string,
    idChannelType?: number
  ): void {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '900px';
    dialogConfig.panelClass = 'custom-dialog-container';
    if (type === 'channel') {
      dialogConfig.data = {
        managementType: 'channel',
        channels: this.channelsType,
        idCustomer: this.project.customer.idCustomer,
        translations: this.translations,
      };
    } else {
      dialogConfig.data = {
        managementType: 'channelSpecification',
        channelsSpecifications: this.channelsSpecifications.filter(
          (spec: IChannelSpecification) => {
            return spec.idChannelType === idChannelType;
          }
        ),
        idCustomer: this.project.customer.idCustomer,
        translations: this.translations,
        idChannelType,
      };
    }
    const dialogRef = this.dialog.open(
      ChannelsManagementDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'refresh') {
        if (type === 'channel') {
          this.subscriptions.add(
            this.irFormService
              .getChannelsTypeByIdCustomer(
                this.project.customer.idCustomer,
                false
              )
              .subscribe({
                next: (channels: IChannelsType[]) => {
                  this.channelsType = channels;
                  this.customChannelDistributionStrategy
                    .getRawValue()
                    .forEach((channel: any, i: number) => {
                      if (
                        !channels.some((ch: any) => {
                          return ch.idChannelType === channel.idChannelType;
                        })
                      ) {
                        this.customChannelDistributionStrategy
                          .at(i)
                          .get('idChannelType')
                          .setValue(null);
                        this.activateSpecificationsSelect(
                          new MatSelectChange(null, null),
                          i
                        );
                      }
                    });
                },
              })
          );
        } else {
          this.subscriptions.add(
            this.common
              .getSpecificationChannels(this.project.customer.idCustomer, false)
              .subscribe({
                next: (channelsSpec: IChannelSpecification[]) => {
                  this.channelsSpecifications = channelsSpec;
                  this.customChannelDistributionStrategy
                    .getRawValue()
                    .forEach((channel: any, i: number) => {
                      if (
                        !channelsSpec.some((ch: any) => {
                          return (
                            ch.idSpecificationChannelType ===
                            channel.idSpecificationChannelType
                          );
                        })
                      ) {
                        this.customChannelDistributionStrategy
                          .at(i)
                          .get('idSpecificationChannelType')
                          .setValue(null);
                      }
                    });
                },
              })
          );
        }
      }
    });
  }

  activateSpecificationsSelect(change: MatSelectChange, index: number): void {
    if (change.value) {
      this.channelProjectObjective
        .at(index)
        .get('idSpecificationChannelType')
        .enable();
    } else {
      this.channelProjectObjective
        .at(index)
        .get('idSpecificationChannelType')
        .disable();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
