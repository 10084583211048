<form [formGroup]="channelsForm" (ngSubmit)="submit()">
  <div class="header">
    <div class="title" [ngSwitch]="managementType">
      <span *ngSwitchCase="'channel'">
        {{ "CRM.ChannelsManagement" | translate }}
      </span>
      <span *ngSwitchCase="'channelSpecification'">
        {{ "CRM.ChannelsSpecificationsManagement" | translate }}
      </span>
      <span *ngSwitchCase="'uom'">
        {{ "CRM.UomManagement" | translate }}
      </span>
    </div>
    <button
      class="close-dialog"
      type="button"
      [mat-dialog-close]="refresh ? 'refresh' : null"
      mat-icon-button
      [disabled]="loading"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div
    *ngIf="managementType === 'channel'"
    class="content"
    formArrayName="channels"
  >
    <div class="row">
      <div
        *ngFor="let channel of channels.controls; let i = index"
        class="col-4"
        [formGroupName]="i"
      >
        <div class="row">
          <mat-form-field
            appearance="standard"
            class="col show-prefix-when-hover"
          >
            <mat-label for="description">
              {{ "CRM.Channel" | translate }}
            </mat-label>
            <svg-icon
              *ngIf="channel.get('idCustomer').value"
              matPrefix
              name="edit_icon"
              class="w16 h16"
            ></svg-icon>
            <input
              matInput
              type="text"
              formControlName="description"
              name="description"
            />
            <button
              type="button"
              class="only-icon-button error"
              *ngIf="
                channel.get('idChannelType').value &&
                channel.get('idCustomer').value
              "
              matSuffix
              (click)="deleteChannel(channel.getRawValue(), i)"
            >
              <svg-icon name="trash_icon" class="w16 h16"></svg-icon>
            </button>
            <button
              type="button"
              *ngIf="!channel.get('idChannelType').value"
              matSuffix
              class="only-icon-button"
              (click)="removeChannel(i)"
            >
              <mat-icon>clear</mat-icon>
            </button>
            <mat-error>
              {{
                channel.get("description").errors
                  | getErrorMessage: translations.Channel
              }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngIf="!channels.getRawValue().length" class="no-data">
      {{ "CHANNELS_MANAGEMENT.NoChannelsAvailable" | translate }}
    </div>
  </div>
  <div
    *ngIf="managementType === 'channelSpecification'"
    class="content"
    formArrayName="channelsSpecifications"
  >
    <div class="row">
      <div
        *ngFor="let spec of channelsSpec.controls; let i = index"
        class="col-4"
        [formGroupName]="i"
      >
        <div class="row">
          <mat-form-field
            appearance="standard"
            class="col show-prefix-when-hover"
          >
            <mat-label for="description">
              {{ "CRM.ChannelSpecification" | translate }}
            </mat-label>
            <svg-icon
              *ngIf="spec.get('idChannelType').value"
              matPrefix
              name="edit_icon"
              class="w16 h16"
            ></svg-icon>
            <input
              matInput
              type="text"
              formControlName="description"
              name="description"
            />
            <button
              type="button"
              class="only-icon-button error"
              *ngIf="spec.get('idSpecificationChannelType').value"
              matSuffix
              (click)="deleteChannelSpecification(spec.getRawValue(), i)"
            >
              <svg-icon name="trash_icon" class="w16 h16"></svg-icon>
            </button>
            <button
              type="button"
              *ngIf="!spec.get('idSpecificationChannelType').value"
              matSuffix
              class="only-icon-button"
              (click)="removeChannelSpec(i)"
            >
              <mat-icon>clear</mat-icon>
            </button>
            <mat-error>
              {{
                spec.get("description").errors
                  | getErrorMessage: translations.ChannelSpecification
              }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngIf="!channelsSpec.getRawValue().length" class="no-data">
      {{ "CHANNELS_MANAGEMENT.NoChannelsSpecificationsAvailable" | translate }}
    </div>
  </div>
  <div
    *ngIf="managementType === 'uom'"
    class="content"
    formArrayName="unitsOfMeasure"
  >
    <div class="row align-items-center">
      <div
        *ngFor="let uom of uoms.controls; let i = index"
        class="col-6 mb-10"
        [formGroupName]="i"
      >
        <div class="row">
          <mat-form-field
            appearance="standard"
            class="col-4 show-prefix-when-hover"
          >
            <mat-label for="description">
              {{ "COMMON.UnitOfMeasure" | translate }}
            </mat-label>
            <svg-icon
              *ngIf="uom.get('idCustomer').value"
              matPrefix
              name="edit_icon"
              class="w16 h16"
            ></svg-icon>
            <input matInput type="text" formControlName="name" name="name" />
            <mat-error>
              {{ uom.get("name").errors | getErrorMessage: translations.Name }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="standard"
            class="col-7 show-prefix-when-hover"
          >
            <mat-label for="description">
              {{ "IR_FORM.Description" | translate }}
            </mat-label>
            <svg-icon
              *ngIf="uom.get('idCustomer').value"
              matPrefix
              name="edit_icon"
              class="w16 h16"
            ></svg-icon>
            <input
              matInput
              type="text"
              formControlName="description"
              name="description"
            />
            <mat-error>
              {{
                uom.get("description").errors
                  | getErrorMessage: translations.Description
              }}
            </mat-error>
          </mat-form-field>
          <button
            type="button"
            *ngIf="!uom.get('idUom').value"
            class="only-icon-button"
            (click)="removeUom(i)"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!uoms.getRawValue().length" class="no-data">
      {{ "CHANNELS_MANAGEMENT.NoUOMAvailable" | translate }}
    </div>
  </div>
  <div class="d-flex justify-content-center mb-20">
    <button
      *ngIf="managementType === 'channel'"
      type="button"
      class="rounded-button primary-border-button mr-30"
      (click)="addChannel()"
      [disabled]="loading"
    >
      {{ "CRM.AddChannel" | translate }}
    </button>
    <button
      *ngIf="managementType === 'channelSpecification'"
      type="button"
      class="rounded-button primary-border-button mr-30"
      (click)="addChannelSpec()"
      [disabled]="loading"
    >
      {{ "CRM.AddChannelSpecification" | translate }}
    </button>
    <button
      *ngIf="managementType === 'uom'"
      type="button"
      class="rounded-button primary-border-button mr-30"
      (click)="addUom()"
      [disabled]="loading"
    >
      {{ "CHANNELS_MANAGEMENT.AddUom" | translate }}
    </button>
    <button
      class="rounded-button primary-bg-button"
      type="submit"
      [disabled]="loading"
    >
      <span>{{ "IR_FORM.Save" | translate }}</span>
    </button>
  </div>
</form>
