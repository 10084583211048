import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  Form,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { PsSwot, SwItem, OtItem } from 'src/app/models/swot';
import { Project, ProjectDTO } from 'src/app/models/project';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { ICompetitorPositioning } from 'src/app/models/competitor-positioning';
import { NgxCurrencyModule } from 'ngx-currency';
import { currencyDefaultConfig } from 'src/app/config';
import { Router } from '@angular/router';
import { number } from 'echarts';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { ICountries } from 'src/app/models/countries';

@Component({
  selector: 'app-ps-competitor',
  animations: [
    trigger('addTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 })),
      ]),
    ]),
    trigger('moveTrigger', [transition('* => *', [animate('300ms')])]),
  ],
  templateUrl: './ps-competitor.component.html',
  styleUrls: ['./ps-competitor.component.scss'],
})
export class PsCompetitorComponent implements OnInit, OnDestroy {
  competitorForm: FormGroup;
  psCompetitor: ICompetitorPositioning[] = [];
  private subscriptions: Subscription = new Subscription();
  project: ProjectDTO;
  searchValueCompetitorsTable: string;
  filteredCompetitors: FormArray = this.fb.array([]);
  // countriesForSections: ICountries[] = [];
  mappedCompetitorsOnCountries: any[] = [];
  customerData: any;

  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;

  startDataOnInit: any;

  @Input() initialValues: Observable<Project>;
  @Input() translations: any;
  @Input() isSupervisor: any;
  @Input() isIspDelivered: any;
  @Input() isReadOnly: any;

  @Output() submitFuncEmitter: EventEmitter<PsSwot[]> = new EventEmitter();

  @ViewChild('filterInput') filterInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private router: Router,
    private irFormService: IrFormService
  ) {
    this.competitorForm = fb.group({
      customerInformationMktMixDTO: this.fb.group({
        idCustomerInformation: null,
        product: [
          null,
          [Validators.required, Validators.min(1), Validators.max(10)],
        ],
        distribution: [
          null,
          [Validators.required, Validators.min(1), Validators.max(10)],
        ],
        priceSelling: [
          null,
          [Validators.required, Validators.min(1), Validators.max(10)],
        ],
        communicationMarketing: [
          null,
          [Validators.required, Validators.min(1), Validators.max(10)],
        ],
      }),
      competitorCompanyCrmDTO: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialValues: Project) => {
          this.project = initialValues;
          this.psCompetitor =
            initialValues?.psCompetitor?.competitorCompanyCrmDTO;
          this.customerData = {
            ...initialValues?.psCompetitor?.customerInformationMktMixDTO,
            businessName: initialValues?.customer?.businessName,
          };
          this.customer.patchValue(
            initialValues?.psCompetitor?.customerInformationMktMixDTO
          );
          this.competitors.clear();
          const idCountries: number[] = [];
          // this.countriesForSections = [];
          initialValues?.psCompetitor?.competitorCompanyCrmDTO?.forEach(
            (competitor: ICompetitorPositioning) => {
              this.addCompetitor(competitor);
              if (!idCountries.includes(competitor.country.idCountry)) {
                idCountries.push(competitor.country.idCountry);
                // this.countriesForSections.push(competitor.country);
              }
            }
          );
          this.filteredCompetitors = this.competitors;
          this.competitorForm.markAsPristine();
          this.competitorForm.markAsUntouched();
          if (this.isIspDelivered || this.isReadOnly) {
            this.competitorForm.disable();
          }
          this.startDataOnInit = this.competitorForm.getRawValue();
        },
      })
    );
  }

  get customer(): FormGroup {
    return this.competitorForm.get('customerInformationMktMixDTO') as FormGroup;
  }

  get competitors(): FormArray {
    return this.competitorForm.get('competitorCompanyCrmDTO') as FormArray;
  }

  addCompetitor(competitor?: ICompetitorPositioning): void {
    this.competitors.push(
      this.fb.group({
        idCompetitorCompanyCrm: [competitor?.idCompetitorCompanyCrm || null],
        companyName: [
          { value: competitor?.companyName || null, disabled: true },
        ],
        country: this.fb.group({
          country: [
            { value: competitor?.country?.country || null, disabled: true },
          ],
          idCountry: [competitor?.country?.idCountry || null],
        }),
        valueRevenueYear: [competitor?.valueRevenueYear || null],
        product: [
          competitor?.product || null,
          [Validators.required, Validators.min(1), Validators.max(10)],
        ],
        distribution: [
          competitor?.distribution || null,
          [Validators.required, Validators.min(1), Validators.max(10)],
        ],
        priceSelling: [
          competitor?.priceSelling || null,
          [Validators.required, Validators.min(1), Validators.max(10)],
        ],
        communicationMarketing: [
          competitor?.communicationMarketing || null,
          [Validators.required, Validators.min(1), Validators.max(10)],
        ],
        isSelected: [competitor?.isSelected || false],
      })
    );
  }

  filterCountries(event: Event): void {
    const search = (event.target as HTMLInputElement).value;
    this.competitors.clear();
    this.psCompetitor
      .filter(
        (entity) => entity.country.country.toLowerCase().indexOf(search) > -1
      )
      .sort(
        (a, b) =>
          a.country.country.toLowerCase().indexOf(search) -
          b.country.country.toLowerCase().indexOf(search)
      )
      .forEach((competitor: ICompetitorPositioning) => {
        this.addCompetitor(competitor);
      });
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(
        this.competitorForm.getRawValue(),
        this.irFormService.replacer
      )
    );
  }

  checkInvalidForm(): boolean {
    this.competitorForm.markAllAsTouched();
    return this.competitorForm.invalid;
  }

  onSubmit(): void {
    if (this.competitorForm.valid) {
      this.submitFuncEmitter.emit(this.competitorForm.getRawValue());
      this.competitorForm.markAsPristine();
      this.competitorForm.markAsUntouched();
      // this.filterInput.nativeElement.value = '';
    }
  }

  goToCrmContactCompetitors(): void {
    this.router.navigate([
      `crm/customer/${this.project.customer.idCustomer}/project/${this.project.idProject}`,
    ]);
  }

  filterCompetitorsTable(value: string): void {
    this.filteredCompetitors = this.fb.array([]);
    if (!value || !this.competitors.length) {
      this.filteredCompetitors.controls = this.competitors.controls;
    }
    this.competitors.controls.forEach((comp: FormGroup) => {
      if (
        comp.get('companyName').value.toLowerCase().includes(value) ||
        comp.get('country.country').value.toLowerCase().includes(value)
      ) {
        if (!this.filteredCompetitors.controls.includes(comp)) {
          this.filteredCompetitors.push(comp);
        }
      }
    });
  }

  checkSelectedCompetitorForRAP(index: number): boolean {
    // if (this.competitors[index].selectedForRAP) {
    //   return true;
    // } else {
    //   return false;
    // }
    return false;
  }

  checkCompetitorForRAP(): void {
    console.log('checkato!');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
