import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoggedUser } from 'src/app/models/user';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DisableDependentControl, MustMatch } from 'src/app/utility/validators';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  resetPasswordSubscription: Subscription;
  subscriptions: Subscription;
  formResetPassword: FormGroup;

  hide: boolean = true;
  hideTwo: boolean = true;
  token: string;
  user: LoggedUser;

  constructor(
    private activetedRoute: ActivatedRoute,
    private jwtHelper: JwtHelperService,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscriptions = this.activetedRoute.queryParams.subscribe(
      params => {
        this.token = params.token;
        this.user = this.jwtHelper.decodeToken(this.token);
      }
    );

    this.formResetPassword = this.fb.group({
      password: new FormControl(null, [ Validators.required, Validators.pattern(/(?=^.{4,128}$)^[a-zA-Z0-9._@&$#!?-]+$/) ]),
      confirmPassword: new FormControl(null, [ Validators.required, MustMatch('password') ]),
    }, { validator: DisableDependentControl('password', 'confirmPassword' ) });
  }

  onSubmit(): void {
    if (this.formResetPassword.valid) {
      const formValues = this.formResetPassword.getRawValue();
      this.resetPasswordSubscription = this.authService.resetPassword(this.user.email, this.token, formValues.password).subscribe({
        complete: () => this.router.navigate(['/'])
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.resetPasswordSubscription.unsubscribe();
  }

}
