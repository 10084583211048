<div>
  <mat-dialog-content [formGroup]="form">
    <h2 mat-dialog-title>{{ message }}</h2>
    <mat-form-field>
      <mat-label>{{ 'IR_TO_APPROVE.Reason' | translate }}</mat-label>
      <textarea
        matInput
        formControlName="reason"
      ></textarea>
      <mat-error *ngIf="form.get('reason').invalid">
        {{ form.get('reason').errors | getErrorMessage:translations.Reason }}
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Annulla</button>
    <button mat-button (click)="confirm()" cdkFocusInitial>Conferma</button>
  </mat-dialog-actions>
</div>