<div class="current-month">
  <mat-icon
    class="set-before"
    *ngIf="monthIndex !== 0"
    (click)="setMonth('decrease')"
  >
    navigate_before
  </mat-icon>
  <span>
    {{ selectedMonth | date: "MMMM" | titlecase }}
    {{ selectedMonth | date: "yyyy" }}
  </span>
  <mat-icon
    *ngIf="monthIndex < 6"
    class="set-after"
    (click)="setMonth('increase')"
  >
    navigate_next
  </mat-icon>
</div>
<ul class="week-days-header">
  <li *ngFor="let day of days">
    <span>{{ day }}</span>
  </li>
</ul>
<div class="month-days-container">
  <div class="center-spinner-30">
    <mat-spinner diameter="30" *ngIf="loader"></mat-spinner>
  </div>
  <div *ngIf="!loader">
    <ul
      class="month-days"
      *ngFor="let row of calendar | chunk: 7; let i = index"
    >
      <li *ngFor="let c of row; let j = index">
        <span
          [ngClass]="{
            today: c.isToday,
            'different-month': !differentMonth(c.date)
          }"
          >{{ c?.date?.getDate() }}</span
        >
        <div
          *ngIf="
            checkAppointment(c.date) === 'telephonic' ||
            checkAppointment(c.date) === 'personal'
          "
          class="there-is-appointment-container"
        >
          <div
            *ngIf="checkAppointment(c.date) === 'telephonic'"
            class="telephonic"
            (click)="goToSpecificMeet(c.date)"
          ></div>
          <div
            *ngIf="checkAppointment(c.date) === 'personal'"
            class="personal"
            (click)="goToSpecificMeet(c.date)"
          ></div>
        </div>
      </li>
    </ul>
  </div>
</div>
