<form [formGroup]="dealForm" (ngSubmit)="saveDeal()">
  <div class="header">
    <div class="title">
      <span>{{ "CRM_DETAIL.NewDealTitle" | translate }}</span>
    </div>
    <button
      class="close-dialog"
      mat-dialog-close
      mat-icon-button
      [disabled]="loading || loadingFile"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="content">
    <div class="row">
      <mat-form-field
        appearance="standard"
        formGroupName="dealState"
        [ngClass]="showOpportunityFlag ? 'col-4' : 'col-6'"
      >
        <mat-label for="idDealState">
          {{ "CRM_DETAIL.Status2" | translate }}
        </mat-label>
        <mat-select
          disableOptionCentering
          panelClass="custom-panel-class"
          formControlName="idDealState"
          name="idDealState"
          (selectionChange)="reinitializeDealFormByDealState($event)"
        >
          <mat-option
            *ngFor="let dealState of dealStates"
            [value]="dealState.idDealState"
          >
            {{
              "DEAL_STATE_NAME." + dealStateNameEnum[dealState.name] | translate
            }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{
            dealForm.get("dealState.idDealState").errors
              | getErrorMessage: translations.DealState
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field
        *ngIf="showDealStateReason"
        appearance="standard"
        class="col-6"
        formGroupName="dealStateReason"
      >
        <mat-label for="idDealStateReason">{{
          "CRM_DETAIL.Reason" | translate
        }}</mat-label>
        <mat-select
          disableOptionCentering
          panelClass="custom-panel-class"
          formControlName="idDealStateReason"
          name="idDealStateReason"
        >
          <mat-option
            *ngFor="let dealStateReason of filteredDealStateReasons"
            [value]="dealStateReason.idDealStateReason"
          >
            {{
              "CRM_DETAIL." + dealStateReasonEnum[dealStateReason.name]
                | translate
            }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{
            dealForm.get("dealStateReason.idDealStateReason").errors
              | getErrorMessage: translations.Reason
          }}
        </mat-error>
      </mat-form-field>
      <div *ngIf="showOpportunityFlag" class="col-2 no-padding">
        <div class="opportunity-container">
          <mat-checkbox
            color="primary"
            formControlName="isOpportunity"
          >
            {{ "CRM_DETAIL.LosedOffer" | translate }}
          </mat-checkbox>
        </div>
      </div>
      <mat-form-field
        appearance="standard"
        [ngClass]="showOpportunityFlag ? 'col-4' : 'col-6'"
        formGroupName="commerceSector"
      >
        <mat-label for="idCommerceSector">
          {{ "IR_FORM.CommerceSector" | translate }}
        </mat-label>
        <mat-select
          disableOptionCentering
          panelClass="custom-panel-class"
          formControlName="idCommerceSector"
          name="idCommerceSector"
        >
          <mat-option
            *ngFor="let sector of commerceSectors"
            [value]="sector.idCommerceSector"
          >
            {{ sector.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field appearance="standard" class="col-6">
        <mat-label for="dateDeal">{{
          "CRM_DETAIL.Date" | translate
        }}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="dateDeal" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>
          {{
            dealForm.get("dateDeal").errors | getErrorMessage: translations.Date
          }}
        </mat-error>
      </mat-form-field> -->
    </div>
    <div class="row">
      <mat-form-field appearance="standard" class="col-6">
        <mat-label for="productName">{{
          "CRM_DETAIL.ProductName" | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="productName"
          name="productName"
        />
        <mat-error>
          {{
            dealForm.get("productName").errors
              | getErrorMessage: translations.ProductName
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard"class="col-6" formGroupName="contactReference">
        <mat-label for="idContactReference">
          {{ "CRM_DETAIL.Buyer" | translate }}
        </mat-label>
        <mat-select
          disableOptionCentering
          panelClass="custom-panel-class"
          formControlName="idContactReference"
          name="idContactReference"
        >
          <mat-option
            *ngFor="let contact of checkWichContactReferencesToShow()"
            [value]="contact.idContactReference"
          >
            {{ contact.name }} {{ contact.surname }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{
            dealForm.get("contactReference.idContactReference").errors
              | getErrorMessage: translations.Buyer
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="standard" class="col-12">
        <mat-label for="italianDescription">{{
          "CRM_DETAIL.ItalianDescription" | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="italianDescription"
          name="italianDescription"
        />
        <mat-error>
          {{
            dealForm.get("italianDescription").errors
              | getErrorMessage: translations.ItalianDescription
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="standard" class="col-12">
        <mat-label for="englishDescription">{{
          "CRM_DETAIL.EnglishDescription" | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="englishDescription"
          name="englishDescription"
        />
        <mat-error>
          {{
            dealForm.get("englishDescription").errors
              | getErrorMessage: translations.EnglishDescription
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="standard" class="col-4" formGroupName="uom">
        <mat-label for="idUom">{{
          "CRM_DETAIL.UnitOfMeasure" | translate
        }}</mat-label>
        <mat-select formControlName="idUom">
          <mat-option *ngFor="let uom of unitsOfMeasure" [value]="uom.idUom">
            {{ uom.description }}
          </mat-option>
        </mat-select>
        <button
          type="button"
          class="only-icon-button primary"
          matSuffix
          [disabled]="isReadonly"
          (click)="showUomManagementDialog($event)"
        >
          <svg-icon name="list_icon_2" class="w20 h20"></svg-icon>
        </button>
        <mat-error>
          {{
            dealForm.get("uom.idUom").errors
              | getErrorMessage: translations.UnitOfMeasure
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard" class="col-4">
        <mat-label for="quantity">{{ "CRM_DETAIL.Quantity" | translate }}</mat-label>
        <input
          currencyMask
          [options]="currencyQuantityConfig"
          matInput
          autocomplete="off"
          name="quantity"
          formControlName="quantity"
          (keyup)="updateValueYear(dealForm)"
        />
        <mat-error>
          {{
            dealForm.get("quantity").errors
              | getErrorMessage: translations.Quantity
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard" class="col-4">
        <mat-label for="revenue">{{ "CRM_DETAIL.Value" | translate }}</mat-label>
        <input
          currencyMask
          [options]="currencyDefaultConfig"
          matInput
          autocomplete="off"
          name="revenue"
          formControlName="revenue"
          (keyup)="updateValueYear(dealForm)"
        />
        <mat-error>
          {{
            dealForm.get("revenue").errors | getErrorMessage: translations.Value
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="dealForm.get('dealState.idDealState').value === 3" class="row">
      <mat-form-field appearance="standard" class="col-4">
        <mat-label for="estimatedQuantityOrder">
          {{ "CRM_DETAIL.EstimatedQuantityOrder" | translate }}
        </mat-label>
        <input
          currencyMask
          [options]="currencyQuantityConfig"
          matInput
          autocomplete="off"
          name="estimatedQuantityOrder"
          formControlName="estimatedQuantityOrder"
        />
        <mat-error>
          {{
            dealForm.get("estimatedQuantityOrder").errors
              | getErrorMessage: translations.EstimatedQuantityOrder
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard" class="col-4">
        <mat-label for="estimatedQuantityYear">
          {{ "CRM_DETAIL.EstimatedQuantityYear" | translate }}
        </mat-label>
        <input
          currencyMask
          [options]="currencyQuantityConfig"
          matInput
          autocomplete="off"
          name="estimatedQuantityYear"
          formControlName="estimatedQuantityYear"
          (keyup)="updateValueYear(dealForm)"
        />
        <mat-error>
          {{
            dealForm.get("estimatedQuantityYear").errors
              | getErrorMessage: translations.EstimatedQuantityYear
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard" class="col-4">
        <mat-label for="estimatedValueYear">
          {{ "CRM_DETAIL.EstimatedValueYear" | translate }}
        </mat-label>
        <input
          currencyMask
          [options]="currencyDefaultConfig"
          matInput
          autocomplete="off"
          name="estimatedValueYear"
          formControlName="estimatedValueYear"
        />
        <mat-error>
          {{
            dealForm.get("estimatedValueYear").errors
              | getErrorMessage: translations.EstimatedValueYear
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="standard" class="col-12">
        <mat-label for="note">{{ "CRM_DETAIL.Note" | translate }}</mat-label>
        <textarea
          matInput
          type="text"
          name="note"
          formControlName="note"
          rows="1"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="d-flex align-items-center justify-content-center mt-30 mb-10">
      <div style="position: relative;">
        <mat-error *ngIf="documentRequired" style="position: absolute; top: -30px; left: 50%; transform: translateX(-50%); width: 600px; text-align: center;">
          {{ "CRM_DETAIL.UploadDealDocument" | translate }}
        </mat-error>
        <app-upload-file
        [fileToHandle]="uploadedDocument"
        [fileType]="'CRM_DETAIL.Document' | translate"
        [downloadFileFromName]="true"
        [showDocumentName]="true"
        [uploadingFile]="loadingFile"
        (uploadFileEmitter)="uploadDealDocument($event)"
        (downloadFileEmitter)="downloadDealDocument()"
        [disableButton]="isReadonly || loadingFile || loading"
        [parentUploadError]="uploadError"
        ></app-upload-file>
      </div>
      <button
        class="rounded-button primary-bg-button ml-30"
        type="submit"
        [disabled]="loading || loadingFile || isReadonly"
      >
        <span>{{ "IR_FORM.Save" | translate }}</span>
      </button>
    </div>
  </div>
</form>
