import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { formatDate } from '@angular/common';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  month(event: any): string {
    return `<b>${formatDate(event.start, 'HH:mm', this.locale)} - ${formatDate(
      event.end,
      'HH:mm',
      this.locale
    )}</b> ${event.customerDataSheet.businessName}`;
    // return `<b>${formatDate(event.start, 'HH:mm', this.locale)} - ${formatDate(event.end, 'HH:mm', this.locale)}</b> ${
    //   event.title
    // } - ${event.customerDataSheet.businessName}`;
  }

  week(event: any): string {
    return `${event.customerDataSheet.businessName}`;
    // return `${event.title}`;
  }

  day(event: any): string {
    return `<b>${formatDate(event.start, 'HH:mm', this.locale)} - ${formatDate(
      event.end,
      'HH:mm',
      this.locale
    )}</b> ${event.customerDataSheet.businessName}`;
    // return `<b>${formatDate(event.start, 'HH:mm', this.locale)} - ${formatDate(event.end, 'HH:mm', this.locale)}</b> ${
    //   event.title
    // } - ${event.customerDataSheet.businessName}`;
  }
}
