import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getErrorMessage',
  pure: false,
})
export class GetErrorMessagePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(
    errors: any,
    fieldName: string = '',
    secondaryField: string = '',
    noFormMessage: boolean = false
  ): unknown {
    if (!errors) {
      return null;
    }

    if (errors.required) {
      if (noFormMessage) {
        return this.translate.instant(fieldName);
      }
      return this.translate.instant('FORM_MESSAGE.Required', { fieldName });
    }
    if (errors.pattern || errors.email) {
      return this.translate.instant('FORM_MESSAGE.Pattern', { fieldName });
    }
    if (errors.min) {
      return this.translate.instant('FORM_MESSAGE.Min', {
        fieldName,
        minValue: errors.min.min,
      });
    }
    if (errors.max) {
      return this.translate.instant('FORM_MESSAGE.Max', {
        fieldName,
        maxValue: errors.max.max,
      });
    }
    if (errors.mustMatch) {
      return this.translate.instant('FORM_MESSAGE.NotMatch', { fieldName });
    }
    if (errors.minlength) {
      return this.translate.instant('FORM_MESSAGE.MinLength', { fieldName });
    }
    if (errors.maxlength) {
      return this.translate.instant('FORM_MESSAGE.MaxLength', { fieldName });
    }
    if (errors.matDatepickerParse?.text) {
      return this.translate.instant('FORM_MESSAGE.InvalidDate', {
        text: errors.matDatepickerParse?.text,
      });
    }
    if (errors.overMax) {
      return this.translate.instant('FORM_MESSAGE.OverMax', {
        fieldName,
        max: errors.overMax.max,
      });
    }
    if (errors.isRepeted) {
      return this.translate.instant('FORM_MESSAGE.IsRepeted', {
        fieldName,
        secondaryField,
      });
    }
    if (errors.isRepetedFromOther) {
      return this.translate.instant('FORM_MESSAGE.IsRepetedFromOther', {
        fieldName,
        secondaryField,
      });
    }
    if (errors.isAfter) {
      return this.translate.instant('FORM_MESSAGE.IsBefore', {
        fieldName,
        secondaryField,
      });
    }
    if (errors.isBefore) {
      return this.translate.instant('FORM_MESSAGE.IsAfter', {
        fieldName,
        secondaryField,
      });
    }
    if (errors.requiredForExport) {
      return this.translate.instant('FORM_MESSAGE.RequiredForExport', {
        fieldName,
      });
    }
    if (errors.exportMustBeTrue) {
      return this.translate.instant('FORM_MESSAGE.ExportMustBeTrue', {
        fieldName,
      });
    }
    if (errors.percentageBreakdownSurplus) {
      return this.translate.instant(
        'FORM_MESSAGE.PercentageRepartitionMoreThan100',
        { fieldName }
      );
    }
    if (errors.percentageBreakdownLack) {
      return this.translate.instant(
        'FORM_MESSAGE.PercentageRepartitionLessThan100',
        { fieldName }
      );
    }
    if (errors.duplicateWord) {
      return this.translate.instant('FORM_MESSAGE.DuplicateWord', {
        fieldName,
      });
    }
    if (errors.duplicate) {
      return this.translate.instant('FORM_MESSAGE.ValueAlreadyExisting', {
        fieldName,
      });
    }
  }
}
