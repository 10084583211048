import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { IProjectForTable, ProjectDTO } from 'src/app/models/project';
import { IColumnsName, ITableButtons, ITableOptions } from 'src/app/models/utility';
import { fromProjectDtoToProjectTable } from 'src/app/utility/elaborations';

@Component({
  selector: 'app-ir-sent-table',
  templateUrl: './ir-sent-table.component.html',
  styleUrls: ['./ir-sent.component.scss']
})
export class IrSentTableComponent implements OnInit, OnChanges {

  projectDataTable: MatTableDataSource<IProjectForTable> = new MatTableDataSource<IProjectForTable>();

  @Input() projectData: ProjectDTO[];
  @Input() idUser: any;
  @Input() columnsName: IColumnsName[] = [
    {value: 'businessName', name: 'Ragione Sociale'},
    {value: 'telephoneNumber', name: 'Telefono'},
    {value: 'email', name: 'Email'},
    {value: 'address', name: 'Indirizzo'},
    {value: 'lastModified', name: 'Ultima lavorazione'},
  ];
  @Input() displayedColumns: string[] = [
    'lastModified',
    'businessName',
    'telephoneNumber',
    'email',
    'address',
    'menuButtons'
  ];
  @Input() tableMenuButtons: ITableButtons[] = [
    {
      name: 'ViewCustomer',
      tooltip: 'ViewCustomer',
      click: (original: IProjectForTable) => { this.visualizeCustomer(original); },
      icon: 'person',
    }
  ];
  @Input() tableOptions: ITableOptions = {
    showFilter: true,
    showSort: true,
    showPaginator: true
  };
  @Input() loader: boolean = false;


  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const projectData: ProjectDTO[]  = changes.projectData?.currentValue || [];
    if (projectData?.length || projectData.length === 0) {
      this.projectDataTable.data = projectData.map(fromProjectDtoToProjectTable).filter((element) => {
        return element.idUser !== this.idUser;
      });
    }
  }

  visualizeCustomer(original): void {
    this.router.navigate([`/customers`, original?.customer?.idCustomer]);
  }

}
