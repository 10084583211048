import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { ProjectDTO } from 'src/app/models/project';
import { LAB_MANAGER, RI_STATUS_DESCRIPTION } from 'src/app/config';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-ir-to-approve',
  templateUrl: './ir-to-approve.component.html',
  styleUrls: ['./ir-to-approve.component.css']
})
export class IrToApproveComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  irToApprove: ProjectDTO[];
  translations: any;

  riStatus: RI_STATUS_DESCRIPTION;
  loader: boolean = false;
  labRole: string;
  isLabManager: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private irFormService: IrFormService,
    private translate: TranslateService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.labRole = LAB_MANAGER;
    this.authService.userProfile?.roles.map((role) => {
      if (role === this.labRole){
        this.isLabManager = true;
      }
    });
    this.subscriptions.add(this.route?.data?.subscribe(data => {
      this.riStatus = data?.riStatus;
      this.loader = true;
      this.getIrToApprove();
    }));
    this.subscriptions.add(this.translate.get('IR_TO_APPROVE').subscribe((translations) => {
      this.translations = translations;
    }));
  }

  getIrToApprove(): void {
    if (this.isLabManager) {
      this.subscriptions.add(this.irFormService.getProjectInternalByStatusDescription(this.riStatus, true).subscribe((project: ProjectDTO[]) => {
        this.irToApprove = project;
        this.loader = false;
      }));
    } else {
      this.subscriptions.add(this.irFormService.getProjectByStatusDescription(this.riStatus, true).subscribe((project: ProjectDTO[]) => {
        this.irToApprove = project;
        this.loader = false;
      }));
    } 
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
