import { HttpRequest } from '@angular/common/http';
import { Storage } from './storage';
import { storageKeys, noAuthApiRoutes, apiRootUrl } from '../config';

/**
 * This functione retrive the from storage
 * @param request HttpRequest
 */
export const tokenGetter = (request: HttpRequest<any>): string | Promise<string> => {
  return Storage.get(storageKeys.accessToken);
};

/**
 * This function retrive the auth schema
 * (mostely bearer)
 * @param request HttpRequest
 */
export const authScheme = (request: HttpRequest<any>): string => {
  return `${Storage.get(storageKeys.tokenShema)} `;
};

/**
 * Map the noAuthApiRoute array and and concat the
 * apiRootUrl to every route
 */
export const disallowedRoutes = (): Array<string|RegExp> => {
  return noAuthApiRoutes.map(
    (route): string | RegExp => {
      return `${apiRootUrl}${route}`;
    });
};
