<div class="general-container">
  <div class="select-date-container">
    <div class="select-range-container">
      <button
        mat-icon-button
        class="calendar-action-button"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
        <mat-icon>navigate_before</mat-icon>
      </button>
      <div class="bold-text-color">
        {{ viewDate | calendarDate: view + "ViewTitle":locale:1 }}
      </div>
      <button
        mat-icon-button
        class="calendar-action-button"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
    <div class="select-view-container">
      <div class="view-buttons">
        <button
          (click)="setView(CalendarView.Month)"
          [ngClass]="view === CalendarView.Month ? 'active' : ''"
        >
          {{ "CALENDAR.Month" | translate }}
        </button>
        <button
          (click)="setView(CalendarView.Week)"
          [ngClass]="view === CalendarView.Week ? 'active' : ''"
        >
          {{ "CALENDAR.Week" | translate }}
        </button>
        <button
          (click)="setView(CalendarView.Day)"
          [ngClass]="view === CalendarView.Day ? 'active' : ''"
        >
          {{ "CALENDAR.Day" | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="isSupervisorAgent" class="select-agent-container">
      <mat-form-field>
        <mat-label>{{ "CALENDAR.UserAgent" | translate }}</mat-label>
        <mat-select
          [formControl]="entityCtrl"
          disableOptionCentering
          panelClass="custom-panel-class"
          (selectionChange)="handleAgentSelect($event)"
        >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="entityFilterCtrl"
              [placeholderLabel]="'COMMON.Search...' | translate"
              [noEntriesFoundLabel]="'Nessun risultato'"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            [ngStyle]="{
              display: filteredAgents.includes(agent) ? 'flex' : 'none'
            }"
            *ngFor="let agent of agents"
            [value]="agent.idUser"
          >
            {{ agent?.name }} {{ agent?.surname }} - {{ agent?.email }}
          </mat-option>
          <mat-option
            *ngIf="!filteredAgents?.length"
            style="pointer-events: none"
          >
            {{ "COMMON.NoResult" | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div [ngSwitch]="view" class="calendar-switch-container">
    <div class="calendar-spinner-container" *ngIf="calendarLoader">
      <mat-spinner
        class="m-0-auto calendar-spinner"
        diameter="100"
      ></mat-spinner>
    </div>
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      [locale]="locale"
      [weekStartsOn]="weekStartsOn"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="openEventDialog($event.event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [locale]="locale"
      [weekStartsOn]="weekStartsOn"
      [dayStartHour]="7"
      [dayEndHour]="21"
      [eventTemplate]="test"
      [tooltipTemplate]="tooltipTemplate"
      (eventClicked)="openEventDialog($event.event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [locale]="locale"
      [weekStartsOn]="weekStartsOn"
      [dayStartHour]="7"
      [dayEndHour]="21"
      [tooltipTemplate]="tooltipTemplate"
      (eventClicked)="openEventDialog($event.event)"
    >
    </mwl-calendar-day-view>
  </div>
</div>

<ng-template #modalContent let-close="close" let-data>
  <div class="dialog-print-container">
    <div class="print-header">
      <img src="../../../assets/img/headerPrint.png" />
      <div>
        <div class="fs-26">
          {{ "CALENDAR.ExportAppointment" | translate }}
        </div>
        <div class="fs-18">
          {{ today | date: "medium":"":locale }}
        </div>
      </div>
    </div>
    <div class="header h70">
      <div class="title">
        <span>{{ "MEETINGS.MeetingDetails" | translate }}</span>
      </div>
      <button
        class="close-dialog"
        mat-dialog-close
        mat-icon-button
        [disabled]="disableButtons"
        (click)="cancelReasonForm()"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <form
      *ngIf="!reasonMeetSelection"
      [formGroup]="meetForm"
      (ngSubmit)="submitMeet()"
    >
      <div class="content">
        <div class="row">
          <!-- <mat-form-field class="col-4" appearance="standard">
            <mat-label for="title">{{
              "COMMON.AppointmentTitle" | translate
            }}</mat-label>
            <input matInput formControlName="title" name="title" type="text" />
            <mat-error *ngIf="meetForm.get('title').invalid">
              {{
                meetForm.get("title").errors | getErrorMessage: translations.Title
              }}
            </mat-error>
          </mat-form-field> -->
          <mat-form-field class="col-4" appearance="standard">
            <mat-label for="businessName">
              {{ "COMMON.BusinessName" | translate }}
            </mat-label>
            <input
              matInput
              formControlName="businessName"
              name="businessName"
              type="text"
            />
          </mat-form-field>
          <mat-form-field class="col-4" appearance="standard">
            <mat-label for="startDate">{{
              "COMMON.AppointmentStart" | translate
            }}</mat-label>
            <input
              matInput
              formControlName="startDate"
              type="datetime-local"
              name="startDate"
            />
            <mat-error *ngIf="meetForm.get('startDate').invalid">
              {{
                meetForm.get("startDate").errors
                  | getErrorMessage: translations.DateStart:translations.DateEnd
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" class="col-4">
            <mat-label for="endDate">{{
              "COMMON.AppointmentEnd" | translate
            }}</mat-label>
            <input
              matInput
              formControlName="endDate"
              type="datetime-local"
              name="endDate"
              [min]="meetForm.get('startDate').value"
            />
            <mat-error *ngIf="meetForm.get('endDate').invalid">
              {{
                meetForm.get("endDate").errors
                  | getErrorMessage: translations.DateEnd:translations.DateStart
              }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col-4" appearance="standard">
            <mat-label for="address">
              {{ "COMMON.Address" | translate }}
            </mat-label>
            <input
              matInput
              formControlName="address"
              name="address"
              type="text"
            />
            <mat-error *ngIf="meetForm.get('address').invalid">
              {{
                meetForm.get("address").errors
                  | getErrorMessage: translations.Address
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-4" appearance="standard">
            <mat-label for="referent">{{
              "COMMON.Referent" | translate
            }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="referent"
              name="referent"
            />
          </mat-form-field>
          <mat-form-field class="col-4" appearance="standard">
            <mat-label for="telephoneNumber">
              {{ "COMMON.Telephone" | translate }}
            </mat-label>
            <input
              matInput
              name="telephoneNumber"
              formControlName="telephoneNumber"
              type="tel"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col-4" appearance="standard">
            <mat-label for="email">
              {{ "COMMON.Email" | translate }}
            </mat-label>
            <input
              style="padding-right: 10px"
              matInput
              name="email"
              formControlName="email"
              type="email"
            />
            <button
              type="button"
              class="only-icon-button primary"
              (click)="sendEmail()"
              matSuffix
            >
              <svg-icon name="send_mail_icon" class="w20 h20"></svg-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="col-4" appearance="standard">
            <mat-label for="appointmentType">
              {{ "COMMON.Type" | translate }}
            </mat-label>
            <mat-select formControlName="appointmentType">
              <mat-option [value]="appointmentType.Telephonic">{{
                "COMMON.Telephonic" | translate
              }}</mat-option>
              <mat-option [value]="appointmentType.Personal">{{
                "COMMON.Personal" | translate
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="meetForm.get('appointmentType').invalid">
              {{
                meetForm.get("appointmentType").errors
                  | getErrorMessage: translations.AppoinmentType
              }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col" appearance="standard">
            <mat-label for="note">
              {{ "COMMON.Note" | translate }}
            </mat-label>
            <input matInput formControlName="note" name="note" type="text" />
          </mat-form-field>
        </div>
      </div>
      <div class="buttons center appt-detail-bialog-buttons">
        <!-- <button
          type="button"
          [disabled]="disableButtons"
          class="only-text-button error mr-20"
          (click)="deleteMeet()"
        >
          {{ "COMMON.NegativeOutcome" | translate }}
        </button> -->
        <!-- <div class="d-flex"> -->
          <button 
            type="button"
            [disabled]="disableButtons"
            class="rounded-button primary-border-button mr-10"
            (click)="printPage()"
            >
            {{ "COMMON.PrintAppointment" | translate }}
          </button>
          <!-- <button class="rounded-button primary-border-button" type="submit">
            {{ "COMMON.SaveChanges" | translate }}
          </button> -->
          <!-- <button 
            class="rounded-button primary-border-button ml-10" 
            type="button"
            [disabled]="disableButtons"
            [matMenuTriggerFor]="moreOptions"
            *ngIf="meetForm.get('appointmentType').value === appointmentType.Personal"
          >
            {{ "COMMON.Actions" | translate }}
            <mat-icon style="margin-right: -10px;">chevron_right</mat-icon>
          </button> -->
          <!-- <mat-menu #moreOptions="matMenu">
            <button
              mat-menu-item
              (click)="initializeReasonForm('NotInterested', data.event)"
            >
              {{ "CALENDAR.CustomerNotInterested" | translate }}
            </button>
            <button
              mat-menu-item
              (click)="initializeReasonForm('CompileBM', data.event)"
            >
              {{ "CALENDAR.CompileBM" | translate }}
            </button>
            <button
              mat-menu-item
              (click)="initializeReasonForm('CompileASS', data.event)"
            >
              {{ "CALENDAR.CompileASS" | translate }}
            </button>
          </mat-menu> -->
        <!-- </div> -->
      </div>
    </form>
    <form
      *ngIf="reasonMeetSelection"
      [formGroup]="reasonForm"
      (ngSubmit)="submitReason()"
    >
      <div class="content">
        <div *ngIf="reasonMeetSelection !== 'CompileASS'" class="row">
          <mat-form-field appearance="standard" class="col-12">
            <mat-label for="idNoContractReason">
              {{ "CALENDAR.NoContractReason" | translate }}
            </mat-label>
            <mat-select formControlName="idNoContractReason">
              <mat-option
                *ngFor="let reason of reasons"
                [value]="reason.idProjectRefusalReason"
              >
                {{ reason.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{
                reasonForm.get("idNoContractReason").errors
                  | getErrorMessage: translations.NoContractReason
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" class="col-12">
            <mat-label for="noContractReason">{{
              "COMMON.Note" | translate
            }}</mat-label>
            <textarea
              matInput
              name="noContractReason"
              formControlName="noContractReason"
              type="text"
            ></textarea>
            <mat-error>
              {{
                reasonForm.get("noContractReason").errors
                  | getErrorMessage: translations.NoContractReason
              }}
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="reasonMeetSelection === 'NotInterested'" class="row">
          <mat-form-field appearance="standard" class="col-12">
            <mat-label for="idNoBmReason">
              {{ "CALENDAR.NoBmReason" | translate }}
            </mat-label>
            <mat-select formControlName="idNoBmReason">
              <mat-option
                *ngFor="let reason of reasons"
                [value]="reason.idProjectRefusalReason"
              >
                {{ reason.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{
                reasonForm.get("idNoBmReason").errors
                  | getErrorMessage: translations.NoContractReason
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" class="col">
            <mat-label for="noBmReason">
              {{ "COMMON.Note" | translate }}
            </mat-label>
            <textarea
              matInput
              type="text"
              formControlName="noBmReason"
              name="noBmReason"
            ></textarea>
            <mat-error>
              {{
                reasonForm.get("noBmReason").errors
                  | getErrorMessage: translations.NoBmReason
              }}
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="reasonMeetSelection === 'CompileASS'" class="row">
          <mat-form-field class="col-6">
            <mat-label for="expectedContractStartDate">
              {{ "CALENDAR.ExpectedContractStartDate" | translate }}
            </mat-label>
            <input
              matInput
              type="date"
              formControlName="expectedContractStartDate"
              name="expectedContractStartDate"
            />
            <mat-error>
              {{
                reasonForm.get("expectedContractStartDate").errors
                  | getErrorMessage: translations.ExpectedContractStartDate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-6">
            <mat-label for="expectedContractDuration">
              {{ "CALENDAR.ExpectedContractDurationWithMonths" | translate }}
            </mat-label>
            <input
              matInput
              type="number"
              formControlName="expectedContractDuration"
              name="expectedContractDuration"
              min="1"
              step="1"
            />
            <mat-error>
              {{
                reasonForm.get("expectedContractDuration").errors
                  | getErrorMessage: translations.ExpectedContractDuration
              }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="buttons center">
        <button
          type="button"
          class="only-text-button error mr-20"
          [disabled]="disableButtons"
          (click)="cancelReasonForm()"
        >
          {{ "COMMON.GoBack" | translate }}
        </button>
        <button
          type="submit"
          class="rounded-button primary-bg-button"
          [disabled]="disableButtons"
        >
          {{ "COMMON.Continue" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
