<mat-dialog-content>
  <h2 mat-dialog-title>Note</h2>
  <div *ngFor="let note of notes">
    <div class="note-container">
      <div class="note-date">
        <span>{{note.dateInsert | date:'dd/MM/yyyy - hh:mm'}}</span>
      </div>
      <div class="note-tab">
        <span>
          {{ 'PROJECT_NOTE.' + note.riTab | translate }}
        </span>
      </div>
      <div class="note-description">
        <p>{{note.description}}</p>
        <div class="border-line"></div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ 'IR_IN_PROGRESS.Close' | translate }}
  </button>
</mat-dialog-actions>