import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout/layout.service';
import { CommonService } from '../../services/common/common.service';
import { Observable, Subscription } from 'rxjs';
import {
  IContact,
  IDeal,
  IDealForTable,
  IDealState,
  IDealStateCount,
  IDealStateReason,
} from '../../models/contact';
import { CrmService } from '../../services/crm/crm.service';

import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ContactDealDialogComponent } from '../contact-deal-dialog/contact-deal-dialog.component';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DEAL_STATE_ID, DEAL_STATE_NAME } from 'src/app/config';
import { ISectors } from 'src/app/models/sectors';
import {
  ITableButton,
  ITableColumn,
  UnitsOfMeasure,
} from 'src/app/models/utility';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelect, MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-contact-deals',
  templateUrl: './contact-deals.component.html',
  styleUrls: ['./contact-deals.component.scss'],
})
export class ContactDealsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  idProject: number;
  idCustomer: number;
  dealStateCount: IDealStateCount;
  createdSumRevenue: number;
  pendingSumRevenue: number;
  losedSumRevenue: number;
  orderPlacedSumRevenue: number;
  createdDeals: IDeal[] = [];
  pendingDeals: IDeal[] = [];
  losedDeals: IDeal[] = [];
  orderPlacedDeals: IDeal[] = [];
  dealStates: IDealState[];
  dealStateReasons: IDealStateReason[];
  isReadonly: boolean = false;

  /**NUOVOOOO */
  deals: IDealForTable[];
  oldDeals: IDealForTable[];
  dealsTableColumns: ITableColumn[];
  dealsTableButtons: ITableButton[];
  dealsTableLoader: boolean = false;
  startDateDealFilter: FormControl = new FormControl();
  endDateDealFilter: FormControl = new FormControl();
  dealFilterSelects: any = {
    commerceSectors: null,
    dealStatus: null,
    dealRangeDate: null,
  };
  dealStateNameEnum: typeof DEAL_STATE_NAME = DEAL_STATE_NAME;
  idCommerceSector: number;
  @Input() unitsOfMeasure: UnitsOfMeasure[];
  @Input() commerceSectors: ISectors[] = [];
  @Input() dealStatus: string[] = [];
  @Input() translations: any;
  @Input() contact: IContact;
  @ViewChild('dealCommerceSectorFilter') dealCommerceSectorFilter: MatSelect;
  @ViewChild('dealStatusFilter') dealStatusFilter: MatSelect;
  @Output() refreshContactDetails: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() refreshUnitsOfMeasure: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private crmService: CrmService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // CHeck if is read only
    this.subscriptions.add(
      this.route?.data?.subscribe((data: any) => {
        if (data?.readonly) {
          this.isReadonly = true;
        }
      })
    );
    this.subscriptions.add(
      this.crmService.idCommerceSectorObs.subscribe({
        next: (id: number) => {
          this.idCommerceSector = id;
        },
      })
    );
    // Initialize deals table
    this.dealsTableColumns = [
      {
        name: 'dealStateDesc',
        translatedName: this.translations.Status2,
      },
      {
        name: 'dateDeal',
        translatedName: this.translations.InsertDate,
        type: 'shortDate',
      },
      {
        name: 'commerceSectorForTable',
        translatedName: this.translations.CommerceSector,
      },
      {
        name: 'quantity',
        translatedName: this.translations.Quantity,
      },
      {
        name: 'unitOfMeasure',
        translatedName: this.translations.UnitOfMeasure,
      },
      {
        name: 'offerValue',
        translatedName: this.translations.OfferValue,
        type: 'currency',
        align: 'right',
      },
      {
        name: 'revenue',
        translatedName: this.translations.Revenue,
        type: 'currency',
        align: 'right',
      },
      {
        name: 'buttons',
        disableSort: true,
      },
    ];
    this.dealsTableButtons = [
      {
        icon: 'edit_icon',
        class: 'primary',
        disabled: () => this.isReadonly,
        clickFunction: (deal: IDealForTable) => {
          this.openDealDialog(deal);
        },
      },
    ];
    if (this.contact?.idContact) {
      this.getDeals();
    }
    this.getDealStates();
    this.getDealStateReasons();
  }

  getDeals(): void {
    this.subscriptions.add(
      this.crmService.getDealsByIdContact(this.contact.idContact).subscribe({
        next: (deals: IDeal[]) => {
          this.deals = deals.map(
            (deal: IDeal, ind: number): IDealForTable => ({
              ...deal,
              dateDeal: deal?.dateDeal,
              dateDealMs: new Date(deal?.dateDeal).getTime(),
              commerceSectorForTable: deal?.commerceSector?.name,
              quantity: deal?.quantity,
              offerValue:
                deal?.dealState?.idDealState !== DEAL_STATE_ID.OrderPlaced
                  ? deal?.revenue
                  : null,
              revenue:
                deal?.dealState?.idDealState === DEAL_STATE_ID.OrderPlaced
                  ? deal?.revenue
                  : null,
              dealStateDesc: this.translate.instant(
                `DEAL_STATE_NAME.${DEAL_STATE_NAME[deal?.dealState?.name]}`
              ),
              unitOfMeasure: 
                deal?.uom?.description === 'Unit' 
                  ? this.translate.instant(`COMMON.${deal?.uom?.description}`)
                  : deal?.uom?.name,
              index: ind,
            })
          );
          this.oldDeals = deals.map(
            (deal: IDeal, ind: number): IDealForTable => ({
              ...deal,
              dateDeal: deal?.dateDeal,
              dateDealMs: new Date(deal?.dateDeal).getTime(),
              commerceSectorForTable: deal?.commerceSector?.name,
              quantity: deal?.quantity,
              offerValue:
                deal?.dealState?.idDealState !== DEAL_STATE_ID.OrderPlaced
                  ? deal?.revenue
                  : null,
              revenue:
                deal?.dealState?.idDealState === DEAL_STATE_ID.OrderPlaced
                  ? deal?.revenue
                  : null,
              dealStateDesc: this.translate.instant(
                `DEAL_STATE_NAME.${DEAL_STATE_NAME[deal?.dealState?.name]}`
              ),
              unitOfMeasure:
                deal?.uom?.description === 'Unit' 
                  ? this.translate.instant(`COMMON.${deal?.uom?.description}`)
                  : deal?.uom?.name,
              index: ind,
            })
          );
        },
      })
    );
  }

  getDealStates(): void {
    this.subscriptions.add(
      this.crmService
        .getAllDealStates(true, true)
        .subscribe((dealStates: IDealState[]) => {
          this.dealStates = dealStates;
        })
    );
  }

  getDealStateReasons(): void {
    this.subscriptions.add(
      this.crmService
        .getDealStateReasons(true)
        .subscribe((dealStateReasons: IDealStateReason[]) => {
          this.dealStateReasons = dealStateReasons;
        })
    );
  }

  openDealDialog(deal?: IDealForTable): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      deal,
      unitsOfMeasure: this.unitsOfMeasure,
      translations: this.translations,
      contact: this.contact,
      dealStates: this.dealStates,
      dealStateReasons: this.dealStateReasons,
      commerceSectors: this.commerceSectors,
      isReadonly: this.isReadonly,
      idCommerceSector: this.idCommerceSector,
    };
    const dialogRef: MatDialogRef<any> = this.dialog.open(
      ContactDealDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (dialogRef.componentInstance.unitsOfMeasureAreModified) {
        this.refreshUnitsOfMeasure.emit(true);
      }
      if (result) {
        this.getDeals();
        this.refreshContactDetails.emit(true);
      }
    });
  }

  handleRangeDateDealTableFilter(
    event: MatDatepickerInputEvent<any, any>
  ): void {
    if (event.value) {
      const rangeDate: {} = {
        startDate: new Date(this.startDateDealFilter.value).getTime(),
        endDate: new Date(event.value).getTime(),
      };
      this.filterDealsTable('dealRangeDate', null, rangeDate);
    }
  }

  filterDealsTable(
    filter: string,
    select: MatSelectChange,
    rangeDate?: {}
  ): void {
    if (rangeDate) {
      this.dealFilterSelects[filter] = rangeDate;
    } else {
      this.dealFilterSelects[filter] = select.value;
    }
    this.deals = [];
    this.deals = this.oldDeals.filter((deal: IDealForTable) => {
      if (this.dealFilterSelects.commerceSector) {
        return (
          deal.commerceSector?.name === this.dealFilterSelects.commerceSector
          // this.translate.instant(
          //   'COMMERCE_SECTORS.' + this.filterSelects.commerceSector
          // )
        );
      }
      return deal;
    });
    this.deals = this.deals.filter((deal: IDealForTable) => {
      if (this.dealFilterSelects.dealStatus) {
        return (
          deal.dealStateDesc ===
          this.translate.instant(
            'DEAL_STATE_NAME.' + this.dealFilterSelects.dealStatus
          )
        );
      }
      return deal;
    });
    this.deals = this.deals.filter((deal: IDealForTable) => {
      if (this.dealFilterSelects.dealRangeDate) {
        return (
          deal.dateDealMs >= this.dealFilterSelects.dealRangeDate.startDate &&
          deal.dateDealMs <= this.dealFilterSelects.dealRangeDate.endDate
        );
      }
      return deal;
    });
  }

  resetDealsTableFilters(): void {
    this.deals = this.oldDeals;
    this.dealFilterSelects = {
      commerceSector: null,
      dealStatus: null,
      dealRangeDate: null,
    };
    this.dealCommerceSectorFilter.value = [];
    this.dealStatusFilter.value = [];
    this.startDateDealFilter.reset();
    this.endDateDealFilter.reset();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
