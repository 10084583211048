import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Note } from 'src/app/models/note';

@Component({
  selector: 'app-insert-notes-panel',
  templateUrl: './insert-notes-panel.component.html',
  styleUrls: ['./insert-notes-panel.component.scss']
})
export class InsertNotesPanelComponent implements OnInit, OnDestroy {

  private data: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>([]);

  revisionNotesForm: FormGroup;
  private subscriptions: Subscription = new Subscription();

  @Input() expanded: boolean = false;
  @Input() riTab: string;
  @Input() translations: any;
  @Input()
  set supervisorNotes(value) {
    this.data.next(value);
  }
  get supervisorNotes(): Note[] {
    return this.data.getValue();
  }
  @Input() saveNotesLoader: boolean;

  @Output() emitAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submitEmitter: EventEmitter<Note[]> = new EventEmitter<Note[]>();
  @Output() deleteNoteEmitter: EventEmitter<Note> = new EventEmitter<Note>();

  constructor(
    private fb: FormBuilder
  ) {
    this.revisionNotesForm = this.fb.group({
      notes: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(this.data.subscribe(x => {
      if (x) {
        this.notes.clear();
        x.forEach((note: Note) => {
          if (note.riTab === this.riTab) {
            if (note.checked === false) {
              this.addNote(note);
            }
          }
        });
      }
    }));
  }

  get notes(): FormArray {
    return this.revisionNotesForm.get('notes') as FormArray;
  }

  onCollapse(action: boolean): void {
    this.expanded = action;
    this.emitAction.emit(action);
  }

  addNote(note?: Note): void {
    this.notes.push(this.fb.group({
      idProjectNote: [ note?.idProjectNote || null ],
      riTab: [ this.riTab ],
      description: [ note?.description || '' , Validators.required ],
    }));
  }

  removeNote(i: number): void {
    const id = this.notes.at(i).get('idProjectNote')?.value;
    if (id) {
      this.deleteNoteEmitter.emit(id);
      this.notes.removeAt(i);
    } else {
      this.notes.removeAt(i);
    }
  }

  onSubmit(): void {
    this.submitEmitter.emit(this.revisionNotesForm.getRawValue().notes);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
