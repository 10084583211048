import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { IrFormComponent } from 'src/app/components/ir-form-abstract/ir-form-abstract-components';
import {
  ICustomerInformation,
  ICustomerRevenues,
} from 'src/app/models/customer-information';
import { Project } from 'src/app/models/project';
import { Note } from 'src/app/models/note';
import {
  currencyDefaultConfig,
  currencyQuantityConfig,
  currencyQuantityConfigWithoutDecimal,
  LAB_MANAGER_INTERNAL,
  NOTE_TYPE,
  RI_STATUS,
} from 'src/app/config';
import { NgxCurrencyModule } from 'ngx-currency';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { ISectors } from 'src/app/models/sectors';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { ICountries } from 'src/app/models/countries';
import { MatSelectChange } from '@angular/material/select';
import { UnitsOfMeasure } from 'src/app/models/utility';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChannelsManagementDialogComponent } from '../channels-management-dialog/channels-management-dialog.component';

@Component({
  selector: 'app-customer-information-form',
  templateUrl: './customer-information-form.component.html',
  styleUrls: ['./customer-information-form.component.scss'],
})
export class CustomerInformationFormComponent
  extends IrFormComponent
  implements OnInit, OnDestroy
{
  customerInformationForm: FormGroup;
  private subscriptions: Subscription = new Subscription();

  @Input() initialValues: Observable<Project>;
  @Input() translations: any;
  // @Input() isIsp: boolean;
  @Input() isLabManagerInternal: boolean;
  @Input() isReadOnly: boolean;

  @Output() submitFuncEmitter: EventEmitter<ICustomerInformation> =
    new EventEmitter();
  @Output() submitNoteFuncEmitter: EventEmitter<Note[]> = new EventEmitter();

  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;
  currencyQuantityConfigWithoutDecimal: NgxCurrencyModule =
    currencyQuantityConfigWithoutDecimal;

  commerceSectors: ISectors[] = [];
  startDataOnInit: any;
  dataIsReady: boolean;
  isIsp: boolean = false;
  idCustomer: number;

  unitsOfMeasure: UnitsOfMeasure[] = [];
  countries: ICountries[];

  constructor(
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private authService: AuthService,
    private irFormService: IrFormService,
    private dialog: MatDialog
  ) {
    super(fb);
    this.customerInformationForm = this.fb.group({
      idCustomerInformation: [null],
      idCustomer: [null],
      startup: [null, [Validators.required]],
      hasExport: [null, [Validators.required]],
      productionType: [null, [Validators.required]],
      commerceSector: this.fb.group({
        idCommerceSector: [null, [Validators.required]],
      }),
      product: [null],
      distribution: [null],
      priceSelling: [null],
      communicationMarketing: [null],
      revenueCustomerList: this.fb.array([
        this.fb.group({
          idCustomerInfoRevenue: [null],
          year: [null],
          revenueCustomer: [null, Validators.required],
          revenueExport: [null],
          revenueImport: [null],
        }),
        this.fb.group({
          idCustomerInfoRevenue: [null],
          year: [null],
          revenueCustomer: [null, Validators.required],
          revenueExport: [null],
          revenueImport: [null],
        }),
        this.fb.group({
          idCustomerInfoRevenue: [null],
          year: [null],
          revenueCustomer: [null, Validators.required],
          revenueExport: [null],
          revenueImport: [null],
        }),
      ]),
      countriesOfImport: [null, [Validators.maxLength(255)]],
      hasExpDedicatedResource: [null, [Validators.required]],
      resourceTrained: [null, [Validators.required]],
      foreignLanguageKnown: [null, [Validators.maxLength(255)]],
      productCertification: [null, [Validators.maxLength(255)]],
      processCertification: [null, [Validators.maxLength(255)]],
      sectorProductAssociation: [null, [Validators.maxLength(255)]],
      exportStrategyMarketAdopted: [null, [Validators.required]],
      exportStrategyChannelAdopted: [null, [Validators.required]],
      exportStrategyScoutingClients: [null, [Validators.required]],
      customerTechnicalInfo: this.fb.group({
        idCustomerTechnicalInfo: [null],
        employeeProd: [null],
        employeeCommerceItaly: [null],
        employeeCommerceExport: [null],
        employeeOther: [null],
        unexpressedProdCapacity: [null, [Validators.max(100)]],
        seasonalityProducts: [null],
        seasonalityProductsDesc: [null],
        incidenceTrasportCost: [null],
        existsPriceList: [null],
        priceListDesc: [null],
        paymentSystemDesc: [null],
        creditProtectionAdopted: [null],
        creditProtectionAdoptedDesc: [null],
        surrenderDesc: [null],
        packingDesc: [null],
        deliveryTimeDesc: [null],
        degreeReliability: [null, [Validators.max(100)]],
        annualTurnoverCoverCost: [null],
        annualSalesTargetValue: [null],
        annualSalesTargetQty: [null],
        annualSalesTargetUom: [null],
      }),
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialiValues: Project) => {
          if (initialiValues.idProjectStatus >= RI_STATUS.IspWorking) {
            this.isIsp = true;
          }
          this.dataIsReady = true;
          if (this.isIsp) {
            this.commerceSectors = initialiValues.commerceSector;
            this.idCustomer = initialiValues.customer.idCustomer;
            if (!this.unitsOfMeasure?.length) {
              this.unitsOfMeasure = initialiValues.unitsOfMeasure;
            }
            this.customerInformationForm.patchValue({
              ...initialiValues.customerInformation,
              idCustomer: initialiValues?.customer?.idCustomer,
              revenueCustomerList: this.getRevenueCustomersList(
                initialiValues.customerInformation?.revenueCustomerList
              ),
              commerceSector:
                initialiValues?.customerInformation?.commerceSector || {},
              customerTechnicalInfo:
                initialiValues?.customerInformation?.customerTechnicalInfo ||
                {},
            });
            this.customerInformationForm
              .get('product')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('product')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('distribution')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('distribution')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('priceSelling')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('priceSelling')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('communicationMarketing')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('communicationMarketing')
              .updateValueAndValidity();

            // Technical info section
            this.customerInformationForm
              .get('customerTechnicalInfo.unexpressedProdCapacity')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.unexpressedProdCapacity')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.seasonalityProducts')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.seasonalityProducts')
              .updateValueAndValidity();
            if (
              this.customerInformationForm.get(
                'customerTechnicalInfo.seasonalityProducts'
              ).value
            ) {
              this.customerInformationForm
                .get('customerTechnicalInfo.seasonalityProductsDesc')
                .setValidators(Validators.required);
              this.customerInformationForm
                .get('customerTechnicalInfo.seasonalityProductsDesc')
                .updateValueAndValidity();
            }
            this.customerInformationForm
              .get('customerTechnicalInfo.incidenceTrasportCost')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.incidenceTrasportCost')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.existsPriceList')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.existsPriceList')
              .updateValueAndValidity();
            if (
              this.customerInformationForm.get(
                'customerTechnicalInfo.existsPriceList'
              ).value
            ) {
              this.customerInformationForm
                .get('customerTechnicalInfo.priceListDesc')
                .setValidators(Validators.required);
              this.customerInformationForm
                .get('customerTechnicalInfo.priceListDesc')
                .updateValueAndValidity();
            }
            this.customerInformationForm
              .get('customerTechnicalInfo.paymentSystemDesc')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.paymentSystemDesc')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.creditProtectionAdopted')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.creditProtectionAdopted')
              .updateValueAndValidity();
            if (
              this.customerInformationForm.get(
                'customerTechnicalInfo.creditProtectionAdopted'
              ).value
            ) {
              this.customerInformationForm
                .get('customerTechnicalInfo.creditProtectionAdoptedDesc')
                .setValidators(Validators.required);
              this.customerInformationForm
                .get('customerTechnicalInfo.creditProtectionAdoptedDesc')
                .updateValueAndValidity();
            }
            this.customerInformationForm
              .get('customerTechnicalInfo.surrenderDesc')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.surrenderDesc')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.packingDesc')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.packingDesc')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.deliveryTimeDesc')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.deliveryTimeDesc')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.degreeReliability')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.degreeReliability')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.annualTurnoverCoverCost')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.annualTurnoverCoverCost')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.annualSalesTargetValue')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.annualSalesTargetValue')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.annualSalesTargetQty')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.annualSalesTargetQty')
              .updateValueAndValidity();
            this.customerInformationForm.markAsPristine();
            this.customerInformationForm.markAsUntouched();
          } else {
            this.commerceSectors = initialiValues.commerceSector;
            this.customerInformationForm.patchValue({
              ...initialiValues.customerInformation,
              idCustomer: initialiValues?.customer?.idCustomer,
              revenueCustomerList: this.getRevenueCustomersList(
                initialiValues.customerInformation?.revenueCustomerList
              ),
              commerceSector:
                initialiValues?.customerInformation?.commerceSector || {},
              customerTechnicalInfo:
                initialiValues?.customerInformation?.customerTechnicalInfo ||
                {},
            });
            this.customerInformationForm
              .get('product')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('product')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('distribution')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('distribution')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('priceSelling')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('priceSelling')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('communicationMarketing')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('communicationMarketing')
              .updateValueAndValidity();
            this.customerInformationForm.markAsPristine();
            this.customerInformationForm.markAsUntouched();
          }
          if (this.isLabManagerInternal || this.isReadOnly) {
            this.customerInformationForm.disable();
            // this.noteForm.disable();
          }
          this.startDataOnInit = {
            info: this.customerInformationForm.getRawValue(),
          };
          this.countries = initialiValues.countries;
        },
      })
    );
  }

  get revenuesCustomer(): FormArray {
    return this.customerInformationForm.get('revenueCustomerList') as FormArray;
  }

  getYearLabel(year: number, type: string) {
    const revenuesCustomerData = this.revenuesCustomer.getRawValue();
    let years = revenuesCustomerData.map((item) => item?.year);
    years = years.sort((a: number, b: number) => b - a);
    const index = years.findIndex((yearToCompare) => yearToCompare === year);
    switch (type) {
      case 'import': {
        return index === 0
          ? 'IR_FORM.FirstImportTurnover'
          : index === 1
          ? 'IR_FORM.SecondImportTurnover'
          : 'IR_FORM.ThirdImportTurnover';
      }
      case 'export': {
        return index === 0
          ? 'IR_FORM.FirstExportTurnover'
          : index === 1
          ? 'IR_FORM.SecondExportTurnover'
          : 'IR_FORM.ThirdExportTurnover';
      }
      case 'company': {
        return index === 0
          ? 'IR_FORM.FirstCompanyTurnover'
          : index === 1
          ? 'IR_FORM.SecondCompanyTurnover'
          : 'IR_FORM.ThirdCompanyTurnover';
      }
    }
  }

  toggleDescriptionRequired(field: string, value: boolean): void {
    if (value) {
      this.customerInformationForm
        .get(`customerTechnicalInfo.${field}`)
        .setValidators(Validators.required);
    } else {
      this.customerInformationForm
        .get(`customerTechnicalInfo.${field}`)
        .clearValidators();
      this.customerInformationForm
        .get(`customerTechnicalInfo.${field}`)
        .reset();
    }

    this.customerInformationForm
      .get(`customerTechnicalInfo.${field}`)
      .updateValueAndValidity();
  }

  /**
   * @description Inizialize form array of revenueCustomerList
   * @param revenueCustomerList The revenues list of customer
   */
  getRevenueCustomersList(
    revenueCustomerList: ICustomerRevenues[]
  ): ICustomerRevenues[] {
    if (!revenueCustomerList?.length) {
      const year = new Date().getFullYear() - 1;
      return [
        {
          year,
          revenueCustomer: null,
          revenueExport: null,
          revenueImport: null,
        },
        {
          year: year - 1,
          revenueCustomer: null,
          revenueExport: null,
          revenueImport: null,
        },
        {
          year: year - 2,
          revenueCustomer: null,
          revenueExport: null,
          revenueImport: null,
        },
      ];
    }
    const fillArray = [
      {
        idCustomerInfoRevenue: revenueCustomerList[0].idCustomerInfoRevenue,
        year: revenueCustomerList[0].year,
        revenueCustomer: revenueCustomerList[0].revenueCustomer,
        revenueExport: revenueCustomerList[0].revenueExport,
        revenueImport: revenueCustomerList[0].revenueImport,
      },
      {
        idCustomerInfoRevenue: revenueCustomerList[1]?.idCustomerInfoRevenue,
        year: revenueCustomerList[1]?.year || revenueCustomerList[0].year - 1,
        revenueCustomer: revenueCustomerList[1]?.revenueCustomer,
        revenueExport: revenueCustomerList[1]?.revenueExport,
        revenueImport: revenueCustomerList[1]?.revenueImport,
      },
      {
        idCustomerInfoRevenue: revenueCustomerList[2]?.idCustomerInfoRevenue,
        year: revenueCustomerList[2]?.year || revenueCustomerList[0].year - 2,
        revenueCustomer: revenueCustomerList[2]?.revenueCustomer,
        revenueExport: revenueCustomerList[2]?.revenueExport,
        revenueImport: revenueCustomerList[2]?.revenueImport,
      },
    ];
    fillArray.sort((a, b) => a.year - b.year);
    return fillArray;
  }

  onSubmit(): void {
    if (this.isIsp) {
      if (
        this.customerInformationForm.valid &&
        this.customerInformationForm.dirty
      ) {
        this.submitFuncEmitter.emit(this.customerInformationForm.getRawValue());
      }
    } else {
      if (
        this.customerInformationForm.valid &&
        this.customerInformationForm.dirty
      ) {
        this.submitFuncEmitter.emit(this.customerInformationForm.getRawValue());
      }
    }
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(
        {
          info: this.customerInformationForm.getRawValue(),
          // note: this.noteForm.getRawValue(),
        },
        this.irFormService.replacer
      )
    );
  }

  checkInvalidForm(): boolean {
    this.customerInformationForm.markAllAsTouched();
    return this.customerInformationForm?.invalid;
  }

  private __getValidators(): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    // This Controls must be required only if User have isp functionality create and edit
    if (this.functionalities.isp.create || this.functionalities.isp.edit) {
      validators.push(
        Validators.required,
        Validators.min(1),
        Validators.max(10)
      );
    }
    return validators;
  }

  calculatePercentage(type: string): number {
    let ret: number;
    const total: number =
      this.customerInformationForm.get('customerTechnicalInfo.employeeProd')
        ?.value +
      this.customerInformationForm.get(
        'customerTechnicalInfo.employeeCommerceItaly'
      )?.value +
      this.customerInformationForm.get(
        'customerTechnicalInfo.employeeCommerceExport'
      )?.value +
      this.customerInformationForm.get('customerTechnicalInfo.employeeOther')
        ?.value;
    switch (type) {
      case 'prod':
        ret =
          (this.customerInformationForm.get(
            'customerTechnicalInfo.employeeProd'
          )?.value /
            total) *
          100;
        break;
      case 'italy':
        ret =
          (this.customerInformationForm.get(
            'customerTechnicalInfo.employeeCommerceItaly'
          )?.value /
            total) *
          100;
        break;
      case 'export':
        ret =
          (this.customerInformationForm.get(
            'customerTechnicalInfo.employeeCommerceExport'
          )?.value /
            total) *
          100;
        break;
      case 'other':
        ret =
          (this.customerInformationForm.get(
            'customerTechnicalInfo.employeeOther'
          )?.value /
            total) *
          100;
        break;
    }
    return ret;
  }

  showUomManagementDialog(event: PointerEvent): void {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '900px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      managementType: 'uom',
      uom: this.unitsOfMeasure,
      idCustomer: this.idCustomer,
    };
    const dialogRef = this.dialog.open(
      ChannelsManagementDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'refresh') {
        this.subscriptions.add(
          this.irFormService
            .getUnitsOfMeasure(false, false, this.idCustomer)
            .subscribe({
              next: (uom: UnitsOfMeasure[]) => {
                this.unitsOfMeasure = uom;
              },
            })
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
