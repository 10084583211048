import { HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

export class ServiceExtension {
  /**
   * @description Add hideLoader header
   * @param hideLoader boolean that define if hide loader
   */
  protected _getHeaders(
    hideLoader: boolean = false,
    hideToast: boolean = false,
    toCache: boolean = false,
    headers?: HttpHeaders
  ): HttpHeaders {
    return new HttpHeaders({
      ...headers,
      hideLoader: hideLoader ? 'true' : 'false',
      hideToast: hideToast ? 'true' : 'false',
      toCache: toCache ? 'true' : 'false',
    });
  }

  /**
   * @description execute a forkJoin
   * @param subscriptions Array or Object of subscriptions
   */
  executeForkJoin(
    subscriptions: Array<Observable<any>> | { [key: string]: Observable<any> }
  ): Observable<any> {
    return forkJoin(subscriptions).pipe();
  }
}
