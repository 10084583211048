<div class="section-card">
  <div class="header h70 multi">
    <div class="title">
      <span>{{
        isAgent
          ? ("CUSTOMERS.CustomersInAss" | translate)
          : ("CUSTOMERS.ActiveCustomers" | translate)
      }}</span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!activeCustomers?.length"
        (emitSearchValue)="searchValue($event, 'active')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="customersLoader"
      [columns]="activeCustomersTableColumns"
      [columnsButtons]="activeCustomersTableColumnsButtons"
      [data]="activeCustomers"
      [searchValue]="searchValueActiveCustomers"
    >
    </app-table>
  </div>
</div>
<div class="section-card mt-30" *ngIf="haveBM">
  <div class="header h70 multi">
    <div class="title">
      <span>{{ "CUSTOMERS.BMCustomers" | translate }}</span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!bMCustomers?.length"
        (emitSearchValue)="searchValue($event, 'bMCustomers')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="customersLoader"
      [columns]="bMCustomersTableColumns"
      [columnsButtons]="bMCustomersTableColumnsButtons"
      [data]="bMCustomers"
      [searchValue]="searchValueBMCustomers"
    >
    </app-table>
  </div>
</div>
<div class="section-card mt-30" *ngIf="isAgent">
  <div class="header h70 multi">
    <div class="title">
      <span>{{ "CUSTOMERS.CustomersHistory" | translate }}</span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!customersHistory?.length"
        (emitSearchValue)="searchValue($event, 'customersHistory')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="historyCustomersLoader"
      [columns]="customersHistoryTableColumns"
      [columnsButtons]="customersHistoryTableColumnsButtons"
      [data]="customersHistory"
      [searchValue]="searchValueCustomersHistory"
    >
    </app-table>
  </div>
</div>
<div class="section-card mt-30" *ngIf="isAuthorized">
  <div class="header h70 multi">
    <div class="title">
      <span>{{ "CUSTOMERS.NoActiveCustomers" | translate }}</span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!noActiveCustomers?.length"
        (emitSearchValue)="searchValue($event, 'noActive')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="customersLoader"
      [columns]="noactiveCustomersTableColumns"
      [columnsButtons]="noAactiveCustomersTableColumnsButtons"
      [data]="noActiveCustomers"
      [searchValue]="searchValueNoActiveCustomers"
    >
    </app-table>
  </div>
</div>

<!-- <div class="wrap-1200">
  <div class="ta-center" *ngIf="isAuthorized || haveBM">
    <span class="bold-text-color">{{ 'COMMON.ActiveCustomers' | translate }}</span>
  </div>
  <div>
    <app-customers-table
      [customersData]="activeCustomers"
      [hideOwner]="isDirection"
      [loader]="loader"
    ></app-customers-table>
  </div>
  <div class="mt-60 ta-center" *ngIf="haveBM">
    <span class="bold-text-color">{{ 'COMMON.BMCustomers' | translate }}</span>
  </div>
  <div *ngIf="haveBM">
    <app-customers-table
      [customersData]="bMCustomers"
      [hideOwner]="isDirection"
      [loader]="loader"
    ></app-customers-table>
  </div>
  <div class="mt-60 ta-center" *ngIf="isAgent">
    <span class="bold-text-color">{{ 'COMMON.CustomersHistory' | translate }}</span>
  </div>
  <div *ngIf="isAgent">
    <app-customers-table
      [customersData]="customersHistory"
      [hideOwner]="true"
      [loader]="loader"
    ></app-customers-table>
  </div>
  <div class="mt-60 ta-center" *ngIf="isAuthorized">
    <span class="bold-text-color">{{ 'COMMON.NoActiveCustomers' | translate }}</span>
  </div>
  <div *ngIf="isAuthorized">
    <app-customers-table
      [customersData]="noActiveCustomers"
      [hideOwner]="isDirection"
      [loader]="loader"
    ></app-customers-table>
  </div>
</div> -->
