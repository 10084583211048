
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { ICustomer, ICustomerForTable } from 'src/app/models/customer';
import { IColumnsName, ITableButtons, ITableOptions } from 'src/app/models/utility';
import { fromCustomerProjectToCustomerTable } from 'src/app/utility/elaborations';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersTableComponent implements OnInit, OnChanges {


  customersDataSource: MatTableDataSource<ICustomerForTable> = new MatTableDataSource<ICustomerForTable>();
  isAgent: boolean = false;
  isAgentSupervisor: boolean = false;
  isLabManager: boolean = false;
  isManagerSupervisor: boolean = false;
  isAgentAndAgentSupervisor: boolean = false;
  isManagerAndManagerSupervisor: boolean = false;

  @Input() customersData: ICustomer[];
  @Input() hideOwner: boolean;

  @Input() columnsName: IColumnsName[] = [
    {value: 'dateInsert', name: 'Data inserimento'},
    {value: 'businessName', name: 'Ragione Sociale'},
    {value: 'address', name: 'Indirizzo'},
    {value: 'email', name: 'Email'},
    {value: 'telephoneNumber', name: 'Telefono'},
    {value: 'isMyCustomer', name: 'Owner'}
  ];
  @Input() displayedColumns: string[] = [
    'dateInsert',
    'businessName',
    'address',
    'email',
    'telephoneNumber',
    'isMyCustomer',
    'buttons'
  ];
  @Input() tableButtons: ITableButtons[] = [
    { name: 'Prosegui', tooltip: 'CustomerDetail', click: (customer): void => { this.visualizeCustomer(customer); }, icon: 'read_more' }
  ];
  @Input() tableOptions: ITableOptions = {
    showFilter: true,
    showSort: true,
    showPaginator: true,
    showButtons: true
  };
  @Input() loader: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    if (this.hideOwner) {
      this.displayedColumns.splice(5, 1);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const customers: ICustomer[] = changes.customersData?.currentValue;
    if (customers?.length) {
      this.customersDataSource.data = customers.map(fromCustomerProjectToCustomerTable);
    }
  }

  visualizeCustomer(customer: ICustomerForTable): void {
    this.router.navigate([`/customers`, customer.idCustomer]);
  }

}
