<mat-card>
  <form [formGroup]="editAccountForm">
    <!--<div class="image mb-4">
      <img src="/assets/foto_profilo.jpg" width="150px" height="150px">
      <div *ngIf="!disableEditAccount" class="edit-image">
        <span class="material-icons app-color">edit</span>
        <input type="file" name="uploadImage">
      </div>  
    </div>-->
    <mat-form-field class="account-form-field">
      <mat-label for="name">
        {{ "USERS_MANAGEMENT.Name" | translate }}</mat-label
      >
      <input matInput formControlName="name" type="text" name="name" />
      <mat-error *ngIf="!controls.name.valid">{{
        controls.name.errors | getErrorMessage: translations?.Name
      }}</mat-error>
    </mat-form-field>
    <mat-form-field class="account-form-field">
      <mat-label for="surname">{{
        "USERS_MANAGEMENT.Surname" | translate
      }}</mat-label>
      <input matInput formControlName="surname" type="text" name="surname" />
      <mat-error *ngIf="!controls.surname.valid">{{
        controls.surname.errors | getErrorMessage: translations?.Surname
      }}</mat-error>
    </mat-form-field>
    <mat-form-field class="account-form-field">
      <mat-label for="telephone">{{
        "USERS_MANAGEMENT.Phone" | translate
      }}</mat-label>
      <input
        matInput
        formControlName="telephone"
        type="text"
        name="telephone"
      />
      <mat-error *ngIf="!controls.telephone.valid">{{
        controls.telephone.errors | getErrorMessage: translations?.Phone
      }}</mat-error>
    </mat-form-field>
    <mat-form-field class="account-form-field">
      <mat-label for="email">{{
        "USERS_MANAGEMENT.Email" | translate
      }}</mat-label>
      <input matInput formControlName="email" type="text" name="email" />
      <mat-error *ngIf="!controls.email.valid">{{
        controls.email.errors | getErrorMessage: translations?.Email
      }}</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="roles" class="account-form-field">
      <mat-label for="roles">{{
        "USERS_MANAGEMENT.Roles" | translate
      }}</mat-label>
      <input matInput readonly [value]="roles" type="text" name="roles" />
    </mat-form-field>
    <mat-form-field class="account-form-field">
      <mat-label for="password">{{ "COMMON.Password" | translate }}</mat-label>
      <input
        matInput
        formControlName="password"
        [type]="hide ? 'password' : 'text'"
        name="password"
      />
      <mat-error *ngIf="controls.password.invalid">{{
        controls.password.errors | getErrorMessage: translations?.Password
      }}</mat-error>
      <button mat-icon-button matSuffix (click)="hide = !hide">
        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="account-form-field">
      <mat-label for="confirmPassword">{{
        "RESET_PASSWORD.ConfirmPassword" | translate
      }}</mat-label>
      <input
        matInput
        [errorStateMatcher]="matcher"
        formControlName="confirmPassword"
        [type]="hideTwo ? 'password' : 'text'"
        name="confirmPassword"
      />
      <mat-error *ngIf="editAccountForm.hasError('notSame')">{{
        (controls.confirmPassword.errors
          | getErrorMessage: translations?.ConfirmPassword) ||
          "ACCOUNT_FORM.PasswordsDoNotMatch" | translate
      }}</mat-error>
      <button mat-icon-button matSuffix (click)="hideTwo = !hideTwo">
        <mat-icon>{{ hideTwo ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
    </mat-form-field>
  </form>
  <div class="ta-center mt-30">
    <div class="d-inline-block mr-30">
      <button
        class="rounded-button primary-bg-button"
        type="button"
        (click)="cancelAndGoHome()"
      >
        {{ "COMMON.Cancel" | translate }}
      </button>
    </div>
    <div class="d-inline-block">
      <button
        class="rounded-button error-bg-button"
        type="button"
        [disabled]="editAccountForm.valid !== true"
        (click)="submit()"
      >
        {{ "COMMON.Save" | translate }}
      </button>
    </div>
  </div>
</mat-card>
