import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { fromProjectDtoToProjectTable } from 'src/app/utility/elaborations';
import { IProjectForTable, ProjectDTO } from 'src/app/models/project';
import { ITableButton, ITableColumn, Select } from 'src/app/models/utility';
import { RI_STATUS, RI_STATUS_STRING } from 'src/app/config';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-all-projects',
  templateUrl: './all-projects.component.html',
  styleUrls: ['./all-projects.component.scss'],
})
export class AllProjectsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  translations: any;
  statusSelected: RI_STATUS = 1;
  projects: IProjectForTable[];
  oldProjects: IProjectForTable[];
  projectsTableColumns: ITableColumn[];
  projectsTableButtons: ITableButton[];
  projectsLoader: boolean = false;
  searchValueProjects: FormControl = new FormControl();
  filterTableControl: FormControl = new FormControl(RI_STATUS.RiWorking);

  /**
   * @description The Select object to choose the status of project to show in the table
   */
  statusSelect: Select[] = [
    { label: RI_STATUS_STRING.RI_STATUS_1, value: RI_STATUS.RiWorking },
    { label: RI_STATUS_STRING.RI_STATUS_2, value: RI_STATUS.RiDelivered },
    { label: RI_STATUS_STRING.RI_STATUS_3, value: RI_STATUS.RiApproved },
    { label: RI_STATUS_STRING.RI_STATUS_4, value: RI_STATUS.NoContract },
    { label: RI_STATUS_STRING.RI_STATUS_5, value: RI_STATUS.IspWorking },
    { label: RI_STATUS_STRING.RI_STATUS_6, value: RI_STATUS.IspDelivered },
    { label: RI_STATUS_STRING.RI_STATUS_8, value: RI_STATUS.Closed },
    { label: RI_STATUS_STRING.RI_STATUS_9, value: RI_STATUS.IspWorking2 },
    { label: RI_STATUS_STRING.RI_STATUS_10, value: RI_STATUS.IspWorking3 },
    { label: RI_STATUS_STRING.RI_STATUS_11, value: RI_STATUS.IspWorking4 },
    { label: RI_STATUS_STRING.RI_STATUS_12, value: RI_STATUS.IspWorking5 },
    { label: RI_STATUS_STRING.RI_STATUS_13, value: RI_STATUS.IspWorking6 },
    { label: RI_STATUS_STRING.RI_STATUS_15, value: RI_STATUS.IspDelivered2 },
    { label: RI_STATUS_STRING.RI_STATUS_16, value: RI_STATUS.IspDelivered3 },
    { label: RI_STATUS_STRING.RI_STATUS_17, value: RI_STATUS.IspDelivered4 },
    { label: RI_STATUS_STRING.RI_STATUS_18, value: RI_STATUS.IspDelivered5 },
    { label: RI_STATUS_STRING.RI_STATUS_19, value: RI_STATUS.IspDelivered6 },
  ];

  constructor(
    private irFormService: IrFormService,
    private layoutService: LayoutService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.translate.get('COMMON').subscribe({
        next: (data: any) => {
          this.translations = data;
          this.generateBreadCrumb();
          this.getAllProject();
        },
      })
    );
  }

  /**
   * @description Generate the breadcrumb
   */
  generateBreadCrumb(): void {
    this.subscriptions.add(
      this.layoutService.generateBreadcrumb([
        {
          label: this.translations.ProjectsManagement,
          path: null,
          pageTitle: true,
        },
      ])
    );
  }

  /**
   * @description Get all project filterd for status without filter the owner
   */
  getAllProject(): void {
    this.projectsLoader = true;
    this.subscriptions.add(
      this.irFormService
        .getAllUserProjectByStatus(this.statusSelected, true)
        .subscribe((projects: ProjectDTO[]): void => {
          this.projects = projects.map(fromProjectDtoToProjectTable);
          this.oldProjects = projects.map(fromProjectDtoToProjectTable);
          this.filterTable(new MatSelectChange(null, RI_STATUS.RiWorking));
          this.projectsTableColumns = [
            {
              name: 'lastModified',
              translatedName: this.translate.instant('COMMON.LastWorking'),
              type: 'date',
            },
            {
              name: 'businessName',
              translatedName: this.translate.instant('COMMON.BusinessName'),
            },
            {
              name: 'email',
              translatedName: this.translate.instant('COMMON.Email'),
            },
            {
              name: 'telephoneNumber',
              translatedName: this.translate.instant('COMMON.Telephone'),
            },
            {
              name: 'address',
              translatedName: this.translate.instant('COMMON.Address'),
            },
            {
              name: 'agentOrManager',
              translatedName: this.translate.instant('COMMON.Agent/LabManager'),
            },
            {
              name: 'buttons',
              disableSort: true,
            },
          ];
          this.projectsTableButtons = [
            {
              icon: 'folder_icon',
              class: 'primary',
              disabled: (item: IProjectForTable) => {
                return !item?.documents?.length;
              },
              subMenu: {
                key: 'documents',
                clickFunction: (item: any) => {
                  this.visualizeDocument(item.idDocument);
                },
              },
            },
          ];
          this.projectsLoader = false;
        })
    );
  }

  visualizeDocument(idDocument: number | string): void {
    this.subscriptions.add(
      this.irFormService.downloadDocument(idDocument).subscribe()
    );
  }

  // TODO: chiedere a Mirko se bisogna aggiungere l'opzione "tutti"
  filterTable(select: MatSelectChange): void {
    this.projects = [];
    this.projects = this.oldProjects.filter((project: IProjectForTable) => {
      return project.idProjectStatus === select.value;
    });
  }

  resetFilters(): void {
    this.searchValueProjects.setValue(null);
    this.filterTableControl.setValue(RI_STATUS.RiWorking);
    this.filterTable(new MatSelectChange(null, RI_STATUS.RiWorking));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
