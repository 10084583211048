import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Swot, SwItem, OtItem } from 'src/app/models/swot';
import { Project } from 'src/app/models/project';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { IrFormComponent } from 'src/app/components/ir-form-abstract/ir-form-abstract-components';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';

@Component({
  selector: 'app-swot-form',
  animations: [
    trigger('addTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 })),
      ]),
    ]),
    trigger('moveTrigger', [transition('* => *', [animate('300ms')])]),
  ],
  templateUrl: './swot-form.component.html',
  styleUrls: ['./swot-form.component.scss'],
})
export class SwotFormComponent
  extends IrFormComponent
  implements OnInit, OnDestroy
{
  swotForm: FormGroup;
  private subscriptions: Subscription = new Subscription();
  idCustomer: number | string;

  swot: {
    strengths: SwItem[];
    weaknesses: SwItem[];
    opportunities: OtItem[];
    threats: OtItem[];
  } = {
    strengths: [],
    weaknesses: [],
    opportunities: [],
    threats: [],
  };

  swHelperMenuItems: string[];
  otHelperMenuItems: string[];

  startDataOnInit: any;

  object = Object;

  @Input() initialValues: Observable<Project>;
  @Input() translations: any;
  @Input() index: any;
  @Input() tabIndex: any;
  @Input() isLabManagerInternal: boolean;
  @Input() isReadOnly: boolean;

  @Output() submitFuncEmitter: EventEmitter<Swot> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private irFormService: IrFormService
  ) {
    super(fb);
    this.swotForm = fb.group({
      idCustomer: [this.idCustomer || null],
      idSwotUspAnalysis: [null],
      swDescription: [null, [Validators.maxLength(255)]],
      otDescription: [null, [Validators.maxLength(255)]],
      uspDescription: [null, [Validators.maxLength(255)]],
      swItems: fb.array([]),
      otItems: fb.array([]),
    });
  }

  ngOnInit(): void {
    this.swHelperMenuItems = [
      this.translations.Gamma,
      this.translations.Quality,
      this.translations.ReS,
      this.translations.Patents,
      this.translations.PrePostSalesServices,
      this.translations.CommercialCapacity,
      this.translations.LoyaltyCapacity,
      this.translations.Prices,
      this.translations.SalesConditions,
      this.translations.PrePostSalesServices,
      this.translations.Marketing,
      this.translations.Communication,
      this.translations.LeadGeneration,
      this.translations.HumanResources,
    ];
    this.otHelperMenuItems = [
      this.translations.BarriersToEntry,
      this.translations.MarketTrend,
      this.translations.SocioEconomicFactors,
      this.translations.MarketingAndCommunicationBarriers,
      this.translations.ConsumerPurchasingBehavior,
      this.translations.ExpressedUnexpressedNeeds,
      this.translations.ConsumedProductsFeatures,
      this.translations.CompetitionProductsFeatures,
      this.translations.CompetitionCommercialBehavior,
      this.translations.CompetitionPricesAndConditions,
      this.translations.CompetitorsCommunicationAndMarketing,
    ];
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialValues: Project) => {
          this.updateIdCustomerInfoDescription(
            initialValues?.customerDescription
          );
          this.swot.strengths = [];
          this.swot.weaknesses = [];
          this.swot.opportunities = [];
          this.swot.threats = [];
          this.swotForm.patchValue(initialValues.swot);
          this.idCustomer = initialValues?.customer?.idCustomer;
          this.swotForm.controls.idCustomer.setValue(this.idCustomer);
          this.swItems.clear();
          this.otItems.clear();
          initialValues?.swot?.swItems?.forEach((swItem: SwItem) => {
            if (swItem?.idSwotUspType === 1 || swItem?.idSwotUspType === 5) {
              this.swot.strengths.push(swItem);
            };
            if (swItem?.idSwotUspType === 2) {
              this.swot.weaknesses.push(swItem);
            };
            this.addSwItem(swItem);
          });
          initialValues?.swot?.otItems?.forEach((otItem: OtItem) => {
            if (otItem?.idSwotUspType === 3) {
              this.swot.opportunities.push(otItem);
            };
            if (otItem?.idSwotUspType === 4) {
              this.swot.threats.push(otItem);
            };
            this.addOtItem(otItem);
          });
          if (!this.swot.strengths.length) {
            this.addSwItem({ idSwotUspType: 1 });
          };
          if (!this.swot.weaknesses.length) {
            this.addSwItem({ idSwotUspType: 2 });
          };
          if (!this.swot.opportunities.length) {
            this.addOtItem({ idSwotUspType: 3 });
          };
          if (!this.swot.threats.length) {
            this.addOtItem({ idSwotUspType: 4 });
          };
          this.swotForm.markAsPristine();
          this.swotForm.markAsUntouched();
          if (this.isLabManagerInternal || this.isReadOnly) {
            this.swotForm.disable();
            this.customerDescriptionForm.disable();
          }
          this.startDataOnInit = {
            swot: this.swotForm.getRawValue(),
            description: this.customerDescriptionForm.get('ext1').value,
          };
        },
      })
    );
  }

  get swItems(): FormArray {
    return this.swotForm.get('swItems') as FormArray;
  }

  get otItems(): FormArray {
    return this.swotForm.get('otItems') as FormArray;
  }

  addSwItem(swItem?: SwItem): void {
    this.swItems.push(
      this.fb.group({
        idSwotUspItem: [swItem?.idSwotUspItem || null],
        title: [
          swItem?.title || '',
          [Validators.required, Validators.maxLength(50)],
        ],
        description: [
          swItem?.description || '',
          [Validators.required, Validators.maxLength(500)],
        ],
        levelOfInterest: [swItem?.levelOfInterest || '', [Validators.required]],
        idSwotUspType: [swItem?.idSwotUspType || ''],
      })
    );
  }

  addOtItem(otItem?: OtItem): void {
    this.otItems.push(
      this.fb.group({
        idSwotUspItem: [otItem?.idSwotUspItem || null],
        title: [
          otItem?.title || '',
          [Validators.required, Validators.maxLength(50)],
        ],
        description: [
          otItem?.description || '',
          [Validators.required, Validators.maxLength(500)],
        ],
        levelOfInterest: [otItem?.levelOfInterest || '', [Validators.required]],
        idSwotUspType: [otItem?.idSwotUspType || ''],
      })
    );
  }

  removeSwItem(i: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.swItems.removeAt(i);
        this.swotForm.markAsDirty();
      }
    });
  }

  removeOtItem(i: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.otItems.removeAt(i);
        this.swotForm.markAsDirty();
      }
    });
  }

  checkUspValue(item): boolean {
    return item?.controls?.idSwotUspType?.value === 5;
  }

  uspModelChange(event, item): void {
    const value: number = event.checked === true ? 5 : 1;
    item?.controls?.idSwotUspType?.setValue(value);
    this.swotForm.markAsDirty();
  }

  onSubmit(): void {
    if (this.swotForm.valid && this.swotForm.dirty) {
      this.submitFuncEmitter.emit(this.swotForm.getRawValue());
    }
    // this.customerDescrSubmit();
  }

  submitComment(): void {
    this.customerDescrSubmit('ext1');
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(
        {
          swot: this.swotForm.getRawValue(),
          description: this.customerDescriptionForm.get('ext1').value,
        },
        this.irFormService.replacer
      )
    );
  }

  checkInvalidForm(): boolean {
    this.swotForm.markAllAsTouched();
    return this.swotForm?.invalid;
  }

  setTitleFromSWHelper(item: string, index: number): void {
    (this.swItems.at(index) as FormGroup).get('title').setValue(item);
    this.swItems.markAsDirty();
    this.swItems.markAsTouched();
  }

  setTitleFromOTHelper(item: string, index: number): void {
    (this.otItems.at(index) as FormGroup).get('title').setValue(item);
    this.otItems.markAsDirty();
    this.otItems.markAsTouched();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
