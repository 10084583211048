import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { ICustomer, ICustomerContact } from 'src/app/models/customer';
import { Project } from 'src/app/models/project';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { ICountries } from '../../models/countries';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { PositionDialogComponent } from 'src/app/components/position-dialog/position-dialog.component';
import { CONTACT_REFERENCE_TYPE, RI_STATUS, TITLE_TYPE } from 'src/app/config';
import { CommonService } from 'src/app/services/common/common.service';
import { INominatimCoordinates, ToastStatus } from 'src/app/models/utility';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-customer-form',
  animations: [
    trigger('addTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 })),
      ]),
    ]),
    trigger('moveTrigger', [transition('* => *', [animate('300ms')])]),
  ],
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
})
export class CustomerFormComponent implements OnInit, OnDestroy {
  customerForm: FormGroup;
  private subscriptions: Subscription = new Subscription();
  idCustomer: number;
  // referentRoles: IReferentRole[];
  customerReferenceType: any = CONTACT_REFERENCE_TYPE;
  titleType: string[] = [];
  customer: ICustomer;
  alternativePositionCity: number[];

  /** filtered variables */
  countries: ICountries[];

  /** filtered variables */
  filteredCountries: ICountries[];

  /** control for the MatSelect filter keyword */
  public entityFilterCtrl: FormControl = new FormControl();

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  @Input() initialValues: Observable<Project>;
  @Input() translations: any;
  @Input() isLabManagerInternal: boolean;
  @Input() isReadOnly: boolean;
  @Input() isNoContract: boolean;

  @Output() submitFuncEmitter: EventEmitter<ICustomer> = new EventEmitter();

  /**** NEW ****/

  contactReferenceType: any = CONTACT_REFERENCE_TYPE;
  clonedReferent: ICustomerContact;
  startDataOnInit: any;

  prefixNumber: string;
  isIsp: boolean = false;

  constructor(
    private fb: FormBuilder,
    private irFormService: IrFormService,
    private dialog: MatDialog,
    private common: CommonService
  ) {
    this.customerForm = fb.group({
      idCustomer: [null],
      businessName: [null, [Validators.required, Validators.maxLength(45)]],
      yearFoundation: [null, [Validators.required, Validators.min(1900)]],
      address: this.fb.group({
        address: [{ value: null, disabled: true }, [Validators.required, Validators.maxLength(255)]],
        cap: [
          null,
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5),
            Validators.pattern('[0-9]+'),
          ],
        ],
        city: [null, [Validators.required, Validators.maxLength(255)]],
        province: [null, [Validators.required, Validators.maxLength(255)]],
        country: this.fb.group({
          idCountry: [null, [Validators.required]],
        }),
        position: [[]],
      }),
      telephoneNumber: [
        null,
        [Validators.required, Validators.maxLength(20), Validators.pattern('[0-9]+')],
      ],
      email: [null, [Validators.required, Validators.email, Validators.maxLength(45)]],
      webSiteLink: [null, [Validators.required, Validators.maxLength(255)]],
      vatNumber: [null, [Validators.required]],
      fiscalCode: [null],
      status: [null],
      customerContact: fb.array([]),
    });
  }

  ngOnInit(): void {
    // this.subscriptions.add(
    //   this.irFormService
    //     .getReferentRoles()
    //     .subscribe((data: IReferentRole[]) => {
    //       this.referentRoles = data;
    //     })
    // );
    Object.keys(TITLE_TYPE).forEach((title: string) => {
      this.titleType.push(title);
    });
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialValues: Project) => {
          if (initialValues.idProjectStatus >= RI_STATUS.IspWorking) {
            this.isIsp = true;
          }
          this.customer = initialValues?.customer;
          this.idCustomer = initialValues.customer.idCustomer;
          this.customerForm.patchValue(initialValues.customer);
          if (
            this.customerForm.get('address.city').value !== '' &&
            this.customerForm.get('address.city').value !== null
          ) {
            this.customerForm.get('address.address').enable();
          }
          this.countries = initialValues.countries;
          // Patch prefix phone from country if selected
          const phoneNumber: string =
            this.customerForm.getRawValue()?.telephoneNumber;
          const splittedNumber: string[] = phoneNumber.split(' ');
          if (splittedNumber?.length > 1) {
            this.customerForm
              .get('telephoneNumber')
              .setValue(splittedNumber[1]);
            this.customerForm.get('telephoneNumber').updateValueAndValidity();
            this.prefixNumber = splittedNumber[0];
          } else {
            if (this.customerForm.getRawValue()?.address?.country?.idCountry) {
              this.countries.forEach((country: ICountries) => {
                if (
                  country.idCountry ===
                  this.customerForm.getRawValue().address.country.idCountry
                ) {
                  this.prefixNumber = country.prefixNumber;
                }
              });
            }
          }
          this.customerContact.clear();
          let defaultContact: ICustomerContact;
          if (!this.isNoContract) {
            // Add Administrative Contact (new or already present)
            if (
              initialValues.customer.customerContact?.find(
                (contact: ICustomerContact) => {
                  if (
                    contact?.customerReferenceType?.idCustomerReferenceType ===
                    CONTACT_REFERENCE_TYPE.AdministrativeReferent
                  ) {
                    defaultContact = contact;
                    return (
                      contact.customerReferenceType.idCustomerReferenceType ===
                      CONTACT_REFERENCE_TYPE.AdministrativeReferent
                    );
                  }
                }
              )
            ) {
              this.addCompanyContact(
                defaultContact,
                CONTACT_REFERENCE_TYPE.AdministrativeReferent
              );
            } else {
              this.addCompanyContact(
                null,
                CONTACT_REFERENCE_TYPE.AdministrativeReferent
              );
            }
            // Add Project Contact (new or already present)
            if (
              initialValues.customer.customerContact?.find(
                (contact: ICustomerContact) => {
                  if (
                    contact?.customerReferenceType?.idCustomerReferenceType ===
                    CONTACT_REFERENCE_TYPE.ProjectReferent
                  ) {
                    defaultContact = contact;
                    return (
                      contact.customerReferenceType.idCustomerReferenceType ===
                      CONTACT_REFERENCE_TYPE.ProjectReferent
                    );
                  }
                }
              )
            ) {
              this.addCompanyContact(
                defaultContact,
                CONTACT_REFERENCE_TYPE.ProjectReferent
              );
            } else {
              this.addCompanyContact(
                null,
                CONTACT_REFERENCE_TYPE.ProjectReferent
              );
            }
          }
          // Add Business Matching Purchase Contact (new or already present)
          if (
            initialValues.customer.customerContact?.find(
              (contact: ICustomerContact) => {
                if (
                  contact.customerReferenceType.idCustomerReferenceType ===
                  CONTACT_REFERENCE_TYPE.BMPurchasingReferent
                ) {
                  defaultContact = contact;
                  return (
                    contact.customerReferenceType.idCustomerReferenceType ===
                    CONTACT_REFERENCE_TYPE.BMPurchasingReferent
                  );
                }
              }
            )
          ) {
            this.addCompanyContact(
              defaultContact,
              CONTACT_REFERENCE_TYPE.BMPurchasingReferent
            );
          } else {
            this.addCompanyContact(
              null,
              CONTACT_REFERENCE_TYPE.BMPurchasingReferent
            );
          }
          // Add Business Matching Sale Contact (new or already present)
          if (
            initialValues.customer.customerContact?.find(
              (contact: ICustomerContact) => {
                if (
                  contact.customerReferenceType.idCustomerReferenceType ===
                  CONTACT_REFERENCE_TYPE.BMSalesReferent
                ) {
                  defaultContact = contact;
                  return (
                    contact.customerReferenceType.idCustomerReferenceType ===
                    CONTACT_REFERENCE_TYPE.BMSalesReferent
                  );
                }
              }
            )
          ) {
            this.addCompanyContact(
              defaultContact,
              CONTACT_REFERENCE_TYPE.BMSalesReferent
            );
          } else {
            this.addCompanyContact(
              null,
              CONTACT_REFERENCE_TYPE.BMSalesReferent
            );
          }
          // Add other Contacts (new or already present)
          initialValues.customer.customerContact?.forEach(
            (contact: ICustomerContact) => {
              if (
                contact.customerReferenceType.idCustomerReferenceType ===
                CONTACT_REFERENCE_TYPE.OtherReferent
              ) {
                this.addCompanyContact(contact);
              }
            }
          );

          this.customerForm.markAsPristine();
          this.customerForm.markAsUntouched();
          this.customerContact.markAsPristine();
          this.customerContact.markAsUntouched();

          if (this.isLabManagerInternal || this.isReadOnly) {
            this.customerForm.disable();
          }

          /** Init variables and check on change */
          this.filteredCountries = initialValues.countries;
          this.filterEntities();
          this.entityFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterEntities();
            });
          this.startDataOnInit = this.customerForm.getRawValue();
        },
      })
    );
  }

  get customerContact(): FormArray {
    return this.customerForm.get('customerContact') as FormArray;
  }

  addCompanyContact(
    contact?: ICustomerContact,
    idCustomerReferenceType?: number
  ): void {
    this.customerContact.push(
      this.fb.group({
        idCustomerContact: [contact?.idCustomerContact || ''],
        title: [contact?.title || '', [Validators.required]],
        name: [
          contact?.name || '',
          [Validators.required, Validators.maxLength(45)],
        ],
        surname: [
          contact?.surname || '',
          [Validators.required, Validators.maxLength(45)],
        ],
        notes: [contact?.notes || ''],
        // role: this.fb.group({
        //   idCompanyRoleType: [
        //     contact?.role?.idCompanyRoleType || '',
        //     Validators.required,
        //   ],
        // }),
        email: [
          contact?.email || '',
          [Validators.required, Validators.email, Validators.maxLength(45)],
        ],
        telephoneNumber: [
          contact?.telephoneNumber || '',
          [
            // Validators.required,
            // Validators.pattern('[0-9]+'),
            Validators.maxLength(45),
          ],
        ],
        customerReferenceType: this.fb.group({
          idCustomerReferenceType: [
            contact?.customerReferenceType?.idCustomerReferenceType ||
              idCustomerReferenceType ||
              CONTACT_REFERENCE_TYPE.OtherReferent,
          ],
        }),
      })
    );
  }

  removeCompanyContact(customerContact: FormGroup, i: number): void {
    const contact: ICustomerContact = customerContact.getRawValue();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '340px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (contact.idCustomerContact) {
          this.subscriptions.add(
            this.irFormService
              .deleteCustomerContact(contact.idCustomerContact)
              .subscribe({
                complete: () => {
                  this.customerContact.removeAt(i);
                  this.customerForm.markAsDirty();
                },
              })
          );
        } else {
          this.customerContact.removeAt(i);
          this.customerForm.markAsDirty();
        }
      }
    });
  }

  onSubmit(): void {
    if (this.customerForm.valid && this.customerForm.dirty) {
      const body: ICustomer = this.customerForm.getRawValue();
      body.telephoneNumber = `${this.prefixNumber} ${body.telephoneNumber}`;
      this.submitFuncEmitter.emit(body);
      // this.customerForm.markAsPristine();
      // this.customerForm.markAsUntouched();
    }
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(
        this.customerForm.getRawValue(),
        this.irFormService.replacer
      )
    );
  }

  checkInvalidForm(): boolean {
    this.customerForm.markAllAsTouched();
    return this.customerForm?.invalid;
  }

  /** filter the entities */
  private filterEntities() {
    if (!this.countries) {
      return;
    }
    // get the search keyword
    let search = this.entityFilterCtrl.value;
    if (!search) {
      this.filteredCountries = this.countries;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the data
    this.filteredCountries = this.countries
      .filter((entity) => entity.country.toLowerCase().indexOf(search) > -1)
      .sort(
        (a, b) =>
          a.country.toLowerCase().indexOf(search) -
          b.country.toLowerCase().indexOf(search)
      );
  }

  copyReferent(referent: ICustomerContact): void {
    this.clonedReferent = referent;
    delete this.clonedReferent.customerReferenceType;
    delete this.clonedReferent.idCustomerContact;
    this.common.showToast(
      this.translations.ReferentCopiedIntoClipboard,
      ToastStatus.success,
      2000,
      null
    );
  }

  pasteReferent(destinationIndexReferent: number): void {
    if (this.clonedReferent) {
      this.customerContact
        .at(destinationIndexReferent)
        .patchValue(this.clonedReferent);
      this.customerForm.markAsDirty();
    }
  }

  setPrefixPhoneSelect(change: MatSelectChange): void {
    this.countries.forEach((country: ICountries) => {
      if (country.idCountry === change.value) {
        this.prefixNumber = country.prefixNumber;
      }
    });
  }

  openPositionDialog(): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '800px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      address: this.customerForm.get('address.address').value,
      position: this.customerForm.get('address.position').value,
      alternativePositionCity: this.alternativePositionCity,
    };
    const dialogRef = this.dialog.open(PositionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((position: number[]) => {
      if (position) {
        this.customerForm.get('address.position').setValue(position);
        if (
          JSON.stringify(position.sort()) !==
          JSON.stringify(dialogConfig.data.position.sort())
        ) {
          this.customerForm.markAsDirty();
        }
      }
    });
  }

  checkAndSetCoordinatesAutomatically(): void {
    if (this.customerForm.get('address.address').value !== '') {
      if (this.customerForm.get('address.address').dirty) {
        this.customerForm.get('address.position').setValue([]);
        this.common
          .getAddressFromCoordinates(
            `${this.customerForm.get('address.address').value} ${
              this.customerForm.get('address.city').value
            } ${this.customerForm.get('address.cap').value}`
          )
          .subscribe({
            next: (resp: INominatimCoordinates[]) => {
              this.customerForm.get('address.address').markAsPristine();
              if (resp.length) {
                this.customerForm
                  .get('address.position')
                  .setValue([resp[0].lon, resp[0].lat]);
              }
            },
            complete: () => {
              this.customerForm.get('address.position').markAsDirty();
            },
          });
      }
    }
  }

  checkCity(): void {
    if (this.customerForm.get('address.city').value !== '') {
      this.customerForm.get('address.address').enable();
    } else {
      this.customerForm.get('address.address').disable();
      this.customerForm.get('address.address').setValue(null);
      this.customerForm.get('address.position').setValue([]);
    }
  }

  // checkCityCoordinates(): void {
  //   if (this.customerForm.get('address.city').value) {
  //     this.common
  //       .getCityFromCoordinates(this.customerForm.get('address.city').value)
  //       .subscribe({
  //         next: (resp: INominatimCoordinates[]) => {
  //           this.alternativePositionCity = [
  //             parseFloat(resp[0]?.lon),
  //             parseFloat(resp[0]?.lat),
  //           ];
  //         },
  //       });
  //   }
  // }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    /** destroy entity variables */
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
