<!--<h3 class="welcome" [translate]="'SIGNUP.Welcome'" [translateParams]="{name: user.name, surname: user.surname}"></h3>
<h4 class="welcome" [translate]="'SIGNUP.WelcomeMessage'"></h4>-->
<div class="welcome">
  <div class="title app-color">
    <span
      [translate]="'SIGNUP.Welcome'"
      [translateParams]="{ name: name, surname: surname }"
    ></span>
  </div>
  <div class="message">{{ "SIGNUP.WelcomeMessage" | translate }}</div>
</div>
<div class="account-form-container">
  <app-account-form
    *ngIf="user"
    [initialValues]="user"
    [translations]="translations"
    [mandatoryPassword]="true"
    (submitFn)="confirmSignUp($event)"
  ></app-account-form>
</div>
