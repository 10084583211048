import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { LoggedUser } from 'src/app/models/user';
import { DisableDependentControl, MustMatch } from 'src/app/utility/validators';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );
    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.css'],
})
export class AccountFormComponent implements OnInit, OnChanges {
  editAccountForm: FormGroup;
  hide: boolean = true;
  hideTwo: boolean = true;
  showPasswordField: boolean = false;

  roles: string;

  matcher: MyErrorStateMatcher = new MyErrorStateMatcher();

  @Input() translations: any;
  @Input() initialValues: LoggedUser;
  @Input() mandatoryPassword: boolean = false;

  @Output() submitFn: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder, private router: Router) {}

  ngOnInit(): void {
    this.editAccountForm = this.fb.group(
      {
        name: [this.initialValues?.name || null, Validators.required],
        surname: [this.initialValues?.surname || null, Validators.required],
        telephone: [this.initialValues?.telephone || null, Validators.required],
        email: [this.initialValues?.email || null, Validators.required],
        password: [
          null,
          [
            Validators.pattern(/(?=^.{4,128}$)^[a-zA-Z0-9._@&$#!?-]+$/),
            ...this.__getPasswordValidators(),
          ],
        ],
        confirmPassword: [
          null,
          // [...this.__getPasswordValidators()]
        ],
      },
      {
        validator: [
          this.checkPasswords,
          //DisableDependentControl('password', 'confirmPassword'),
        ],
      }
    );
  }

  checkPasswords(group: FormGroup): any {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { translations } = changes;
    if (translations.currentValue) {
      this.roles =
        this.initialValues?.roles?.reduce(
          (value: string, elem: string) =>
            `${value ? `${value}, ` : ``}${this.translations?.[elem]}`,
          ''
        ) || '';
    }
  }

  get controls(): { [key: string]: AbstractControl } {
    return this.editAccountForm.controls;
  }

  submit(): void {
    if (this.editAccountForm.valid) {
      const account = this.editAccountForm.getRawValue();
      this.submitFn.emit(account);
    }
  }

  cancelAndGoHome(): void {
    this.router.navigate(['/']);
  }

  private __getPasswordValidators(): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if (this.mandatoryPassword) {
      validators.push(Validators.required);
    }
    return validators;
  }
}
