import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {
  BUYER_STATUS,
  BUYER_STATUS_DESCRIPTION,
  CRM_DETAIL_TABS_INDEX,
  PS_VERSION,
  Roles,
  storageKeys,
} from 'src/app/config';
import { IChannelsType } from 'src/app/models/channels-type';
import {
  IBuyerStatus,
  IBuyerStatusReason,
  IContact,
  IContactActivity,
  IContactActivityForTable,
  IContactCommercialSummary,
  IContactCommercialSummaryDTO,
  IContactCompetitor,
  IContactCompetitorForTable,
  IDeal,
  IDealStateReason,
  IOtherContactForTable,
} from 'src/app/models/contact';
import { IReferentRole } from 'src/app/models/referent-role';
import {
  IColumnsName,
  ITableButton,
  ITableButtons,
  ITableColumn,
  ITableOptions,
  ToastStatus,
} from 'src/app/models/utility';
import { CommonService } from 'src/app/services/common/common.service';
import { CrmService } from 'src/app/services/crm/crm.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import {
  fromContactMandatoryActivityToDataForTable,
  fromOtherContactToDataForTable,
} from 'src/app/utility/elaborations';
import { IReport } from 'src/app/models/report';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { CrmContactDetailComponent } from 'src/app/components/crm-contact-detail/crm-contact-detail.component';
import { ContactFormComponent } from 'src/app/components/contact-form/contact-form.component';
import { TableComponent } from 'src/app/components/table/table.component';
import { IProjectCustomer, ProjectDTO } from 'src/app/models/project';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { IManagerStatistics } from 'src/app/models/dashboard';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
// import { ICrmStatistics } from 'src/app/models/crm';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IClonable } from 'src/app/models/clonable';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { ICountries } from 'src/app/models/countries';

@Component({
  selector: 'app-crm-detail',
  templateUrl: './crm-detail.component.html',
  styleUrls: ['./crm-detail.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({
          height: '0',
          opacity: '0',
        }),
        animate(
          '.2s ease-out',
          style({
            height: '*',
            opacity: '1',
          })
        ),
      ]),
      transition(':leave', [
        style({
          height: '*',
          opacity: '1',
        }),
        animate(
          '.2s ease-out',
          style({
            height: '0',
            opacity: '0',
          })
        ),
      ]),
    ]),
  ],
})
export class CrmDetailComponent implements OnInit, OnDestroy {
  constructor(
    private common: CommonService,
    private translate: TranslateService,
    private irFormService: IrFormService,
    private crmService: CrmService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private layoutService: LayoutService,
    private dashboardService: DashboardService
  ) {}
  oldProjectActivities: any[];
  projectActivities: any[];
  projectActivitiesLoader: boolean = false;
  projectActivitiesTableColumns: ITableColumn[];
  projectActivitiesTableButtons: ITableButton[];
  activitiesFilterSelects: any = {
    status: null,
    country: null,
    date: null,
  };

  contactsFilterSelects: any = {
    status: null,
    country: null,
    date: null,
    channel: null
  };

  contactCompetitorsFilterSelects: any = {
    country: null,
  };

  oldContacts: IContact[];
  contacts: IContact[];
  clientCompetitors: IContact[];
  contactsLoader: boolean = false;
  contactsTableColumns: ITableColumn[];
  contactsTableButtons: ITableButton[];
  contactCompetitorsTableColumns: ITableColumn[];
  contactCompetitorsTableButtons: ITableButton[];
  contactCompetitorsDetailTableColumns: ITableColumn[];
  countries: string[] = [];
  countriesForMap: string[] = [];
  filteredCountriesContactCompetitors: string[] = [];
  buyerStatusDescription: IBuyerStatus[] = [];
  contactSummary: any = [];
  contactsCommercialSummaryTableColumns: ITableColumn[];

  matTabGroupIndex: number = 0;

  @ViewChild('searchValueActivities') searchValueActivities: ElementRef;
  @ViewChild('searchValueContacts') searchValueContacts: ElementRef;
  @ViewChild('searchValueCompetitors') searchValueCompetitors: ElementRef;
  @ViewChild('searchValueCommercialSummary') searchValueCommercialSummary: ElementRef;

  @ViewChild('activitiesStatusFilter') activitiesStatusFilter: MatSelect;
  @ViewChild('activitiesCountryFilter') activitiesCountryFilter: MatSelect;
  @ViewChild('contactsStatusFilter') contactsStatusFilter: MatSelect;
  @ViewChild('contactsChannelFilter') contactsChannelFilter: MatSelect;
  @ViewChild('contactsCountryFilter') contactsCountryFilter: MatSelect;
  @ViewChild('contactCompetitorsCountryFilter')
  contactCompetitorsCountryFilter: MatSelect;

  private subscriptions: Subscription = new Subscription();
  private contactSubscriptions: Subscription;
  private managedCustomersSubscription: Subscription;
  private selectedProjectForContactsDuplication: Subscription;
  contact: IContact;
  translations: any;
  idCustomer: number;
  idProject: number;
  project: ProjectDTO;
  projectInfo: any;
  psVersion: any = PS_VERSION;

  companyName: string;
  // countries: ICountries[];
  channels: IChannelsType[];
  buyerStatus: IBuyerStatus[];
  buyerStatusReason: IBuyerStatusReason[];
  referentRoles: IReferentRole[];
  dealStates: any[];
  dealStateReasons: IDealStateReason[];
  contactActivities: IContactActivity[];
  contactBuyerStatus: any[];
  contactDeals: IDeal[];
  oldContactCompetitors: any[];
  contactCompetitors: any[];
  contactReports: IReport[];
  contactPositionCallback: string;
  selectedTabIndex: number;
  selectedContactIndex: number;
  isReadonly: boolean = false;
  isDirection: boolean = false;
  userRole: string;

  // da verificare
  addContactStatement: boolean = false;

  showContactDetailsGeneralTab: boolean;
  showContactDetailsContactTab: boolean;
  showContactDetails: boolean = false;
  showContactDetailsLoader: boolean = false;

  /* General Tab */
  generalTabDataSource: MatTableDataSource<IContactActivityForTable> =
    new MatTableDataSource<IContactActivityForTable>();
  generalTabDisplayedColumns: string[] = [
    'contact',
    'buyerStatus',
    'country',
    'buyer',
    'buyerPhone',
    'buyerEmail',
    'dateFeedback',
    'feedbackDescription',
    'mandatory',
    'buttons',
  ];
  generalTabColumnsName: IColumnsName[];
  generalTabTableButtons: ITableButtons[] = [
    {
      click: (contact: IContact) => {
        this.viewContactDetails(contact, 1);
        // this.contactPositionCallback = 'general';
      },
      icon: 'read_more',
    },
  ];
  generalTabTableOptions: ITableOptions = {
    showSort: true,
    showPaginator: true,
    showButtons: true,
    showFilter: true,
  };
  generalTabLoader: boolean = false;

  /* Status Tabs */
  suspectTableDataSource: MatTableDataSource<IContactActivityForTable> =
    new MatTableDataSource<IContactActivityForTable>();
  prospectTableDataSource: MatTableDataSource<IContactActivityForTable> =
    new MatTableDataSource<IContactActivityForTable>();
  customersTableDataSource: MatTableDataSource<IContactActivityForTable> =
    new MatTableDataSource<IContactActivityForTable>();
  askButDontBuyTableDataSource: MatTableDataSource<IContactActivityForTable> =
    new MatTableDataSource<IContactActivityForTable>();
  notInterestedForNowTableDataSource: MatTableDataSource<IContactActivityForTable> =
    new MatTableDataSource<IContactActivityForTable>();

  /* Contact Tab */
  contactsTabDataSource: MatTableDataSource<any> =
    new MatTableDataSource<any>();
  contactsTabDisplayedColumns: string[] = [
    'businessName',
    'country',
    'phone',
    'email',
    'buyerStatus',
    'buttons',
  ];
  contactsTabColumnsName: IColumnsName[];
  contactsTabTableButtons: ITableButtons[] = [
    {
      click: (contact: IContact) => {
        this.viewContactDetails(contact, 0);
        this.contactPositionCallback = 'contact';
        this.addContactStatement = false;
      },
      icon: 'read_more',
    },
  ];
  contactsTabTableOptions: ITableOptions = {
    showSort: true,
    showPaginator: true,
    showButtons: true,
    showFilter: true,
  };
  contactsTabLoader: boolean = false;

  /* Other Tabs */
  otherStatusTabDataSource: MatTableDataSource<any> =
    new MatTableDataSource<any>();
  otherStatusTabDisplayedColumns: string[] = [
    'businessName',
    'buyerStatus',
    'country',
    'buyer',
    'telephoneNumber',
    'email',
    'buttons',
  ];
  otherStatusTabColumnsName: IColumnsName[];
  otherStatusTabTableButtons: ITableButtons[] = [
    {
      click: (contact: IContact) => {
        this.viewContactDetails(contact, 0);
      },
      icon: 'read_more',
    },
  ];
  otherStatusTabTableOptions: ITableOptions = {
    showButtons: true,
    showFilter: true,
    showPaginator: true,
    showSort: true,
  };
  otherStatusTabDataLoader: boolean = false;

  @ViewChild(ContactFormComponent) contactFormComponent: ContactFormComponent;
  @ViewChild(CrmContactDetailComponent)
  crmContactDetailComponent: CrmContactDetailComponent;
  @ViewChild(TableComponent) tableComponent: TableComponent;
  @ViewChild('crmDetailTabGroup') crmDetailTabGroup: MatTabGroup;
  parentTabName: string;
  projectCode: string;

  customerInfoReady: boolean;

  managerStatistics: IManagerStatistics;
  // crmStatistics: ICrmStatistics;
  contactsCommercialSummary: IContactCommercialSummary[];
  defaultSelectContactsFilter: number = BUYER_STATUS.Prospect;

  exportedBM: any[];
  exportedBMMappedForTable: any[];
  exportedBMColumns: ITableColumn[];
  exportedBMColumnButtons: ITableButton[];

  exportedLostOffers: any[];
  exportedLostOffersMappedForTable: any[];
  exportedLostOffersColumns: ITableColumn[];
  exportedLostOffersButtons: ITableButton[];

  exportedReport: any[];
  exportedReportMappedForTable: any[];
  exportedReportColumns: ITableColumn[];
  exportedReportsButtons: ITableButton[];

  // contactsToShowOnMap: IContact[] = [];
  // map: Map;
  // view: View = new View({
  //   zoom: 5,
  // });
  // showPopup: boolean = false;
  // showLateralPopup: boolean = false;

  @ViewChild('mapDiv') mapDiv: ElementRef;
  @ViewChild('popup') popup: ElementRef;
  @ViewChild('lateralPopup') lateralPopup: ElementRef;
  @ViewChild('opportunityDetailDialog') opportunityDetailDialog: any;
  @ViewChild('contactCompetitorDialog') contactCompetitorDialog: any;

  filterCountryMap: FormControl = new FormControl('all');
  filterStatusMap: FormControl = new FormControl('all');
  filterChannelMap: FormControl = new FormControl('all');

  checkBMOpportunitiesStatus: FormControl = new FormControl(false);
  checkLostOfferStatus: FormControl = new FormControl(false);
  checkReportStatus: FormControl = new FormControl(false);
  pageIsCustom: boolean;
  avoidCloseDialog: boolean = false;

  showDuplicateContactsSection: boolean = false;
  stepperIndex: number = 0;
  managedCustomersForTable: IProjectCustomer[];
  managedCustomersTableColumns: ITableColumn[];
  managedCustomersTableButtons: ITableButton[];
  managedCustomersTableLoader: boolean = false;
  currentProjectContactsTableColumns: ITableColumn[];
  currentProjectContactsTableCheckbox: any;
  alreadyExistingContactForTable: IOtherContactForTable[];
  alreadyExistingContactTableColumns: ITableColumn[];
  alreadyExistingContactTableButtons: ITableButton[];
  alreadyExistingContactTableLoader: boolean = false;
  selectedContactsToBeDuplicated: any[] = [];
  selectedContactsToBeDuplicatedForTable: any[] = [];
  selectedProjectForDuplication: any;
  searchValueProjectWhereToDuplicate: string;
  searchValueOldContacts: string;
  searchValueAlreadyExistingContacts: string;
  disableManagedCustomers: boolean = false;

  @ViewChild('contactsCountrySelection') contactsCountrySelection: MatSelect;

  ngOnInit(): void {
    // for (const key in BUYER_STATUS_DESCRIPTION) {
    //   if (key) {
    //     this.buyerStatusDescription.push(BUYER_STATUS_DESCRIPTION[key]);
    //   }
    // }
    this.subscriptions.add(
      this.translate.get('CRM_DETAIL').subscribe((translations: any) => {
        this.translations = translations;
        this.projectActivitiesTableColumns = [
          { name: 'contact', translatedName: this.translations.Contact },
          { name: 'buyerStatus', translatedName: this.translations.Status2 },
          { name: 'country', translatedName: this.translations.Country },
          { name: 'multiRowContact', translatedName: this.translations.Buyer },
          {
            name: 'dateFeedback',
            translatedName: this.translations.DateFeedback,
            type: 'date',
          },
          {
            name: 'feedbackDescription',
            translatedName: this.translations.LastFeedbackDescription,
          },
          { name: 'mandatory', translatedName: this.translations.Mandatory },
          { name: 'buttons', disableSort: true },
        ];
        this.contactsTableColumns = [
          {
            name: 'businessName',
            translatedName: this.translations.BusinessName,
          },
          {
            name: "channelName",
            translatedName: this.translations.Channel2
          },
          {
            name: 'country',
            translatedName: this.translations.Country,
          },
          {
            name: 'telephone',
            translatedName: this.translations.Telephone,
          },
          {
            name: 'email',
            translatedName: this.translations.Email,
          },
          {
            name: 'status',
            translatedName: this.translations.Status2,
          },
          {
            name: 'buttons',
            disableSort: true,
          },
        ];
        this.contactCompetitorsTableColumns = [
          {
            name: 'companyName',
            translatedName: this.translations.CompetitorBusinessName,
          },
          // { name: 'contactName', translatedName: this.translations.Contact },
          {
            name: 'country',
            translatedName: this.translations.ReferenceMarket,
          },
          // {
          //   name: 'buyerRevenueYear',
          //   translatedName: this.translations.AnnualPurchaseValue,
          //   type: 'currency',
          //   align: 'right',
          // },
          {
            name: 'valueRevenueYear',
            translatedName: this.translations.MarketShare,
            type: 'currency',
            align: 'right'
          },
          {
            name: 'buttons',
          },
        ];
        this.contactCompetitorsTableButtons = [
          {
            icon: 'arrow_green_3_icon',
            class: 'primary',
            tooltip: this.translations.ContactDetails,
            clickFunction: (competitor: any) => {
              this.openContactCompetitorDialog(competitor);
            },
          },
        ];
        this.contactCompetitorsDetailTableColumns = [
          { name: 'contactName', translatedName: this.translations.Contact },
          {
            name: 'annualPurchaseValue',
            translatedName: this.translations.AnnualPurchaseValue,
            type: 'currency',
            align: 'right',
          },
        ];
        this.contactsCommercialSummaryTableColumns = [
          {
            name: 'businessName',
            translatedName: this.translations.BusinessName,
          },
          {
            name: 'developedOffers',
            translatedName: this.translations.DevelopedOffers,
          },
          {
            name: 'refusedOffers',
            translatedName: this.translations.RefusedOffers,
          },
          {
            name: 'refusedOffersValue',
            translatedName: this.translations.RefusedOffersValue,
            type: 'currency',
          },
          {
            name: 'pendingOffers',
            translatedName: this.translations.PendingOffers,
          },
          {
            name: 'pendingOffersValue',
            translatedName: this.translations.PendingOffersValue,
            type: 'currency',
          },
          {
            name: 'orders',
            translatedName: this.translations.Orders,
          },
          {
            name: 'ordersRevenue',
            translatedName: this.translations.Revenue,
            type: 'currency',
          },
        ];
      })
    );
    this.userRole = JSON.parse(
      localStorage.getItem(storageKeys.user)
    ).activeRole;
    if (this.userRole === Roles.Direction) {
      this.isDirection = true;
    }
    this.subscriptions.add(
      this.route?.data?.subscribe((data: any) => {
        if (data?.readonly) {
          this.isReadonly = true;
        }
      })
    );
    this.subscriptions.add(
      this.route?.queryParams?.subscribe({
        next: (queryParams) => {
          if (queryParams?.tab) {
            this.matTabGroupIndex = queryParams?.tab;
          }
        },
      })
    );
    this.subscriptions.add(
      this.route?.params?.subscribe({
        next: (params: Params) => {
          this.idProject = parseInt(params?.idProject, 10);
          this.idCustomer = parseInt(params?.idCustomer, 10);
          this.getCustomerInfoData();
          this.getAllActivitiesByProject();
          // this.getContactsByIdProject();
          this.getAllContactCompetitors();
          // this.getProjectStatisticsCrm();
          this.getExportedBM();
          this.getExportedLostOffers();
          this.getExportedReports();
          this.getContactsCommercialSummary();
          this.irFormService
            .getChannelsTypeByIdCustomer(this.idCustomer)
            .subscribe({
              next: (data: IChannelsType[]) => {
                this.channels = data;
                this.getContactsByIdProject();                
              },
            });
        },
      })
    );
    this.subscriptions.add(
      this.crmService.getAllBuyerStatus().subscribe({
        next: (data: IBuyerStatus[]) => {
          this.buyerStatusDescription = data;
        },
      })
    );
  }

  openContactCompetitorDialog(competitor: any): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '1200px';
    dialogConfig.maxWidth = '80vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      competitorContacts: competitor.contacts,
    };
    const dialogRef: any = this.dialog.open(
      this.contactCompetitorDialog,
      dialogConfig
    );
  }

  getCustomerInfoData(): void {
    this.subscriptions.add(
      this.crmService.getCustomerInfoData(this.idProject).subscribe({
        next: (projectInfo: any) => {
          this.projectInfo = projectInfo;
          this.layoutService.generateBreadcrumb([
            { label: 'CRM', path: '/crm' },
            {
              label: projectInfo.customer.businessName,
              path: null,
              pageTitle: true,
            },
          ]);
        },
      })
    );
  }

  // getProjectById(): void {
  //   this.customerInfoReady = false;
  //   this.subscriptions.add(
  //     this.irFormService
  //       .getProjectById(this.idProject, true)
  //       .subscribe((project: ProjectDTO) => {
  //         this.project = project;
  //         this.projectCode = this.project?.code;
  //         this.companyName = this.project?.customer?.businessName;
  //         this.customerInfoReady = true;
  //       })
  //   );
  // }

  addContact(fromBM?: boolean, idProductAllBusinessMatching?: number): void {
    this.router.navigate([
      `/crm/customer/${this.idCustomer}/project/${this.idProject}/contact/${
        fromBM ? `addFromBM:${idProductAllBusinessMatching}` : 'add'
      }`,
    ]);
  }

  getContactsByIdProject(): void {
    this.contactsLoader = true;
    this.subscriptions.add(
      this.crmService
        .getContactsByIdProject(this.idProject, true)
        .subscribe((contacts: IContact[]) => {
          this.oldContacts = contacts.map((contact: IContact): any => ({
            ...contact,
            businessName: contact.businessName,
            country: contact.country.country,
            telephone: contact.telephoneNumber,
            email: contact.email,
            status: this.translate.instant(
              `BUYER_STATUS_NAME.${contact?.buyerStatus?.name}`
            ),
            idChannel: contact?.channelType?.idChannelType,
            channelName: this.getChannelName(contact?.channelType?.idChannelType)
          }));
          // TODO: ??????????? a cosa serve la riga sotto?
          // this.contact = contacts[0];
          contacts.forEach((contact: IContact) => {
            if (!this.countries.includes(contact.country.country)) {
              this.countries.push(contact.country.country);
            }
            // if (
            //   contact.buyerStatus.idBuyerStatus === BUYER_STATUS.Active ||
            //   contact.buyerStatus.idBuyerStatus === BUYER_STATUS.Prospect
            // ) {
            //   if (!this.countriesForMap.includes(contact.country.country)) {
            //     this.countriesForMap.push(contact.country.country);
            //   }
            //   this.contactsToShowOnMap.push(contact);
            // }
          });
          this.contacts = contacts.map((contact: IContact): any => ({
            ...contact,
            businessName: contact.businessName,
            country: contact.country.country,
            telephone: contact.telephoneNumber,
            email: contact.email,
            status: this.translate.instant(
              `BUYER_STATUS_NAME.${contact?.buyerStatus?.name}`
            ),
            idChannel: contact?.channelType?.idChannelType,
            channelName: this.getChannelName(contact?.channelType?.idChannelType)
          }));
          this.contactsTableButtons = [
            {
              icon: this.isReadonly ? 'arrow_green_3_icon' : 'edit_icon',
              class: 'primary',
              clickFunction: (contact: IContact) => {
                this.router.navigate([
                  `/crm/customer/${this.idCustomer}/project/${this.idProject}/contact/${contact?.idContact}`,
                ]);
              },
            },
          ];
          this.contactsLoader = false;
          this.filterContactsTable(
            'status',
            new MatSelectChange(null, BUYER_STATUS.Prospect)
          );
          // Map initialization
          // this.map = new Map({
          //   view: this.contactsToShowOnMap?.length
          //     ? this.view
          //     : new View({
          //         center: fromLonLat([12, 41]),
          //         zoom: 0,
          //       }),
          //   target: this.mapDiv?.nativeElement,
          //   layers: [
          //     new TileLayer({
          //       source: new OSM(),
          //     }),
          //   ],
          //   controls: [],
          // });
          // if (this.contactsToShowOnMap?.length) {
          //   this.fillMapWithData(this.contactsToShowOnMap);
          //   const popup: Overlay = new Overlay({
          //     element: this.popup.nativeElement,
          //     stopEvent: false,
          //   });
          //   const lateralPopup: Overlay = new Overlay({
          //     element: this.lateralPopup.nativeElement,
          //     stopEvent: false,
          //   });
          //   this.map.addOverlay(popup);
          //   this.map.addOverlay(lateralPopup);
          //   this.map.on('pointermove', (evt: MapBrowserEvent) => {
          //     const pixel = this.map.getEventPixel(evt.originalEvent);
          //     const hit = this.map.hasFeatureAtPixel(pixel);
          //     (this.map.getTarget() as HTMLElement).style.cursor = hit
          //       ? 'pointer'
          //       : '';
          //     const feature: FeatureLike = this.map.forEachFeatureAtPixel(
          //       evt.pixel,
          //       (feat) => {
          //         return feat;
          //       }
          //     );
          //     if (feature && feature?.getProperties()?.features?.length === 1) {
          //       popup.setPosition(evt.coordinate);
          //       this.showPopup = true;
          //       this.popup.nativeElement.classList.add(
          //         feature?.getProperties()?.features[0]?.getProperties()
          //           ?.properties?.type === 'active'
          //           ? 'active'
          //           : 'prospect'
          //       );
          //       this.popup.nativeElement.innerHTML = `
          //         <strong style="font-size: 14px">
          //           ${
          //             feature?.getProperties()?.features[0]?.getProperties()
          //               ?.properties?.name
          //           }
          //         </strong>
          //         <br>
          //         <span style="font-size: 12px">
          //           ${
          //             feature?.getProperties()?.features[0]?.getProperties()
          //               ?.properties?.address
          //           }
          //         </span>
          //       `;
          //     } else if (feature && this.view.getZoom() > 17.5) {
          //       lateralPopup.setPosition(evt.coordinate);
          //       this.showLateralPopup = true;
          //       this.showPopup = false;
          //       let innerHTMLString = '';
          //       this.popup.nativeElement.innerHTML = ``;
          //       if (feature?.getProperties()?.features?.length <= 6) {
          //         feature?.getProperties()?.features?.forEach((feat: any) => {
          //           innerHTMLString =
          //             innerHTMLString +
          //             `
          //             <strong style="font-size: 14px">
          //               ${feat?.getProperties()?.properties?.name}
          //             </strong>
          //             <br>
          //           `;
          //         });
          //       } else {
          //         feature?.getProperties()?.features?.forEach((feat: any) => {
          //           innerHTMLString =
          //             innerHTMLString +
          //             `
          //             <strong style="font-size: 11px; display: block">
          //               ${feat?.getProperties()?.properties?.name}
          //             </strong>
          //           `;
          //         });
          //       }
          //       this.lateralPopup.nativeElement.innerHTML =
          //         innerHTMLString +
          //         `
          //         <br>
          //         <span style="font-size: 12px">
          //           ${
          //             feature?.getProperties()?.features[0]?.getProperties()
          //               ?.properties?.address
          //           }
          //         </span>
          //       `;
          //     } else {
          //       this.showPopup = false;
          //       this.popup.nativeElement.classList.remove('active');
          //       this.popup.nativeElement.classList.remove('prospect');
          //       this.popup.nativeElement.innerHTML = ``;
          //     }
          //   });
          // } else {
          //   // this.view.setCenter(fromLonLat([12.496366, 41.902782]));
          //   this.view.setZoom(5);
          // }
        })
    );
  }

  // fillMapWithData(contactsToShowOnMap: any): void {
  //   const onlyCoordinates: any[] = [];
  //   const featuresData: any = new Array(contactsToShowOnMap);
  //   contactsToShowOnMap.forEach((contact: IContact, index: number) => {
  //     // Remove this condition if/when position will be mandatory for a contact
  //     if (contact.position.length) {
  //       onlyCoordinates.push(fromLonLat(contact?.position));
  //       featuresData[index] = new Feature({
  //         geometry: new Point(fromLonLat(contact.position)),
  //         properties: {
  //           name: contact?.businessName,
  //           address: contact?.city,
  //           type:
  //             contact?.buyerStatus?.idBuyerStatus === BUYER_STATUS.Active
  //               ? 'active'
  //               : 'prospect',
  //         },
  //       });
  //     }
  //   });
  //   const feature: Feature<Geometry> = new Feature({
  //     geometry: new Polygon([onlyCoordinates]),
  //   });
  //   const styleCache: any = {};
  //   this.map.addLayer(
  //     new VectorLayer({
  //       className: 'contacts',
  //       source: new Cluster({
  //         distance: 40,
  //         source: new VectorSource({
  //           features: featuresData,
  //         }),
  //       }),
  //       style: (feat: any) => {
  //         const size = feat.get('features').length;
  //         if (size > 1) {
  //           let style = styleCache[size];
  //           if (!style) {
  //             style = new Style({
  //               image: new Icon({
  //                 anchor: [0.5, 0.5],
  //                 src:
  //                   `data:image/svg+xml;utf8,` +
  //                   escape(
  //                     `
  //                       <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve">
  //                         <circle fill="#006B61" cx="256" cy="256" r="256"/>
  //                       </svg>
  //                     `
  //                   ),
  //               }),
  //               text: new Text({
  //                 text: size.toString(),
  //                 // text: item.companyCount.toString(),
  //                 scale: 1.5,
  //                 fill: new Fill({
  //                   color: '#ffffff',
  //                 }),
  //                 offsetY: 1,
  //               }),
  //             });
  //             styleCache[size] = style;
  //           }
  //           return style;
  //         } else {
  //           const originalFeature = feat.get('features')[0];
  //           return this.clusterMemberStyle(originalFeature);
  //         }
  //       },
  //     })
  //   );
  //   const poly = feature.getGeometry();
  //   this.view.fit(poly.getExtent(), {
  //     padding: [60, 30, 30, 30],
  //     maxZoom: 10,
  //   });
  // }

  // clusterMemberStyle(clusterMember: any): Style {
  //   return new Style({
  //     image: new Icon({
  //       anchor: [0.5, 1],
  //       src:
  //         `data:image/svg+xml;utf8,` +
  //         escape(
  //           `
  //             <svg width="40" height="40" version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  //               <style type="text/css">
  //                 .st0{fill:${
  //                   clusterMember.getProperties().properties.type === 'active'
  //                     ? '#67b30a'
  //                     : '#36d0ff'
  //                 };}
  //               </style>
  //               <path class="st0" d="M255,0c101.1,0,183.1,82,183.1,183.1c0,101.1-183.1,327-183.1,327S71.9,284.3,71.9,183.1C71.9,82,153.9,0,255,0z"/>
  //             </svg>
  //           `
  //         ),
  //     }),
  //     text: new Text({
  //       text: '1',
  //       // text: item.companyCount.toString(),
  //       scale: 1.5,
  //       fill: new Fill({
  //         color: '#ffffff',
  //       }),
  //       offsetY: -25,
  //     }),
  //   });
  // }

  // onTabChange(event: MatTabChangeEvent): void {
  //   if (event.tab.textLabel === 'Geolocation') {
  //     this.map.updateSize();
  //   }
  // }

  getAllActivitiesByProject(): void {
    this.projectActivitiesLoader = true;
    this.subscriptions.add(
      this.crmService
        .getAllActivitiesByProject(this.idProject, true)
        .subscribe({
          next: (contactActivities: IContactActivity[]) => {
            this.projectActivitiesLoader = false;
            this.oldProjectActivities = contactActivities.map(
              (activity: IContactActivity): any => ({
                ...activity,
                contact: activity.contactSummaryDTO.businessName,
                buyerStatus:
                  BUYER_STATUS_DESCRIPTION[
                    `BUYER_STATUS_${activity?.contactSummaryDTO?.buyerStatus?.idBuyerStatus}`
                  ],
                idBuyerStatus:
                  activity?.contactSummaryDTO?.buyerStatus?.idBuyerStatus,
                country: activity.contactSummaryDTO.country.country,
                multiRowContact: `${
                  activity?.contactRefName ? activity?.contactRefName : ''
                } ${
                  activity?.contactRefSurname ? activity?.contactRefSurname : ''
                }`,
                multiRowContactPhone: activity?.contactRefTel,
                multiRowContactEmail: activity?.contactRefEmail,
                // buyer: `${activity?.contactRefName ? activity?.contactRefName : ''} ${
                //   activity?.contactRefSurname ? activity?.contactRefSurname : ''
                // }`,
                buyerEmail: activity?.contactRefEmail,
                buyerPhone: activity?.contactRefTel,
                dateFeedback: activity?.dateFeedback,
                feedbackDescription: activity.feedbackDescription,
                mandatory: activity?.mandatory
                  ? this.translate.instant('COMMON.Yes')
                  : this.translate.instant('COMMON.No'),
              })
            );
            this.projectActivities = contactActivities.map(
              (activity: IContactActivity): any => ({
                ...activity,
                contact: activity.contactSummaryDTO.businessName,
                buyerStatus:
                  BUYER_STATUS_DESCRIPTION[
                    `BUYER_STATUS_${activity?.contactSummaryDTO?.buyerStatus?.idBuyerStatus}`
                  ],
                idBuyerStatus:
                  activity?.contactSummaryDTO?.buyerStatus?.idBuyerStatus,
                country: activity.contactSummaryDTO.country.country,
                multiRowContact: `${
                  activity?.contactRefName ? activity?.contactRefName : ''
                } ${
                  activity?.contactRefSurname ? activity?.contactRefSurname : ''
                }`,
                multiRowContactPhone: activity?.contactRefTel,
                multiRowContactEmail: activity?.contactRefEmail,
                // buyer: `${activity?.contactRefName ? activity?.contactRefName : ''} ${
                //   activity?.contactRefSurname ? activity?.contactRefSurname : ''
                // }`,
                buyerEmail: activity?.contactRefEmail,
                buyerPhone: activity?.contactRefTel,
                dateFeedback: activity?.dateFeedback,
                feedbackDescription: activity.feedbackDescription,
                mandatory: activity?.mandatory
                  ? this.translate.instant('COMMON.Yes')
                  : this.translate.instant('COMMON.No'),
              })
            );
            this.projectActivitiesTableButtons = [
              {
                icon: 'arrow_green_3_icon',
                class: 'primary',
                clickFunction: (contact: IContactActivity) => {
                  this.router.navigate(
                    [
                      `/crm/customer/${this.idCustomer}/project/${contact.idProject}/contact/${contact?.contactSummaryDTO?.idContact}`,
                    ],
                    { queryParams: { activeTabIndex: 1 } }
                  );
                },
              },
            ];
            const mappedActivities: IContactActivityForTable[] =
              contactActivities.map(fromContactMandatoryActivityToDataForTable);
            this.generalTabDataSource.data = mappedActivities;
            mappedActivities.forEach((activity: IContactActivityForTable) => {
              if (activity.idBuyerStatus === BUYER_STATUS.Suspect) {
                this.suspectTableDataSource.data.push(activity);
              }
              if (activity.idBuyerStatus === BUYER_STATUS.Prospect) {
                this.prospectTableDataSource.data.push(activity);
              }
              if (activity.idBuyerStatus === BUYER_STATUS.Active) {
                this.customersTableDataSource.data.push(activity);
              }
              if (activity.idBuyerStatus === BUYER_STATUS.AskButNotBuy) {
                this.askButDontBuyTableDataSource.data.push(activity);
              }
              if (activity.idBuyerStatus === BUYER_STATUS.NotInterestedNow) {
                this.notInterestedForNowTableDataSource.data.push(activity);
              }
            });
            this.filterActivitiesTable(
              'status',
              new MatSelectChange(null, BUYER_STATUS.Prospect)
            );
          },
        })
    );
  }

  viewContactDetails(contact: IContact, index?: number): void {
    // this.showContactDetailsGeneralTab = false;
    // this.showContactDetailsContactTab = false;
    this.selectedContactIndex = index;
    this.contactSubscriptions = new Subscription();
    this.showContactDetails = false;
    this.showContactDetailsLoader = true;
    this.contactSubscriptions.add(
      this.crmService.getContactDetails(contact.idContact, true).subscribe({
        next: (cont: IContact) => {
          this.contact = cont;
        },
        complete: () => {
          this.showContactDetails = true;
          // if (this.contactPositionCallback === 'general') {
          //   this.showContactDetailsGeneralTab = true;
          // }
          // if (this.contactPositionCallback === 'contact') {
          //   this.showContactDetailsContactTab = true;
          // }
          this.showContactDetailsLoader = false;
        },
        error: () => {
          this.showContactDetailsLoader = false;
          this.showContactDetails = false;
          this.common.showToast(
            this.translations.ShowGeneralErrorToast,
            ToastStatus.error,
            3000
          );
        },
      })
    );
  }

  resetTab(event: MatTabChangeEvent): void {
    if (event) {
      if (this.contactSubscriptions) {
        this.contactSubscriptions.unsubscribe();
      }
      this.showContactDetails = false;
      this.showContactDetailsLoader = false;
      this.addContactStatement = false;
    }
  }

  checkSelectedTab(event: MatTabChangeEvent): void {
    if (
      event.index === CRM_DETAIL_TABS_INDEX.NotInterested ||
      event.index === CRM_DETAIL_TABS_INDEX.NotInterestForCustomer ||
      event.index === CRM_DETAIL_TABS_INDEX.Losed
    ) {
      const selectedTabBuyerStatus = CRM_DETAIL_TABS_INDEX[event.index];
      this.otherStatusTabDataLoader = true;
      this.selectedTabIndex = event.index;
      this.subscriptions.add(
        this.crmService
          .getOtherContactsCrmByIdBuyerStatus(
            this.idProject,
            BUYER_STATUS[selectedTabBuyerStatus],
            true
          )
          .subscribe((contacts: IContact[]) => {
            this.otherStatusTabDataSource.data = contacts.map(
              fromOtherContactToDataForTable
            );
            this.otherStatusTabDataLoader = false;
          })
      );
    }
  }

  resetFilters(table: string): void {
    if (table === 'activities') {
      this.searchValueActivities.nativeElement.value = '';
      this.projectActivities = this.oldProjectActivities;
      this.activitiesFilterSelects = {
        status: null,
        country: null,
        date: null,
      };
      this.activitiesStatusFilter.value = [];
      this.activitiesCountryFilter.value = [];
      // this.activitiesDateFilter.value = [];
    }
    if (table === 'contacts') {
      this.searchValueContacts.nativeElement.value = '';
      this.contacts = this.oldContacts;
      this.contactsFilterSelects = {
        status: null,
        country: null,
        date: null,
      };
      this.contactsStatusFilter.value = [];
      this.contactsChannelFilter.value = [];
      this.contactsCountryFilter.value = [];
    }
    if (table === 'competitors') {
      this.searchValueCompetitors.nativeElement.value = '';
      this.contactCompetitors = this.oldContactCompetitors;
      this.contactCompetitorsFilterSelects = {
        country: null,
      };
      this.contactCompetitorsCountryFilter.value = [];
    }
    if (table === 'commercialSummary') {
      this.searchValueCommercialSummary.nativeElement.value = '';
    }
  }

  filterActivitiesTable(filter: string, select: MatSelectChange): void {
    this.activitiesFilterSelects[filter] = select.value;
    this.projectActivities = [];
    this.projectActivities = this.oldProjectActivities.filter(
      (activity: any) => {
        if (this.activitiesFilterSelects.status) {
          return activity.idBuyerStatus === this.activitiesFilterSelects.status;
        }
        return activity;
      }
    );
    this.projectActivities = this.projectActivities.filter((activity: any) => {
      if (this.activitiesFilterSelects.country) {
        return activity.country === this.activitiesFilterSelects.country;
      }
      return activity;
    });
  }

  filterContactsTable(filter: string, select: MatSelectChange): void {
    this.contactsFilterSelects[filter] = select.value;
    this.contacts = [];
    this.contacts = this.oldContacts.filter((contact: any) => {
      if (this.contactsFilterSelects.status) {
        return (
          contact.buyerStatus.idBuyerStatus ===
          this.contactsFilterSelects.status
        );
      }
      return contact;
    });
    this.contacts = this.contacts.filter((contact: any) => {
      if (this.contactsFilterSelects.country) {
        return contact.country === this.contactsFilterSelects.country;
      }
      return contact;
    });
    this.contacts = this.contacts.filter((contact: any) => {
      if (this.contactsFilterSelects.channel) {
        return contact.idChannel === this.contactsFilterSelects.channel;
      }
      return contact;
    });
  }

  filterContactCompetitorsTable(filter: string, select: MatSelectChange): void {
    this.contactCompetitorsFilterSelects[filter] = select.value;
    this.contactCompetitors = [];
    this.contactCompetitors = this.oldContactCompetitors.filter((comp: any) => {
      if (this.contactCompetitorsFilterSelects.country) {
        return (
          comp.country.toLowerCase() ===
          this.contactCompetitorsFilterSelects.country.toLowerCase()
        );
      }
      return comp;
    });
  }

  getContactsCommercialSummary(): void {
    this.subscriptions.add(
      this.crmService.getCommercialSummary(this.idProject).subscribe({
        next: (summary: IContactCommercialSummaryDTO[]) => {
          this.contactsCommercialSummary = summary
            .filter(cont => 
              cont.nDealGenerated || cont.nDealPending || 
              cont.nDealLost || cont.nDealOrder)
            .map(
              (contact: IContactCommercialSummaryDTO) => ({
                businessName: contact?.businessName,
                developedOffers: contact?.nDealGenerated,
                refusedOffers: contact?.nDealLost,
                refusedOffersValue: contact?.refusedOffersValue,
                pendingOffers: contact?.nDealPending,
                pendingOffersValue: contact?.pendingOffersValue,
                orders: contact?.nDealOrder,
                ordersRevenue: contact?.revenueOrder,
              })
          );
        },
      })
    );
  }

  // getProjectStatisticsCrm(): void {
  //   this.subscriptions.add(
  //     this.crmService.getProjectStatisticsCrm(this.idProject).subscribe({
  //       next: (statistics: any) => {
  //         this.crmStatistics = statistics;
  //       },
  //     })
  //   );
  // }

  getExportedBM(): void {
    this.subscriptions.add(
      this.crmService.getExportedBM(this.idCustomer, this.idProject).subscribe({
        next: (data: any) => {
          this.exportedBM = data;
          this.exportedBMMappedForTable = data.map(
            (item: any, ind: number) => ({
              idProductAllBusinessMatching: item.idProductAllBusinessMatching,
              customer: `
                <strong>${item?.businessNameMatched}</strong>
                <br>
                ${item?.refNameMatched} ${item?.refSurnameMatched}
                <br>
                ${item?.refTelephoneMatched}
                <br>
                ${item?.refEmailMatched}
              `,
              typology: item.isSold
                ? this.translate.instant('BUSINESS_OPPORTUNITIES.Sold')
                : this.translate.instant('BUSINESS_OPPORTUNITIES.Bought'),
              commerceSector: item.commerceSector.name,
              quantityOrder: item?.productQuantityOrder,
              quantityYear: item?.productQuantityYear,
              valueYear: item.productValueYear,
              businessNameReferent: `
                <strong>${item?.businessName}</strong>
                <br>
                ${item?.customerRefName} ${item?.customerRefSurname}
                <br>
                ${item?.customerRefTelephone}
                <br>
                ${item?.customerRefEmail}
              `,
              manager: `
                <strong>${item?.manager?.name || item?.agent?.name} ${
                item?.manager?.surname || item?.agent?.surname
              }</strong>
                <br>
                ${item?.manager?.telephone || item?.agent?.telephone}
                <br>
                ${item?.manager?.email || item?.agent?.email}
              `,
              companyBusinessName: item.businessName,
              type: item?.isClient
                ? this.translate.instant('COMMON.Client')
                : this.translate.instant('COMMON.Client'),
              referentName: `${item.customerRefName} ${item.customerRefSurname}`,
              referentPhone: item.customerRefTelephone,
              referentEmail: item.customerRefEmail,
              userName: `${item?.manager?.name || item?.agent?.name} ${
                item?.manager?.surname || item?.agent.surname
              }`,
              userPhone: item?.manager?.telephone || item?.agent.telephone,
              userEmail: item?.manager?.email || item?.agent.email,
              productDescription: item?.productDesc,
              englishProductDescription: item?.productDescEn,
              index: ind,
              isSold: item.isSold,
            })
          );
          this.exportedBMColumns = [
            {
              name: 'customer',
              translatedName: this.translate.instant(
                'BUSINESS_OPPORTUNITIES.Company'
              ),
              type: 'innerHTML',
            },
            {
              name: 'productDescription',
              translatedName: this.translate.instant(
                'BUSINESS_OPPORTUNITIES.ProductDescription'
              ),
            },
            {
              name: 'typology',
              translatedName: this.translate.instant(
                'BUSINESS_OPPORTUNITIES.Typology'
              ),
            },
            {
              name: 'quantityOrder',
              translatedName: this.translate.instant(
                'BUSINESS_OPPORTUNITIES.QuantityOrder'
              ),
              type: 'number',
            },
            {
              name: 'quantityYear',
              translatedName: this.translate.instant(
                'BUSINESS_OPPORTUNITIES.QuantityYear'
              ),
              type: 'number',
            },
            {
              name: 'valueYear',
              translatedName: this.translate.instant(
                'BUSINESS_OPPORTUNITIES.PurchaseValueYear'
              ),
              type: 'currency',
            },
            {
              name: 'businessNameReferent',
              translatedName: this.translate.instant(
                'BUSINESS_OPPORTUNITIES.BusinessNameReferent'
              ),
              type: 'innerHTML',
            },
            {
              name: 'type',
              translatedName: this.translate.instant(
                'BUSINESS_OPPORTUNITIES.Type'
              ),
            },
            {
              name: 'manager',
              translatedName: this.translate.instant(
                'BUSINESS_OPPORTUNITIES.Referent'
              ),
              type: 'innerHTML',
            },
            {
              name: 'buttons',
              disableSort: true,
            },
          ];
          this.exportedBMColumnButtons = [
            {
              icon: 'add_user_icon',
              class: 'primary',
              clickFunction: (item: any) => {
                this.addContact(true, item.idProductAllBusinessMatching);
              },
            },
          ];
        },
      })
    );
  }

  getExportedLostOffers(): void {
    this.subscriptions.add(
      this.crmService.getExportedLO(this.idProject).subscribe({
        next: (data: any) => {
          this.exportedLostOffers = data;
          this.exportedLostOffersMappedForTable = data.map(
            (opp: any, ind: number) => ({
              idDeal: opp?.idDeal,
              alreadyRead: opp?.alreadyRead,
              commerceSector: opp?.commerceSector?.name,
              product: opp?.productName,
              estimatedQuantityOrder: opp?.estimatedQuantityOrder,
              estimatedQuantityYear: opp?.estimatedQuantityYear,
              estimatedValueYear: opp?.estimatedValueYear,
              country: opp?.country?.country,
              businessNameAndBuyerInfo: `
                  <strong>${opp?.contact?.businessName}</strong>
                  <br>
                  ${opp?.contact?.contactMainReference?.name || ''}
                  ${opp?.contact?.contactMainReference?.surname || ''}
                  <br>
                  ${opp?.contact?.contactMainReference?.email || ''}
                  <br>${opp?.contact?.contactMainReference?.tel || ''}
                `,
              dlmanager: `
                  <strong>${opp?.userInfo?.name} ${opp?.userInfo?.surname}</strong>
                  <br>
                  ${opp?.userInfo?.telephone}
                  <br>
                  ${opp?.userInfo?.email}
                `,
              index: ind,
            })
          );
          this.exportedLostOffersColumns = [
            {
              name: 'commerceSector',
              translatedName: this.translations.CommerceSector,
            },
            {
              name: 'product',
              translatedName: this.translations.Product,
            },
            {
              name: 'estimatedQuantityOrder',
              translatedName: this.translations.QuantityOrder,
              type: 'number',
            },
            {
              name: 'estimatedQuantityYear',
              translatedName: this.translations.QuantityYear,
              type: 'number',
            },
            {
              name: 'estimatedValueYear',
              translatedName: this.translations.ValueYear,
              type: 'currency',
            },
            {
              name: 'country',
              translatedName: this.translations.Country,
            },
            {
              name: 'buttons',
              disableSort: true,
            },
          ];
          this.exportedLostOffersButtons = [
            {
              icon: 'search_icon',
              class: 'primary',
              clickFunction: (item: any) => {
                this.showOpportunityDetailDialog(
                  'lostOffer',
                  this.exportedLostOffers[item.index]
                );
              },
            },
            {
              icon: 'folder_icon',
              class: 'primary',
              disabled: (item: any) =>
                !this.exportedLostOffers[item.index].dealDocuments?.length,
              clickFunction: (item: any) => {
                if (this.exportedLostOffers[item.index].dealDocuments?.length) {
                  this.subscriptions.add(
                    this.crmService
                      .downloadDealDocument(
                        this.exportedLostOffers[item.index].dealDocuments[0]
                          .idDealDocument
                      )
                      .subscribe()
                  );
                }
              },
            },
          ];
        },
      })
    );
  }

  getExportedReports() {
    this.subscriptions.add(
      this.crmService.getExportedReport(this.idProject).subscribe({
        next: (data: any) => {
          this.exportedReport = data;
          this.exportedReportMappedForTable = data.map(
            (report: IReport): any => ({
              idOpportunity: report?.idOpportunity,
              alreadyRead: report?.alreadyRead,
              product: report?.productName,
              quantity: report?.quantity,
              quantityYear: report?.quantityYear,
              valueYear: report?.valueYear,
              country: report?.contact?.country?.country,
              commerceSector: report?.commerceSector?.name,
              idCommerceSector: report?.commerceSector?.idCommerceSector,
              businessNameAndBuyerInfo: `
                <strong>${report?.contact?.businessName}</strong>
                <br>
                ${report?.contactReference?.name}
                ${report?.contactReference?.surname}
                <br>
                ${report?.contactReference?.tel}
                <br>
                ${report?.contactReference?.email}
              `,
              dlmanager: `
                <strong>${report?.userInsertDTO?.name} ${report?.userInsertDTO?.surname}</strong>
                <br>
                ${report?.userInsertDTO?.telephone}
                <br>
                ${report?.userInsertDTO?.email}
              `,
            })
          );
          this.exportedReportColumns = [
            {
              name: 'commerceSector',
              translatedName: this.translations.CommerceSector,
            },
            {
              name: 'product',
              translatedName: this.translations.Product,
            },
            {
              name: 'quantity',
              translatedName: this.translations.QuantityOrder,
              type: 'number',
            },
            {
              name: 'quantityYear',
              translatedName: this.translations.QuantityYear,
              type: 'number',
            },
            {
              name: 'valueYear',
              translatedName: this.translations.ValueYear,
              type: 'currency',
            },
            {
              name: 'country',
              translatedName: this.translations.Country,
            },
            {
              name: 'buttons',
              disableSort: true,
            },
          ];
          this.exportedReportsButtons = [
            {
              icon: 'search_icon',
              class: 'primary',
              clickFunction: (item: any) => {
                let index: number;
                index = this.exportedReportMappedForTable.findIndex(
                  (report: any) => {
                    return report.idOpportunity === item.idOpportunity;
                  }
                );
                this.showOpportunityDetailDialog(
                  'report',
                  this.exportedReport[index]
                );
              },
            },
          ];
        },
      })
    );
  }

  // filterContactsOnMap(): void {
  //   this.map.getLayers().forEach((layer: TileLayer | VectorLayer) => {
  //     if (layer?.getClassName() === 'contacts') {
  //       this.map.removeLayer(layer);
  //     }
  //   });
  //   let filteredContacts: IContact[] = [];
  //   filteredContacts = this.contactsToShowOnMap.filter((contact: IContact) => {
  //     return (
  //       contact.country.country === this.filterCountryMap.value ||
  //       this.filterCountryMap.value === 'all'
  //     );
  //   });
  //   filteredContacts = filteredContacts.filter((contact: IContact) => {
  //     return (
  //       contact.buyerStatus.name.toLowerCase() === this.filterStatusMap.value ||
  //       this.filterStatusMap.value === 'all'
  //     );
  //   });
  //   filteredContacts = filteredContacts.filter((contact: IContact) => {
  //     return (
  //       contact.channelType.idChannelType === this.filterChannelMap.value ||
  //       this.filterChannelMap.value === 'all'
  //     );
  //   });
  //   if (filteredContacts?.length) {
  //     this.fillMapWithData(filteredContacts);
  //   } else {
  //     this.view.setZoom(0);
  //   }
  // }

  showOpportunityDetailDialog(type: string, itemData: any): void {
    const refreshDataAfterClosed: boolean = false;
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '1200px';
    dialogConfig.maxWidth = '80vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      opportunityType: type,
      item: itemData,
    };
    const dialogRef: any = this.dialog.open(
      this.opportunityDetailDialog,
      dialogConfig
    );
  }

  // resetMapFilters(): void {
  //   if (this.contactsToShowOnMap?.length) {
  //     this.fillMapWithData(this.contactsToShowOnMap);
  //   }
  //   this.filterCountryMap.setValue('all');
  //   this.filterStatusMap.setValue('all');
  //   this.filterChannelMap.setValue('all');
  // }

  goToduplicateContactsSection(): void {
    if (this.oldContacts?.length) {
      this.disableManagedCustomers = true;
      this.showDuplicateContactsSection = true;
      this.managedCustomersTableLoader = true;
      this.managedCustomersSubscription = new Subscription();
      this.managedCustomersSubscription.add(
        this.crmService.getFollowedCustomers(true).subscribe({
          next: (data: IProjectCustomer[]) => {
            this.managedCustomersForTable = data.map(
              (project: IProjectCustomer): any => ({
                idProject: project?.idProject,
                code: project?.code,
                version: `${PS_VERSION[project?.projectStatusCode]}`,
                businessName: project?.customer?.businessName,
                email: project?.customer?.email,
              })
            );
            const removeIndex: number = this.managedCustomersForTable.findIndex(
              (customer: IProjectCustomer) => {
                return customer.idProject === this.idProject;
              }
            );
            this.managedCustomersForTable.splice(removeIndex, 1);
            this.managedCustomersTableColumns = [
              {
                name: 'code',
                translatedName: this.translations.Code,
              },
              {
                name: 'version',
                translatedName: this.translations.Version,
              },
              {
                name: 'businessName',
                translatedName: this.translations.BusinessName,
              },
              {
                name: 'email',
                translatedName: this.translations.Email,
              },
              {
                name: 'buttons',
              },
            ];
            this.managedCustomersTableButtons = [
              {
                icon: 'arrow_green_3_icon',
                class: 'primary',
                clickFunction: (item: any) => {
                  this.stepperIndex = 1;
                  this.selectProjectWhereToDuplicateContacts(item);
                },
              },
            ];
            this.managedCustomersTableLoader = false;
          },
        })
      );
    } else {
      this.common.showToast(
        this.translations.NoContactsAvailable,
        ToastStatus.warning,
        3000
      );
    }
  }

  selectProjectWhereToDuplicateContacts(project: any): void {
    // Current project contacts
    this.selectedProjectForDuplication = project;
    this.currentProjectContactsTableColumns = [
      {
        name: 'checkbox',
        disableSort: true,
      },
      {
        name: 'businessName',
        translatedName: this.translations.BusinessName,
      },
      {
        name: 'country',
        translatedName: this.translations.Country,
      },
      {
        name: 'telephoneNumber',
        translatedName: this.translations.Telephone,
      },
    ];
    this.currentProjectContactsTableCheckbox = {
      disabled: () => false,
      checked: () => false,
      change: (event: MatCheckboxChange, contact: any) => {
        this.contactsCountrySelection.value = null;
        if (event.checked) {
          this.selectedContactsToBeDuplicated.push({
            idContact: contact?.idContact,
            businessName: contact?.businessName,
            contactMainReference: {
              tel: contact?.telephoneNumber,
            },
            email: contact?.email,
            country: {
              idCountry: contact?.idCountry,
              country: contact?.country,
            },
          });
          this.selectedContactsToBeDuplicatedForTable.push({
            idContact: contact?.idContact,
            businessName: contact?.businessName,
            country: contact?.country,
            telephoneNumber: contact?.telephone,
          });
        } else {
          const index: number = this.selectedContactsToBeDuplicated.findIndex(
            (cont: any) => contact.idContact === cont.idContact
          );
          this.selectedContactsToBeDuplicated.splice(index, 1);
          this.selectedContactsToBeDuplicatedForTable.splice(index, 1);
        }
      },
    };
    // Already existing contacts on selected project
    this.alreadyExistingContactTableLoader = true;
    this.selectedProjectForContactsDuplication = new Subscription();
    this.selectedProjectForContactsDuplication.add(
      this.crmService.getClonableByProject(project?.idProject, true).subscribe({
        next: (data: IClonable) => {
          this.alreadyExistingContactForTable = data?.contacts?.map(
            (contact: IContact): IOtherContactForTable => ({
              businessName: contact?.businessName,
              country: contact?.country?.country,
              telephoneNumber: contact?.telephoneNumber,
            })
          );
          this.alreadyExistingContactTableColumns = [
            {
              name: 'businessName',
              translatedName: this.translations.BusinessName,
            },
            {
              name: 'country',
              translatedName: this.translations.Country,
            },
            {
              name: 'telephoneNumber',
              translatedName: this.translations.Telephone,
            },
          ];
          this.alreadyExistingContactTableLoader = false;
        },
      })
    );
  }

  selectAllContactsToDuplicate(): void {
    this.contactsCountrySelection.value = null;
    this.currentProjectContactsTableCheckbox.checked = () => false;
    setTimeout(() => {
      this.currentProjectContactsTableCheckbox.checked = () => true;
    }, 5);
    this.selectedContactsToBeDuplicated = [];
    this.selectedContactsToBeDuplicatedForTable = [];
    this.oldContacts?.forEach((contact: any) => {
      this.selectedContactsToBeDuplicated.push({
        idContact: contact?.idContact,
        businessName: contact?.businessName,
        contactMainReference: {
          tel: contact?.telephoneNumber,
        },
        email: contact?.email,
        country: {
          idCountry: contact?.idCountry,
          country: contact?.country,
        },
      });
      this.selectedContactsToBeDuplicatedForTable.push({
        idContact: contact?.idContact,
        businessName: contact?.businessName,
        country: contact?.country,
        telephoneNumber: contact?.telephone,
      });
    });
  }

  selectContactsByCountry(select: MatSelectChange): void {
    this.currentProjectContactsTableCheckbox.checked = () => false;
    this.selectedContactsToBeDuplicated = [];
    this.selectedContactsToBeDuplicatedForTable = [];
    setTimeout(() => {
      this.currentProjectContactsTableCheckbox.checked = (item: any) => {
        return item.country.toLowerCase() === select?.value?.toLowerCase();
      };
    }, 5);
    this.oldContacts?.forEach((contact: any) => {
      if (contact?.country?.toLowerCase() === select?.value?.toLowerCase()) {
        this.selectedContactsToBeDuplicated.push({
          idContact: contact?.idContact,
          businessName: contact?.businessName,
          contactMainReference: {
            tel: contact?.telephoneNumber,
          },
          email: contact?.email,
          country: {
            idCountry: contact?.idCountry,
            country: contact?.country,
          },
        });
        this.selectedContactsToBeDuplicatedForTable.push({
          idContact: contact?.idContact,
          businessName: contact?.businessName,
          country: contact?.country,
          telephoneNumber: contact?.telephone,
        });
      }
    });
  }

  goBackOnStepper(index: number): void {
    switch (index) {
      case -1:
        this.showDuplicateContactsSection = false;
        this.disableManagedCustomers = false;
        if (this.managedCustomersSubscription) {
          this.managedCustomersSubscription.unsubscribe();
        }
        break;
      case 0:
        this.stepperIndex = 0;
        this.selectedContactsToBeDuplicated = [];
        this.selectedContactsToBeDuplicatedForTable = [];
        if (this.selectedProjectForContactsDuplication) {
          this.selectedProjectForContactsDuplication.unsubscribe();
        }
        break;
      case 1:
        this.stepperIndex = 1;
        this.currentProjectContactsTableCheckbox.checked = () => false;
        this.selectedContactsToBeDuplicated = [];
        this.selectedContactsToBeDuplicatedForTable = [];
        break;
    }
  }

  goNextOnStepper(): void {
    if (this.selectedContactsToBeDuplicated.length) {
      this.stepperIndex = 2;
    } else {
      this.common.showToast(
        this.translations.SelectAtLeastOneContact,
        ToastStatus.warning,
        3000
      );
    }
  }

  duplicateContacts(): void {
    const body: any = {
      competitors: null,
      completed: null,
      contacts: this.selectedContactsToBeDuplicated,
      deals: null,
    };
    this.crmService
      .cloneToProject(
        this.selectedProjectForDuplication?.idProject,
        body,
        false
      )
      .subscribe({
        complete: () => {
          this.showDuplicateContactsSection = false;
          this.disableManagedCustomers = false;
          this.stepperIndex = 0;
          this.selectedContactsToBeDuplicated = [];
          this.selectedContactsToBeDuplicatedForTable = [];
        },
      });
  }

  searchValue(value: string, table: string): void {
    switch (table) {
      case 'projectWhereToDuplicate':
        this.searchValueProjectWhereToDuplicate = value;
        break;
      case 'oldContacts':
        this.searchValueOldContacts = value;
        break;
      case 'alreadyExisting':
        this.searchValueAlreadyExistingContacts = value;
        break;
    }
  }

  resetDuplicateContactsSection(): void {
    this.disableManagedCustomers = false;
    this.showDuplicateContactsSection = false;
    if (this.managedCustomersSubscription) {
      this.managedCustomersSubscription.unsubscribe();
    }
  }

  getChannelName(idChannel: any): string {    
    let result: string;
    this.channels?.forEach((channelType: IChannelsType) => {
      if (channelType.idChannelType === idChannel) {
        result = channelType.description;
      }
    });    
    return result;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.contactSubscriptions) {
      this.contactSubscriptions.unsubscribe();
    }
    if (this.managedCustomersSubscription) {
      this.managedCustomersSubscription.unsubscribe();
    }
    if (this.selectedProjectForContactsDuplication) {
      this.selectedProjectForContactsDuplication.unsubscribe();
    }
  }

  private getAllContactCompetitors(): void {
    this.crmService
      .getAllContactCompetitors(this.idProject, false)
      .subscribe((competitors: IContactCompetitor[]) => {
        this.contactCompetitors = competitors
          .filter((competitor, i, self) => 
            i === self.findIndex(comp => 
              comp.companyName === competitor.companyName)
          )
          .map((comp) => ({
            companyName: comp.companyName,
            country: comp.country.country,
            valueRevenueYear: 0,
            contacts: []
          }))
        this.contactCompetitors.forEach(competitor => {
          competitors.forEach(comp => {
            if (comp.companyName === competitor.companyName) {
              competitor.contacts.push({
                contactName: comp.contactName,
                annualPurchaseValue: comp.valueRevenueYear
              });
              competitor.valueRevenueYear += comp.valueRevenueYear;
            }
          })
        })
        this.oldContactCompetitors = this.contactCompetitors;
        // this.contactCompetitors = competitors.map(
        //   (competitor: IContactCompetitor): IContactCompetitorForTable => ({
        //     ...competitor,
        //     country: competitor.country.country,
        //   })
        // );
        // this.oldContactCompetitors = competitors.map(
        //   (competitor: IContactCompetitor): IContactCompetitorForTable => ({
        //     ...competitor,
        //     country: competitor.country.country,
        //   })
        // );
        competitors.forEach((comp: IContactCompetitor) => {
          if (
            !this.filteredCountriesContactCompetitors.includes(
              comp.country.country
            )
          ) {
            this.filteredCountriesContactCompetitors.push(comp.country.country);
          }
        });
      });
  }
}
