import { Pipe, PipeTransform } from '@angular/core';
import { ITableButton, ITableSubButton } from '../models/utility';

@Pipe({
  name: 'tableMenuSkim',
})
export class TableMenuSkimPipe implements PipeTransform {
  transform(initialMenu: any[], row: any): ITableButton[] | ITableSubButton {
    initialMenu = initialMenu.filter(
      (menuItem: ITableButton | ITableSubButton): boolean =>
        !!(menuItem.conditions === undefined || menuItem.conditions(row))
    );
    const ret: ITableButton[] | ITableSubButton[] = [];
    for (const menuItem of initialMenu) {
      ret.push({
        ...menuItem,
        icon:
          typeof menuItem.icon === 'function'
            ? menuItem.icon(row)
            : menuItem.icon,
        class:
          typeof menuItem.class === 'function'
            ? menuItem.class(row)
            : menuItem.class,
        disabled:
          typeof menuItem.disabled === 'function'
            ? menuItem.disabled(row)
            : menuItem.disabled,
        tooltip:
          typeof menuItem.tooltip === 'function'
            ? menuItem.tooltip(row)
            : menuItem.tooltip,
        text:
          typeof menuItem.text === 'function'
            ? menuItem.text(row)
            : menuItem.text,
      });
    }
    return ret;
  }
}
