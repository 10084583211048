<!-- TABLE LOADER -->
<div *ngIf="skeletonType === 'table'" class="loader-container">
  <div class="fake-row">
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
  </div>
  <div class="fake-row">
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
  </div>
  <div class="fake-row">
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
  </div>
  <div class="fake-row">
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
    <div class="fake-container">
      <div class="fake-content"></div>
    </div>
  </div>
</div>

<!-- CARDS CONTAINTER -->
<mat-card *ngIf="skeletonType === 'cardsContainer'" class="skeleton-cards-container mat-elevation-z8">
  <mat-card class="skeleton-card mat-elevation-z8">
    <div class="skeleton-card-info">
      <div class="fake-content"></div>
    </div>
    <div class="skeleton-card-info">
      <div class="fake-content"></div>
    </div>
    <div class="skeleton-card-info">
      <div class="fake-content"></div>
    </div>
  </mat-card>
  <mat-card class="skeleton-card mat-elevation-z8">
    <div class="skeleton-card-info">
      <div class="fake-content"></div>
    </div>
    <div class="skeleton-card-info">
      <div class="fake-content"></div>
    </div>
    <div class="skeleton-card-info">
      <div class="fake-content"></div>
    </div>
  </mat-card>
  <mat-card class="skeleton-card mat-elevation-z8">
    <div class="skeleton-card-info">
      <div class="fake-content"></div>
    </div>
    <div class="skeleton-card-info">
      <div class="fake-content"></div>
    </div>
    <div class="skeleton-card-info">
      <div class="fake-content"></div>
    </div>
  </mat-card>
</mat-card>

<!-- Carousel -->
<carousel
  class="carousel-skeleton-container"
  *ngIf="skeletonType === 'carousel'"
  [arrowsOutside]="true"
  [cellsToShow]="3"
  [margin]="0"
>
  <div class="carousel-cell custom-cells">
    <mat-card class="mat-elevation-z8 customer-card">
      <div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
      </div>
    </mat-card>  
  </div>
  <div class="carousel-cell custom-cells">
    <mat-card class="mat-elevation-z8 customer-card">
      <div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
      </div>
    </mat-card>  
  </div>
  <div class="carousel-cell custom-cells">
    <mat-card class="mat-elevation-z8 customer-card">
      <div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
        <div class="card-info">
          <div class="fake-content"></div>
        </div>
      </div>
    </mat-card>  
  </div>
</carousel>