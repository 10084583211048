import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { fromMeetingsToMeetingsTable } from 'src/app/utility/elaborations';
import { IMeeting, IMeetingForTable } from 'src/app/models/meeting';
import { ProjectDTO } from 'src/app/models/project';
import { IColumnsName, ITableButtons, ITableOptions } from 'src/app/models/utility';
import { RI_STATUS } from 'src/app/config';

@Component({
  selector: 'app-meetings-table',
  templateUrl: './meetings-table.component.html',
  styleUrls: ['./meetings.component.scss']
})
export class MeetingsTableComponent implements OnInit, OnChanges, OnDestroy {

  subscriptions: Subscription = new Subscription();

  meetingsDataTable: MatTableDataSource<IMeetingForTable> = new MatTableDataSource<IMeetingForTable>();

  @Input() meetingsData: IMeeting[];
  @Input() loader: boolean = false;
  @Input() columnsName: IColumnsName[] = [
    {value: 'date', name: 'Data inserimento'},
    {value: 'startDate', name: 'Appuntamento'},
    {value: 'businessName', name: 'Business Name'},
    {value: 'telephoneNumber', name: 'Telefono'},
    {value: 'address', name: 'Indirizzo'},
  ];
  @Input() displayedColumns: string[] = [
    'date',
    'startDate',
    'businessName',
    'telephoneNumber',
    'address',
    'menuButtons'
  ];
  @Input() tableMenuButtons: ITableButtons[] = [
    {name: 'Compila ASS', click: (original: IMeetingForTable) => { this.contractIr(original); }, icon: 'edit'},
    {name: 'Compila ASS non contrattualizzato', click: (original: IMeetingForTable) => { this.noContractIr(original); }, icon: 'edit'}
  ];

  @Input() tableOptions: ITableOptions = {
    showFilter: true,
    showSort: true,
    showPaginator: true,
    showButtons: true
  };

  constructor(
    private router: Router,
    private irServece: IrFormService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const meetingsData: IMeeting[] = changes.meetingsData?.currentValue || [];
    if (meetingsData.length || meetingsData.length === 0) {
      this.meetingsDataTable.data = meetingsData.map(fromMeetingsToMeetingsTable);
    }
  }

  noContractIr(meeting: IMeetingForTable): void {
    if (meeting.currentProject?.idProject) {
      this.router.navigate(['/assessment', meeting.currentProject.idProject]);
    }
    if (!meeting.currentProject?.idProject) {
      const body: ProjectDTO = {
        idCustomer: meeting.customerAppointmentSummary.idCustomer,
        idProjectStatus: RI_STATUS.NoContract
      };
      this.subscriptions.add(this.irServece.createProject(body).subscribe({
        next: (resp: ProjectDTO) => this.router.navigate(['/assessment', resp.idProject])
      }));
    }
  }

  contractIr(meeting: IMeetingForTable): void {
    if (meeting.currentProject?.idProject) {
      this.router.navigate(['/assessment', meeting.currentProject.idProject]);
    }
    if (!meeting.currentProject?.idProject) {
      const body: ProjectDTO = {
        idCustomer: meeting.customerAppointmentSummary.idCustomer,
        idProjectStatus: RI_STATUS.RiWorking
      };
      this.subscriptions.add(this.irServece.createProject(body).subscribe({
        next: (resp: ProjectDTO) => this.router.navigate(['/assessment', resp.idProject])
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
