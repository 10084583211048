<div
  class="mb-30"
  *ngIf="idProject && !isSupervisor && !isDirection && !isReadonly"
>
  <app-customer-info-panel
    [idProject]="idProject"
    [isReadonly]="isReadonly"
    [path]="'ps-form'"
  ></app-customer-info-panel>
</div>

<div class="p-relative">
  <div class="global-actions">
    <button
      *ngIf="!isIspDelivered && !isManagerAndSupervisorManagar && !isReadonly"
      type="button"
      class="only-icon-button primary"
      (click)="saveAndSendStrategy()"
    >
      <svg-icon name="send_icon" class="w20 h20" ></svg-icon>
      <span class="ml-10 primary-color">{{ "COMMON.SendPS" | translate }}</span>
    </button>
    <button
      *ngIf="isManagerAndSupervisorManagar && !isReadonly"
      type="button"
      class="only-icon-button primary"
      (click)="saveAndSendStrategy()"
    >
      <svg-icon name="send_icon" class="w20 h20"></svg-icon>
      <span class="ml-10 primary-color">
        {{ "COMMON.SendAndApprovePS" | translate }}
      </span>
    </button>
    <button
      *ngIf="isIspDelivered && !isReadonly"
      type="button"
      class="only-icon-button primary"
      (click)="approvePs()"
    >
      <svg-icon name="accept_2_icon" class="w20 h20"></svg-icon>
      <span class="ml-10 primary-color">{{
        "COMMON.Approve" | translate
      }}</span>
    </button>
    <button
      *ngIf="isIspDelivered && !isReadonly"
      type="button"
      class="only-icon-button error ml-20"
      (click)="rejectPs()"
    >
      <svg-icon name="decline_2_icon" class="w20 h20"></svg-icon>
      <span class="ml-10 error-color">{{ "COMMON.Reject" | translate }}</span>
    </button>
  </div>
  <mat-tab-group
    mat-align-tabs="center"
    dynamicHeight
    class="custom-mat-tab"
    #matTabGroup
    [(selectedIndex)]="index"
    (focusChange)="showDialogCurrentTabDirty()"
  >
    <!-- CURRENT STRATEGY -->
    <mat-tab>
      <ng-template matTabLabel>
        <span>{{ "PS_FORM.ResumeAndSettings" | translate }}</span>
      </ng-template>
      <app-ps-current-strategy
        #currentStrategy
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [tabIndex]="0"
        [isSupervisor]="isSupervisor"
        [isIspDelivered]="isIspDelivered"
        [isReadOnly]="isReadonly"
        (saveCurrentStrategyEmitter)="saveCurrentStrategy($event)"
        (refreshCurrentStrategyEmitter)="refreshStrategy()"
      ></app-ps-current-strategy>
    </mat-tab>
    <!--SWOT-->
    <mat-tab>
      <ng-template matTabLabel>
        <span>{{ "PS_FORM.SwotUsp" | translate }}</span>
      </ng-template>
      <app-ps-swot
        #swot
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [tabIndex]="1"
        [isSupervisor]="isSupervisor"
        [isIspDelivered]="isIspDelivered"
        [isReadOnly]="isReadonly"
        (submitFuncEmitter)="saveSwot($event)"
        (refreshSwot)="refreshSwot()"
      ></app-ps-swot>
    </mat-tab>
    <!--COMPETITOR-->
    <mat-tab>
      <ng-template matTabLabel>
        <span>{{ "PS_FORM.CompetitorPositioning" | translate }}</span>
      </ng-template>
      <app-ps-competitor
        #competitors
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [isSupervisor]="isSupervisor"
        [isIspDelivered]="isIspDelivered"
        [isReadOnly]="isReadonly"
        (submitFuncEmitter)="saveCompetitorPositioning($event)"
      ></app-ps-competitor>
    </mat-tab>
    <!--STRATEGIA-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Strategy?.length"
          [matBadge]="splittedNotes?.Strategy?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PS_FORM.Strategy" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Strategy?.length">
          {{ "PS_FORM.Strategy" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="splittedNotes?.Strategy?.length || (isSupervisor && !isReadonly)"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isSupervisor"
        [userRole]="userRole"
        [tab]="psTab.Strategy"
        [notes]="splittedNotes?.Strategy"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project?.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
        (checkedNote)="childOnChecked($event)"
      >
      </app-note-button>
      <app-ps-strategy
        #strategy
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [isSupervisor]="isSupervisor"
        [isIspDelivered]="isIspDelivered"
        [isReadOnly]="isReadonly"
        [isManagerAndSupervisorManagar]="isManagerAndSupervisorManagar"
        (saveStrategyEmitter)="saveStrategy($event)"
        (rejectIspEmitter)="rejectPs()"
        (approveIspEmitter)="approvePs()"
      ></app-ps-strategy>
    </mat-tab>
  </mat-tab-group>
</div>
