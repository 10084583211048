<div class="filter-container">
  <mat-form-field class="filter">
    <mat-label>{{ 'COMMON.Filter' | translate }}</mat-label>
    <input matInput (keyup)="applyFilter($event)" maxlength="40" >
  </mat-form-field>
</div>
<ngu-carousel *ngIf="followedPMIsBM" id="pmiCarousel" [inputs]="carouselTile" #myCarousel [dataSource]="followedPMIsBM" class="custom-carousel">
  <ngu-item id="pmiCarouselItem"  *nguCarouselDef="let item; index as i; let ani = animate"
            [@slider]="ani" >
    <mat-card
         class="mat-elevation-z8 customer-card custom-cells"
         matRipple>
      <div class="custom-cells-left"
           (click)="viewContactCrmDetailPMI(item)">
          <div>
            <div class="card-info">
            <span class="bold-text-color">
              {{ item?.customer?.businessName }}
            </span>
            </div>
            <div class="card-info">
            <span class="text-color">
              {{ item?.code }}
            </span>
            </div>
            <div class="card-info">
            <span class="text-color">
              {{ 'CRM.Product' | translate }}:
            </span>
              <span class="text-color">
              {{ item?.productName }}
            </span>
            </div>
            <div class="card-info">
            <span class="text-color">
              {{ 'CRM.Version' | translate }}:
            </span>
              <span class="bold-text-color">
              {{ psVersion[item?.projectStatusCode] }}
            </span>
            </div>
            <div *ngIf="item?.reason" class="card-info">
            <span class="text-color">
              {{ 'CRM.SubProjectType' | translate }}:
            </span>
              <span class="bold-text-color">
              {{ item?.reason }}
            </span>
            </div>
          </div>
      </div>
      <div class="custom-cells-right">
        <button 
          matBadge
          [matBadgeHiddenDirective]="!item?.hasBm"
          [matBadgeIcon]="'notifications'"
          [matBadgeColor]="'warn'"
          class="bm-button"
          mat-button
          (click)="goToCrmBm(item)"
        >
          <mat-icon>search</mat-icon>
          BM
        </button>
        <button [ngClass]="['pmi-menu-button']" mat-button [matMenuTriggerFor]="menu">
          <mat-icon class="pmi-menu-button-horiz">more_horiz</mat-icon></button>
        <mat-menu class="pmi-menu-item-button" #menu="matMenu">
          <button mat-menu-item (click)="sendCurrentPS(item)">{{ 'CRM.SendCurrentPS' | translate }}</button>
          <button mat-menu-item (click)="sendToDeals(item)">{{ 'CRM_DETAIL.DealsView' | translate }}</button>
          <button mat-menu-item (click)="cloneProject(item)">{{ 'CRM_DETAIL.CloneProject' | translate }}</button>
        </mat-menu>
      </div>

    </mat-card>
  </ngu-item>
  <button NguCarouselPrev class='slider-nav leftRs' [style.opacity]="myCarousel.isFirst ? 0.5 : 1">
    <mat-icon class="mt-8"> keyboard_arrow_left </mat-icon>
  </button>
  <button NguCarouselNext class='slider-nav rightRs' [style.opacity]="myCarousel.isLast ? 0.5 : 1">
    <mat-icon class="mt-8"> keyboard_arrow_right </mat-icon>
  </button>
</ngu-carousel>
