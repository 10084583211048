<div class="wrapper">
  <div class="sidebar">
    <app-sidebar
      [links]="navBar"
      [name]="authService?.userProfile?.name"
      [surname]="authService?.userProfile?.surname"
      (reloadPage)="reloadPage()"
      (logout)="logout()"
    ></app-sidebar>
    <app-hidden-archor
      [urlListener]="fileUrlListener$"
      (emitRevokeUrl)="revokeDownloadUrl()"
    ></app-hidden-archor>
  </div>
  <div class="content">
    <div class="header">
      <app-header 
        (logoutEmitter)="logout()" 
        (setReloading)="setReloading($event)"
        (reloadPage)="reloadPage()"
      ></app-header>
    </div>
    <div class="section">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
