import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { DOC_TYPE, RI_STATUS, Roles, ROLES } from 'src/app/config';
import { Contract, DocumentDTO } from 'src/app/models/contract';
import { Project } from 'src/app/models/project';
import { MustBeAfter, MustBeBefore } from 'src/app/utility/validators';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { IFileToHandle, ToastStatus } from 'src/app/models/utility';
import { CommonService } from 'src/app/services/common/common.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss'],
})
export class ContractFormComponent implements OnInit, OnDestroy {
  contractForm: FormGroup;
  // TODO: se il blocco di codice per vedere lo user non serve allora eliminare anche questa riga sotto
  // contract: Contract;
  user: string;
  fileToChild: IFileToHandle = {};
  isLabManager: boolean;

  subscriptions: Subscription = new Subscription();

  startDataOnInit: any;
  showUploadError: boolean = false;
  isIsp: boolean = false;
  otherDocuments: DocumentDTO[] = [];

  @Input() initialValues: Observable<Project>;
  @Input() documentChanged: Observable<DocumentDTO>;
  @Input() translations: any;
  @Input() userRole: string;
  @Input() isLabManagerInternal: boolean;
  @Input() isLabManagerSupervisor: boolean;
  @Input() isReadOnly: boolean;
  @Input() otherDocumentsObs: Observable<DocumentDTO[]>;

  @Output() submitFuncEmitter: EventEmitter<Contract> = new EventEmitter();
  @Output() uploadDocumentEmitter: EventEmitter<File> = new EventEmitter();
  @Output() uploadOtherDocumentEmitter: EventEmitter<File> = new EventEmitter();
  @Output() refreshOtherDocumentsEmitter: EventEmitter<boolean> =
    new EventEmitter();
  @Output() viewDocumentEmitter: EventEmitter<number | string> =
    new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private common: CommonService,
    private irFormService: IrFormService,
    private dialog: MatDialog
  ) {
    this.contractForm = fb.group({
      idContract: [null],
      contractNumber: [null, [Validators.required]],
      customerId: [null, [Validators.required]],
      user: fb.group({
        idUser: [null],
      }),
      dateStart: [null, [Validators.required]],
      dateStartProject: [null],
      // dateStart: [ null, [ Validators.required, MustBeAfter('dateEnd') ]],
      // dateEnd: [ null, [ Validators.required, MustBeBefore('dateStart') ]],
      commissionStandard: [null, [Validators.min(0), Validators.max(100)]],
      commissionAgent: [
        null,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      documentDTO: this.fb.group({
        idDocument: [null],
        path: [null, [Validators.required]],
        type: [DOC_TYPE.CONTRACT],
        name: [null],
      }),
      projectDTO: this.fb.group({
        idProject: [null],
      }),
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.otherDocumentsObs.subscribe({
        next: (otherDocuments: DocumentDTO[]) => {
          this.otherDocuments = [];
          otherDocuments.forEach((doc: DocumentDTO) => {
            if (doc.type === 'OTHER_DOC') {
              this.otherDocuments.push(doc);
            }
          });
        },
      })
    );
    // Values Change subscription
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialValues: Project) => {
          if (initialValues.idProjectStatus >= RI_STATUS.IspWorking) {
            this.isIsp = true;
          }
          if (
            this.userRole === Roles.LabManager ||
            this.userRole === Roles.LabManagerSupervisor
          ) {
            this.contractForm.addControl(
              'dateStartProject',
              this.fb.control(null, [Validators.required])
            );
            this.isLabManager = true;
          }
          // TODO: a che serve questo blocco di codice se lo user è comunque colui che è a capo del progetto?
          // this.contract = initialValues.contract;
          // if (this.contract && this.contract.user) {
          //   this.userName = `${this.contract.user.name} ${this.contract.user.surname}`;
          // }
          // TODO: la riga sottostante sostituisce il blocco di codice qui sopra
          if (initialValues.contract?.user?.idUser) {
            this.user = `${initialValues.contract.user.name} ${initialValues.contract.user.surname}`;
          } else {
            this.user = `${initialValues.user?.name} ${initialValues.user?.surname}`;
          }
          const initialContract: Contract = {
            idContract: initialValues.contract?.idContract || null,
            contractNumber: initialValues.contract?.contractNumber || null,
            dateStart: initialValues.contract?.dateStart || null,
            dateStartProject: initialValues.contract?.dateStartProject || null,
            // dateStart: initialValues.contract?.dateStart || null,
            // dateEnd: initialValues.contract?.dateEnd || null,
            commissionStandard:
              initialValues.contract?.commissionStandard || null,
            commissionAgent: initialValues.contract?.commissionAgent || null,
            user: {
              idUser:
                initialValues.contract?.user?.idUser ||
                initialValues.idUser ||
                null,
            },
            customerId: initialValues.idCustomer || null,
            documentDTO: {
              idDocument: initialValues.contract?.idDocument || null,
              path: initialValues.contract?.documentDTO?.path || null,
              name: initialValues.contract?.documentDTO?.name || null,
            },
            projectDTO: {
              idProject: initialValues.idProject || null,
            },
          };
          this.fileToChild.name = initialValues.contract?.documentDTO?.name;
          this.contractForm.patchValue(initialContract);
          if (this.contractForm.get('documentDTO.path').invalid) {
            this.showUploadError = true;
          }
          this.contractForm.markAsPristine();
          this.contractForm.markAsUntouched();
          if (this.isLabManagerInternal || this.isReadOnly) {
            this.contractForm.disable();
          }
          this.startDataOnInit = this.contractForm.getRawValue();
        },
      })
    );
    // Subscription to document change event
    this.subscriptions.add(
      this.documentChanged.subscribe({
        next: (documentDTO: DocumentDTO) =>
          this.updateDocumentValues(documentDTO),
      })
    );
  }

  /**
   * @description Get method of contractForm Controls
   */
  get controls(): { [key: string]: AbstractControl } {
    return this.contractForm.controls;
  }

  /**
   * @description Get method of documentDTO Form
   */
  get documentControls(): { [key: string]: AbstractControl } {
    return (this.contractForm.get('documentDTO') as FormGroup).controls;
  }

  /**
   * @description Emit submit event
   */
  submit(): void {
    if (this.contractForm.valid && this.contractForm.dirty) {
      const contract: Contract = this.contractForm.getRawValue();
      // if (!this.documentControls.path.dirty) { delete contract.documentDTO?.path; }
      this.submitFuncEmitter.emit(contract);
      // this.contractForm.markAsPristine();
      // this.contractForm.markAsUntouched();
    }
    if (this.contractForm.get('documentDTO.path').invalid) {
      this.common.showToast(
        this.translations.UploadTheContract,
        ToastStatus.error,
        3000
      );
    }
  }

  /**
   * @description Emit the upload file event
   * @param document the uploaded file
   */
  uploadDocument(document: File): void {
    this.uploadDocumentEmitter.emit(document);
  }

  uploadOtherDocument(otherDocument: File): void {
    this.uploadOtherDocumentEmitter.emit(otherDocument);
  }

  /**
   * @description Emit the event to view the document
   */
  viewDocument(): void {
    if (this.documentControls.idDocument.value) {
      this.viewDocumentEmitter.emit(this.documentControls.idDocument.value);
    } else {
      this.common.showToast(this.translations.SaveBeforeMessage);
    }
  }

  downloadOtherDocument(idDocument: string | number): void {
    this.subscriptions.add(
      this.irFormService.downloadDocument(idDocument).subscribe()
    );
  }

  deleteOtherDocument(idDocument: string | number): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: this.translations.Attention,
      message: this.translations.DeleteDocumentQuestion,
      buttonTrue: this.translations.Delete,
      buttonFalse: this.translations.Cancel,
    };
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> =
      this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.add(
          this.irFormService
            .deleteCustomerDocument(false, idDocument)
            .subscribe({
              complete: () => {
                this.refreshOtherDocumentsEmitter.emit(true);
              },
            })
        );
      }
    });
  }

  /**
   * @description Update the form when the document is uploaded
   * @param documentDTO the document form group values
   */
  updateDocumentValues(documentDTO: DocumentDTO): void {
    this.controls.documentDTO.patchValue(documentDTO);
    this.fileToChild.name = documentDTO.name;
    this.documentControls.path.markAsDirty();
    this.showUploadError = false;
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(this.contractForm.getRawValue())
    );
  }

  checkInvalidForm(): boolean {
    this.contractForm.markAllAsTouched();
    return this.contractForm?.invalid;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
