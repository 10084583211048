import { DOC_TYPE, RI_STATUS } from '../config';
import { DocumentDTO } from '../models/contract';
import { IProjectForTable, ProjectDTO } from '../models/project';

// tslint:disable: variable-name
const __filtersOnArray = (array: any[], filter: any) => {
  try {
    for (const item of array) {
      if (item === filter || item.trim().toLowerCase().includes(filter)) {
        return true;
        break;
      }
    }
    return false;
  } catch (err) {
    console.error(err)
    return true;
  }
};

const __filterOnAllData = (data: any, filter: any): boolean => {
  try {
    let ret: boolean = false;
    Object.keys(data).forEach((field) => {
      if (!ret) {
        if (data[field]) {
          if (typeof data[field] === 'string') {
            ret = data[field].trim().toLowerCase().includes(`${filter}`);
          } else if (Array.isArray(data[field])) {
            ret = __filtersOnArray(data[field], filter);
          } else if (typeof data[field] === 'number') {
            ret = data[field] === filter;
          } else if (typeof data[field] === 'boolean') {
            ret = data[field] === filter;
          } else if (typeof data[field] === 'boolean') {
            ret = data[field] === filter;
          } else {
            ret = data[field].toLowerCase() === filter;
          }
        }
      }
    });
    return ret;
  } catch (err) {
    return true;
  }
};
/**
 * @description custom filter callback
 * @param data the elaborated user in filterPredicate cycle callback
 * @param filter the filter insert in the dom from app user
 */
export function CustomFilters(data: unknown, filter: string): boolean {

  try {
    const filters: {[key: string]: any} = JSON.parse(filter);
    let ret: boolean[] = [];
    ret = Object.keys(filters).map((key): boolean => {
      if (key === 'ALL') {
        return __filterOnAllData(data, filters[key]);
      } else {
        if (Array.isArray(data[key])) {
          return  __filtersOnArray(data[key], filters[key]);
        } else if (typeof data[key] === 'string') {
          return data[key].toLowerCase().includes(filters[key]);
        } else {
          return data[key] === filters[key];
        }
      }
    });
    return ret.indexOf(false) < 0;
  } catch (err) {
    return true;
  }
}

/**
 * @description Check the status of project to hide or show the menu item
 * @param project the project in the row of the table
 */
export function checkIspStatus(project: IProjectForTable): boolean {
  return project?.idProjectStatus === RI_STATUS.IspWorking ||
    project?.idProjectStatus === RI_STATUS.IspWorking2 ||
    project?.idProjectStatus === RI_STATUS.IspWorking3 ||
    project?.idProjectStatus === RI_STATUS.IspWorking4 ||
    project?.idProjectStatus === RI_STATUS.IspWorking5 ||
    project?.idProjectStatus === RI_STATUS.IspWorking6 ||
    project?.idProjectStatus === RI_STATUS.IspDelivered ||
    project?.idProjectStatus === RI_STATUS.IspDelivered2 ||
    project?.idProjectStatus === RI_STATUS.IspDelivered3 ||
    project?.idProjectStatus === RI_STATUS.IspDelivered4 ||
    project?.idProjectStatus === RI_STATUS.IspDelivered5 ||
    project?.idProjectStatus === RI_STATUS.IspDelivered6;
}

export function checkIspWorking1(project: IProjectForTable): boolean {
  return project?.idProjectStatus === RI_STATUS.IspWorking;
}

/**
 * @description Check the status of project to hide or show the menu item
 * @param project the project in the row of the table
 */
export function checkIspDelivered2(project: IProjectForTable): boolean {
  return project?.idProjectStatus === RI_STATUS.IspDelivered2 ||
          project?.idProjectStatus === RI_STATUS.IspDelivered3 ||
          project?.idProjectStatus === RI_STATUS.IspDelivered4 ||
          project?.idProjectStatus === RI_STATUS.IspDelivered5 ||
          project?.idProjectStatus === RI_STATUS.IspDelivered6 ;
}

/**
 * @description Check the status of project to hide or show the menu item
 * @param project the project in the row of the table
 */
export function checkModify(project: IProjectForTable): boolean {
  return project?.idProjectStatus === RI_STATUS.RiWorking ||
    project?.idProjectStatus === RI_STATUS.RiDelivered || 
    project?.idProjectStatus === RI_STATUS.IspWorking ||
    project?.idProjectStatus === RI_STATUS.IspDelivered ||
    project?.idProjectStatus === RI_STATUS.NoContract;
}

/**
 * @description Check the status of project to hide or show the menu item
 * @param project the project in the row of the table
 */
export function checkModifyCRM(project: IProjectForTable): boolean {
  return project?.idProjectStatus === RI_STATUS.IspWorking2 ||
        project?.idProjectStatus === RI_STATUS.IspWorking3 ||
        project?.idProjectStatus === RI_STATUS.IspWorking4 ||
        project?.idProjectStatus === RI_STATUS.IspWorking5 ||
        project?.idProjectStatus === RI_STATUS.IspWorking6;
}

/**
 * @description Check the status of project to hide or show the menu item
 * @param project the project in the row of the table
 */
export function checkVisualizePs1(project: IProjectForTable): boolean {
  return project?.idProjectStatus === RI_STATUS.IspWorking2 ||
        project?.idProjectStatus === RI_STATUS.IspWorking3 ||
        project?.idProjectStatus === RI_STATUS.IspWorking4 ||
        project?.idProjectStatus === RI_STATUS.IspWorking5 ||
        project?.idProjectStatus === RI_STATUS.IspWorking6;
}

/**
 * @description Check the status of project to hide or show the menu item
 * @param project the project in the row of the table
 */
export function checkIrStatus(project: IProjectForTable): boolean {
  return project?.idProjectStatus === RI_STATUS.RiWorking ||
    project?.idProjectStatus === RI_STATUS.RiApproved ||
    project?.idProjectStatus === RI_STATUS.RiDelivered;
}

/**
 * @description Check if there's a document of type RI to show or hide the menu item
 * @param project the project in the row of the table
 */
export function checkDocuments(project: IProjectForTable): boolean {
  return project.documents?.length > 0 || project.document?.length > 0;
}

/**
 * @description Check if there's a document of type OTHER_DOC to show or hide the menu item
 * @param document the document in the row of the table
 */
export function checkCustomerDocument(document: DocumentDTO): boolean {
  return document !== null || undefined;
}

/**
 * @description Check if there's a document of type ISP to show or hide the menu item
 * @param project the project in the row of the table
 */
export function checkIspDocument(project: IProjectForTable): boolean {
  return project.documents.filter((doc: DocumentDTO) => (doc.type === DOC_TYPE.ISP)).length > 0;
}

/**
 * @description Check if there's a document of type RI to show or hide the menu item
 * @param project the project in the row of the table
 */
export function checkIrDocument(project: IProjectForTable): boolean {
  return project.documents.filter((doc: DocumentDTO) => (doc.type === DOC_TYPE.RI || doc.type === DOC_TYPE.CONTRACT)).length > 0;
}

export function checkIspWorking2OrGreater(project: IProjectForTable): boolean {
  return project?.idProjectStatus === RI_STATUS.IspWorking2 ||
  project?.idProjectStatus === RI_STATUS.IspWorking3 ||
  project?.idProjectStatus === RI_STATUS.IspWorking4 ||
  project?.idProjectStatus === RI_STATUS.IspWorking5 ||
  project?.idProjectStatus === RI_STATUS.IspWorking6;
}
