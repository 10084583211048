import { Component, OnInit, Input, Output, EventEmitter,
  OnDestroy, OnChanges, SimpleChanges
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { fromProjectDtoToProjectTable } from 'src/app/utility/elaborations';
import { IProjectForTable, ProjectDTO } from 'src/app/models/project';
import { IColumnsName, ITableOptions } from 'src/app/models/utility';
import { RI_TAB } from 'src/app/config';

@Component({
  selector: 'app-ir-approved-table',
  templateUrl: './ir-approved-table.component.html',
  styleUrls: ['./ir-approved.component.css']
})
export class IrApprovedTableComponent implements OnInit, OnChanges, OnDestroy {

  subscriptions: Subscription = new Subscription();

  projectDataTable: MatTableDataSource<IProjectForTable> = new MatTableDataSource<IProjectForTable>();

  riTabs: string[] = Object.values(RI_TAB);

  @Input() loader: boolean;
  @Input() translations: any;
  @Input() projectData: ProjectDTO[];
  @Input() columnsName: IColumnsName[] = [
    {value: 'idProject', name: 'ID Progetto'},
    {value: 'businessName', name: 'Ragione Sociale'},
    {value: 'telephoneNumber', name: 'Telefono'},
    {value: 'email', name: 'E-Mail'},
    {value: 'address', name: 'Indirizzo'},
    {value: 'lastModified', name: 'Data'}
  ];
  @Input() displayedColumns: string[] = [
    'lastModified',
    'idProject',
    'businessName',
    'telephoneNumber',
    'email',
    'address'    
  ];
  @Input() tableOptions: ITableOptions = {
    showFilter: true,
    showSort: true,
    showPaginator: true,
    showButtons: false
  };

  @Output() reloadDataEmitter: EventEmitter<void> = new EventEmitter();

  constructor(
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    const projectData: ProjectDTO[]  = changes.projectData?.currentValue || [];
    if (projectData?.length || projectData.length === 0) {
      this.projectDataTable.data = projectData.map(fromProjectDtoToProjectTable);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
