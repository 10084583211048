<div class="section-card">
  <div class="print-header">
    <img src="../../../assets/img/headerPrint.png" />
    <div>
      <div class="fs-26">
        {{ "CALENDAR.ExportAppointments" | translate }}
      </div>
      <div class="fs-18">
        {{ today | date: "medium" }}
      </div>
    </div>
  </div>
  <div class="header h70">
    <div class="title">
      <span>
        {{ "COMMON.Today" | translate }},
        {{ today | date: "longDate" }}
      </span>
    </div>
  </div>
  <div class="content">
    <app-calendar
      [isSupervisorAgent]="isAgentSupervisor"
      [isAgent]="isAgent"
      [idUser]="idUser"
    ></app-calendar>
    <div class="meetings-footer">
      <div class="meetings-legend">
        <div class="legend-item">
          <div class="phone">
            <span>{{ "COMMON.Telephonic" | translate }}</span>
          </div>
        </div>
        <div class="legend-item">
          <div class="operator">
            <span>{{ "COMMON.Personal" | translate }}</span>
          </div>
        </div>
        <div class="legend-item">
          <div class="rescheduled">
            <span>{{ "COMMON.Rescheduled" | translate }}</span>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="rounded-button primary-border-button"
        (click)="printPage()"
      >
        {{ "COMMON.Print" | translate }}
      </button>
    </div>
  </div>
</div>
