<form [formGroup]="reportForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <h2 mat-dialog-title class="ta-center">{{ 'CRM_DETAIL.Report' | translate }}</h2>
    <div *ngIf="!dataForSelectReady" class="my-40">
      <mat-spinner class="m-0-auto" diameter="40"></mat-spinner>
    </div>
    <div *ngIf="dataForSelectReady">
      <div class="row">
        <mat-form-field class="col-6" formGroupName="commerceSector">
          <mat-label for="idCommerceSector">
            {{ 'IR_FORM.CommerceSector' | translate }}
          </mat-label>
          <mat-select disableOptionCentering panelClass="custom-panel-class" formControlName="idCommerceSector" name="idCommerceSector">
            <mat-option *ngFor="let sector of commerceSectors" [value]="sector.idCommerceSector">
              {{sector.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="!reportForm.controls.commerceSector.get('idCommerceSector').valid">
            {{ reportForm.controls.commerceSector.get('idCommerceSector').errors | getErrorMessage: translations.CommerceSector }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label for="productName">
            {{ 'IR_FORM.ProductName' | translate }}
          </mat-label>
          <input
            matInput
            formControlName="productName"
            type="text"
            name="productName"
            autocomplete="off"
          >
          <mat-error *ngIf="reportForm.get('productName').invalid">
            {{ reportForm.get('productName').errors | getErrorMessage:translations.Name }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-12">
          <mat-label for="productDescription">
            {{ 'IR_FORM.ExtendedDescription' | translate }}
          </mat-label>
          <textarea
            matInput
            formControlName="productDescription"
            type="text"
            name="productDescription"
            autocomplete="off"
          ></textarea>
          <mat-error *ngIf="reportForm.get('productDescription').invalid">
            {{ reportForm.get('productDescription').errors | getErrorMessage:translations.ExtendedDescription }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-12">
          <mat-label for="productEnglishDescription">
            {{ 'IR_FORM.EnglishExtendedDescription' | translate }}
          </mat-label>
          <textarea
            matInput
            formControlName="productEnglishDescription"
            type="text"
            name="productEnglishDescription"
            autocomplete="off"
          ></textarea>
          <mat-error *ngIf="reportForm.get('productEnglishDescription').invalid">
            {{ reportForm.get('productEnglishDescription').errors | getErrorMessage:translations.EnglishExtendedDescription }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-4" formGroupName="unitOfMeasure">
          <mat-label for="idUom">
            {{ 'IR_FORM.UnitOfMeasure' | translate }}
          </mat-label>
          <mat-select disableOptionCentering panelClass="custom-panel-class" formControlName="idUom" name="idUom">
            <mat-option *ngFor="let unit of unitsOfMeasure" [value]="unit.idUom">
              {{ unit.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="reportForm.controls.unitOfMeasure.get('idUom').invalid">
            {{ reportForm.controls.unitOfMeasure.get('idUom').errors | getErrorMessage:translations.UnitOfMeasure }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-4">
          <mat-label for="quantity">
            {{ 'IR_FORM.Quantity' | translate }}
          </mat-label>
          <input
            currencyMask
            matInput
            formControlName="quantity"
            [options]="currencyQuantityConfig"
            autocomplete="off"
            name="quantity"
          >
          <mat-error *ngIf="reportForm.get('quantity').invalid">
            {{ reportForm.get('quantity').errors | getErrorMessage:translations.Quantity }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-4">
          <mat-label for="quantityYear">
            {{ 'IR_FORM.QuantityYear' | translate }}
          </mat-label>
          <input
            currencyMask
            matInput
            formControlName="quantityYear"
            [options]="currencyQuantityConfig"
            name="quantityYear"
            autocomplete="off"
            (keyup)="updateValueYear(reportForm)"
          >
          <mat-error *ngIf="reportForm.get('quantityYear').invalid">
            {{ reportForm.get('quantityYear').errors | getErrorMessage:translations.QuantityYear }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-4">
          <mat-label for="valueOfUnit">
            {{ 'IR_FORM.ValueOfUnit' | translate }}
          </mat-label>
          <input
            matInput
            name="valueOfUnit"
            currencyMask
            formControlName="valueOfUnit"
            autocomplete="off"
            [options]="currencyDefaultConfig"
            (keyup)="updateValueYear(reportForm)"
          >
          <mat-error *ngIf="reportForm.get('valueOfUnit').invalid">
            {{ reportForm.get('valueOfUnit').errors | getErrorMessage:translations.ValueOfUnit }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-4">
          <mat-label for="valueYear">
            {{ 'IR_FORM.ValueYear' | translate }}
          </mat-label>
          <input
            matInput
            formControlName="valueYear"
            readonly
            name="valueYear"
            autocomplete="off"
            [options]="currencyDefaultConfig"
            currencyMask
          >
        </mat-form-field>
      </div>
      <div formArrayName="reportMatching">
        <div *ngFor="let matching of reportMatching.controls; let i = index" [formGroupName]="i">
          <button type="button" mat-button >
            <mat-icon class="delete-note" (click)="removeReportMatching(i)" [ngClass]="disabled-color">clear</mat-icon>
          </button>
          <mat-form-field class="note-description-field">
            <mat-label for="wordKey">{{ 'COMMON.WordKey' | translate }}</mat-label>
            <input matInput type="text" formControlName="wordKey" name="wordKey" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button type="submit" mat-raised-button color="primary" [disabled]="isReadonly">
      <mat-icon>save</mat-icon>
      {{ 'IR_FORM.Save' | translate }}
    </button>
    <button mat-raised-button color="warn" mat-dialog-close>
      <mat-icon>clear</mat-icon>
      {{ 'CRM_DETAIL.Cancel' | translate }}
    </button>
  </mat-dialog-actions>
</form>
