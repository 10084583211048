import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, Route } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Credetials,
  UserFunctionality,
  LoggedUser,
  LoginResponse,
  SignUpRequest,
} from 'src/app/models/user';
import Config, {
  ADMINISTRATOR_ROLE,
  AGENT_ROLE,
  AGENT_SUPERVISOR,
  DIRECTION_ROLE,
  LAB_MANAGER,
  LAB_MANAGER_INTERNAL,
  LAB_MANAGER_SUPERVISOR,
  storageKeys,
} from 'src/app/config';
import { Storage } from 'src/app/utility/storage';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastStatus } from 'src/app/models/utility';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  functionality: UserFunctionality;
  userProfile: LoggedUser;
  translations: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private zone: NgZone,
    private common: CommonService
  ) {
    if (!this.functionality) {
      this.__setFunctionalityFromSession();
    }
    if (!this.userProfile) {
      this.__setUserProfileFromSession();
    }
  }

  /**
   * @description Set all token info in session
   * @param token the getToken response
   */
  setTokenInSession(token: LoginResponse): void {
    Storage.set(storageKeys.accessToken, token.access_token);
    Storage.set(storageKeys.expireToken, token.expires_in);
    Storage.set(storageKeys.tokenDate, token.issue_date);
    Storage.set(storageKeys.tokenShema, token.token_type);
    Storage.set(storageKeys.refreshToken, token.refresh_token);
  }

  /**
   * @description Set the user profile in the local storage
   * @param sessionUser the user returned in login API
   */
  setUserInSession(sessionUser: LoggedUser, role?: string): void {
    this.userProfile = {
      idUser: sessionUser.idUser,
      name: sessionUser.name,
      surname: sessionUser.surname,
      email: sessionUser.email,
      username: sessionUser.username,
      roles: sessionUser.roles,
      demonstrator: sessionUser?.demonstrator,
      activeRole: role || sessionUser.roles[0],
      parentUserId: sessionUser.parentUserId,
      telephone: sessionUser.telephone,
      address: sessionUser.address,
      cap: sessionUser.cap,
      city: sessionUser.city,
      competenceArea: sessionUser.competenceArea,
      image: sessionUser.image,
    };
    this._getFunctionality(this.userProfile.activeRole);
    Storage.set(storageKeys.user, this.userProfile);
    Storage.set(storageKeys.functionality, this.functionality);
  }

  /**
   * @description This method inizialize "functionality" property of authService istance
   */
  private __setFunctionalityFromSession(): void {
    this.functionality = new UserFunctionality();
    const functionality = Storage.get(storageKeys.functionality);
    if (functionality) {
      this.functionality = functionality;
    }
  }

  /**
   * @description This method inizialize "userProfile" property of authService istance
   */
  private __setUserProfileFromSession(): void {
    const sessionUser: LoggedUser = Storage.get(storageKeys.user);
    if (sessionUser) {
      this.userProfile = sessionUser;
    }
  }

  /**
   * @description Store the avaible functionality set
   * @TODO Implement the others roles functionalities
   */
  private _getFunctionality(role: string): void {
    this.functionality = new UserFunctionality();

    if (role === ADMINISTRATOR_ROLE) {
      this.functionality.administrator.enabled = true;
      this.functionality.customer.enabled = true;
    }

    if (role === DIRECTION_ROLE) {
      /*
      this.functionality.appointment.create = true;
      this.functionality.appointment.assign = true;
      this.functionality.appointment.edit = true;
      this.functionality.appointment.enabled = false;
      */
      this.functionality.infoReport.enabled = true;
      this.functionality.infoReport.assign = true;
      this.functionality.isp.enabled = true;
      this.functionality.isp.assign = true;
      this.functionality.customer.enabled = true;
      this.functionality.bm.enabled = true;
    }

    if (role === AGENT_ROLE) {
      this.functionality.appointment.confirm = true;
      this.functionality.appointment.edit = true;
      this.functionality.appointment.enabled = true;
      this.functionality.infoReport.enabled = true;
      this.functionality.infoReport.create = true;
      this.functionality.infoReport.edit = true;
      this.functionality.infoReport.delivered = true;
      this.functionality.customer.enabled = true;
      this.functionality.bm.enabled = true;
    }

    if (role === AGENT_SUPERVISOR) {
      this.functionality.appointment.confirm = true;
      this.functionality.appointment.edit = true;
      this.functionality.appointment.enabled = true;
      this.functionality.infoReport.enabled = true;
      this.functionality.infoReport.approve = true;
      this.functionality.customer.enabled = true;
      this.functionality.bm.enabled = true;
    }

    if (role === LAB_MANAGER) {
      this.functionality.isp.enabled = true;
      this.functionality.isp.create = true;
      this.functionality.isp.edit = true;
      this.functionality.isp.delivered = true;
      this.functionality.isp.approve = true;
      this.functionality.isp.reject = true;
      this.functionality.customer.enabled = true;
      this.functionality.crm.enabled = true;
      this.functionality.bm.enabled = true;
      this.functionality.bmCustom.enabled = true;
    }

    if (role === LAB_MANAGER_SUPERVISOR) {
      this.functionality.isp.enabled = true;
      this.functionality.isp.approve = true;
      this.functionality.isp.reject = true;
      this.functionality.customer.enabled = true;
      this.functionality.bm.enabled = true;
    }

    if (role === LAB_MANAGER_INTERNAL) {
      this.functionality.isp.enabled = true;
      this.functionality.isp.create = true;
      this.functionality.isp.edit = true;
      this.functionality.isp.delivered = true;
      this.functionality.customer.enabled = true;
      this.functionality.crm.enabled = true;
      this.functionality.bm.enabled = true;
      this.functionality.bmCustom.enabled = true;
      // TODO: ask if internal will see de BusinessMatching section
      // this.functionality.bm.enabled = true;
    }

    // if (roles.includes('LAB_MANAGER') && roles.includes('LAB_MANAGER_SUPERVISOR')) {
    //   this.functionality.isp.delivered = false;
    // }
  }

  /**
   * @description Check if user have token, if not return false, if token is expired perform refresh token
   */
  isAuthenticated(): boolean {
    const token = Storage.get(storageKeys.accessToken);
    if (!token) {
      return false;
    }
    return true;
  }

  /**
   * @description Return a void observable after getToken and getProfile API
   * @TODO Create a better CatchError and ErrorsHandler
   */
  login(body: Credetials): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      this.getToken(body).subscribe({
        complete: () => {
          this.getProfile().subscribe({
            complete: () => subscriber.complete(),
          });
        },
      });
    });
  }

  /**
   * @desciption Call the POST `account/refresh_token` API, Refresh token API CALL
   * @TODO rivedere la logica della refresh token
   */
  refreshToken(): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      `${Config.apiRootUrl}/account/refresh_token`,
      null,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        params: {
          access_token: Storage.get(storageKeys.accessToken),
          refresh_token: Storage.get(storageKeys.refreshToken),
        },
      }
    );
  }

  /**
   * @description Call the PUT `account/signup` API, Sign up the user
   * @param body the body of signup request
   */
  confirmSignUp(body: SignUpRequest): Observable<any> {
    return this.http
      .put(`${Config.apiRootUrl}/account/confirmsignup`, body)
      .pipe();
  }

  /**
   * @description Call the POST `/account/access_token` API
   * @param body the credentials of User
   */
  getToken(body: Credetials): Observable<LoginResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${btoa(`${body.username}:${body.password}`)}`,
    });
    return this.http
      .post<LoginResponse>(`${Config.apiRootUrl}/account/access_token`, null, {
        headers,
      })
      .pipe(
        map((token: LoginResponse) => {
          this.setTokenInSession(token);
          return token;
        })
      );
  }

  /**
   * @description Call the GET `/user/profile` API, Get the user profile
   */
  getProfile(): Observable<LoggedUser> {
    return this.http.get<LoggedUser>(`${Config.apiRootUrl}/user/profile`).pipe(
      map((profile: LoggedUser): LoggedUser => {
        profile.activeRole = profile.roles[0];
        if (profile.roles[0] === 'CALL_CENTER') {
          this.common.showToast(
            'Utente non autorizzato',
            ToastStatus.error,
            3000
          );
          this.logout();
          return;
        }
        this.setUserInSession(profile);
        return profile;
      })
    );
  }

  /**
   * @description Call the PUT `account/passwordReset` API, confirm reset password
   * @param email email of user
   * @param jwt token to confirm validity
   * @param password the password to set
   */
  resetPassword(
    email: string,
    jwt: string,
    password: string
  ): Observable<void> {
    return this.http
      .put<void>(`${Config.apiRootUrl}/account/passwordReset`, {
        email,
        jwt,
        password,
      })
      .pipe();
  }

  updateAccount(idUser: string | number, userInfo: any): Observable<any> {
    return this.http
      .put<any>(`${Config.apiRootUrl}/user/${idUser}`, userInfo, {
        headers: new HttpHeaders({ hideLoader: 'true' }),
      })
      .pipe();
  }

  /**
   * @description Applicative LOGOUT and clean the storage e navigate in login page
   */
  logout(): void {
    Storage.cleanAll();
    this.functionality = new UserFunctionality();
    this.userProfile = null;
    this.zone.run(() => {
      this.router.navigate(['/login']);
    });
  }

  logoutWithoutLogin(): void {
    Storage.cleanAll();
    this.functionality = new UserFunctionality();
    this.userProfile = null;
  }
}
