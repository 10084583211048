<mat-dialog-content>
  <h2 class="dialog-title" mat-dialog-title>
    {{ 'IR_TO_APPROVE.RejectIrMessageConfirmation' | translate }}
  </h2>
  <form [formGroup]="noteForm">
    <div formArrayName="notes" class="notes-container">
      <mat-card
        class="note-container"
        *ngFor="let note of notes.controls; let i = index"
        [formGroupName]="i"
      >
        <div class="tab-input-container">
          <mat-form-field>
            <mat-label>{{ 'IR_TO_APPROVE.Tab' | translate }}</mat-label>
            <mat-select disableOptionCentering panelClass="custom-panel-class" name="riTab" formControlName="riTab">
              <mat-option *ngFor="let riTab of riTabs" [value]="riTab">
                {{ 'PROJECT_NOTE.' + riTab | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="note.get('riTab').invalid">
              {{ note.get('riTab').errors | getErrorMessage:translations?.Tab }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="reason-input-container">
          <mat-form-field *ngIf="note.get('riTab').valid">
            <mat-label>{{ 'IR_TO_APPROVE.Reason' | translate }}</mat-label>
            <textarea matInput formControlName="description" name="description"></textarea>
            <mat-error *ngIf="note.get('description').invalid">  
              {{ note.get('description').errors | getErrorMessage:translations?.Reason }}
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card>        
    </div>
    <div class="add-note">
      <button
        mat-stroked-button
        color="primary"
        (click)="addNote()"
      >
        <mat-icon>add</mat-icon>
        {{'IR_TO_APPROVE.AddNote' | translate}}
      </button>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]>Annulla</button>
  <button
    mat-button
    [mat-dialog-close]="noteForm"
    [disabled]="noteForm.invalid || notes.length < 1"
  >Conferma</button>
</mat-dialog-actions>