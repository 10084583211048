import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { ProjectDTO } from 'src/app/models/project';
import { RI_STATUS_DESCRIPTION } from 'src/app/config';

@Component({
  selector: 'app-isp-assigned',
  templateUrl: './isp-assigned.component.html',
  styleUrls: ['./isp-assigned.component.css']
})
export class IspAssignedComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  ispAssigned: ProjectDTO[];
  translations: any;

  riStatus: RI_STATUS_DESCRIPTION;
  loader: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private irFormService: IrFormService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.route?.data?.subscribe(data => {
      this.riStatus = data?.riStatus;
      this.loader = true;
      this.getIspAssigned();
    }));
  }

  getIspAssigned(): void {
    this.subscriptions.add(this.irFormService.getProjectByStatusDescription(this.riStatus, true).subscribe((project: ProjectDTO[]) => {
      this.ispAssigned = project;
      this.loader = false;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
