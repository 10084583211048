import { Pipe, PipeTransform } from '@angular/core';
import { LoggedUser } from '../models/user';
import {
  AGENT_ROLE,
  LAB_MANAGER,
  LAB_MANAGER_SUPERVISOR,
  AGENT_SUPERVISOR,
  LAB_MANAGER_INTERNAL,
} from 'src/app/config';
import { Select } from '../models/utility';

@Pipe({
  name: 'supervisorfilter',
})
export class SuperVisorFilterPipe implements PipeTransform {
  /**
   * @description Return a list of possible supervisors for the user passed in param `row`
   * @param users La lista degli utenti
   * @param row l'utente al quale si vuole assegnare il supervisore
   * @TODO Aggiungere traduzione, bisogna controllare se c'è un modo di usare @ngx/translate nei pipe
   */
  transform(users: LoggedUser[], row: LoggedUser, translations: any): any {
    if (!users || !row) {
      return [];
    }
    const isAgent = row.roles.indexOf(AGENT_ROLE) > -1;
    const isManager = row.roles.indexOf(LAB_MANAGER) > -1;
    const isInternalManager = row.roles.indexOf(LAB_MANAGER_INTERNAL) > -1;
    const supervisorSelect = users.filter(
      (item) =>
        (item.idUser !== row.idUser &&
          item.enabled &&
          isAgent &&
          item.roles.indexOf(AGENT_SUPERVISOR) > -1) ||
        (item.idUser !== row.idUser &&
          item.enabled &&
          isManager &&
          item.roles.indexOf(LAB_MANAGER_SUPERVISOR) > -1) ||
        (item.idUser !== row.idUser &&
          item.enabled &&
          isInternalManager &&
          item.roles.indexOf(LAB_MANAGER) > -1)
    );
    const ret: Select[] = supervisorSelect.map((item) => ({
      value: item.idUser,
      label: `${item.name} ${item.surname} - ${item.email}`,
      description: `${item.name} ${item.surname}`,
    }));
    if (isManager || isInternalManager) {
      ret.push({
        value: row.idUser,
        label: `${row.name} ${row.surname} - ${row.email}`,
        description: `${row.name} ${row.surname}`,
      });
    }
    // if (row.parentUserId) {
    //   ret.unshift({
    //     value: null,
    //     label: translations.RemoveSupervisor.toUpperCase(),
    //   });
    // }
    return ret;
  }
}
