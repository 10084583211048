import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';

/**
 * @description set the error in control if this don't match with fieldMatch control
 * @param fieldMatch the controlName of "primary" control
 */
export function MustMatch(fieldMatch: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.parent) {
      const controlMatch: AbstractControl = control.parent.get(fieldMatch);
      if (controlMatch.value !== control.value) {
        return { mustMatch: true };
      }
    }
    return null;
  };
}

/**
 * @description JUST FOR DATE TYPE CONTROLS set the error in control if its date is after of fieldMatch control
 * @param fieldMatch the controlName of "primary" control
 */
export function MustBeBefore(fieldMatch: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.parent) {
      const controlMatch: AbstractControl = control.parent.get(fieldMatch);
      if (!controlMatch.value) {
        return null;
      }
      if (!controlMatch.errors || controlMatch.errors.isBefore) {
        if (
          new Date(controlMatch.value).getTime() >=
          new Date(control.value).getTime()
        ) {
          controlMatch.setErrors({ isBefore: true });
          return { isAfter: true };
        }
      }
      controlMatch.setErrors(null);
    }
    return null;
  };
}

/**
 * @description JUST FOR DATE TYPE CONTROLS set the error in control if its date is before of fieldMatch control
 * @param fieldMatch the controlName of "primary" control
 */
export function MustBeAfter(fieldMatch: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.parent) {
      const controlMatch: AbstractControl = control.parent.get(fieldMatch);
      if (!controlMatch.value) {
        return null;
      }
      if (!controlMatch.errors || controlMatch.errors.isAfter) {
        if (
          new Date(controlMatch.value).getTime() <=
          new Date(control.value).getTime()
        ) {
          controlMatch.setErrors({ isAfter: true });
          return { isBefore: true };
        }
      }
      controlMatch.setErrors(null);
    }
    return null;
  };
}

/**
 * @description disable dependent control if dependency is invalid or untouched(usaly used with MustMatch validators)
 * @param dependency name of control dependency
 * @param dependent name of control dependent
 */
export function DisableDependentControl(
  dependency: string,
  dependent: string
): any {
  return (formGroup: FormGroup): void => {
    const dependecyControl = formGroup.get(dependency);
    const dependentControl = formGroup.get(dependent);

    if (dependentControl.disabled && dependecyControl.valid) {
      dependentControl.enable();
    }
    if (dependentControl.enabled && dependecyControl.invalid) {
      dependentControl.disable();
    }
  };
}

/**
 * @description Check if in the formArray trere is two item with the same value in a soecific field
 * @param fieldName The filed name or path in the formGroup of array
 * @useNote Work Only on ArrayForm
 */
export function ItemFieldValueInArrayCannotBeSame(
  fieldName: string
): ValidatorFn {
  return (group: FormArray) => {
    let errors: any = null;
    const controls: FormGroup[] = group.controls as FormGroup[];
    if (controls) {
      const values: number[] = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controls.length; i++) {
        /** Added this condition below to avoid errors when fields are null or unsetted */
        if (controls[i].get(fieldName).value) {
          if (
            controls[i].get(fieldName) &&
            (!controls[i].get(fieldName).errors ||
              controls[i].get(fieldName).errors.isRepeted)
          ) {
            if (values.indexOf(controls[i].get(fieldName).value) >= 0) {
              controls[i].get(fieldName).setErrors({ isRepeted: true });
              errors = { repetedValue: true };
            } else {
              controls[i].get(fieldName).setErrors(null);
            }
            values.push(controls[i].get(fieldName).value);
          }
        }
      }
    }
    return errors;
  };
}

/**
 * @description Check if in the formArray trere is two item with the same value in a soecific field
 * @param fieldName The filed name or path in the formGroup of array
 * @useNote Work Only on ArrayForm
 */
export function ItemFieldValueInArrayCannotBeSameOnlyString(
  fieldName: string
): ValidatorFn {
  return (group: FormArray) => {
    let errors: any = null;
    const controls: FormGroup[] = group.controls as FormGroup[];
    if (controls) {
      const values: number[] = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controls.length; i++) {
        if (
          controls[i].get(fieldName) &&
          (!controls[i].get(fieldName).errors ||
            controls[i].get(fieldName).errors.isRepeted)
        ) {
          if (
            values.indexOf(controls[i].get(fieldName).value?.toLowerCase()) >= 0
          ) {
            controls[i].get(fieldName).setErrors({ isRepeted: true });
            errors = { repetedValue: true };
          } else {
            controls[i].get(fieldName).setErrors(null);
          }
          values.push(controls[i].get(fieldName).value?.toLowerCase());
        }
      }
    }
    return errors;
  };
}

export function ItemFieldValueCannotBeSameRelatedToOtherArrays(
  fieldName: string,
  otherArrays: FormArray[]
): ValidatorFn {
  return (group: FormArray) => {
    let errors: any = null;
    const controls: FormGroup[] = group.controls as FormGroup[];
    if (controls) {
      const values: number[] = [];
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controls.length; i++) {
        for (const otherArray of otherArrays) {
          for (const j in otherArray.controls) {
            if (
              controls[i].get(fieldName) &&
              (!controls[i].get(fieldName).errors ||
                controls[i].get(fieldName).errors.isRepetedFromOther)
            ) {
              if (
                otherArray.controls[j].get(fieldName).value ===
                controls[i].get(fieldName).value
              ) {
                controls[i]
                  .get(fieldName)
                  .setErrors({ isRepetedFromOther: true });
                errors = { repetedValueFromOther: true };
              } else {
                controls[i].get(fieldName).setErrors(null);
              }
            }
          }
        }
      }
    }
    return errors;
  };
}

/**
 * @description Check if in the formArray the field or path exceeds the max
 * @param fieldName The filed name or path in the formGroup of array
 * @param max the max of field
 */
export function MaxInArrayFields(fieldName: string, max: number): ValidatorFn {
  return (group: FormArray) => {
    let errors: any = null;
    const controls: FormGroup[] = group.controls as FormGroup[];
    if (controls) {
      let sum: number = 0;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < controls.length; i++) {
        if (
          controls[i].get(fieldName) &&
          (!controls[i].get(fieldName).errors ||
            controls[i].get(fieldName).errors.overMax)
        ) {
          sum += controls[i].get(fieldName).value;
          if (sum > max) {
            // tslint:disable-next-line: prefer-for-of
            for (let idx = 0; idx < controls.length; idx++) {
              if (!controls[i].get(fieldName).errors) {
                controls[idx]
                  .get(fieldName)
                  .setErrors({ overMax: { max, overMax: true } });
              }
            }
            errors = { overMax: true };
          } else {
            controls[i].get(fieldName).setErrors(null);
          }
        }
      }
    }
    return errors;
  };
}

export function MustBeRequired(condition: boolean): ValidatorFn {
  return (control: AbstractControl) => {
    if (condition) {
      if (!control.value) {
        return { requiredForExport: true };
      } else {
        return null;
      }
    }
    return null;
  };
}

export function ExportMustBeTrue(condition: boolean): ValidatorFn {
  return (control: AbstractControl) => {
    if (condition) {
      if (control.value !== true) {
        return { exportMustBeTrue: true };
      }
    } else {
      return null;
    }
    return null;
  };
}

/**
 * @description Workaround to check the validity of a disabled control. Disabled controls always have an invalid status, so the only way to check real validity is by temporarily enabling it.
 * @param control The disabled control to check the validity of.
 * @returns The validity of the control.
 */
export function isDisabledControlValid(control: AbstractControl): boolean {
  control.enable({ emitEvent: false });
  const isValid = control.valid;
  control.disable({ emitEvent: false });
  return isValid;
}
