import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { ProjectDTO } from 'src/app/models/project';
import { RI_STATUS } from 'src/app/config';

@Component({
  selector: 'app-ir-to-assign',
  templateUrl: './ir-to-assign.component.html',
  styleUrls: ['./ir-to-assign.component.scss']
})
export class IrToAssignComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();

  projects: ProjectDTO[];
  loader: boolean = false;

  constructor(
    private irFormService: IrFormService
  ) { }

  ngOnInit(): void {
    this.loader = true;
    this.getPrjectToAssign();
  }

  getPrjectToAssign(): void {
    this.subscriptions.add(
      this.irFormService.getAllIrToAssign(RI_STATUS.RiApproved, true).subscribe((projects: ProjectDTO[]) => {
        this.projects = projects;
        this.loader = false;
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
