export interface Credetials {
  username: string;
  password: string;
}

export interface LoginResponse {
  access_token: string;
  refresh_token: string;
  issue_date: Date | string;
  token_type: string;
  expires_in: number;
}

export interface IRoles {
  id: number | string;
  role: string;
}

export interface LoggedUser {
  idUser?: number | string;
  creationTime?: Date | null | string;
  version?: string | null;
  username?: string | null;
  password?: string | null;
  name?: string | null;
  surname?: string | null;
  email?: string | null;
  roles?: string[] | null;
  activeRole?: string;
  passwordTime?: Date | null | string;
  expired?: boolean | null;
  enabled?: boolean | null;
  updateTime?: Date | null | string;
  parentUser?: string[] | null;
  parentUserId?: number | null;
  telephone?: string | null;
  address?: string | null;
  cap?: string | null;
  city?: string | null;
  competenceArea?: IProvince[];
  token?: string;
  iss?: string;
  status?: boolean | string;
  province?: string;
  idCustomer?: number | string;
  image?: any;
  demonstrator?: boolean;
}

export interface SignUpRequest extends LoggedUser {
  jwt: string;
}

export class PermissionsFunc {
  enabled: boolean = false;
  create?: boolean = false;
  edit?: boolean = false;
  assign?: boolean = false;
  confirm?: boolean = false;
  delivered?: boolean = false;
  approve?: boolean = false;
  reject?: boolean = false;
  constructor() {}
}

export class UserFunctionality {
  appointment?: PermissionsFunc = new PermissionsFunc();
  infoReport?: PermissionsFunc = new PermissionsFunc();
  isp?: PermissionsFunc = new PermissionsFunc();
  administrator?: PermissionsFunc = new PermissionsFunc();
  customer?: PermissionsFunc = new PermissionsFunc();
  crm?: PermissionsFunc = new PermissionsFunc();
  bm?: PermissionsFunc = new PermissionsFunc();
  bmCustom?: PermissionsFunc = new PermissionsFunc();
  constructor() {}
}

export interface IUser {
  idUser?: number;
  name?: string;
  surname?: string;
  email?: string;
  telephone?: string;
}

export interface IProvince {
  idProvince?: number;
  acronym?: string;
  name?: string;
  regione?: string;
}
