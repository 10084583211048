import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Roles } from 'src/app/config';
import { IBMOChecker, NavBarProps } from 'src/app/models/utility';
import { CrmService } from 'src/app/services/crm/crm.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  expandSidebar: boolean = false;
  showNewBusinessOpportunitiesBadge: boolean = false;
  isDLManager: boolean = false;

  @Input() links: NavBarProps;
  @Input() name: string;
  @Input() surname: string;

  @Output() logout: EventEmitter<any> = new EventEmitter();
  @Output() reloadPage: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, private crmService: CrmService) {}

  ngOnInit(): void {
    if (
      JSON.parse(localStorage.getItem('session_user')).activeRole ===
      Roles.LabManager
    ) {
      this.isDLManager = true;
      this.checkNewBM();
    }
    this.subscriptions.add(
      this.router.events.subscribe({
        next: (data: any) => {
          if (data instanceof NavigationEnd) {
            this.expandSidebar = false;
            // Check new BM if DLManager
            if (this.isDLManager) {
              this.checkNewBM();
            }
          }
        },
      })
    );
  }

  checkNewBM(): void {
    this.crmService.checkNewBusinessOpportunities(true).subscribe({
      next: (response: IBMOChecker) => {
        if (response.global) {
          this.showNewBusinessOpportunitiesBadge = true;
        }
        this.reloadPage.emit();
      },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
