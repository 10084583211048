import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { RI_STATUS_DESCRIPTION } from 'src/app/config';
import { ProjectDTO } from 'src/app/models/project';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';

@Component({
  selector: 'app-ir-sent',
  templateUrl: './ir-sent.component.html',
  styleUrls: ['./ir-sent.component.scss']
})
export class IrSentComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  projects: ProjectDTO[];

  riStatus: RI_STATUS_DESCRIPTION;
  loader: boolean = false;
  idUser: any;

  constructor(
    private irFormService: IrFormService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.idUser = this.authService.userProfile?.idUser;
    this.subscriptions.add(this.route?.data?.subscribe(data => {
      this.riStatus = data?.riStatus;
      this.loader = true;
      this.getAllProject();
    }));
  }

  getAllProject(): void {
    this.subscriptions.add(
      this.irFormService.getProjectByStatusDescription(this.riStatus, true).subscribe((projects: ProjectDTO[]) => {
        this.projects = projects;
        this.loader = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
