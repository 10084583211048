import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { JwtHelperService, JwtInterceptor } from '@auth0/angular-jwt';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { apiRootUrl } from 'src/app/config';
import { mergeMap } from 'rxjs/operators';
import { LoginResponse } from 'src/app/models/user';
import { BackEndResponse, ToastStatus } from 'src/app/models/utility';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(
    private jwtInterceptor: JwtInterceptor,
    private jwtService: JwtHelperService,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.jwtInterceptor.isDisallowedRoute(request) || !this.jwtService.isTokenExpired()) {
      return next.handle(request);
    }

    if (request.url.includes(apiRootUrl) && this.jwtService.isTokenExpired()) {
      return this.refreshToken().pipe(
        mergeMap(() => {
          return this.jwtInterceptor.intercept(request, next);
      }));
    }
    return next.handle(request);
  }

  refreshToken(): Observable<LoginResponse> {
    const refreshSubject = new ReplaySubject<LoginResponse>(1);
    refreshSubject.subscribe((r: LoginResponse) => {
      this.authService.setTokenInSession(r);
    }, (err: BackEndResponse): HttpResponse<any> => {
      const error = new HttpErrorResponse({
        status: 401,
        error: {
          error: 'Unauthorized',
        }
      });
      throw error;
    });
    this.authService.refreshToken().subscribe(refreshSubject);
    return refreshSubject;
  }
}

export const HttpRefreshInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: RefreshTokenInterceptor,
  multi: true,
};
