import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { IChannelsType } from 'src/app/models/channels-type';
import { IBuyerStatus, IBuyerStatusReason, IContact, IContactBuyer, IDealState, IDealStateReason } from 'src/app/models/contact';
import { ICountries } from 'src/app/models/countries';
import { IReferentRole } from 'src/app/models/referent-role';
import { ToastStatus } from 'src/app/models/utility';
import { CommonService } from 'src/app/services/common/common.service';
import {NgxCurrencyModule} from 'ngx-currency';
import {CHANNELS_TYPE, currencyDefaultConfig} from '../../config';
import { CrmService } from 'src/app/services/crm/crm.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactFormComponent implements OnInit, OnDestroy, OnChanges {

  private subscriptions: Subscription = new Subscription();

  contactByBusinessName = new BehaviorSubject({});

  @Input() isReadonly: boolean = false;
  @Input() idProject: number;
  @Input() contact: IContact;
  @Input() translations: any;
  @Input() channels: IChannelsType[];
  @Input() countries: ICountries[];
  @Input() referentRoles: IReferentRole[];
  @Input() buyerStatus: IBuyerStatus[];
  @Input() buyerStatusReason: IBuyerStatusReason[];

  newContact: boolean;
  editContact: boolean;
  contactForm: FormGroup;
  mainReferent: number[] = [];
  atLeastOneMain: boolean = false;
  removeBuyerLoader: boolean = false;
  saveContactLoader: boolean = false;
  deletingBuyer = false;
  dataForSelectReady: boolean = false;

  @Output() clearContactEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadContactsTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadActivitiesTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadCompetitorsTabTable: EventEmitter<any> = new EventEmitter<any>();

  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;

  /** filtered variables */
  filteredCountries: ICountries[];

  /** control for the MatSelect filter keyword */
  public entityFilterCtrl: FormControl = new FormControl();

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    public changeDetectorRefs: ChangeDetectorRef,
    private crmService: CrmService,
    private irFormService: IrFormService
  ) {
    this.contactForm = this.fb.group({
      idCustomer: [],
      idContact: [],
      idUser: [],
      businessName: [null, [Validators.required, Validators.maxLength(45)]],
      channelType: this.fb.group({
        idChannelType: [null, Validators.required]
      }),
      channelTypeDesc: [null, Validators.maxLength(255)],
      channelTypeSpec: [null, [Validators.required, Validators.maxLength(255)]],
      address: [null, Validators.maxLength(255)],
      telephoneNumber: [null, Validators.maxLength(20)],
      email: [null, [Validators.required, Validators.maxLength(45)]],
      webSiteLink: [null, Validators.maxLength(255)],
      note: [],
      country: this.fb.group({
        idCountry: [null, Validators.required]
      }),
      buyerStatus: this.fb.group({
        idBuyerStatus: [null, Validators.required]
      }),
      buyerStatusReason: this.fb.group({
        idBuyerStatusReason: [null, Validators.required]
      }),
      statusDetails: [null, Validators.maxLength(1024)],
      isVip: [false],
      province: [null, [Validators.maxLength(45)]],
      // ↓ TODO: inserire un regex che permetta i punti per le migliaia ↓
      revenue: [null, /*Validators.pattern('^(?!$)\d{0,10}(?:\.\d{1,2})?$')*/],
      contactReference: this.fb.array([])
    });
  }

  get contactBuyers(): FormArray {
    return this.contactForm.get('contactReference') as FormArray;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.irFormService.getAllCountries(true).subscribe((countries: ICountries[]) => {
        this.countries = countries;
        this.irFormService.getChannelsType(true).subscribe((channels: IChannelsType[]) => {
          this.channels = channels;
          this.crmService.getAllBuyerStatus(true).subscribe((buyerStatus: IBuyerStatus[]) => {
            this.buyerStatus = buyerStatus.map((status: IBuyerStatus): any => ({
              idBuyerStatus: status.idBuyerStatus,
              description: `${CHANNELS_TYPE[status.name]}`
            }));
            this.crmService.getAllBuyerStatusReason(true).subscribe((buyerStatusReason: IBuyerStatusReason[]) => {
              this.buyerStatusReason = buyerStatusReason;
              this.irFormService.getReferentRoles(true).subscribe((roles: IReferentRole[]) => {
                this.referentRoles = roles;
                this.dataForSelectReady = true;
                this.changeDetectorRefs.detectChanges();
              });
            });
          });
        });
        /** Init variables and check on change */
        this.filteredCountries = this.countries;
        this.entityFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterEntities();
        });
      })
    );
    if (this.contact) {
      this.contactForm.patchValue(this.contact);
      for (const contactBuyer of this.contact.contactReference) {
        this.addContactBuyer(contactBuyer);
      }
      this.contactForm.disable();
    } else {
      this.newContact = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.contact?.currentValue) {
      this.contactForm.patchValue(changes?.contact?.currentValue);
    }
  }

  addContactBuyer(contactReference?: IContactBuyer): void {
    if (!contactReference) {
      this.contactBuyers.push(this.fb.group({
        idContact: [this.contact?.idContact ? this.contact.idContact : null],
        role: this.fb.group({
          idCompanyRoleType: [null, Validators.required]
        }),
        name: [null, [Validators.required, Validators.maxLength(45)]],
        surname: [null, [Validators.required, Validators.maxLength(45)]],
        officeTel: [null, Validators.maxLength(20)],
        personalTel: [null, Validators.maxLength(20)],
        email: [null, Validators.maxLength(45)],
        title: [null, Validators.maxLength(10)],
        main: [false]
      }));
    } else if (contactReference) {
      this.contactBuyers.push(this.fb.group({
        idContact: [contactReference?.idContact || this.contact.idContact],
        idContactReference: [contactReference?.idContactReference || null],
        role: this.fb.group({
          idCompanyRoleType: [contactReference?.role?.idCompanyRoleType || null]
        }),
        name: [contactReference?.name || null, [Validators.required, Validators.maxLength(45)]],
        surname: [contactReference?.surname || null, [Validators.required, Validators.maxLength(45)]],
        officeTel: [contactReference?.officeTel || null, Validators.maxLength(20)],
        personalTel: [contactReference?.personalTel || null, Validators.maxLength(20)],
        email: [contactReference?.email || null, Validators.maxLength(45)],
        title: [contactReference?.title || null, Validators.maxLength(10)],
        main: [contactReference?.main || null]
      }));
    }
  }

  enableEditContact(): void {
    this.editContact = true;
    this.contactForm. enable();
  }

  removeContactBuyer(buyer: FormGroup, index: number): void {
    if (buyer.get('idContactReference')) {
      this.removeBuyerLoader = true;
      this.contactBuyers.controls[index].disable();
      this.subscriptions.add(
        this.crmService.deleteContactBuyerBydId(buyer.get('idContactReference').value, true).subscribe({
          complete: () => {
            this.contactBuyers.removeAt(index);
            this.removeBuyerLoader = false;
            this.changeDetectorRefs.detectChanges();
          },
          error: () => {
            this.removeBuyerLoader = false;
            this.contactBuyers.controls[index].enable();
            this.changeDetectorRefs.detectChanges();
          }
        })
      );
    } else {
      this.contactBuyers.removeAt(index);
    }
  }

  checkMainBuyer(event: MatSlideToggleChange, index: number): void {
    this.contactForm.markAsDirty();
    if (event.checked === true) {
      this.mainReferent.push(index);
      this.contactBuyers.controls[index].get('main').setValue(true);
    } else if (event.checked === false) {
      this.mainReferent.splice(this.mainReferent.indexOf(index), 1);
      this.contactBuyers.controls[index].get('main').setValue(false);
    }
  }

  checkCorrespondance(index: number): boolean {
    let ret: boolean = false;
    this.mainReferent.forEach((ind: number) => {
      if (ind === index) {
        ret = true;
      }
    });
    return ret;
  }

  handleChannelTypeSelect(contactForm: FormGroup, channelTypeOption: MatOption): void {
    if (channelTypeOption.value === 1) {
      contactForm.get('channelTypeDesc').setValue('');
      contactForm.get('channelTypeSpec').setValue('');
      contactForm.get('channelTypeDesc').setValidators([Validators.required]);
      contactForm.get('channelTypeDesc').updateValueAndValidity();
      contactForm.get('channelTypeSpec').updateValueAndValidity();
    } else {
      contactForm.get('channelTypeDesc').setValue(channelTypeOption.viewValue);
      contactForm.get('channelTypeSpec').setValue(channelTypeOption.viewValue);
    }
  }

  handleChannelDescription(contactForm: FormGroup, channelDescription: MatInput): void {
    contactForm.get('channelTypeSpec').setValue(channelDescription.value);
    contactForm.get('channelTypeSpec').updateValueAndValidity();
  }

  onSubmit(): void {
    this.atLeastOneMain = false;
    for (const buyer of this.contactBuyers.getRawValue()) {
      if (buyer.main === true) {
        this.atLeastOneMain = true;
        break;
      }
    }
    if (this.contactForm.valid && this.contactForm.dirty && this.atLeastOneMain && this.contactBuyers.length) {
      this.saveContactLoader = true;
      this.contactForm.disable();
      this.subscriptions.add(
        this.crmService.createUpdateContact(this.idProject, this.contactForm.getRawValue(), true).subscribe({
          next: (contact: IContact) => {
            this.contact = contact;
          },
          complete: () => {
            this.saveContactLoader = false;
            this.contactForm.disable();
            this.editContact = false;
            this.crmService.getContactDetails(this.contact.idContact, true).subscribe((contactDetails: IContact) => {
              this.contactForm.patchValue(contactDetails);
              this.contactBuyers.clear();
              for (const contactReferent of contactDetails.contactReference) {
                this.addContactBuyer(contactReferent);
              }
              this.contactForm.disable();
            });
            this.contactForm.markAsPristine();
            this.contactForm.markAsUntouched();
            this.clearContact();
            this.reloadContactsTable.emit();
            this.reloadActivitiesTable.emit();
            this.reloadCompetitorsTabTable.emit();
          },
          error: () => {
            this.saveContactLoader = false;
            this.contactForm.enable();
            this.common.showToast(
              this.translations.ShowGeneralErrorToast,
              ToastStatus.error,
              3000
            );
          }
        })
      );
    }
    if (this.contactForm.valid && this.contactForm.dirty && this.contactBuyers.length && !this.atLeastOneMain) {
      this.common.showToast(
        this.translations.SetABuyerAsMain,
        ToastStatus.warning,
        3000
      );
    }
    if (this.contactForm.valid && this.contactForm.dirty && !this.contactBuyers.length) {
      this.common.showToast(
        this.translations.AddAtLeastOneBuyer,
        ToastStatus.error,
        3000
      );
    }
    if (this.contactForm.valid && this.atLeastOneMain) {
      this.saveContactLoader = false;
      this.contactForm.disable();
      this.editContact = false;
    }
  }

  clearContact(): void {
    this.clearContactEmitter.emit();
  }

  getContactByBusinessName(event): void {
    if (event.target.value) {
      this.subscriptions.add(
        this.crmService.getContactByBusinessName(event.target.value, true).subscribe((contact: any) => {
          this.contactByBusinessName.next({...contact, idContact: contact.idUser, tel: contact.telephone, businessName:event.target.value});
          this.changeDetectorRefs.detectChanges();
        })
      );
    }
  }

  setContactByBusinessName(event): void {
    this.contactByBusinessName = new BehaviorSubject({});
    this.changeDetectorRefs.detectChanges();
  }

  /** filter the entities */
  private filterEntities() {
    if (!this.countries) {
      return;
    }
    // get the search keyword
    let search = this.entityFilterCtrl.value;
    if (!search) {
      this.filteredCountries = this.countries;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the data
    this.filteredCountries = this.countries
      .filter(entity => entity.country.toLowerCase().indexOf(search) > -1)
      .sort((a, b) => a.country.toLowerCase().indexOf(search) - b.country.toLowerCase().indexOf(search));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    /** destroy entity variables */
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
