import { Component, OnInit, Input, AfterViewChecked, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as CanvasJS from 'src/assets/lib/canvasjs.min';
import { Chart, Dataset } from 'src/app/models/chart';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {

  @Input() chartContainer: string = 'chartContainer';
  @Input() chartData: Dataset[];
  @Input() option: Chart = {};
  DefaultOption: Chart = {
    animationEnabled: true,
    axisX: {
      titleFontSize: 16
    },
    axisY: {
      titleFontSize: 16
    },
  };

  chart: any;

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentValue } = changes.chartData;
    if (currentValue && document.getElementById(this.chartContainer)) {
      this.renderGraph();
    }
  }

  ngAfterViewChecked(): void {
    if (this.chartData && !this.chart && document.getElementById(this.chartContainer)) {
      this.renderGraph();
    }
  }

  private renderGraph(): void {
    const chartSet: Chart = {
      ...this.DefaultOption,
      ...this.option,
      data: this.getChartData()
    };
    this.chart = new CanvasJS.Chart(this.chartContainer, chartSet);
    this.chart.render();
  }

  private getChartData(): Dataset[] {
    return this.chartData.map((dataset: Dataset) => ({
      type: this.option.type,
      showInLegend: this.option.showInLegend,
      toolTipContent: this.option.showToolTip ? '<b>{name}</b><br/>N.ro Paesi Export: {x}<br/> Perc. Fatturato Ex: {y}<br/> Quota Export: {z}%' : null,
      name: dataset.name,
      dataPoints: dataset.dataPoints
    }));
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

}
