import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Config from 'src/app/config';
import { Observable } from 'rxjs';
import { ServiceExtension } from 'src/app/services/serviceExtension';
import { IMeeting } from 'src/app/models/meeting';

@Injectable({
  providedIn: 'root'
})
export class MeetingsService extends ServiceExtension {

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * @description CALL GET `/appointment/all` to get all appointment
   */
  getMeetings(): Observable<IMeeting[]> {
    return this.http.get<IMeeting[]>(`${Config.apiRootUrl}/appointment/all`).pipe();
  }

  /**
   * @description CALL GET `appointment/user/:idUser` to get appointments of a user
   * @param idUser The user who get appointment
   */
  getMeetingsByUser(idUser: number | string, hideLoader: boolean = false): Observable<IMeeting[]> {
    return this.http.get<IMeeting[]>(
      `${Config.apiRootUrl}/appointment/user/${idUser}`,
      { headers: this._getHeaders(hideLoader) }
    ).pipe();
  }
}
