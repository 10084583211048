<div
  *ngIf="!project?.matrixMarketAnalysis?.countries?.length"
  class="section-card"
>
  <div class="header">
    <div class="title">
      <span>{{ "IR_FORM.StrategyNotAvailable" | translate }}</span>
    </div>
  </div>
</div>
<form
  *ngIf="project?.matrixMarketAnalysis?.countries?.length"
  [formGroup]="strategyForm"
  (ngSubmit)="saveStrategy()"
>
  <div class="section-card mb-30 pt-20">
    <div class="header">
      <div class="title">
        <span>{{ "IR_FORM.Targets" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div formGroupName="projectObjective" class="row">
        <mat-form-field
          class="col-3"
          [ngClass]="
            !strategyForm.get('projectObjective.dateEnd')?.value
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="dateEnd">{{
            "IR_FORM.StrategyDateEnd" | translate
          }}</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dateEnd" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error
            *ngIf="!strategyForm.get('projectObjective.dateEnd').valid"
          >
            {{
              strategyForm.get("projectObjective").controls.dateEnd.errors
                | getErrorMessage: translations.StrategyDateEnd
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="col-3"
          [ngClass]="
            !strategyForm.get('projectObjective.revenue')?.value
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="revenue">
            {{ "IR_FORM.StrategyRevenue" | translate }}
          </mat-label>
          <input
            currencyMask
            matInput
            autocomplete="off"
            formControlName="revenue"
            [options]="currencyDefaultConfig"
            name="revenue"
          />
          <mat-error
            *ngIf="!strategyForm.get('projectObjective.revenue').valid"
          >
            {{
              strategyForm.get("projectObjective").controls.revenue.errors
                | getErrorMessage: translations.StrategyRevenue
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div>
    <div class="section-card mt-20" style="height: 100%">
      <div class="header">
        <div class="title">
          <span>{{ "IR_FORM.Strategy" | translate }}</span>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-6">
            <div>
              <span>{{ "IR_FORM.Product" | translate }}</span>
            </div>
            <div class="row">
              <mat-form-field
                class="col-12 mt-10"
                formGroupName="projectObjective"
              >
                <mat-label for="productName">
                  {{ "IR_FORM.Product" | translate }}
                </mat-label>
                <input
                  matInput
                  formControlName="productName"
                  [readonly]="true"
                  name="productName"
                />
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="col-6">
            <div>
              <span>{{ "IR_FORM.Markets" | translate }}</span>
            </div>
            <div class="row">
              <mat-form-field class="col-12 mt-10">
                <mat-label for="idsMarketsSelected">
                  {{ "IR_FORM.Markets" | translate }}
                </mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  #marketStrategySelect
                  formControlName="idsMarketsSelected"
                  name="idsMarketsSelected"
                  multiple
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="entityFilterCtrl"
                      [placeholderLabel]="'Cerca...'"
                      [noEntriesFoundLabel]="'Nessun risultato'"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    #marketStrategyOption
                    *ngFor="let country of filteredCountries"
                    [value]="country.idCountry"
                    (click)="handleMarketStrategy(marketStrategyOption)"
                  >
                    {{ country.country }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div> -->
        </div>
        <div class="mt-10">
          <span>{{ "IR_FORM.Markets" | translate }}</span>
        </div>
        <div class="markets-container">
          <div class="custom-progress-bars">
            <div
              class="custom-progress-bar-container"
              *ngFor="let average of totalWeightedAverage; let i = index"
            >
              <div
                class="custom-progress-bar"
                [style.height.%]="average.weightedAverage * 10"
              ></div>
            </div>
          </div>
          <div class="countries-data" formArrayName="marketProjectObjective">
            <div
              *ngFor="
                let country of marketProjectObjective.controls;
                let i = index
              "
              [formGroupName]="i"
            >
              <div class="country-name">
                {{ country.controls.country.value }}
              </div>
              <div class="market-type">
                <div class="type-label">
                  {{ "IR_FORM.MarketTypology" | translate }}:
                </div>
                <div *ngIf="!country.controls.idMarketType.value">--</div>
                <div
                  *ngIf="
                    country.controls.idMarketType.value === 1 &&
                    !country.controls.toIncrease.value
                  "
                >
                  {{ "IR_FORM.OnlyOfPresence" | translate }}
                </div>

                <div
                  *ngIf="
                    country.controls.idMarketType.value === 1 &&
                    country.controls.toIncrease.value
                  "
                >
                  {{ "IR_FORM.OfPresenceToIncrease" | translate }}
                </div>

                <div *ngIf="country.controls.idMarketType.value === 3">
                  {{ "IR_FORM.OfActualHighPotential" | translate }}
                </div>
                <div *ngIf="country.controls.idMarketType.value === 4">
                  {{ "IR_FORM.OfFutureHighPotential" | translate }}
                </div>
              </div>
              <div class="divider"></div>
              <div
                class="priority-label"
                [ngClass]="country?.controls?.priority?.errors ? 'red' : ''"
              >
                {{ "IR_FORM.Priority" | translate }}
              </div>
              <mat-form-field class="priority-select">
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  name="countryMarketsPriority"
                  formControlName="priority"
                  (selectionChange)="checkPriorityDuplicates('markets')"
                >
                  <mat-option
                    *ngFor="
                      let item of marketProjectObjective.controls;
                      let i = index
                    "
                    [value]="i + 1"
                  >
                    {{ i + 1 }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!country.controls.priority.valid">
                  {{ "IR_FORM.DuplicatedPriority" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="mt-30">
          <span>{{ "IR_FORM.DistributionChannels2" | translate }}</span>
        </div>
        <div formArrayName="channelProjectObjective" class="mt-20">
          <div
            *ngFor="
              let channelItem of channelProjectObjective.controls;
              let i = index
            "
            [formGroupName]="i"
            class="d-flex align-items-center mb-20"
          >
            <mat-checkbox
              color="primary"
              class="select-channel"
              formControlName="selectedForStrategy"
              (change)="toggleChannelPriority($event, channelItem)"
            ></mat-checkbox>
            <div class="d-flex w100">
              <mat-form-field
                class="col-3"
                [ngClass]="
                  !channelItem.get('idChannelType')?.value
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="idChannelType">
                  {{ "IR_FORM.Distribution" | translate }}
                </mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="idChannelType"
                  name="idChannelType"
                  (selectionChange)="activateSpecificationsSelect($event, i)"
                >
                  <mat-option
                    #channelTypeOption
                    *ngFor="let channelType of channelsType"
                    [value]="channelType.idChannelType"
                  >
                    {{ channelType.description }}
                  </mat-option>
                </mat-select>
                <button
                  type="button"
                  class="only-icon-button primary"
                  matSuffix
                  [disabled]="
                    isReadOnly || channelItem.controls.idChannelType.disabled
                  "
                  (click)="showChannelsManagementDialog($event, 'channel')"
                >
                  <svg-icon name="list_icon_2" class="w20 h20"></svg-icon>
                </button>
                <mat-error *ngIf="!channelItem.controls.idChannelType.valid">{{
                  channelItem.controls.idChannelType.errors
                    | getErrorMessage: translations.Distribution
                }}</mat-error>
              </mat-form-field>
              <mat-form-field
                class="col-5"
                [ngClass]="
                  !channelItem.get('idSpecificationChannelType')?.value
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="idSpecificationChannelType">
                  {{ "IR_FORM.ChannelSpec" | translate }}
                </mat-label>
                <!-- <input
                matInput
                formControlName="channelSpecification"
                type="text"
                name="channelSpecification"
              /> -->
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="idSpecificationChannelType"
                  name="idSpecificationChannelType"
                >
                  <mat-option
                    *ngFor="
                      let spec of channelsSpecifications
                        | filterSelectByCondition
                          : channelItem.get('idChannelType').value
                          : 'idChannelType'
                    "
                    [value]="spec.idSpecificationChannelType"
                  >
                    {{ spec.description }}
                  </mat-option>
                </mat-select>
                <div class="d-flex" matSuffix>
                  <button
                    type="button"
                    class="only-icon-button primary"
                    [disabled]="
                      isReadOnly ||
                      !channelItem.get('idChannelType').value ||
                      channelItem.controls.idChannelType.disabled
                    "
                    (click)="
                      showChannelsManagementDialog(
                        $event,
                        'channelSpecification',
                        channelItem.get('idChannelType').value
                      )
                    "
                  >
                    <svg-icon name="list_icon_2" class="w20 h20"></svg-icon>
                  </button>
                  <button
                    type="button"
                    [matMenuTriggerFor]="channelSpecDescription"
                    class="only-icon-button primary ml-10"
                    (click)="$event.stopPropagation()"
                  >
                    <mat-icon>info</mat-icon>
                  </button>
                </div>
                <mat-menu #channelSpecDescription="matMenu">
                  <div style="padding: 0 10px">
                    {{
                      "IR_FORM.ChannelSpecDescription" | translate
                    }}
                  </div>
                </mat-menu>
                <mat-error
                  *ngIf="!channelItem.controls.idSpecificationChannelType.valid"
                  >{{
                    channelItem.controls.idSpecificationChannelType.errors
                      | getErrorMessage: translations.ChannelSpec
                  }}</mat-error
                >
              </mat-form-field>
              <mat-form-field 
                class="col-2"
                [ngClass]="
                  !channelItem.get('levelOfInterest')?.value
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="levelOfInterest">
                  {{ "IR_FORM.ImportanceLevel" | translate }}
                </mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="levelOfInterest"
                  name="levelOfInterest"
                >
                  <mat-option
                    *ngFor="let item of [].constructor(10); let i = index"
                    [value]="i + 1"
                  >
                    {{ i + 1 }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="!channelItem.controls?.levelOfInterest.valid"
                >{{
                  channelItem.controls.levelOfInterest?.errors
                    | getErrorMessage: translations.ImportanceLevel
                }}</mat-error>
              </mat-form-field>
              <mat-form-field
                *ngIf="channelItem.controls.selectedForStrategy.value"
                class="col-2"
                [ngClass]="
                  !channelItem.get('priority')?.value
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="channelsPriority">
                  {{ "IR_FORM.Priority" | translate }}
                </mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  name="channelsPriority"
                  formControlName="priority"
                  (selectionChange)="checkPriorityDuplicates('channels')"
                >
                  <mat-option
                    *ngFor="
                      let item of channelProjectObjective.controls;
                      let i = index
                    "
                    [value]="i + 1"
                  >
                    {{ i + 1 }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!channelItem.controls.priority.valid">
                  <span *ngIf="channelItem.controls.priority?.errors?.required">
                    {{
                      channelItem.controls.priority.errors
                        | getErrorMessage: translations.Priority
                    }}
                  </span>
                  <span
                    *ngIf="channelItem.controls.priority?.errors?.incorrect"
                  >
                    {{ "IR_FORM.DuplicatedPriority" | translate }}
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="remove-channel-container">
              <button
                *ngIf="!isReadOnly && channelItem.get('isCustom').value"
                type="button"
                class="only-icon-button error"
                (click)="removeCustomChannelDistributionStrategy(i)"
              >
                <svg-icon name="close_icon" class="w16 h16"></svg-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button
            *ngIf="!isReadOnly"
            type="button"
            class="rounded-button primary-bg-button"
            (click)="addChannelDistributionStrategy(null, true)"
          >
            {{ "IR_FORM.AddChannel" | translate }}
          </button>
        </div>
        <!-- <div class="mt-30">
          <span>{{ "IR_FORM.LeadGeneration" | translate }}</span>
        </div>
        <div class="row mt-20">
          <mat-form-field class="col-12">
            <mat-label for="leadGeneration">
              {{ "IR_FORM.LeadGeneration" | translate }}
            </mat-label>
            <input
              matInput
              type="text"
              name="leadGeneration"
              formControlName="leadGeneration"
            />
          </mat-form-field>
        </div> -->
      </div>
    </div>
  </div>
  <div class="section-card pt-20 mt-30">
    <div class="header">
      <div class="title">
        <span>{{ "IR_FORM.ActionPlan" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="action-plan-items-container">
        <div class="action-plan-item">
          <div class="title">
            <span>{{ "IR_FORM.Product" | translate }}</span>
          </div>
          <div class="icons">
            <svg-icon
              [name]="
                productPlan.get('actionLm').value
                  ? 'manager_true'
                  : 'manager_false'
              "
            ></svg-icon>
            <svg-icon
              [name]="
                productPlan.get('actionCustomer').value
                  ? 'client_true'
                  : 'client_false'
              "
            ></svg-icon>
          </div>
        </div>
        <div class="action-plan-item">
          <div class="title">
            <span>{{ "IR_FORM.distribution" | translate }}</span>
          </div>
          <div class="icons">
            <svg-icon
              [name]="
                distributionPlan.get('actionLm').value
                  ? 'manager_true'
                  : 'manager_false'
              "
            ></svg-icon>
            <svg-icon
              [name]="
                distributionPlan.get('actionCustomer').value
                  ? 'client_true'
                  : 'client_false'
              "
            ></svg-icon>
          </div>
        </div>
        <div class="action-plan-item">
          <div class="title">
            <span>{{ "IR_FORM.PriceSelling" | translate }}</span>
          </div>
          <div class="icons">
            <svg-icon
              [name]="
                priceSellingPlan.get('actionLm').value
                  ? 'manager_true'
                  : 'manager_false'
              "
            ></svg-icon>
            <svg-icon
              [name]="
                priceSellingPlan.get('actionCustomer').value
                  ? 'client_true'
                  : 'client_false'
              "
            ></svg-icon>
          </div>
        </div>
        <div class="action-plan-item">
          <div class="title">
            <span>{{ "IR_FORM.LeadGeneration" | translate }}</span>
          </div>
          <div class="icons">
            <svg-icon
              [name]="
                communicationMktgLgPlan.get('actionLm').value
                  ? 'manager_true'
                  : 'manager_false'
              "
            ></svg-icon>
            <svg-icon
              [name]="
                communicationMktgLgPlan.get('actionCustomer').value
                  ? 'client_true'
                  : 'client_false'
              "
            ></svg-icon>
          </div>
        </div>
        <div class="action-plan-item">
          <div class="title">
            <span>{{ "IR_FORM.OtherAction" | translate }}</span>
          </div>
          <div class="icons">
            <svg-icon
              [name]="
                otherActionPlan.get('actionLm').value
                  ? 'manager_true'
                  : 'manager_false'
              "
            ></svg-icon>
            <svg-icon
              [name]="
                otherActionPlan.get('actionCustomer').value
                  ? 'client_true'
                  : 'client_false'
              "
            ></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-50">
    <div class="col-6">
      <div class="section-card pt-20">
        <div class="header">
          <div class="title">
            <span>{{ "IR_FORM.Product" | translate }}</span>
          </div>
        </div>
        <div class="content" [formGroup]="productPlan">
          <mat-form-field
            class="col-12"
          >
            <mat-label for="actionLm">
              {{ "IR_FORM.ExportSpecialist" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="actionLm"
              name="actionLm"
            ></textarea>
          </mat-form-field>
          <mat-form-field
            class="col-12"
          >
            <mat-label for="actionCustomer">{{
              "IR_FORM.CompanyClient" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="actionCustomer"
              name="actionCustomer"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="section-card pt-20 mt-20">
        <div class="header">
          <div class="title">
            <span>{{ "IR_FORM.PriceSelling" | translate }}</span>
          </div>
        </div>
        <div class="content" [formGroup]="priceSellingPlan">
          <mat-form-field
            class="col-12"
          >
            <mat-label for="actionLm">
              {{ "IR_FORM.ExportSpecialist" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="actionLm"
              name="actionLm"
            ></textarea>
          </mat-form-field>
          <mat-form-field
            class="col-12"
          >
            <mat-label for="actionCustomer">{{
              "IR_FORM.CompanyClient" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="actionCustomer"
              name="actionCustomer"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="section-card pt-20 mt-20">
        <div class="header">
          <div class="title">
            <span>{{ "IR_FORM.OtherAction" | translate }}</span>
          </div>
        </div>
        <div class="content" [formGroup]="otherActionPlan">
          <mat-form-field
            class="col-12"
          >
            <mat-label for="actionLm">
              {{ "IR_FORM.ExportSpecialist" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="actionLm"
              name="actionLm"
            ></textarea>
          </mat-form-field>
          <mat-form-field
            class="col-12"
          >
            <mat-label for="actionCustomer">{{
              "IR_FORM.CompanyClient" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="actionCustomer"
              name="actionCustomer"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="section-card pt-20 mb-20">
        <div class="header">
          <div class="title">
            <span>{{ "IR_FORM.distribution" | translate }}</span>
          </div>
        </div>
        <div class="content" [formGroup]="distributionPlan">
          <mat-form-field
            class="col-12"
          >
            <mat-label for="actionLm">
              {{ "IR_FORM.ExportSpecialist" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="actionLm"
              name="actionLm"
            ></textarea>
          </mat-form-field>
          <mat-form-field
            class="col-12"
          >
            <mat-label for="actionCustomer">{{
              "IR_FORM.CompanyClient" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="actionCustomer"
              name="actionCustomer"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="section-card pt-20">
        <div class="header">
          <div class="title">
            <span>{{ "IR_FORM.LeadGeneration" | translate }}</span>
          </div>
        </div>
        <div class="content" [formGroup]="communicationMktgLgPlan">
          <mat-form-field
            class="col-12"
          >
            <mat-label for="actionLm">
              {{ "IR_FORM.ExportSpecialist" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="actionLm"
              name="actionLm"
            ></textarea>
          </mat-form-field>
          <mat-form-field
            class="col-12"
          >
            <mat-label for="actionCustomer">{{
              "IR_FORM.CompanyClient" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="actionCustomer"
              name="actionCustomer"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isReadOnly" class="center-buttons mt-50">
    <button type="submit" class="rounded-button primary-bg-button">
      {{ "COMMON.SaveChanges" | translate }}
    </button>
  </div>
</form>
<div class="mt-40">
  <button
    *ngIf="marketProjectObjective.getRawValue()?.length"
    type="button"
    class="rounded-button primary-border-button m-0-auto"
    appScrollToTop
  >
    {{ "COMMON.ComeUp" | translate }}
  </button>
</div>
