import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LayoutService } from 'src/app/services/layout/layout.service';
import {
  FileBody,
  NavBarProps,
  HiddenArchorProps,
} from 'src/app/models/utility';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  needsReloading: boolean = false;
  navBar: NavBarProps[];
  spinner: boolean = false;
  downloadUrl: string;
  /**
   * @description This BehaviorSubject keep url of file from service
   */
  private fileUrl: BehaviorSubject<HiddenArchorProps> = new BehaviorSubject(
    null
  );
  /**
   * @description Use fileBody as observable to enable subscription
   */
  fileUrlListener$ = this.fileUrl.asObservable();

  constructor(
    private layoutService: LayoutService,
    private translate: TranslateService,
    public authService: AuthService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.translate
        .get('COMMON')
        .subscribe((translations: { [param: string]: string }): void => {
          this.navBar = this.layoutService.getNavigationMenu(
            this.authService.functionality,
            translations
          );
        })
    );
    /**
     * @usageNotes Subscription to fileBody observable of layut service, used to trigger download of file
     */
    this.subscriptions.add(
      this.layoutService.fileBodyListner$.subscribe({
        next: (fileBody: FileBody) => {
          if (fileBody) {
            this.downloadUrl = window.URL.createObjectURL(fileBody.file);
            this.fileUrl.next({
              url: this.downloadUrl,
              filename: fileBody.fileName,
            });
          }
        },
      })
    );
  }

  /**
   * @description Revoke the last downloaded url, usally used in hidden archor component after click event
   */
  revokeDownloadUrl(): void {
    URL.revokeObjectURL(this.downloadUrl);
    this.layoutService.clearFileDownload();
  }

  /**
   * @description Execute logout method of authService
   */
  logout(): void {
    this.authService.logout();
  }

  setReloading(needsReloading: boolean): void {    
    this.needsReloading = needsReloading;
  }

  reloadPage(): void {
    if (this.needsReloading) {
      this.needsReloading = false;
      window.location.reload();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
