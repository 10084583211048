import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import {
  ISearchProduct,
  ISearchProductDetail,
} from 'src/app/models/search-product';
import { PRODUCT_TYPE } from 'src/app/config';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatRadioChange } from '@angular/material/radio';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastStatus } from 'src/app/models/utility';
import { fromProductSearchToProductSearchTable } from 'src/app/utility/elaborations';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { ISectors } from 'src/app/models/sectors';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.scss'],
})
export class SearchProductComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  productInformation: ISearchProduct;
  searchProductForm: FormGroup;
  enableSearchText: boolean = false;
  totQuantityYear: string;
  totValueYear: string;
  productDetailsData: any[];
  productInput: string;
  productError: any;
  translations: any = {};
  loading: boolean = false;
  productValid: boolean = false;
  commerceSectors: ISectors[];

  constructor(
    private irFormService: IrFormService,
    private layoutService: LayoutService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private common: CommonService
  ) {
    this.searchProductForm = this.fb.group({
      product: [null],
      idProductType: [{ value: PRODUCT_TYPE.bought, disabled: this.loading }],
      idCommerceSector: [{ value: 'all', disabled: this.loading }],
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.translate.get('COMMON').subscribe((translations) => {
        this.translations = translations;
        this.generateBreadCrumb();
        this.getCommerceSectors();
      })
    );
  }

  generateBreadCrumb(): void {
    this.subscriptions.add(
      this.layoutService.generateBreadcrumb([
        {
          label: this.translate.instant('PAGES.search-product'),
          path: '/search-product',
          pageTitle: true,
        },
      ])
    );
  }

  getCommerceSectors(): void {
    this.subscriptions.add(
      this.irFormService.getAllSectors().subscribe({
        next: (sectors: ISectors[]) => {
          this.commerceSectors = sectors;
        },
      })
    );
  }

  searchProduct(): void {
    if (this.searchProductForm.valid && this.productValid) {
      this.loading = true;
      this.enableSearchText = false;
      this.productInput = this.searchProductForm.get('product').value.trim();
      const idProductType = this.searchProductForm.get('idProductType').value;
      this.subscriptions.add(
        this.irFormService
          .searchAllProducts(this.productInput, idProductType, true)
          .subscribe({
            next: (product: ISearchProduct) => {
              this.productInformation = product;
              this.totValueYear = new Intl.NumberFormat('it-IT').format(
                this.productInformation.totValueYear
              );
              (this.totQuantityYear = new Intl.NumberFormat('it-IT').format(
                product?.totQuantityYear
              )),
                this.searchProductsDetails();
              this.loading = false;
              this.enableSearchText = true;
            },
            error: () => {
              this.loading = false;
              this.enableSearchText = true;
              this.common.showToast(
                this.translations.NoMessage,
                ToastStatus.error,
                3000
              );
            },
          })
      );
    }
  }

  __checkString(): void {
    let prodValue: string = this.searchProductForm.get('product').value;
    prodValue = prodValue.trim();
    if (prodValue.length < 3) {
      this.productValid = false;
      this.productError = {
        minlength: { requiredLength: 3, actualLength: prodValue.length },
      };
    } else {
      this.productValid = true;
    }
  }

  searchProductsDetails(): void {
    const idProductType = this.searchProductForm.get('idProductType').value;
    this.subscriptions.add(
      this.irFormService
        .searchAllProductsDetails(this.productInput, idProductType, true)
        .subscribe(
          (productDetails: ISearchProductDetail[]) => {
            this.productDetailsData = fromProductSearchToProductSearchTable(
              productDetails,
              this.translations
            );
            this.enableSearchText = true;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          }
        )
    );
  }

  resetPage(event: MatRadioChange): void {
    if (event) {
      this.enableSearchText = false;
    }
  }

  resetFilters(): void {
    this.searchProductForm = this.fb.group({
      product: [null],
      idProductType: [{ value: PRODUCT_TYPE.bought, disabled: this.loading }],
    });
    this.productValid = true;
    this.enableSearchText = false;
    this.productDetailsData = [];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
