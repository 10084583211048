import { Component, OnInit, Inject, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IProductMatching, KeyMatching } from 'src/app/models/product-matching';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISectors } from 'src/app/models/sectors';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { UnitsOfMeasure } from 'src/app/models/utility';
import { NgxCurrencyModule } from 'ngx-currency';
import { currencyDefaultConfig, currencyQuantityConfig } from 'src/app/config';
import { IReport, IReportMatching } from 'src/app/models/report';
import { CrmService } from 'src/app/services/crm/crm.service';

@Component({
  selector: 'app-contact-reports-dialog',
  templateUrl: './contact-reports-dialog.component.html',
  styleUrls: ['./contact-reports-dialog.component.scss']
})
export class ContactReportsDialogComponent implements OnInit {

  translations: any;
  reportForm: FormGroup;

  addContactReportEmitter: EventEmitter<any> = new EventEmitter();

  commerceSectors: ISectors[] = [];
  unitsOfMeasure: UnitsOfMeasure[] = [];
  contactReport: IReport;
  private subscriptions: Subscription = new Subscription();
  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;
  currencyQuantityConfig: NgxCurrencyModule = currencyQuantityConfig;
  idContact: number | string;
  dataForSelectReady: boolean = false;
  isReadonly: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private irFormService: IrFormService,
    private crmService: CrmService
  ) {
    this.translations = data.translations;
    this.idContact = data.idContact;
    this.contactReport = data.contactReport;
    this.isReadonly = data.isReadonly;
    this.reportForm = fb.group({
      idOpportunity: [null],
      idDeal: [null],
      commerceSector: this.fb.group({
        idCommerceSector: [null, [Validators.required]],
      }),
      productName: [null, [Validators.required]],
      productDescription: [null, [Validators.required]],
      productEnglishDescription: [null, [Validators.required]],
      unitOfMeasure: this.fb.group({
        idUom: [null, [Validators.required]],
      }),
      quantity: [null, [Validators.required]],
      quantityYear: [null, [Validators.required]],
      valueOfUnit: [null, [Validators.required]],
      valueYear: [null, [Validators.required]],
      dateInsert: [null],
      userInsert: [null],
      userModify: [null],
      dateModify: [null],
      reportMatching: fb.array([])
    });
  }

  ngOnInit(): void {
    if (this.isReadonly) {
      this.reportForm.disable();
    }
    this.reportMatching.clear();
    this.subscriptions.add(
      this.irFormService.getAllSectors(true).subscribe((commerceSectors: ISectors[]) => {
        this.commerceSectors = commerceSectors;
        this.subscriptions.add(
          this.irFormService.getUnitsOfMeasure(true).subscribe((unitsOfMeasure: UnitsOfMeasure[]) => {
            this.unitsOfMeasure = unitsOfMeasure;
            this.dataForSelectReady = true;
          })
        );
      })
    );
    this.reportMatching.markAsPristine();
    this.reportMatching.markAsUntouched();
    if (this.contactReport) {
      this.reportForm.patchValue(this.contactReport);
      this.contactReport.reportMatching?.forEach(reportMatching => {
          this.addReportMatching(reportMatching);
      });
    }
  }

  get reportMatching(): FormArray {
    return this.reportForm.get('reportMatching') as FormArray;
  }

  addReportMatching(reportMatching?: IReportMatching): void {
    this.reportMatching.push(this.fb.group({
      idReportMatching: [reportMatching?.idReportMatching || null],
      wordKey: [reportMatching?.wordKey || null, [Validators.maxLength(250)]]
    }));
  }

  updateValueYear(report: FormGroup): void {
    report.get('valueYear').setValue(
      (report.get('valueOfUnit').value || 0) * (report.get('quantityYear').value || 0)
    );
  }

  onSubmit(): void {
    if (this.reportForm.valid && this.reportForm.dirty) {
      this.subscriptions.add(
        this.crmService.addContactReport(this.idContact, this.reportForm.getRawValue(), false).subscribe({
          complete: () => {
            this.addContactReportEmitter.emit();
          },
          error: () => {
          }
        })
      );
    }
  }

  removeReportMatching(i: number): void {
    this.reportMatching.removeAt(i);
  }

}
