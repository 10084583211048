import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Observable, Subscription, Subject } from 'rxjs';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { IrFormComponent } from 'src/app/components/ir-form-abstract/ir-form-abstract-components';
import {
  ItemFieldValueInArrayCannotBeSame,
  ItemFieldValueCannotBeSameRelatedToOtherArrays,
  MaxInArrayFields,
  isDisabledControlValid,
} from 'src/app/utility/validators';
import { CalculateRevenueShare } from 'src/app/utility/elaborations';
import { Project } from 'src/app/models/project';
import { IMarkets } from 'src/app/models/markets';
import { ICountries } from 'src/app/models/countries';
import { MARKET_TYPE } from 'src/app/config';
import { elementAt, takeUntil } from 'rxjs/operators';
import {
  MatExpansionPanel,
  MatExpansionPanelActionRow,
} from '@angular/material/expansion';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastStatus } from 'src/app/models/utility';

@Component({
  selector: 'app-market-form',
  templateUrl: './market-form.component.html',
  styleUrls: ['./market-form.component.scss'],
})
export class MarketFormComponent
  extends IrFormComponent
  implements OnInit, OnDestroy
{
  marketForm: FormGroup;
  private subscriptions: Subscription = new Subscription();
  idCustomer: number;
  otherRevenues: { [key: string]: number } = {
    presenceMarkets: 0,
  };

  @Input() initialValues: Observable<Project>;
  @Input() translations: any;
  @Input() index: any;
  @Input() tabIndex: any;
  @Input() isLabManagerInternal: boolean;
  @Input() isReadOnly: boolean;
  @Input() isIsp: boolean;
  countries: ICountries[];

  /** filtered variables */
  filteredCountries: ICountries[];

  /** control for the MatSelect filter keyword */
  public entityFilterCtrl: FormControl = new FormControl();

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  @Output() submitFuncEmitter: EventEmitter<IMarkets[]> = new EventEmitter();
  @Output() refreshMarkets: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren('formPanel') formPanel: QueryList<MatExpansionPanel>;

  initialValuesOnForm: any;
  startDataOnInit: any;

  constructor(
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private irFormService: IrFormService,
    private common: CommonService
  ) {
    super(fb);
    this.marketForm = fb.group({
      presenceMarkets: fb.array([]),
      hightPotentialMarkets: fb.array([]),
      hightPotentialFutureMarkets: fb.array([]),
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialValues: Project) => {
          this.updateIdCustomerInfoDescription(
            initialValues?.customerDescription
          );
          this.countries = initialValues.countries;
          this.idCustomer = initialValues.customer.idCustomer;
          this.presenceMarkets.clear();
          this.hightPotentialMarkets.clear();
          this.hightPotentialFutureMarkets.clear();
          initialValues.markets.forEach((market: IMarkets) => {
            if (market.idMarketType === MARKET_TYPE.MarketPresence) {
              this.addPresenceMarket(market);
            }
            if (market.idMarketType === MARKET_TYPE.HighPotentialMarket) {
              this.addHightPotentialMarket(market);
            }
            if (market.idMarketType === MARKET_TYPE.HighPotentialFutureMarket) {
              this.addHightPotentialFutureMarket(market);
            }
          });
          this.presenceMarkets.setValue(
            (this.presenceMarkets as FormArray).value.sort(
              (a: IMarkets, b: IMarkets) => {
                return b.revenueShare - a.revenueShare;
              }
            )
          );
          this.hightPotentialMarkets.setValue(
            (this.hightPotentialMarkets as FormArray).value.sort(
              (a: IMarkets, b: IMarkets) => {
                return b.levelOfInterest - a.levelOfInterest;
              }
            )
          );
          this.hightPotentialFutureMarkets.setValue(
            (this.hightPotentialFutureMarkets as FormArray).value.sort(
              (a: IMarkets, b: IMarkets) => {
                return b.levelOfInterest - a.levelOfInterest;
              }
            )
          );
          this.marketForm.markAsPristine();
          this.marketForm.markAsUntouched();
          this.presenceMarkets.setValidators([
            ItemFieldValueInArrayCannotBeSame('country.idCountry'),
            ItemFieldValueCannotBeSameRelatedToOtherArrays(
              'country.idCountry',
              [this.hightPotentialMarkets, this.hightPotentialFutureMarkets]
            ),
            MaxInArrayFields('revenueShare', 100),
          ]);
          this.presenceMarkets.updateValueAndValidity();
          this.hightPotentialMarkets.setValidators([
            ItemFieldValueInArrayCannotBeSame('country.idCountry'),
            ItemFieldValueCannotBeSameRelatedToOtherArrays(
              'country.idCountry',
              [this.presenceMarkets, this.hightPotentialFutureMarkets]
            ),
            MaxInArrayFields('revenueShare', 100),
          ]);
          this.hightPotentialMarkets.updateValueAndValidity();
          this.hightPotentialFutureMarkets.setValidators([
            ItemFieldValueInArrayCannotBeSame('country.idCountry'),
            ItemFieldValueCannotBeSameRelatedToOtherArrays(
              'country.idCountry',
              [this.presenceMarkets, this.hightPotentialMarkets]
            ),
            MaxInArrayFields('revenueShare', 100),
          ]);
          this.hightPotentialFutureMarkets.updateValueAndValidity();
          /** Init variables and check on change */
          this.filteredCountries = initialValues.countries;
          this.filterEntities();
          this.entityFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterEntities();
            });
          if (this.isLabManagerInternal || this.isReadOnly) {
            this.marketForm.disable();
            this.customerDescriptionForm.disable();
          }
          this.initialValuesOnForm = this.marketForm.getRawValue();
          this.startDataOnInit = {
            markets: this.marketForm.getRawValue(),
            description: this.customerDescriptionForm.get('marketDesc').value,
          };
        },
      })
    );
  }

  get presenceMarkets(): FormArray {
    return this.marketForm.get('presenceMarkets') as FormArray;
  }

  get hightPotentialMarkets(): FormArray {
    return this.marketForm.get('hightPotentialMarkets') as FormArray;
  }

  get hightPotentialFutureMarkets(): FormArray {
    return this.marketForm.get('hightPotentialFutureMarkets') as FormArray;
  }

  get allMarkets(): any[] {
    return this.presenceMarkets
      .getRawValue()
      .concat(this.hightPotentialMarkets.getRawValue())
      .concat(this.hightPotentialFutureMarkets.getRawValue());
  }

  addPresenceMarket(market?: IMarkets): void {
    // Subscribe only if is the first add
    // if (!this.presenceMarkets.length) {
    //   this.subscriptions.add(
    //     this.presenceMarkets.valueChanges.subscribe(
    //       (value: IMarkets[]): void => {
    //         this.otherRevenues.presenceMarkets = CalculateRevenueShare(value);
    //       }
    //     )
    //   );
    // }
    this.presenceMarkets.push(
      this.fb.group({
        idForeignMarket: [market?.idForeignMarket || null],
        toIncrease: [market?.toIncrease || false],
        idCustomer: [this.idCustomer],
        idMarketType: [MARKET_TYPE.MarketPresence],
        country: this.fb.group({
          idCountry: [
            market?.country?.idCountry || null,
            [Validators.required],
          ],
        }),
        description: [market?.description || null, [Validators.maxLength(50)]],
        revenueShare: [
          market?.revenueShare || 0,
          [Validators.required, Validators.min(0), Validators.max(100)],
        ],
        levelOfInterest: [market?.levelOfInterest || null, Validators.required],
        reason: [market?.reason || null, [Validators.required, Validators.maxLength(500)]],
        potentialReason: [
          market?.potentialReason || null,
          [Validators.maxLength(255)],
        ],
      })
    );
  }

  submitComment(): void {
    this.customerDescrSubmit('marketDesc');
  }

  toggleToIncreaseReasonRequired(slideValue: boolean, index: number) {    
    if (slideValue) {
      this.presenceMarkets.at(index).get('potentialReason')
        .setValidators(Validators.required);
    } else {
      this.presenceMarkets.at(index).get('potentialReason').clearValidators()
      this.presenceMarkets.at(index).get('potentialReason').reset();
    };
    this.presenceMarkets.at(index).get('potentialReason').updateValueAndValidity();
  }

  addHightPotentialMarket(market?: IMarkets): void {
    this.hightPotentialMarkets.push(
      this.fb.group({
        idCustomer: [this.idCustomer],
        toIncrease: [false],
        idMarketType: [MARKET_TYPE.HighPotentialMarket],
        country: this.fb.group({
          idCountry: [
            market?.country?.idCountry || null,
            [Validators.required],
          ],
        }),
        idForeignMarket: [market?.idForeignMarket || null],
        description: [market?.description || null, [Validators.maxLength(50)]],
        levelOfInterest: [
          market?.levelOfInterest || null,
          [Validators.required],
        ],
        reason: [market?.reason || null, [Validators.required, Validators.maxLength(500)]],
      })
    );
  }

  addHightPotentialFutureMarket(market?: IMarkets): void {
    this.hightPotentialFutureMarkets.push(
      this.fb.group({
        idCustomer: [this.idCustomer],
        toIncrease: [false],
        idMarketType: [MARKET_TYPE.HighPotentialFutureMarket],
        country: this.fb.group({
          idCountry: [
            market?.country?.idCountry || null,
            [Validators.required],
          ],
        }),
        idForeignMarket: [market?.idForeignMarket || null],
        description: [market?.description || null, [Validators.maxLength(50)]],
        levelOfInterest: [
          market?.levelOfInterest || null,
          [Validators.required],
        ],
        reason: [market?.reason || null, [Validators.required, Validators.maxLength(500)]],
      })
    );
  }

  isNewMarket(market: FormGroup): boolean {
    if (!market.getRawValue().idForeignMarket) {
      return true;
    }
  }

  removeMarket(market: FormGroup, index: number): void {
    let marketType: FormArray;
    if (market.get('idMarketType').value === MARKET_TYPE.MarketPresence) {
      marketType = this.presenceMarkets;
    }
    if (market.get('idMarketType').value === MARKET_TYPE.HighPotentialMarket) {
      marketType = this.hightPotentialMarkets;
    }
    if (
      market.get('idMarketType').value === MARKET_TYPE.HighPotentialFutureMarket
    ) {
      marketType = this.hightPotentialFutureMarkets;
    }
    const idMarket: number =
      marketType.at(index).get('idForeignMarket').value || null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '400px';
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    let dialogRef: MatDialogRef<ConfirmationDialogComponent, any>;
    if (idMarket) {
      if (this.marketForm.dirty) {
        this.common.showToast(
          this.translations.SaveChangesBefore,
          ToastStatus.warning,
          3000
        );
      } else {
        dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            this.subscriptions.add(
              this.irFormService.deleteMarket(idMarket).subscribe({
                complete: () => {
                  marketType.removeAt(index);
                  this.refreshMarkets.emit();
                },
              })
            );
          }
        });
      }
    } else {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          marketType.removeAt(index);
        }
      });
    }
  }

  onSubmit(): void {
    /* if (this.isIsp) {
      if (
        (this.marketForm.valid &&
          this.marketForm.dirty &&
          this.customerDescriptionForm.get(
            'marketDesc'
          ).valid) || (
          this.marketForm.valid &&
          this.customerDescriptionForm.get(
            'marketDesc'
          ).valid &&
          this.customerDescriptionForm.get(
            'marketDesc'
          ).dirty
        )
      ) {
        if (!this.marketForm.dirty) {
          this.customerDescrSubmit('marketDesc');
        } else {
          const body: IMarkets[] = [
            ...this.presenceMarkets.getRawValue(),
            ...this.hightPotentialMarkets.getRawValue(),
            ...this.hightPotentialFutureMarkets.getRawValue(),
          ];
          this.submitFuncEmitter.emit(body);
          this.marketForm.markAsPristine();
          this.marketForm.markAsUntouched();
          this.customerDescrSubmit('marketDesc');
        }
      }
    } else { */
      if (
        this.marketForm.valid &&
        this.marketForm.dirty
      ) {
        const body: IMarkets[] = [
          ...this.presenceMarkets.getRawValue(),
          ...this.hightPotentialMarkets.getRawValue(),
          ...this.hightPotentialFutureMarkets.getRawValue(),
        ];
        this.submitFuncEmitter.emit(body);
        this.marketForm.markAsPristine();
        this.marketForm.markAsUntouched();
      }
    // }
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(
        {
          markets: this.marketForm.getRawValue(),
          description: this.customerDescriptionForm.get('marketDesc').value,
        },
        this.irFormService.replacer
      )
    );
  }

  checkInvalidForm(): boolean {
    this.marketForm.markAllAsTouched();
    return this.marketForm.invalid;
  }

  checkAtLeastOnePresenceMarket(): boolean {
    return this.presenceMarkets.getRawValue().some((market: IMarkets) => {
      return market.idForeignMarket;
    });
  }

  checkIfPresenceMarketsPercentageIs100(): boolean {
    let hundred: number = 0;
    this.presenceMarkets.controls.forEach((market: FormGroup) => {
      if (market.get('idForeignMarket').value) {
        if (
          !market.get('revenueShare').dirty &&
          isDisabledControlValid(market.get('revenueShare'))
        ) {
          hundred = hundred + market.get('revenueShare').value;
        }
      }
    });
    return hundred === 100;
  }

  /** filter the entities */
  private filterEntities() {
    if (!this.countries) {
      return;
    }
    // get the search keyword
    let search = this.entityFilterCtrl.value;
    if (!search) {
      this.filteredCountries = this.countries;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the data
    this.filteredCountries = this.countries
      .filter((entity) => entity.country.toLowerCase().indexOf(search) > -1)
      .sort(
        (a, b) =>
          a.country.toLowerCase().indexOf(search) -
          b.country.toLowerCase().indexOf(search)
      );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    /** destroy entity variables */
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getCountryForPanelTitle(market: any): string {
    let countryString: string;
    this.countries.forEach((country: ICountries) => {
      if (market.get('country').get('idCountry').value === country.idCountry) {
        countryString = country.country;
      }
    });
    return countryString;
  }

  expandPanelOnScroll(): void {
    this.formPanel.toArray().forEach((panel, index) => {
      setTimeout(() => {
        const invalidControl: HTMLElement =
          panel._body.nativeElement.querySelector('.mat-form-field-invalid');
        if (invalidControl) {
          this.formPanel.toArray()[index].open();
        }
      });
    });
  }
}
