import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Observable, Subscription, Subject } from 'rxjs';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { IrFormComponent } from 'src/app/components/ir-form-abstract/ir-form-abstract-components';
import { Project } from 'src/app/models/project';
import { ICompanyChannel, IChannel } from 'src/app/models/channels';
import { ICountries } from 'src/app/models/countries';
import { CHANNEL_TYPE, CHANNEL_USED } from 'src/app/config';
import { takeUntil, timestamp } from 'rxjs/operators';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MaxInArrayFields, isDisabledControlValid } from 'src/app/utility/validators';
import { MatExpansionPanel } from '@angular/material/expansion';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { EmitterVisitorContext } from '@angular/compiler';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastStatus } from 'src/app/models/utility';
import {
  IChannelSpecification,
  IChannelsType,
} from 'src/app/models/channels-type';
import { ChannelsManagementDialogComponent } from '../channels-management-dialog/channels-management-dialog.component';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-channels-form',
  templateUrl: './channels-form.component.html',
  styleUrls: ['./channels-form.component.scss'],
})
export class ChannelsFormComponent
  extends IrFormComponent
  implements OnInit, OnDestroy {
  channelsForm: FormGroup;

  private subscriptions: Subscription = new Subscription();

  idCustomer: number;
  channelUsed = CHANNEL_USED;

  @Input() initialValues: Observable<Project>;
  @Input() channelTypesAndSpecifications: Observable<any>;
  @Input() translations: any;
  @Input() index: any;
  @Input() tabIndex: any;
  @Input() isLabManagerInternal: boolean;
  @Input() isReadOnly: boolean;
  @Input() isIsp: boolean;
  @Input() isAgent: boolean;
  countries;
  channelsType;
  specificationsChannels;

  startDataOnInit: any;

  /** filtered variables */
  filteredCountries: ICountries[];

  /** control for the MatSelect filter keyword */
  public entityFilterCtrl: FormControl = new FormControl();

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  @Output() submitFuncEmitter: EventEmitter<IChannel[]> = new EventEmitter();
  @Output() deleteCompanyEmitter: EventEmitter<number | string> =
    new EventEmitter();
  @Output() deleteChannelEmitter: EventEmitter<number | string> =
    new EventEmitter();
  @Output() refreshChannels: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshChannelsType: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshSpecificationsChannels: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren('formPanel') formPanel: QueryList<MatExpansionPanel>;
  @ViewChildren('channelFormGroup') channelFormGroup: QueryList<ElementRef>;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private irFormService: IrFormService,
    private common: CommonService
  ) {
    super(fb);
    this.channelsForm = fb.group({
      channels: fb.array([], MaxInArrayFields('revenueShare', 100)),
      desiredChannels: fb.array([]),
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.channelTypesAndSpecifications?.subscribe({
        next: (channelTypesAndSpecifications: any) => {          
          this.channelsType = channelTypesAndSpecifications.types;
          this.specificationsChannels = channelTypesAndSpecifications.specifications;
        }
      })
    );
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialValues: Project) => {
          this.updateIdCustomerInfoDescription(
            initialValues?.customerDescription
          );
          this.countries = initialValues.countries.map((country) => ({
            descripton: country?.country,
            country: country?.country,
            idCountry: country?.idCountry,
          }));
          this.idCustomer = initialValues.idCustomer;
          this.channels.clear();
          this.desiredChannels.clear();
          initialValues.channels?.forEach((channel: IChannel) => {
            if (channel.type === CHANNEL_TYPE.Actual) {
              this.addChannel(channel);
            }
            if (channel.type === CHANNEL_TYPE.Desired) {
              this.addDesiredChannel(channel);
            }
          });
          this.channelsForm.markAsPristine();
          this.channelsForm.markAsUntouched();
          /** Init variables and check on change */
          this.filteredCountries = initialValues.countries;
          this.filterEntities();
          this.entityFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterEntities();
            });
          if (this.isLabManagerInternal || this.isReadOnly) {
            this.channelsForm.disable();
            this.customerDescriptionForm.disable();
          }
          this.startDataOnInit = {
            channels: this.channelsForm.getRawValue(),
            description: this.customerDescriptionForm.get('channelDesc').value,
          };
        },
      })
    );
  }

  // -------- CHANNELS FORM --------------------------------------------------------------------------------
  /**
   * @description Get of channels Formarray in channelsForm to easy axcess to the controls
   */
  get channels(): FormArray {
    return this.channelsForm.get('channels') as FormArray;
  }

  /**
   * @description add a new element in channel form array, used in initialization and in insert.
   * @param initialValues the value of channel that is pushed in array, if null inizialize an empty channel
   */
  addChannel(initialValues?: IChannel): void {
    const channelIdx: number = this.channels.length;
    this.channels.push(
      this.fb.group({
        customer: this.fb.group({
          idCustomer: [initialValues?.customer?.idCustomer],
        }),
        idDistributionChannel: [initialValues?.idDistributionChannel || null],
        idChannelType: [
          initialValues?.idChannelType || null,
          Validators.required,
        ],
        // description: [initialValues?.description || null],
        idSpecificationChannelType: [
          {
            value: initialValues?.idSpecificationChannelType || null,
            disabled: initialValues?.idChannelType ? false : true,
          },
          Validators.required,
        ],
        // channelSpecification: [
        //   initialValues?.channelSpecification || null,
        //   Validators.required,
        // ],
        revenueShare: [
          initialValues?.revenueShare || 0,
          [Validators.required, Validators.min(0), Validators.max(100)],
        ],
        isChannelGlobal: [
          initialValues?.isChannelGlobal || null,
          Validators.required,
        ],
        levelOfInterest: [initialValues?.levelOfInterest || null,
          [Validators.required]
        ],
        reason: [initialValues?.reason || null, 
          [Validators.required, Validators.maxLength(500)]
        ],
        customerChannelTargetDescription: [
          initialValues?.customerChannelTargetDescription || null,
          [Validators.required]
        ],
        companies: this.fb.array([]),
        type: [initialValues?.type || CHANNEL_TYPE.Actual],
      })
    );

    if (initialValues) {
      initialValues?.companies?.map((company) =>
        this.addCompanyToChannel(channelIdx, company)
      );
    } else {
      this.addCompanyToChannel(channelIdx)
    }
    
    // Scroll to added channel
    setTimeout(() => {
      this.channelFormGroup.last.nativeElement.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }, 100);
  }

  isNewChannel(channel: FormGroup): boolean {
    if (!channel.getRawValue().idDistributionChannel) {
      return true;
    }
  }

  submitComment(): void {
    this.customerDescrSubmit('channelDesc');
  }

  /**
   * @description Remove a channel form channels ArrayForm
   * @param channelIdx the index of channel to remove
   */
  removeChannel(channelIdx: number): void {
    const distributionsChannels: IChannel[] = this.channels.getRawValue();
    const idChannel =
      distributionsChannels[channelIdx]?.idDistributionChannel || null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    let dialogRef: MatDialogRef<ConfirmationDialogComponent, any>;
    if (idChannel) {
      if (this.channelsForm.dirty) {
        this.common.showToast(
          this.translations.SaveChangesBefore,
          ToastStatus.warning,
          3000
        );
      } else {
        dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.subscriptions.add(
              this.irFormService.deleteChannel(idChannel).subscribe({
                complete: () => {
                  this.channels.removeAt(channelIdx);
                  this.refreshChannels.emit();
                },
              })
            );
          }
        });
      }
    } else {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.channels.removeAt(channelIdx);
        }
      });
    }
  }

  /**
   * @description Add company to the channel in channelIdx position of formArray
   * @param channelIdx the index of channel
   * @param initialValues the initial value if it is initialization
   */
  addCompanyToChannel(
    channelIdx: number,
    initialValues?: ICompanyChannel
  ): void {
    (
      (this.channels.at(channelIdx) as FormGroup).controls
        .companies as FormArray
    ).push(
      this.fb.group({
        idDistributionClient: [initialValues?.idDistributionClient || null],
        company: [initialValues?.company || null, Validators.required],
        idCountry: [initialValues?.idCountry || null, Validators.required],
      })
    );
  }

  /**
   * @description remove company to the channel in channelIdx position of formArray
   * @param channelIdx the index of channel
   */
  removeCompany(channelIdx: number, companyIdx: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        const distributionsChannels: IChannel[] = this.channels.getRawValue();
        const idCompany: number | string =
          distributionsChannels[channelIdx].companies[companyIdx]
            ?.idDistributionClient || null;
        if (idCompany) {
          this.subscriptions.add(
            this.irFormService.deleteChannelCompany(idCompany).subscribe()
          );
        }
        (
          (this.channels.at(channelIdx) as FormGroup).controls
            .companies as FormArray
        ).removeAt(companyIdx);
      }
    });
  }

  // -------- DESIRED CHANNELS FORM --------------------------------------------------------------------------------
  /**
   * @description Get of DESIRED channels Formarray in channelsForm to easy axcess to the controls
   */
  get desiredChannels(): FormArray {
    return this.channelsForm.get('desiredChannels') as FormArray;
  }

  /**
   * @description add a new element in DESIRED channel form array, used in initialization and in insert.
   * @param initialValues the value of channel that is pushed in array, if null inizialize an empty channel
   */
  addDesiredChannel(initialValues?: IChannel): void {
    const channelIdx: number = this.desiredChannels.length;
    this.desiredChannels.push(
      this.fb.group({
        customer: this.fb.group({
          idCustomer: [initialValues?.customer?.idCustomer],
        }),
        idDistributionChannel: [initialValues?.idDistributionChannel || null],
        idChannelType: [
          initialValues?.idChannelType || null,
          Validators.required,
        ],
        idSpecificationChannelType: [
          {
            value: initialValues?.idSpecificationChannelType || null,
            disabled: initialValues?.idChannelType ? false : true,
          },
          Validators.required,
        ],
        // description: [initialValues?.description || null],
        // channelSpecification: [
        //   initialValues?.channelSpecification || null,
        //   Validators.required,
        // ],
        levelOfInterest: [initialValues?.levelOfInterest || null, 
          [Validators.required]
        ],
        reason: [initialValues?.reason || null, 
          [Validators.required, Validators.maxLength(500)]
        ],
        companies: this.fb.array([]),
        type: [initialValues?.type || CHANNEL_TYPE.Desired],
      })
    );

    if (initialValues) {
      initialValues?.companies?.map((company) =>
        this.addCompanyToDesiredChannel(channelIdx, company)
      );
    } else if (!this.isAgent) {
      this.addCompanyToDesiredChannel(channelIdx)
    };
  }

  removeDesiredChannel(channelIdx: number): void {
    const distributionsChannels: IChannel[] =
      this.desiredChannels.getRawValue();
    const idChannel =
      distributionsChannels[channelIdx]?.idDistributionChannel || null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    let dialogRef: MatDialogRef<ConfirmationDialogComponent, any>;
    if (idChannel) {
      if (this.channelsForm.dirty) {
        this.common.showToast(
          this.translations.SaveChangesBefore,
          ToastStatus.warning,
          3000
        );
      } else {
        dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.subscriptions.add(
              this.irFormService.deleteChannel(idChannel).subscribe({
                complete: () => {
                  this.desiredChannels.removeAt(channelIdx);
                  this.refreshChannels.emit();
                },
              })
            );
          }
        });
      }
    } else {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.desiredChannels.removeAt(channelIdx);
        }
      });
    }
  }

  /**
   * @description Add company to the DESIRED channel in channelIdx position of formArray
   * @param channelIdx the index of channel
   * @param initialValues the initial value if it is initialization
   */
  addCompanyToDesiredChannel(
    channelIdx: number,
    initialValues?: ICompanyChannel
  ): void {
    (
      (this.desiredChannels.at(channelIdx) as FormGroup).controls
        .companies as FormArray
    ).push(
      this.fb.group({
        idDistributionClient: [initialValues?.idDistributionClient || null],
        company: [initialValues?.company || null, Validators.required],
        idCountry: [initialValues?.idCountry || null, Validators.required],
      })
    );
  }

  /**
   * @description remove company to the DESIRED channel in channelIdx position of formArray
   * @param channelIdx the index of channel
   */
  removeCompanyToDesiredChannel(channelIdx: number, companyIdx: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        const distributionsChannels: IChannel[] =
          this.desiredChannels.getRawValue();
        const idCompany: number | string =
          distributionsChannels[channelIdx].companies[companyIdx]
            ?.idDistributionClient || null;
        if (idCompany) {
          this.subscriptions.add(
            this.irFormService.deleteChannelCompany(idCompany).subscribe()
          );
        }
        (
          (this.desiredChannels.at(channelIdx) as FormGroup).controls
            .companies as FormArray
        ).removeAt(companyIdx);
      }
    });
  }

  /**
   * @description Check Validity form and Emit submit function after check if and how many item in array are changed or new
   */
  onSubmit(): void {
    /* if (this.isIsp) {
      if (!this.channels.length) {
        if (this.customerDescriptionForm.get('channelDesc').valid &&
          this.customerDescriptionForm.get('channelDesc').dirty
        ) {
          this.customerDescrSubmit('channelDesc');
        }
      } else {
        if (
          (this.channelsForm.valid &&
            this.channelsForm.dirty &&
            this.customerDescriptionForm.get('channelDesc').valid
          ) ||
          (this.channelsForm.valid &&
            this.customerDescriptionForm.get('channelDesc').valid &&
            this.customerDescriptionForm.get('channelDesc').dirty
          )
        ) {
          this.saveChanges();
        }
      }
    } else { */
      if (
        this.channels.length &&
        this.channelsForm.valid &&
        this.channelsForm.dirty
      ) {
        this.saveChanges()
      }
    // }
  }

  saveChanges(): void {
    const body: IChannel[] = [
      ...this.channels.getRawValue(),
      ...this.desiredChannels.getRawValue(),
    ];
    let allRevenuPercentages: number = 0;
    this.channels.getRawValue().forEach((channel: IChannel) => {
      allRevenuPercentages = allRevenuPercentages + channel.revenueShare;
    });
    if (allRevenuPercentages === 100) {
      this.submitFuncEmitter.emit(body);
      // this.customerDescrSubmit('channelDesc');
    }
    if (allRevenuPercentages < 100) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '400px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translations?.Attention,
        message:
          this.translations?.TotalTurnoverSharesNot100ConfirmationMessage,
        buttonTrue: this.translations.Yes,
        buttonFalse: this.translations.No,
      };
      const dialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.submitFuncEmitter.emit(body);
          // this.customerDescrSubmit('channelDesc');
        }
      });
    }
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(
        {
          channels: this.channelsForm.getRawValue(),
          description: this.customerDescriptionForm.get('channelDesc').value,
        },
        this.irFormService.replacer
      )
    );
  }

  checkInvalidForm(): boolean {
    this.channelsForm.markAllAsTouched();
    return this.channelsForm.invalid;
  }

  checkIfCurrentChannelsPercentageIs100(): boolean {
    let hundred: number = 0;
    this.channels.controls.forEach((channel: FormGroup) => {
      if (channel.get('idDistributionChannel').value) {
        if (
          !channel.get('revenueShare').dirty &&
          isDisabledControlValid(channel.get('revenueShare'))
        ) {
          hundred = hundred + channel.get('revenueShare').value;
        }
      }
    });
    return hundred === 100;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    /** destroy entity variables */
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /** filter the entities */
  private filterEntities(): void {
    if (!this.countries) {
      return;
    }
    // get the search keyword
    let search = this.entityFilterCtrl.value;
    if (!search) {
      this.filteredCountries = this.countries;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the data
    this.filteredCountries = this.countries
      .filter((entity) => entity.country.toLowerCase().indexOf(search) > -1)
      .sort(
        (a, b) =>
          a.country.toLowerCase().indexOf(search) -
          b.country.toLowerCase().indexOf(search)
      );
  }

  // handleChannelTypeSelect(
  //   channelItem: FormGroup,
  //   channelTypeOption: MatOption
  // ): void {
  //   if (channelItem.get('idChannelType').value === 1) {
  //     channelItem.get('description').setValue('');
  //     channelItem.get('channelSpecification').setValue('');
  //     channelItem.get('description').setValidators([Validators.required]);
  //     channelItem.get('description').updateValueAndValidity();
  //     channelItem.get('channelSpecification').updateValueAndValidity();
  //   } else {
  //     channelItem.get('description').setValue(channelTypeOption.viewValue);
  //     channelItem
  //       .get('channelSpecification')
  //       .setValue(channelTypeOption.viewValue);
  //   }
  // }

  handleChannelDescription(
    channelItem: FormGroup,
    channelDescription: MatInput
  ): void {
    channelItem.get('channelSpecification').setValue(channelDescription.value);
    channelItem.get('channelSpecification').updateValueAndValidity();
  }

  expandPanelOnScroll(): void {
    this.formPanel.toArray().forEach((panel, index) => {
      setTimeout(() => {
        const invalidControl: HTMLElement =
          panel._body.nativeElement.querySelector('.mat-form-field-invalid');
        if (invalidControl) {
          this.formPanel.toArray()[index].open();
        }
      });
    });
  }

  showChannelsManagementDialog(
    event: PointerEvent,
    type: string,
    idChannelType?: number,
    channelListType?: string
  ): void {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '900px';
    dialogConfig.panelClass = 'custom-dialog-container';
    if (type === 'channel') {
      dialogConfig.data = {
        managementType: 'channel',
        channels: this.channelsType,
        idCustomer: this.idCustomer,
        translations: this.translations,
      };
    } else {
      dialogConfig.data = {
        managementType: 'channelSpecification',
        channelsSpecifications: this.specificationsChannels.filter(
          (spec: IChannelSpecification) => {
            return spec.idChannelType === idChannelType;
          }
        ),
        idCustomer: this.idCustomer,
        translations: this.translations,
        idChannelType,
      };
    }
    const dialogRef = this.dialog.open(
      ChannelsManagementDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'refresh') {
        if (type === 'channel') {
          this.subscriptions.add(
            this.irFormService
              .getChannelsTypeByIdCustomer(this.idCustomer, false)
              .subscribe({
                next: (channels: IChannelsType[]) => {
                  this.channelsType = channels;
                  if (channelListType === 'current') {
                    this.channels
                      .getRawValue()
                      .forEach((channel: any, i: number) => {
                        if (
                          !channels.some((ch: any) => {
                            return ch.idChannelType === channel.idChannelType;
                          })
                        ) {
                          this.channels
                            .at(i)
                            .get('idChannelType')
                            .setValue(null);
                          this.activateSpecificationsSelect(
                            new MatSelectChange(null, null),
                            'current',
                            i
                          );
                        }
                      });
                  } else {
                    this.desiredChannels
                      .getRawValue()
                      .forEach((channel: any, i: number) => {
                        if (
                          !channels.some((ch: any) => {
                            return ch.idChannelType === channel.idChannelType;
                          })
                        ) {
                          this.desiredChannels
                            .at(i)
                            .get('idChannelType')
                            .setValue(null);
                          this.activateSpecificationsSelect(
                            new MatSelectChange(null, null),
                            'desired',
                            i
                          );
                        }
                      });
                  }
                  this.refreshChannelsType.emit();
                },
              })
          );
        } else {
          this.subscriptions.add(
            this.common
              .getSpecificationChannels(this.idCustomer, false)
              .subscribe({
                next: (channelsSpec: IChannelSpecification[]) => {
                  this.specificationsChannels = channelsSpec;
                  if (channelListType === 'current') {
                    this.channels
                      .getRawValue()
                      .forEach((channel: any, i: number) => {
                        if (
                          !channelsSpec.some((ch: any) => {
                            return (
                              ch.idSpecificationChannelType ===
                              channel.idSpecificationChannelType
                            );
                          })
                        ) {
                          this.channels
                            .at(i)
                            .get('idSpecificationChannelType')
                            .setValue(null);
                        }
                      });
                  } else {
                    this.desiredChannels
                      .getRawValue()
                      .forEach((channel: any, i: number) => {
                        if (
                          !channelsSpec.some((ch: any) => {
                            return (
                              ch.idSpecificationChannelType ===
                              channel.idSpecificationChannelType
                            );
                          })
                        ) {
                          this.desiredChannels
                            .at(i)
                            .get('idSpecificationChannelType')
                            .setValue(null);
                        }
                      });
                  }
                  this.refreshSpecificationsChannels.emit();
                },
              })
          );
        }
      }
    });
  }

  activateSpecificationsSelect(
    change: MatSelectChange,
    type: string,
    index: number
  ): void {
    switch (type) {
      case 'current':
        if (change.value) {
          this.channels.at(index).get('idSpecificationChannelType').enable();
        } else {
          this.channels.at(index).get('idSpecificationChannelType').disable();
        }
        break;
      case 'desired':
        if (change.value) {
          this.desiredChannels
            .at(index)
            .get('idSpecificationChannelType')
            .enable();
        } else {
          this.desiredChannels
            .at(index)
            .get('idSpecificationChannelType')
            .disable();
        }
        break;
    }
  }
}
