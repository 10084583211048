import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { trigger, transition, animate, style } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users/users.service';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { IProvince, LoggedUser } from 'src/app/models/user';
import {
  AGENT_ROLE,
  AGENT_SUPERVISOR,
  LAB_MANAGER,
  LAB_MANAGER_INTERNAL,
  LAB_MANAGER_SUPERVISOR,
} from 'src/app/config';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { UserManagmentFormComponent } from 'src/app/components/user-managment-form/user-managment-form.component';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.scss'],
})
export class UsersManagementComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  translations: any = {};
  loadingUsers: boolean = false;
  filterObject: { [key: string]: any } = {};
  users: LoggedUser[];
  roles: any[] = [];
  checked: boolean = false;
  displayedColumns = ['name', 'roles', 'supervisor', 'email', 'tools'];
  // NUOVO CODICE
  usersData: any;
  oldUsersData: any;
  usersLoader: boolean = false;
  filterSelects: any = {
    role: null,
    type: null,
    teamLeader: null,
  };
  provinces: IProvince[];
  teamLeaders: LoggedUser[] = [];

  @ViewChild('roleFilter') roleFilter: MatSelect;
  @ViewChild('userTypeFilter') userTypeFilter: MatSelect;
  @ViewChild('teamLeaderFilter') teamLeaderFilter: MatSelect;
  @ViewChild('searchValue') searchValue: ElementRef;

  constructor(
    private usersService: UsersService,
    private dialog: MatDialog,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private common: CommonService
  ) {}

  ngOnInit(): void {
    // Initialize page with translations
    this.subscriptions.add(
      this.translate.get('USERS_MANAGEMENT').subscribe((translations: any) => {
        this.translations = translations;
        this.generateBreadcrumb();
        this.getUsers();
        this.getUserRoles();
        this.getAllProvince();
      })
    );
  }

  generateBreadcrumb(): void {
    this.subscriptions.add(
      this.layoutService.generateBreadcrumb([
        {
          label: this.translate.instant('PAGES.users-management'),
          path: null,
          pageTitle: true,
        },
      ])
    );
  }

  getUsers(): void {
    this.loadingUsers = true;
    this.usersService.getUsers().subscribe({
      next: (users: LoggedUser[]): void => {
        this.usersData = users;
        this.oldUsersData = users;
        this.teamLeaders = [];
        users.forEach((user: LoggedUser) => {
          if (user?.parentUserId && user?.enabled === true) {
            if (
              !this.teamLeaders.some((user2: LoggedUser) => {
                return user2?.idUser === user?.parentUserId;
              })
            ) {
              this.teamLeaders.push(
                users[
                  users.findIndex((user3: LoggedUser) => {
                    return user3?.idUser === user.parentUserId;
                  })
                ]
              );
            }
          }
        });
        this.filterTableWithActualValues();
      },
      complete: () => {
        this.loadingUsers = false;
      },
      error: () => {
        this.loadingUsers = false;
      },
    });
  }

  getUserRoles(): void {
    this.subscriptions.add(
      this.usersService.getUserRoles().subscribe({
        next: (roles: any[]): void => {
          this.roles = roles;
        },
      })
    );
  }

  getAllProvince(): void {
    this.subscriptions.add(
      this.common.getAllProvince().subscribe({
        next: (data: IProvince[]) => {
          this.provinces = data;
        },
      })
    );
  }

  showUserDialog(user?: any): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    dialogConfig.maxWidth = '95vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      user: user || null,
      roles: this.roles,
      provinces: this.provinces,
    };
    const dialogRef: MatDialogRef<any> = this.dialog.open(
      UserManagmentFormComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'refreshUsers') {
        this.getUsers();
      }
    });
  }

  /**
   * @description filter the datasource table, the filter value could be boolean if it went from checkboxEvent
   * @param event the event of dom, could be checkbox click or ionput keyup
   * @TODO Part of this must be move in utility/table.ts
   */
  applyFilter(event: any): void {
    if (event instanceof KeyboardEvent) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.filterObject = {
        ...this.filterObject,
        ALL: filterValue.trim().toLowerCase(),
      };
    }
    if (event instanceof MatCheckboxChange) {
      if (event.checked) {
        this.filterObject = { ...this.filterObject, enabled: true };
      }
      if (!event.checked) {
        delete this.filterObject.enabled;
      }
    }
  }

  /**
   * @description disabled/enabled user passed in param `user`
   * @param user The user to disable/enable
   * @param value The action, `true=enabled` | `false=disabled`
   */
  enableDisableUser(user: LoggedUser, value: boolean): void {
    const { idUser } = user;
    this.subscriptions = this.usersService
      .enableDisableUser(idUser, value)
      .subscribe({
        complete: () => this.getUsers(),
      });
  }

  /**
   * @description call the assign supervisor api in user service
   * @param row the who assign the supervisor
   * @param parentId the supervisor to assign
   */
  assignSupervisor(row: LoggedUser, parentId: number): void {
    const { idUser } = row;
    this.subscriptions = this.usersService
      .assignSupervisor(idUser, parentId)
      .subscribe({
        complete: () => this.getUsers(),
      });
  }

  /**
   * @TODO Forse questo metdo è inutile
   */
  getSuperVisor(row: LoggedUser): number {
    let ret: number;
    if (!row.parentUserId) {
      return null;
    }
    this.users.forEach((item) => {
      if (row.parentUserId === item.idUser) {
        ret = item.idUser;
      }
    });
    return ret;
  }

  /**
   * @description Open the confirm dialog window for the assign supervisor action
   * @param event the click event
   * @param user the user
   */
  openAssignSupervisorConfirm(event: MatSelectChange, user: LoggedUser): void {
    const { value } = event;
    if (value !== -1 && value !== user.parentUserId) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translations?.Attention,
        message: this.translate.instant(
          'USERS_MANAGEMENT.ConfirmChangeSupervisor',
          { name: `${user.name} ${user.surname}` }
        ),
        buttonFalse: this.translate.instant('COMMON.No'),
        buttonTrue: this.translate.instant('COMMON.Yes'),
      };
      const dialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.assignSupervisor(user, value);
        } else {
          event.source.value = user.parentUserId;
        }
      });
    }
  }

  /**
   * @description Open the confirm dialog window for the enable/disable user
   * @param user the user
   * @param value the value which update the abilitation of user
   */
  openEnableDisableDialog(user: LoggedUser, value: boolean): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: value
        ? this.translate.instant('USERS_MANAGEMENT.EnabledUser', {
            name: `${user.name} ${user.surname}`,
          })
        : this.translate.instant('USERS_MANAGEMENT.DisabledUser', {
            name: `${user.name} ${user.surname}`,
          }),
      buttonFalse: this.translate.instant('COMMON.No'),
      buttonTrue: this.translate.instant('COMMON.Yes'),
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.enableDisableUser(user, value);
      }
    });
  }

  /**
   * @description Open the confirm dialog window for the enable/disable user
   * @param user the user
   * @param value the value which update the abilitation of user
   */
  openResetPasswordDialog(user: LoggedUser): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translate.instant('USERS_MANAGEMENT.ConfirmResetPwd', {
        name: `${user.name} ${user.surname}`,
        email: user.email,
      }),
      buttonFalse: this.translate.instant('COMMON.No'),
      buttonTrue: this.translate.instant('COMMON.Yes'),
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.usersService.resetPassword(user.email).subscribe();
      }
    });
  }

  checkSelectSupervisor(user: LoggedUser): boolean {
    if (
      user.enabled === true &&
      ((user.roles.indexOf(AGENT_ROLE) > -1 &&
        user.roles.indexOf(AGENT_SUPERVISOR) === -1) ||
        (user.roles.indexOf(LAB_MANAGER) > -1 &&
          user.roles.indexOf(LAB_MANAGER_SUPERVISOR) === -1) ||
        (user.roles.indexOf(LAB_MANAGER_INTERNAL) > -1 &&
          user.roles.indexOf(LAB_MANAGER) === -1))
    ) {
      // if (user.idUser !== user.parentUserId) {
      //   return true;
      // } else {
      //   return false;
      // }
      return true;
    } else {
      return false;
    }
  }

  filterTable(filter: string, select: MatSelectChange): void {
    this.filterSelects[filter] = select.value;
    this.usersData = [];
    this.usersData = this.oldUsersData.filter((user: any) => {
      if (this.filterSelects.role) {
        return user?.roles?.includes(this.filterSelects.role);
      }
      return user;
    });
    this.usersData = this.usersData.filter((user: any) => {
      if (this.filterSelects.type === 'enabled') {
        return user.enabled === true;
      }
      if (this.filterSelects.type === 'disabled') {
        return user.enabled === false;
      }
      return user;
    });
    this.usersData = this.usersData.filter((user: any) => {
      if (this.filterSelects.teamLeader) {
        return (
          user?.parentUserId === this.filterSelects.teamLeader &&
          user?.enabled === true
        );
      }
      return user;
    });
  }

  filterTableWithActualValues(): void {
    this.filterTable('role', new MatSelectChange(null, this.roleFilter.value));
    this.filterTable(
      'type',
      new MatSelectChange(null, this.userTypeFilter.value)
    );
    if (
      this.teamLeaders.some((tl: LoggedUser) => {
        return tl.idUser === this.teamLeaderFilter.value;
      })
    ) {
      this.filterTable(
        'teamLeader',
        new MatSelectChange(null, this.teamLeaderFilter.value)
      );
    } else {
      this.filterTable('teamLeader', new MatSelectChange(null, null));
      this.teamLeaderFilter.value = null;
    }
  }

  resetFilters(): void {
    this.searchValue.nativeElement.value = null;
    this.usersData = this.oldUsersData;
    this.filterSelects = {
      role: null,
      type: null,
      teamLeader: null,
    };
    this.roleFilter.value = null;
    this.userTypeFilter.value = null;
    this.teamLeaderFilter.value = null;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
