import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { Observable, Subscription } from 'rxjs';
import {
  currencyDefaultConfig,
  currencyDefaultConfigAllowNegative,
} from 'src/app/config';
import { IChannelsType } from 'src/app/models/channels-type';
import { Project } from 'src/app/models/project';
import { CrmService } from 'src/app/services/crm/crm.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { PsFormService } from 'src/app/services/ps-form/ps-form.service';

@Component({
  selector: 'app-ps-current-strategy',
  templateUrl: './ps-current-strategy.component.html',
  styleUrls: ['./ps-current-strategy.component.scss'],
})
export class PsCurrentStrategyComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  oldStrategyForm: FormGroup;
  activeStrategyForm: FormGroup;
  dataIsReady: boolean = false;

  startDataOnInit: any;

  channelsType: IChannelsType[];

  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;
  currencyDefaultConfigAllowNegative: NgxCurrencyModule =
    currencyDefaultConfigAllowNegative;

  startDate: Date = new Date();
  endDate: Date = new Date();
  effectiveEndDateOrToday: Date = new Date();

  @Input() translations: any;
  @Input() initialValues: Observable<Project>;
  @Input() isSupervisor: any;
  @Input() isIspDelivered: any;
  @Input() isReadOnly: any;
  @Input() isDirection: boolean;
  @Input() isManagerAndSupervisorManagar: any;

  @Output() saveCurrentStrategyEmitter: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() refreshCurrentStrategyEmitter: EventEmitter<any> =
    new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private psService: PsFormService,
    private irFormService: IrFormService
  ) {
    this.oldStrategyForm = this.fb.group({
      projectStartDate: [null],
      monthFromProjectStart: [null],
      projectStrategyStart: [null],
      projectEstimatedStrategyEnd: [null],
      estimatedRevenue: [null],
      effectiveRevenue: [null],
      deviationRevenue: [null],
    });
    this.activeStrategyForm = this.fb.group({
      projectObjective: this.fb.group({
        idProjectObjective: [null],
        idCustomer: [null],
        idProduct: [null],
        idProject: [null],
        productName: [{ value: null, disabled: true }],
        idUser: [null],
        dateEnd: [null],
        revenue: [null],
      }),
      marketProjectObjective: this.fb.array([]),
      channelProjectObjective: this.fb.array([]),
      idsChannelsSelected: [null],
      idsMarketsSelected: [null],
      confirmStrategy: [null],
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (project: Project) => {
          this.startDate = new Date(
            project?.psStrategy?.activeStrategy?.projectStrategyStart
          );
          this.endDate = new Date(
            project?.psStrategy?.activeStrategy?.projectObjectiveCustomDTO?.projectObjective?.dateEnd
          );
          if (
            project?.psStrategy?.activeStrategy?.projectObjectiveCustomDTO
              ?.projectObjective?.dateEffectiveEnd
          ) {
            this.effectiveEndDateOrToday = new Date(
              project?.psStrategy?.activeStrategy?.projectObjectiveCustomDTO?.projectObjective?.dateEffectiveEnd
            );
          } else {
            this.effectiveEndDateOrToday = new Date();
          }
          this.channelsType = project?.channelsType;
          this.dataIsReady = true;
          this.activeMarketProjectObjective.clear();
          this.activeChannelProjectObjective.clear();
          this.activeIdsMarketsSelected.setValue([]);
          this.activeIdsChannelsSelected.setValue([]);

          if (
            project?.psStrategy?.activeStrategy?.projectObjectiveCustomDTO
              ?.projectObjective
          ) {
            this.oldStrategyForm
              .get('projectEstimatedStrategyEnd')
              .setValue(
                project?.psStrategy?.activeStrategy?.projectObjectiveCustomDTO
                  ?.projectObjective?.dateEnd
              );
            this.oldStrategyForm
              .get('projectEstimatedStrategyEnd')
              .updateValueAndValidity();
          }
          if (project?.psStrategy?.activeStrategy) {
            this.oldStrategyForm.patchValue(project.psStrategy.activeStrategy);
            this.oldStrategyForm.disable();
          }

          if (project?.psStrategy?.activeStrategy?.projectObjectiveCustomDTO) {
            this.activeStrategyForm.patchValue(
              project.psStrategy.activeStrategy.projectObjectiveCustomDTO
            );
            project.psStrategy?.activeStrategy?.projectObjectiveCustomDTO?.marketProjectObjective?.forEach(
              (market) => {
                this.addActiveMarketForStrategy(market);
              }
            );
            this.activeIdsMarketsSelected.updateValueAndValidity();
            project.psStrategy?.activeStrategy?.projectObjectiveCustomDTO?.channelProjectObjective?.forEach(
              (channel) => {
                let desc: string;
                this.channelsType.find((chann: IChannelsType) => {
                  desc = chann.description;
                  return chann.idChannelType === channel.idChannelType;
                });
                this.addActiveChannelForStrategy(channel, desc);
              }
            );
          }
          if (this.isReadOnly) {
            this.activeStrategyForm.disable();
          }
          this.startDataOnInit = this.activeStrategyForm.getRawValue();
        },
      })
    );
  }

  get idProjectObjective(): number {
    return this.activeStrategyForm.get('projectObjective.idProjectObjective')
      .value;
  }

  get activeMarketProjectObjective(): FormArray {
    return this.activeStrategyForm.get('marketProjectObjective') as FormArray;
  }

  get activeIdsMarketsSelected(): FormControl {
    return this.activeStrategyForm.get('idsMarketsSelected') as FormControl;
  }

  get activeChannelProjectObjective(): FormArray {
    return this.activeStrategyForm.get('channelProjectObjective') as FormArray;
  }

  get activeIdsChannelsSelected(): FormControl {
    return this.activeStrategyForm.get('idsChannelsSelected') as FormControl;
  }

  addActiveMarketForStrategy(market?: any): void {
    this.activeMarketProjectObjective.push(
      this.fb.group({
        idMarketProjectObjective: [market?.idMarketProjectObjective || null],
        idCountry: [market?.idCountry || market?.value || null],
        country: [market?.country || market?.viewValue || null],
        idProjectObjective: [this.idProjectObjective || null],
        selected: [market?.selected || null],
      })
    );
    if (market && market.selected) {
      this.activeIdsMarketsSelected.value.push(
        market?.idCountry || market?.value
      );
    }
  }

  addSelectedActiveMarket(index?: number): void {
    this.activeMarketProjectObjective.at(index).get('selected').setValue(true);
  }

  removeSelectedActiveMarket(index?: number): void {
    this.activeMarketProjectObjective.at(index).get('selected').setValue(false);
  }

  addActiveChannelForStrategy(channel?: any, description?: string): void {
    this.activeChannelProjectObjective.push(
      this.fb.group({
        idChannelProjectObjective: [channel?.idChannelProjectObjective || null],
        idChannelType: [channel?.idChannelType || null],
        channelSpecification: [description || null],
        idProjectObjective: [this.idProjectObjective || null],
      })
    );
    if (channel) {
      this.activeIdsChannelsSelected.value.push(channel?.idChannelType);
    }
  }

  handleTrendClass(trend: any): string {
    let trendClass: string = 'trend-icon-no-color';
    if (trend && trend > 0) {
      trendClass = 'trend-icon-green';
    }
    if (trend && trend < 0) {
      trendClass = 'trend-icon-red';
    }
    return trendClass;
  }

  handleTrend(trend: any): string {
    let trendString: string = 'trending_flat';
    if (trend && trend > 0) {
      trendString = 'trending_up';
    }
    if (trend && trend < 0) {
      trendString = 'trending_down';
    }
    return trendString;
  }

  handleActiveMarketStrategy(option, i): void {
    if (option._selected) {
      this.addSelectedActiveMarket(i);
    }
    if (!option._selected) {
      this.removeSelectedActiveMarket(i);
    }
  }

  private async _checkActiveSelectedMarket(): Promise<void> {
    if (this.activeIdsMarketsSelected.value.length === 0) {
      this.activeIdsMarketsSelected.setValidators([Validators.required]);
      this.activeIdsMarketsSelected.markAsTouched();
      this.activeIdsMarketsSelected.updateValueAndValidity();
    }
  }

  saveCurrentStrategy(): void {
    if (this.activeStrategyForm.dirty && this.activeStrategyForm.valid) {
      const body: any = {
        activeMarket: this.activeMarketProjectObjective.getRawValue(),
      };
      this.saveCurrentStrategyEmitter.emit(body);
    }
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(
        this.activeStrategyForm.getRawValue(),
        this.irFormService.replacer
      )
    );
  }

  getExpectedMonthsDifference(): number {
    let months: number;
    months = (this.endDate?.getFullYear() - this.startDate?.getFullYear()) * 12;
    months -= this.startDate?.getMonth();
    months += this.endDate?.getMonth();
    return months <= 0 ? 0 : months;
  }

  getActuallyMonthsDifference(): number {
    let months: number;
    months =
      (this.effectiveEndDateOrToday?.getFullYear() -
        this.startDate?.getFullYear()) *
      12;
    months -= this.startDate?.getMonth();
    months += this.effectiveEndDateOrToday?.getMonth();
    return months <= 0 ? 0 : months;
  }
}
