import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { currencyDefaultConfig, MARKET_TYPE } from 'src/app/config';
import { ICountryMarketObjective } from 'src/app/models/markets';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  IInterchangeData,
  IProductsServices,
} from 'src/app/models/products-services';
import { Project } from 'src/app/models/project';
import {
  ICustomerStrategy,
  IActionPlan,
  IChannelStrategy,
} from 'src/app/models/strategy';
import { NgxCurrencyModule } from 'ngx-currency';
import { ICountries } from 'src/app/models/countries';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastStatus } from 'src/app/models/utility';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { LayoutService } from 'src/app/services/layout/layout.service';
import {
  IChannelSpecification,
  IChannelsType,
} from 'src/app/models/channels-type';
import { ChannelsManagementDialogComponent } from '../channels-management-dialog/channels-management-dialog.component';

@Component({
  selector: 'app-strategy-form',
  templateUrl: './strategy-form.component.html',
  styleUrls: ['./strategy-form.component.scss'],
})
export class StrategyFormComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  strategyForm: FormGroup;
  projectObjective: FormGroup;
  project: Project;
  selectedProduct: IProductsServices;
  countryMarketObjectives: ICountryMarketObjective[] = [];
  currentYear: number = new Date().getFullYear();
  last10Year: number[] = [];
  private subscriptions: Subscription = new Subscription();
  // private interchangeTypologyDataSubject: BehaviorSubject<IInterchangeData[]> = new BehaviorSubject<IInterchangeData[]>([]);

  selectedMarkets: IInterchangeData[] = [];
  selectedCountries: number[] = [];
  countries: ICountries[];
  enableDescription: boolean = false;

  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;

  marketType: any = MARKET_TYPE;

  haveAnalysis = false;
  startDataOnInit: any;

  totalWeightedAverage: any[] = [];
  initialMarketPriority: number = 1;
  initialChannelPriority: number = 1;

  @ViewChild('productSelection') productSelection: MatSelect;
  @ViewChildren('selectedCountry') selectedCountry: QueryList<any>;
  @ViewChildren('visualizedCountry') visualizedCountry: QueryList<any>;

  @Input() translations: any;
  @Input() initialValues: Observable<Project>;
  @Input() channelTypesAndSpecifications: Observable<any>;
  @Input() index: any;
  @Input() tabIndex: any;
  @Input() isSupervisor: boolean;
  @Input() isLabManager: boolean;
  @Input() isIspDelivered: boolean;
  @Input() isLabManagerInternal: boolean;
  @Input() isReadOnly: boolean;
  @Input() canModifyStrategy: boolean;
  @Input() isManagerAndSupervisor: boolean;

  channelsType: IChannelsType[];
  channelsSpecifications: IChannelSpecification[];

  @Output() saveStrategyEmitter: EventEmitter<ICustomerStrategy> =
    new EventEmitter();
  @Output() sendIspEmitter: EventEmitter<any> = new EventEmitter();
  @Output() rejectIspEmitter: EventEmitter<any> = new EventEmitter();
  @Output() approveIspEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private common: CommonService,
    private cdref: ChangeDetectorRef,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private irFormService: IrFormService,
    private dialog: MatDialog
  ) {
    this.strategyForm = this.fb.group({
      projectObjective: this.fb.group({
        idProjectObjective: [null],
        idCustomer: [null],
        idProduct: [null],
        idProject: [null],
        productName: [null],
        idUser: [null],
        dateEnd: [null, [Validators.required]],
        revenue: [null, [Validators.required]],
      }),
      marketProjectObjective: this.fb.array([]),
      channelProjectObjective: this.fb.array([]),
      // channelDistributionStrategy: this.fb.array([]),
      // customChannelDistributionStrategy: this.fb.array([]),
      idsMarketsSelected: [{ value: null, disabled: true }],
      actionPlan: this.fb.array([]),
      leadGeneration: [null],
    });
  }

  get idProjectObjective(): number {
    return this.strategyForm.get('projectObjective').get('idProjectObjective')
      .value;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.channelTypesAndSpecifications?.subscribe({
        next: (channelTypesAndSpecifications: any) => {          
          this.channelsType = channelTypesAndSpecifications.types;
          this.channelsSpecifications = channelTypesAndSpecifications.specifications;
        }
      })
    );
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (project: Project) => {
          this.project = project;
          this.initializeStrategy(project?.strategy);
          for (let i = 0; i < 10; i++) {
            this.last10Year.push(this.currentYear - i);
          }
          if (this.isReadOnly) {
            this.strategyForm.disable();
          }
          this.startDataOnInit = this.strategyForm.getRawValue();
          delete this.startDataOnInit.channelDistributionStrategy;
          delete this.startDataOnInit.customChannelDistributionStrategy;
          // this.calculateWeightedAverage();
        },
      })
    );
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  initializeStrategy(strategy: ICustomerStrategy): void {
    this.strategyForm.reset();
    this.strategyForm.get('projectObjective').reset();
    this.marketProjectObjective.clear();
    this.channelProjectObjective.clear();
    this.idsMarketsSelected.setValue([]);
    if (strategy?.projectObjective) {
      this.strategyForm.patchValue(strategy);
      this.haveAnalysis = true;
      this.strategyForm
        .get('projectObjective')
        .get('productName')
        .setValue(strategy?.projectObjective?.productName);
      this.strategyForm
        .get('projectObjective')
        .get('productName')
        .updateValueAndValidity();
      this.idsMarketsSelected.setValue([]);
      strategy?.marketProjectObjective.forEach((market) => {
        this.project.marketAnalysis.interchangeTrendBalance.forEach(
          (country) => {
            if (country.idCountry === market.idCountry) {
              market.idMarketType = +country?.idMarketType;
              market.toIncrease = country?.toIncrease;
            }
          }
        );
        this.addMarketForStrategy(market);
      });
      this.marketProjectObjective.controls.sort(
        (a: FormGroup, b: FormGroup) =>
          a.controls.priority.value - b.controls.priority.value
      );
      this.initialMarketPriority = 1;
      this.calculateWeightedAverage();
      const result: any[] = [];
      const supportResult: number[] = [];
      const supportResult2: number[] = [];
      strategy?.channelProjectObjective?.forEach(
        (channel: IChannelStrategy) => {
          result.push(channel);
          // supportResult.push(channel?.idSpecificationChannelType);
          // supportResult2.push(channel?.idChannelType);
        }
      );
      if (!strategy?.channelProjectObjective?.length) {
        strategy?.distributionChannel?.forEach((channel: IChannelStrategy) => {
          result.push(channel);
        });
      } else {
        strategy?.distributionChannel?.forEach(
          (distribChannel: IChannelStrategy) => {
            if (
              !result.some((item: any) => {
                return (
                  distribChannel.idChannelType === item.idChannelType &&
                  distribChannel.idSpecificationChannelType ===
                    item.idSpecificationChannelType
                );
              })
            ) {
              result.push(distribChannel);
            }
            // strategy?.channelProjectObjective.forEach(
            //   (objChannel: IChannelStrategy) => {
            //     if (
            //       distribChannel?.idSpecificationChannelType ===
            //         objChannel?.idSpecificationChannelType &&
            //       distribChannel?.idChannelType === objChannel?.idChannelType
            //     ) {
            //     } else {
            //       if (
            //         !supportResult2.includes(distribChannel.idChannelType) &&
            //         !supportResult.includes(
            //           distribChannel.idSpecificationChannelType
            //         )
            //       ) {
            //         result.push(distribChannel);
            //         supportResult.push(
            //           distribChannel.idSpecificationChannelType
            //         );
            //         supportResult2.push(distribChannel.idChannelType);
            //       }
            //     }
            //   }
            // );
          }
        );
      }
      const mappedResult = result?.map(
        (channel: IChannelStrategy): IChannelStrategy => ({
          idProjectObjective: channel?.idProjectObjective,
          idChannelProjectObjective: channel?.idChannelProjectObjective,
          idChannelType: channel?.idChannelType,
          idSpecificationChannelType: channel?.idSpecificationChannelType,
          levelOfInterest: channel?.levelOfInterest,
          priority: channel?.priority,
          selectedForStrategy: channel?.idChannelProjectObjective
            ? true
            : false,
        })
      );
      const remainingMappedResult = [];
      mappedResult?.forEach((channel: IChannelStrategy) => {
        if (channel.selectedForStrategy) {
          this.addChannelDistributionStrategy(channel, false);
        } else {
          remainingMappedResult.push(channel);
        }
      });
      remainingMappedResult
        .sort((a: IChannelStrategy, b: IChannelStrategy) => {
          return b.levelOfInterest - a.levelOfInterest;
        })
        .forEach((sortedChannel: IChannelStrategy) => {
          this.addChannelDistributionStrategy(sortedChannel, false);
        });
      this.initialChannelPriority = 1;
      // strategy?.channelProjectObjective.forEach((channel) => {
      //   this.addInitialChannelStrategy(channel);
      // });
      // strategy?.distributionChannel.forEach((channel) => {
      //   this.addChannelDistributionStrategy(channel);
      // });
    }
    // if (strategy?.actionPlan) {
    //   this.initializeActionPlan(
    //     strategy?.actionPlan,
    //     this.project?.customer?.idCustomer
    //   );
    // }
    this.initializeActionPlan(
      strategy?.actionPlan,
      this.project?.customer?.idCustomer
    );
    this.strategyForm.markAsPristine();
    this.strategyForm.markAsUntouched();
  }

  setCurrencyMask(value: number): any {
    const curr = value.toLocaleString('it-IT');
    return curr;
  }

  // selectCountry(
  //   checked: boolean,
  //   country: ICountryMarketObjective,
  //   index: number
  // ): void {
  //   if (checked) {
  //     this.selectedCountries.push(country.idCountry);
  //   } else {
  //     this.selectedCountries = this.selectedCountries.filter(
  //       (item: number): boolean => item !== country.idCountry
  //     );
  //   }
  // }

  // checkCountries(country: ICountryMarketObjective): boolean {
  //   let ret: boolean = false;
  //   for (const selected of this.selectedCountries) {
  //     if (selected === country.idCountry) {
  //       ret = true;
  //       break;
  //     }
  //   }
  //   return ret;
  // }

  saveStrategy(): void {
    if (this._checkStrategyForm()) {
      if (this.strategyForm.valid) {
        const channelProjObj: IChannelStrategy[] = [];
        this.channelProjectObjective
          .getRawValue()
          .forEach((channel: IChannelStrategy) => {
            if (channel?.selectedForStrategy) {
              delete channel?.selectedForStrategy;
              delete channel?.isCustom;
              channelProjObj.push(channel);
            }
          });
        const body: ICustomerStrategy = {
          projectObjective: this.strategyForm.get('projectObjective').value,
          marketProjectObjective: this.marketProjectObjective.getRawValue(),
          channelProjectObjective: channelProjObj,
          actionPlan: this.actionPlan.getRawValue(),
        };
        this.saveStrategyEmitter.emit(body);
      } else {
        this.common.showToast(
          this.translations.StrategyTargetMarketError,
          ToastStatus.warning,
          3000
        );
      }
    }
  }

  get marketProjectObjective(): FormArray {
    return this.strategyForm.get('marketProjectObjective') as FormArray;
  }

  get idsMarketsSelected(): FormControl {
    return this.strategyForm.get('idsMarketsSelected') as FormControl;
  }

  calculateWeightedAverage(): void {
    let countries = this.project?.matrixMarketAnalysis?.countries;
    this.totalWeightedAverage = [];
    if (countries?.length) {
      const standardWeightValues: number[] = [];
      this.project.matrixMarketAnalysis.indexMarketObjective.forEach(
        (index) => {
          standardWeightValues.push(index.weight);
        }
      );
      const standardWeightedAverage: any[] = [];
      for (let i = 0; i < standardWeightValues.length; i++) {
        let index = 0;
        this.project.matrixMarketAnalysis.indexMarketObjective[
          i
        ].countryMarketObjective.forEach(
          (countryMarket: ICountryMarketObjective) => {
            if (countries.includes(countryMarket.idCountry)) {
              if (!standardWeightedAverage[index]?.weightedAverage) {
                standardWeightedAverage[index] = { weightedAverage: 0 };
              }
              standardWeightedAverage[index].weightedAverage +=
                countryMarket.value * standardWeightValues[i];
              standardWeightedAverage[index].idCountry =
                countryMarket.idCountry;
              index++;
            }
          }
        );
      }
      standardWeightedAverage.forEach((average) => {
        if (average.weightedAverage > 0) {
          average.weightedAverage /= 100;
        } else {
          average.weightedAverage = 0;
        }
        this.totalWeightedAverage.push(average);
      });
      this.marketProjectObjective.getRawValue().forEach((el) => {
        standardWeightedAverage.forEach((elem) => {
          if (el.idCountry === elem.idCountry) {
            elem.priority = el.priority;
          }
        });
      });
      this.totalWeightedAverage.sort((a, b) => a.priority - b.priority);
    }
  }

  addMarketForStrategy(market?: any): void {
    this.marketProjectObjective.push(
      this.fb.group({
        idMarketProjectObjective: [market?.idMarketProjectObjective || null],
        idCountry: [market?.idCountry || market?.value || null],
        country: [market?.country || null],
        idProjectObjective: [this.idProjectObjective || null],
        idMarketType: [market?.idMarketType || null],
        toIncrease: [market?.toIncrease || null],
        priority: [market?.priority || this.initialMarketPriority++],
      })
    );
    if (market) {
      this.idsMarketsSelected.value.push(market?.idCountry || market?.value);
    }
  }

  removeMarketForStrategy(market?: any): void {
    let marketIdProjectObjective;
    let marketIndex;
    this.marketProjectObjective.value.forEach((element, i) => {
      if (element.idCountry === market?.value) {
        marketIdProjectObjective = element.idMarketProjectObjective;
        marketIndex = i;
      }
    });
    if (marketIdProjectObjective) {
      // this.deleteMarketProjectObjectiveEmitter.emit(marketIdProjectObjective);
    }
    this.marketProjectObjective.removeAt(marketIndex);
  }

  handleMarketStrategy(option): void {
    if (option._selected) {
      this.addMarketForStrategy(option);
    }
    if (!option._selected) {
      this.removeMarketForStrategy(option);
    }
  }

  get channelProjectObjective(): FormArray {
    return this.strategyForm.get('channelProjectObjective') as FormArray;
  }

  get channelDistributionStrategy(): FormArray {
    return this.strategyForm.get('channelDistributionStrategy') as FormArray;
  }

  get customChannelDistributionStrategy(): FormArray {
    return this.strategyForm.get(
      'customChannelDistributionStrategy'
    ) as FormArray;
  }

  // addInitialChannelStrategy(channel?: IChannelStrategy): void {
  //   this.channelProjectObjective.push(
  //     this.fb.group({
  //       idChannelProjectObjective: [channel?.idChannelProjectObjective || null],
  //       idChannelType: [channel?.idChannelType || null, [Validators.required]],
  //       idProjectObjective: [this.idProjectObjective || null],
  //       channelDescription: [
  //         channel?.channelDescription || null,
  //         [Validators.required],
  //       ],
  //       channelSpecification: [
  //         channel?.channelSpecification || null,
  //         [Validators.required],
  //       ],
  //     })
  //   );
  // }

  addChannelDistributionStrategy(
    channel?: IChannelStrategy,
    isCustom?: boolean
  ): void {
    this.channelProjectObjective.push(
      this.fb.group({
        idChannelProjectObjective: [channel?.idChannelProjectObjective || null],
        idChannelType: [
          {
            value: channel?.idChannelType || null,
            disabled: this.isReadOnly || !isCustom ? true : false,
          },
          [Validators.required],
        ],
        idSpecificationChannelType: [
          {
            value: channel?.idSpecificationChannelType || null,
            disabled:
              this.isReadOnly || !isCustom || !channel?.idChannelType
                ? true
                : false,
          },
          [Validators.required]
        ],
        idProjectObjective: [this.idProjectObjective || null],
        // channelDescription: [
        //   {
        //     value: channel?.channelDescription || null,
        //     disabled: this.isReadOnly || !isCustom ? true : false,
        //   },
        //   [Validators.required],
        // ],
        // channelSpecification: [
        //   {
        //     value: channel?.channelSpecification || null,
        //     disabled: this.isReadOnly || !isCustom ? true : false,
        //   },
        //   [Validators.required],
        // ],
        selectedForStrategy: [
          {
            value: channel?.selectedForStrategy || (isCustom ? true : false),
            disabled: this.isReadOnly || isCustom,
          },
        ],
        levelOfInterest: [
          {
            value: channel?.levelOfInterest || null,
            disabled: this.isReadOnly || !isCustom,
          },
          [Validators.required]
        ],
        isCustom: [{ value: isCustom, disabled: isCustom }],
        priority: [
          channel?.priority || null,
          channel?.selectedForStrategy || isCustom
            ? [Validators.required]
            : null,
        ],
      })
    );
  }

  // addCustomChannelDistributionStrategy(): void {
  //   this.customChannelDistributionStrategy.push(
  //     this.fb.group({
  //       idChannelType: [null, [Validators.required]],
  //       idProjectObjective: [this.idProjectObjective || null],
  //       channelDescription: [null, [Validators.required]],
  //       channelSpecification: [null, [Validators.required]],
  //     })
  //   );
  // }

  removeCustomChannelDistributionStrategy(channelIndex?: any): void {
    this.channelProjectObjective.removeAt(channelIndex);
  }

  toggleChannelPriority(e, channel: AbstractControl) {
    if (!e.checked) {
      channel.get('priority').setValue(null);
      channel.get('priority').setValidators(null);
      channel.get('priority').updateValueAndValidity();
    } else {
      channel.get('priority').setValidators(Validators.required);
      channel.get('priority').updateValueAndValidity();
    }
  }

  // isChannelChecked(channel?: IChannelStrategy): boolean {
  //   if (channel?.selectedForStrategy) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  //   // let isChecked: boolean = false;
  //   // for (const i in this.channelProjectObjective.value) {
  //   //   if (
  //   //     channel?.channelSpecification ===
  //   //     this.channelProjectObjective.value[i].channelSpecification
  //   //   ) {
  //   //     isChecked = true;
  //   //   }
  //   // }
  //   // return isChecked;
  // }

  // handleChannelForStrategy(
  //   event?: boolean,
  //   channel?: IChannelStrategy,
  //   index?: number
  // ): void {
  //   if (event) {
  //     this.channelProjectObjective
  //       .at(index)
  //       .get('selectedForStrategy')
  //       .setValue(true);
  //     this.channelProjectObjective
  //       .at(index)
  //       .get('selectedForStrategy')
  //       .updateValueAndValidity();
  //   } else {
  //     this.channelProjectObjective.removeAt(index);
  //   }
  //   // if (event) {
  //   //   this.channelProjectObjective.push(
  //   //     this.fb.group({
  //   //       idChannelProjectObjective: [channel?.idChannelProjectObjective],
  //   //       idChannelType: [channel?.idChannelType],
  //   //       idProjectObjective: [this.idProjectObjective],
  //   //       channelDescription: [channel?.channelDescription],
  //   //       channelSpecification: [channel?.channelSpecification],
  //   //     })
  //   //   );
  //   // } else {
  //   //   this.channelProjectObjective.value.forEach((element, i) => {
  //   //     if (channel?.channelSpecification === element.channelSpecification) {
  //   //       this.channelProjectObjective.removeAt(i);
  //   //     }
  //   //   });
  //   // }
  // }

  // handleChannelTypeSelect(
  //   channelItem: FormGroup,
  //   channelTypeOption: MatOption
  // ): void {
  //   if (channelItem.get('idChannelType').value === 1) {
  //     channelItem.get('channelDescription').setValue('');
  //     channelItem.get('channelDescription').updateValueAndValidity();
  //     channelItem.get('channelSpecification').setValue('');
  //     channelItem.get('channelSpecification').updateValueAndValidity();
  //   } else {
  //     channelItem
  //       .get('channelDescription')
  //       .setValue(channelTypeOption.viewValue);
  //     channelItem.get('channelDescription').updateValueAndValidity();
  //     channelItem
  //       .get('channelSpecification')
  //       .setValue(channelTypeOption.viewValue);
  //     channelItem.get('channelSpecification').updateValueAndValidity();
  //   }
  // }

  // handleChannelDescription(
  //   channelItem: FormGroup,
  //   channelDescription: MatInput
  // ): void {
  //   channelItem.get('channelSpecification').setValue(channelDescription.value);
  //   channelItem.get('channelSpecification').updateValueAndValidity();
  // }

  get actionPlan(): FormArray {
    return this.strategyForm.get('actionPlan') as FormArray;
  }

  get productPlan(): FormGroup {
    return this.actionPlan.at(0) as FormGroup;
  }

  get distributionPlan(): FormGroup {
    return this.actionPlan.at(1) as FormGroup;
  }

  get priceSellingPlan(): FormGroup {
    return this.actionPlan.at(2) as FormGroup;
  }

  get communicationMktgLgPlan(): FormGroup {
    return this.actionPlan.at(3) as FormGroup;
  }

  get otherActionPlan(): FormGroup {
    return this.actionPlan.at(4) as FormGroup;
  }

  initializeActionPlan(actionPlan: IActionPlan[], idCustomer: number): void {
    this.actionPlan.clear();
    for (let i = 0; i < 5; i++) {
      this.actionPlan.push(
        this.fb.group({
          idCustomerStrategy: [actionPlan[i]?.idCustomerStrategy || null],
          idCustomer: [idCustomer || null],
          idStrategyType: [i + 1],
          actionLm: [actionPlan[i]?.actionLm || this.translations.NoActionExpected],
          actionCustomer: [actionPlan[i]?.actionCustomer || this.translations.NoActionExpected],
        })
      );
    }
  }

  private _checkStrategyForm(): boolean {
    if (
      this.channelProjectObjective.getRawValue().some((item: any) => {
        return item.selectedForStrategy;
      })
    ) {
      return true;
    } else {
      this.common.showToast(
        this.translations.StrategyChannelError,
        ToastStatus.warning,
        3000
      );
      return false;
    }
  }

  checkDirtyForm(): boolean {
    const strategyBody: any = this.strategyForm.getRawValue();
    delete strategyBody.channelDistributionStrategy;
    delete strategyBody.customChannelDistributionStrategy;
    const result: boolean =
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(strategyBody, this.irFormService.replacer);
    return result;
  }

  checkInvalidForm(): boolean {
    this.strategyForm.markAllAsTouched();
    return this.strategyForm.invalid;
  }

  showChannelsManagementDialog(
    event: PointerEvent,
    type: string,
    idChannelType?: number
  ): void {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '900px';
    dialogConfig.panelClass = 'custom-dialog-container';
    if (type === 'channel') {
      dialogConfig.data = {
        managementType: 'channel',
        channels: this.channelsType,
        idCustomer: this.project.customer.idCustomer,
        translations: this.translations,
      };
    } else {
      dialogConfig.data = {
        managementType: 'channelSpecification',
        channelsSpecifications: this.channelsSpecifications.filter(
          (spec: IChannelSpecification) => {
            return spec.idChannelType === idChannelType;
          }
        ),
        idCustomer: this.project.customer.idCustomer,
        translations: this.translations,
        idChannelType,
      };
    }
    const dialogRef = this.dialog.open(
      ChannelsManagementDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'refresh') {
        if (type === 'channel') {
          this.subscriptions.add(
            this.irFormService
              .getChannelsTypeByIdCustomer(
                this.project.customer.idCustomer,
                false
              )
              .subscribe({
                next: (channels: IChannelsType[]) => {
                  this.channelsType = channels;
                  this.customChannelDistributionStrategy
                    .getRawValue()
                    .forEach((channel: any, i: number) => {
                      if (
                        !channels.some((ch: any) => {
                          return ch.idChannelType === channel.idChannelType;
                        })
                      ) {
                        this.customChannelDistributionStrategy
                          .at(i)
                          .get('idChannelType')
                          .setValue(null);
                        this.activateSpecificationsSelect(
                          new MatSelectChange(null, null),
                          i
                        );
                      }
                    });
                },
              })
          );
        } else {
          this.subscriptions.add(
            this.common
              .getSpecificationChannels(this.project.customer.idCustomer, false)
              .subscribe({
                next: (channelsSpec: IChannelSpecification[]) => {
                  this.channelsSpecifications = channelsSpec;
                  this.customChannelDistributionStrategy
                    .getRawValue()
                    .forEach((channel: any, i: number) => {
                      if (
                        !channelsSpec.some((ch: any) => {
                          return (
                            ch.idSpecificationChannelType ===
                            channel.idSpecificationChannelType
                          );
                        })
                      ) {
                        this.customChannelDistributionStrategy
                          .at(i)
                          .get('idSpecificationChannelType')
                          .setValue(null);
                      }
                    });
                },
              })
          );
        }
      }
    });
  }

  activateSpecificationsSelect(change: MatSelectChange, index: number): void {
    if (change.value) {
      this.channelProjectObjective
        .at(index)
        .get('idSpecificationChannelType')
        .enable();
    } else {
      this.channelProjectObjective
        .at(index)
        .get('idSpecificationChannelType')
        .disable();
    }
  }

  checkPriorityDuplicates(formType: string): void {
    let priorities: number[] = [];
    const form =
      formType === 'markets'
        ? 'marketProjectObjective'
        : 'channelProjectObjective';
    this[form].getRawValue().forEach((item) => {
      if (item.priority) {
        priorities.push(item.priority);
      }
    });
    let isDupe: boolean = false;
    if (priorities.length > 1) {
      for (let i = 0; i < priorities.length; i++) {
        for (let j = 0; j < priorities.length; j++) {
          if (i !== j) {
            if (priorities[i] === priorities[j]) {
              this[form].at(i).get('priority').setErrors({ incorrect: true });
              this[form].at(i).get('priority').markAsTouched();
              isDupe = true;
            }
          }
        }
        if (!isDupe) {
          this[form].at(i).get('priority').setErrors(null);
        }
        isDupe = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
