import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { ProjectDTO } from 'src/app/models/project';
import { LAB_MANAGER_SUPERVISOR, RI_STATUS_DESCRIPTION } from 'src/app/config';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-ir-rejected',
  templateUrl: './ir-rejected.component.html',
  styleUrls: ['./ir-rejected.component.css']
})
export class IrRejectedComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  irRejected: ProjectDTO[];
  translations: any;

  riStatus: RI_STATUS_DESCRIPTION;
  loader: boolean = false;
  isLabSupervisor: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private irFormService: IrFormService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.userProfile?.roles.map((role) => {
      if (role === LAB_MANAGER_SUPERVISOR){
        this.isLabSupervisor = true;
      }
    });
    this.subscriptions.add(this.route?.data?.subscribe(data => {
      this.riStatus = data?.riStatus;
      this.loader = true;
      this.getIrRejected();
    }));
  }

  getIrRejected(): void {
    this.subscriptions.add(
      this.irFormService.getProjectRejectedForSupervisorByStatusDescription(this.riStatus, true).subscribe((project: ProjectDTO[]) => {
      this.irRejected = project;
      this.loader = false;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
