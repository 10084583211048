<!-- <div class="wrap-1200">
  <div class="mb-30 ta-center">
    <span class="bold-text-color">
      {{ 'CRM_DETAIL.Projects' | translate }}
    </span>
  </div>
  <div class="mt-30 mb-60 px-15 crm-general-table">
    <app-table
      [dataSource]="projectTableDataSource"
      [columnsName]="projectTableColumnsName"
      [displayedColumns]="projectTableDisplayedColumns"
      [tableOptions]="projectTableOptions"
      [tableButtons]="projectTabTableButtons"
      [loader]="projectTableLoader"
    ></app-table>
  </div>
  <div *ngIf="true" class="px-15 mb-60 mt-60">
    <mat-card class="mat-elevation-z8 p-25">
      <div class="mb-30 ta-center">
        <span class="bold-text-color">
          {{ 'CRM_DETAIL.ImportInfo' | translate }} {{idProject}}
        </span>
      </div>
      <form appSrollOnError [formGroup]="cloneProjectForm" (ngSubmit)="onSubmit()">
        <mat-tab-group
          mat-align-tabs="center"
          dynamicHeight
          class="custom-mat-tab-group center-tab-group auto-width-tab-label hide-tab-paginators"
        >-->
          <!-- CONTACT TAB -->
          <!-- <mat-tab>
            <ng-template mat-tab-label>
              <span>{{ 'CRM_DETAIL.Contacts' | translate }}</span>
            </ng-template>
            <div class="row">
              <div class="col-5">
                <div class="my-30 px-15">
                  <app-table
                    [dataSource]="contactTableDataSource"
                    [columnsName]="contactTableColumnsName"
                    [displayedColumns]="contactTableDisplayedColumns"
                    [tableOptions]="contactTableOptions"
                    [loader]="tableLoader"
                  ></app-table>
                </div>
              </div>
              <div class="col-2">
                <div class="ta-center mt-70">
                  <button mat-raised-button color="primary" type="button">
                    <mat-icon>forward</mat-icon>
                    {{ 'CRM_DETAIL.Clone' | translate }}
                  </button>
                </div>
              </div>
              <div class="col-5">
                <div class="my-30 px-15">
                  <app-table
                    [dataSource]="contactParentTableDataSource"
                    [columnsName]="contactParentTableColumnsName"
                    [displayedColumns]="contactParentTableDisplayedColumns"
                    [tableOptions]="contactParentTableOptions"
                    [loader]="tableLoader"
                  ></app-table>
                </div>
              </div>
            </div>
            
          </mat-tab>
        </mat-tab-group>
        <div class="ta-center mt-30">
          <button mat-raised-button color="primary" type="submit">
            <mat-icon>save</mat-icon>
            {{ 'CRM_DETAIL.Save' | translate }}
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div> -->

<div class="wrap-1200-no-top-padding">
  <app-customer-info-panel *ngIf="idProject" [idProject]="idProject"></app-customer-info-panel>
  <mat-horizontal-stepper [linear]="true" labelPosition="bottom" #stepper (selectionChange)="selectionChange($event)">
    <mat-step [stepControl]="cloneProjectForm" [editable]="!enableProgresBar">
      <form [formGroup]="cloneProjectForm">
        <ng-template matStepLabel>{{ 'CRM_DETAIL.SelectProject' | translate }}</ng-template>
        <div class="ta-center">
          <span>
            ({{ 'CRM_DETAIL.ImportInfo' | translate }})
          </span>
        </div>
        <div class="mt-30 mb-60 crm-general-table">
          <!-- <app-table
            [dataSource]="projectTableDataSource"
            [columnsName]="projectTableColumnsName"
            [displayedColumns]="projectTableDisplayedColumns"
            [tableOptions]="projectTableOptions"
            [tableButtons]="projectTabTableButtons"
            [loader]="projectTableLoader"
          ></app-table> -->
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="contactsForm" [editable]="!enableProgresBar">
      <form [formGroup]="contactsForm">
        <ng-template matStepLabel>{{ 'CRM_DETAIL.SelectContacts' | translate }}</ng-template>
        <div class="ta-center">
          <span>
            ({{ 'CRM_DETAIL.ImportContactInfo' | translate }})
          </span>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="bold-text-color ta-center mt-40 mbm-30">
              <span>
                {{ 'CRM_DETAIL.ContactsToImport' | translate }}
              </span>
            </div>
            <div class="my-30">
              <!-- <app-table
                [dataSource]="contactTableDataSource"
                [columnsName]="contactTableColumnsName"
                [displayedColumns]="contactTableDisplayedColumns"
                [tableOptions]="contactTableOptions"
                [checkbox]="contactCheckbox"
                [loader]="tableLoader"
              ></app-table> -->
            </div>
          </div>
          <div class="col-6">
            <div class="bold-text-color ta-center mt-40 mbm-30">
              <span>
                {{ 'CRM_DETAIL.ContactsPresent' | translate }}
              </span>
            </div>
            <div class="my-30">
              <!-- <app-table
                [dataSource]="contactParentTableDataSource"
                [columnsName]="contactParentTableColumnsName"
                [displayedColumns]="contactParentTableDisplayedColumns"
                [tableOptions]="contactParentTableOptions"
                [loader]="tableLoader"
              ></app-table> -->
            </div>
          </div>
        </div>
        <div>
          <button mat-raised-button color="primary" class="mr-20" matStepperPrevious>{{ 'CRM_DETAIL.Back' | translate }}</button>
          <button mat-raised-button color="primary" (click)="checkContacts()">{{ 'CRM_DETAIL.Next' | translate }}</button>
        </div>
      </form>
    </mat-step>
    <mat-step [completed]="isStepCompleted">
      <ng-template matStepLabel>{{ 'CRM_DETAIL.ImportContacts' | translate }}</ng-template>
      <div class="ta-center mt-30 mb-30" *ngIf="enableProgresBar">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div class="ta-center">
        <span>
          <span>{{ 'CRM_DETAIL.ImportDoneInfo' | translate }}</span>
          <span class="bold-text-color"> {{contacts.controls.length}} </span>
          <span>{{(contacts.controls.length === 1 ? 'CRM_DETAIL.contact' : 'CRM_DETAIL.contacts') | translate }}</span>
        </span>
      </div>
      <div class="my-30">
        <!-- <app-table
          [dataSource]="importTableDataSource"
          [columnsName]="importParentTableColumnsName"
          [displayedColumns]="importParentTableDisplayedColumns"
          [tableOptions]="importParentTableOptions"
          [loader]="importTableLoader"
        ></app-table> -->
      </div>
      <div>
        <button mat-raised-button color="primary" [disabled]="enableProgresBar" class="mr-20" matStepperPrevious>{{ 'CRM_DETAIL.Back' | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="enableProgresBar" (click)="onSubmit()">{{ 'CRM_DETAIL.Import' | translate }}</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
