import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

/**
 * @description Scroll the view on the first invalid form element
 * @UsageNote It work only if the form have `ngSubmit` event emitter to trigger submit since it have a hotListner on submit
 */
@Directive({
  selector: '[appSrollOnError]'
})
export class SrollOnErrorDirective {

  @Output() scrollOnErrorEvent: EventEmitter<any> = new EventEmitter();

  constructor(private el: ElementRef) {}

  @HostListener('submit')
  onFormSubmit(): void {
    this.scrollOnErrorEvent.emit();
    setTimeout(() => {
      const invalidControl: HTMLElement = this.el.nativeElement.querySelector('.mat-form-field-invalid');
      if (invalidControl) {
        invalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 300);
  }

}
