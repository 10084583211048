import * as moment from 'moment';
import {
  Contract,
  DocumentDTO,
  IDocumentForTable,
} from 'src/app/models/contract';
import {
  BUYER_STATUS,
  BUYER_STATUS_DESCRIPTION,
  CUSTOMER_STATUS,
  DATE_PIKER_FORMATS,
  DEAL_STATE_NAME,
  DOCUMENT_TYPE,
  ID_COMMERCE_SECTORS,
  MARKET_TYPE,
  PRODUCT_TYPE,
  PS_VERSION,
  RI_STATUS,
  RI_STATUS_STRING,
} from 'src/app/config';
import { IMeeting, IMeetingForTable } from 'src/app/models/meeting';
import {
  IProjectCustomer,
  IProjectForTable,
  IProjectInfo,
  ProjectDTO,
} from 'src/app/models/project';
import {
  IInterchangeData,
  IInterchangeDataForTable,
  IProductsServices,
} from 'src/app/models/products-services';
import { IDistributionChannels, IMarkets } from 'src/app/models/markets';
import {
  DistributionChannel,
  DistributionChannelForm,
  IReferenceCompetitor,
} from 'src/app//models/reference-competitor';
import { Note } from 'src/app/models/note';
import { ICustomer, ICustomerForTable } from 'src/app/models/customer';
import {
  IContactActivity,
  IContactActivityForTable,
  IDeal,
  IDealForTable,
  IDealOpportunityForTable,
  IOpportunityForTable,
  IOtherContact,
  IOtherContactForTable,
} from 'src/app/models/contact';
import {
  ISearchProductDetail,
  ISearchProductForTable,
} from 'src/app/models/search-product';
import { IReport, IReportForTable } from '../models/report';

/**
 * @description Return the contratc after an elaboration
 * @param initialValue The contract not elaborated
 */
export function contract(initialValue: Contract): Contract {
  return {
    ...initialValue,
    dateInsert: moment(initialValue.dateInsert).format(
      DATE_PIKER_FORMATS.display.dateInput
    ),
    dateStartProject: moment(initialValue.dateStartProject).format(
      DATE_PIKER_FORMATS.display.dateInput
    ),
    // dateStart: moment(initialValue.dateStart).format(DATE_PIKER_FORMATS.display.dateInput),
    // dateEnd: moment(initialValue.dateEnd).format(DATE_PIKER_FORMATS.display.dateInput)
  };
}

/**
 * @description Return the meeting after elaboration for meeting-table
 * @param meeting The meeting not elaborated from getMeeting service
 */
export function fromMeetingsToMeetingsTable(
  meeting: IMeeting
): IMeetingForTable {
  return {
    date: moment(
      meeting.dateModify ? meeting.dateModify : meeting.dateInsert
    ).format(`${DATE_PIKER_FORMATS.parse.dateInput} HH:mm`),
    startDate: moment(meeting.startDate).format(
      `${DATE_PIKER_FORMATS.parse.dateInput} HH:mm`
    ),
    businessName: meeting.customerAppointmentSummary.businessName,
    telephoneNumber: meeting.customerAppointmentSummary.telephoneNumber,
    address: meeting.address,
    currentProject: meeting.currentProject,
    customerAppointmentSummary: meeting.customerAppointmentSummary,
  };
}

/**
 * @description Return the project in progress after elaboration for poject-in-progress-table
 * @param project The project-in-progress not elaborated from getIrProjectInProgress service
 */
export function fromProjectDtoToProjectTable(
  project: ProjectDTO,
  index: number,
  translations: any
): IProjectForTable {
  return {
    businessName: project?.customer?.businessName,
    telephoneNumber: project?.customer?.telephoneNumber,
    email: project?.customer?.email,
    address: `${project?.customer?.formattedAddress || ''} ${
      project?.customer?.city || ''
    }`,
    startWorking: project?.startWorking
      ? project?.startWorking
      : project?.startWorkTime,
    // startWorking: project?.startWorking
    //   ? moment(project?.startWorking).format(DATE_PIKER_FORMATS.parse.dateInput)
    //   : moment(project?.startWorkTime).format(
    //       `${DATE_PIKER_FORMATS.parse.dateInput} HH:mm`
    //     ),
    lastModified: project?.lastModified,
    idProject: project?.idProject,
    documents: project?.documents || [],
    document: project?.document || [],
    agent: project?.user
      ? `${project.user.name || ''} ${project.user.surname || ''}`
      : `${project?.name || ''} ${project?.surname || ''}`,
    idCustomer: project?.idCustomer,
    idUser: project.idUser,
    customer: project?.customer,
    documentType: null,
    riLvl: '',
    previewOwnerId: project?.previousOwnerId,
    status: `${translations[project?.projectStatusDesc]}`,
    psVersion: PS_VERSION[project.status],
    idProjectStatus: project?.idProjectStatus,
    projectNoteList: project?.projectNoteList,
    idProjectMaster: project?.idProjectMaster || '-',
    code: project?.code || '-',
    idSupervisor: project?.supervisor?.idUser,
    manager: project?.manager?.name + ' ' + project?.manager?.surname,
    idManager: project?.manager?.idUser,
    idAgent: project?.idAgent,
    idAgentSupervisor: project?.idAgentSupervisor,
    contactInfo: `${project?.customer?.email || ''}
    <br>
    ${project?.customer?.telephoneNumber || ''}`,
    agentOrManager: project?.manager?.name
      ? `${project?.manager?.name} ${project?.manager?.surname}`
      : `${project?.agent?.name} ${project?.agent?.surname}`,
  };
}

export function fromDocumentDtoToDocumentForTable(
  document: DocumentDTO
): IDocumentForTable {
  return {
    idDocument: document?.idDocument,
    content: document?.content,
    dateInsert: document?.dateInsert,
    dateModify: document?.dateModify,
    mimeType: document?.mimeType,
    name: document?.name,
    note: document?.note,
    path: document?.path,
    status: document?.status,
    type: DOCUMENT_TYPE[document?.type],
    userInsert: document?.userInsert,
    userModify: document?.userModify,
    userName: `${document?.user?.name} ${document?.user?.surname}`,
  };
}

export function fromCustomerProjectToCustomerTable(
  customerProject: ICustomer
): ICustomerForTable {
  return {
    idCustomer: customerProject?.idCustomer,
    businessName: customerProject?.businessName,
    address: `${customerProject?.address?.address || ''} ${
      customerProject?.address?.city || ''
    }`,
    email: customerProject?.email,
    telephoneNumber: customerProject?.telephoneNumber,
    dateInsert: moment(customerProject?.dateInsert).format(
      DATE_PIKER_FORMATS.display.dateInput
    ),
    isMyCustomer: customerProject?.isMyCustomer ? 'Yes' : 'No',
  };
}

/**
 * @description this function must be used in array.reduce method to split the product array in two arrays
 * @param param0 Is desired result as Array.prototype.reduce method doc
 * @param elem the elem elaborated
 */
export function SplitProductArray(
  product: IProductsServices[]
): [IProductsServices[], IProductsServices[]] {
  return product.reduce(
    (
      [sold, bought],
      elem: IProductsServices
    ): [IProductsServices[], IProductsServices[]] =>
      elem.idProductType === PRODUCT_TYPE.sold
        ? [[...sold, elem], bought]
        : PRODUCT_TYPE.bought
        ? [sold, [...bought, elem]]
        : [sold, bought],
    [[], []]
  );
}

/**
 * @description this function must be used in array.reduce method to split the markets array in four arrays
 * @param param0 Is desired result as Array.prototype.reduce method doc
 * @param elem the elem elaborated
 */
export function SplitMarketsArray(
  markets: IMarkets[]
): [IMarkets[], IMarkets[], IMarkets[], IMarkets[]] {
  return markets.reduce(
    (
      [presence, desired, hightPotential, unexplored],
      elem: IMarkets
    ): [IMarkets[], IMarkets[], IMarkets[], IMarkets[]] =>
      elem.idMarketType === MARKET_TYPE.MarketPresence
        ? [[...presence, elem], desired, hightPotential, unexplored]
        : // : elem.idMarketType === MARKET_TYPE.DesiredPresenceMarket ? [presence, [...desired, elem], hightPotential, unexplored]
        elem.idMarketType === MARKET_TYPE.HighPotentialMarket
        ? [presence, desired, [...hightPotential, elem], unexplored]
        : elem.idMarketType === MARKET_TYPE.HighPotentialFutureMarket
        ? [presence, desired, hightPotential, [...unexplored, elem]]
        : [presence, desired, hightPotential, unexplored],
    [[], [], [], []]
  );
}

export function SeparateCompetitors(competitors: IReferenceCompetitor[]): {
  [param: string]: IReferenceCompetitor[];
} {
  return competitors.reduce(
    (
      nationsCompetitors: { [param: string]: IReferenceCompetitor[] },
      elem: IReferenceCompetitor
    ) => {
      if (!nationsCompetitors[elem.idCountry]) {
        nationsCompetitors[elem.idCountry] = [];
      }
      nationsCompetitors[elem.idCountry].push(elem);
      return {
        ...nationsCompetitors,
        [elem.idCountry]: nationsCompetitors[elem.idCountry],
      };
    },
    {}
  );
}

/**
 * @description Split the array notes in an object of array notes
 * @param notes the notes array
 */
export function SplitNotesArray(notes: Note[]): { [key: string]: Note[] } {
  return notes.reduce((prevValue: { [key: string]: Note[] }, elem: Note) => {
    if (!prevValue[elem.riTab]) {
      prevValue[elem.riTab] = [];
    }
    prevValue[elem.riTab].push(elem);
    return { ...prevValue, [elem.riTab]: prevValue[elem.riTab] };
  }, {});
}

/**
 * @description Split the array notes in an object of array notes(return only unchecked)
 * @param notes the notes array
 */
export function SplitUncheckedNotesArray(notes: Note[]): {
  [key: string]: Note[];
} {
  return notes.reduce((prevValue: { [key: string]: Note[] }, elem: Note) => {
    if (!prevValue[elem.riTab]) {
      prevValue[elem.riTab] = [];
    }
    if (!elem.checked) {
      prevValue[elem.riTab].push(elem);
    }
    return { ...prevValue, [elem.riTab]: prevValue[elem.riTab] };
  }, {});
}

/**
 * @description Split the array distributions in an object of DistributionChannelForm
 * @param distributions array of Competitors Distributions
 */
// export function SplitCompetitorDistribution(
//   distributions: DistributionChannel[]
// ): DistributionChannelForm {
//   return distributions.reduce(
//     (prevValue: DistributionChannelForm, elem: DistributionChannel) => {
//       if (prevValue.idChannelType !== elem.idChannelType) {
//         prevValue = {
//           idChannelType: elem.idChannelType,
//           anagrafics: [],
//         };
//       }
//       prevValue.anagrafics.push(elem);
//       return {
//         ...prevValue,
//       };
//     },
//     {}
//   );
// }

// export function filterAndMapCompetitorDistributions(
//   distributions: DistributionChannel[]
// ): DistributionChannelForm[] {
//   const channels: number[] = [];
//   const filteredChannels: DistributionChannelForm[] = [];
//   const sortedDistributions = distributions.sort((a, b) => {
//     return (
//       a.idCompetitorDistributionChannel - b.idCompetitorDistributionChannel
//     );
//   });
//   sortedDistributions.forEach((distrib: DistributionChannel) => {
//     if (!channels.includes(distrib.idChannelType)) {
//       channels.push(distrib.idChannelType);
//       filteredChannels.push({
//         idChannelType: distrib.idChannelType,
//         anagrafics: [],
//       });
//     }
//     const elementIndex: number = filteredChannels.findIndex(
//       (channel: DistributionChannelForm) => {
//         return channel.idChannelType === distrib.idChannelType;
//       }
//     );
//     filteredChannels[elementIndex].anagrafics.push(distrib);
//   });
//   return filteredChannels;
// }

/**
 * @description Calculate the total of revenue share
 * @param array An array of Markets
 */
export function CalculateRevenueShare(array: IMarkets[]): number {
  return (
    100 -
    array.reduce((tot: number, elem: IMarkets) => tot + elem.revenueShare, 0)
  );
}

export function fromProductSearchToProductSearchTable(
  products: ISearchProductDetail[],
  translations: any
): ISearchProductForTable[] {
  const newList: any = products.map(
    (product: ISearchProductDetail): ISearchProductForTable => ({
      name: product?.name,
      description: product?.description,
      businessName: product?.businessName,
      country: product?.country,
      buyerReference: product?.buyerReference,
      type: translations[CUSTOMER_STATUS[product?.customerStatus]],
      telephone: product?.telephone,
      email: product?.email,
      valueYear: `€ ${new Intl.NumberFormat('it-IT').format(
        product?.valueYear
      )}`,
      quantityYear: `${new Intl.NumberFormat('it-IT').format(
        product?.quantityYear
      )} ${product?.uomName}`,
    })
  );
  return newList;
}

export function fromInterchangeDataToDataForTable(
  interchangeData: IInterchangeData
): IInterchangeDataForTable {
  return {
    ...interchangeData,
    idMarketType: interchangeData?.idMarketType,
    countryName: interchangeData?.countryName,
    marketTypeIcon:
      interchangeData?.idMarketType === MARKET_TYPE.MarketPresence
        ? 'check_icon'
        : interchangeData?.idMarketType === MARKET_TYPE.MarketPresence &&
          interchangeData?.toIncrease
        ? 'trash_icon'
        : '',
    marketTypeNameId: interchangeData?.idMarketType,
    benchmark: interchangeData?.benchmark,
    valueSaldo:
      Math.round((interchangeData?.saldo?.value + Number.EPSILON) * 100) / 100,
    positionSaldo: interchangeData?.saldo?.position,
    valueSaldoProCapite:
      Math.round(
        (interchangeData?.saldoProCapite?.value + Number.EPSILON) * 100
      ) / 100,
    positionSaldoProCapite: interchangeData?.saldoProCapite?.position,
    valueTrend:
      Math.round((interchangeData?.trend?.value + Number.EPSILON) * 100) / 100,
    positionTrend: interchangeData?.trend?.position,
    sortByPresenceMarket:
      interchangeData?.idMarketType === 1
        ? interchangeData?.toIncrease
          ? 2
          : 1
        : 0,
    sortByPotential:
      interchangeData?.idMarketType > 2
        ? interchangeData?.idMarketType === 3
          ? 2
          : 1
        : 0,
    sortByBenchmark: interchangeData?.benchmark
      ? workItemBenchmark(interchangeData?.benchmark)
      : 0,
    levelOfInterest: interchangeData?.levelOfInterest,
  };
}

export function workItemBenchmark(item: any): number {
  let result: number = 0;
  let fav: number;
  let sfav: number;
  let splittedString: string[];
  if (item?.includes(',')) {
    splittedString = item?.split(',');
    splittedString?.forEach((bench: string) => {
      if (bench.includes('F') && !bench.includes('S')) {
        if (bench.includes('()')) {
          fav = parseInt(
            bench.substring(bench.indexOf('(') + 1, bench.lastIndexOf(')')),
            10
          );
        } else {
          fav = 1;
        }
      } else if (bench.includes('SF')) {
        if (bench.includes('()')) {
          sfav = parseInt(
            bench.substring(bench.indexOf('(') + 1, bench.lastIndexOf(')')),
            10
          );
        } else {
          sfav = 1;
        }
      } else {
        result = 0;
      }
    });
  } else {
    if (item?.includes('F') && !item?.includes('S')) {
      if (item?.includes('()')) {
        fav = parseInt(
          item.substring(item.indexOf('(') + 1, item.lastIndexOf(')')),
          10
        );
      } else {
        fav = 1;
      }
    } else if (item?.includes('SF')) {
      if (item?.includes('()')) {
        sfav = parseInt(
          item.substring(item.indexOf('(') + 1, item.lastIndexOf(')')),
          10
        );
      } else {
        sfav = 1;
      }
    } else {
      result = 0;
    }
  }
  if (fav) {
    result = fav + 1000;
  }
  if (!fav && sfav) {
    result = sfav + 10;
  }
  return result;
}

export function fromContactToDataForTable(contact: any): any {
  return {
    idContact: contact?.idContact,
    businessName: contact?.businessName,
    country: contact?.country?.country,
    phone: contact?.telephoneNumber,
    email: contact?.email,
    buyerStatus:
      BUYER_STATUS_DESCRIPTION[
        `BUYER_STATUS_${contact?.buyerStatus?.idBuyerStatus}`
      ],
  };
}

export function fromContactMandatoryActivityToDataForTable(
  activity: IContactActivity
): IContactActivityForTable {
  return {
    idCustomer: activity?.customerSummaryDTO?.idCustomer,
    idContact: activity?.contactSummaryDTO?.idContact,
    company: activity?.customerSummaryDTO?.businessName,
    contact: activity?.contactSummaryDTO?.businessName,
    buyer: `${activity?.contactRefName ? activity?.contactRefName : ''} ${
      activity?.contactRefSurname ? activity?.contactRefSurname : ''
    }`,
    idBuyerStatus: activity?.contactSummaryDTO?.buyerStatus?.idBuyerStatus,
    buyerStatus:
      BUYER_STATUS_DESCRIPTION[
        `BUYER_STATUS_${activity?.contactSummaryDTO?.buyerStatus?.idBuyerStatus}`
      ],
    buyerPhone: activity?.contactRefTel,
    buyerEmail: activity?.contactRefEmail,
    country: activity?.contactSummaryDTO?.country?.country,
    dateActivity: moment(activity?.dateActivity).format(
      `${DATE_PIKER_FORMATS.display.dateInput} - HH:mm`
    ),
    previousDateFeedback: activity?.previousDateFeedback,
    previousFeedbackDescription: activity?.previousFeedbackDescription,
    mandatory: activity?.mandatory ? 'Yes' : 'No',
    idProject: activity?.idProject,
    dateFeedback: moment(activity?.dateFeedback).format(
      `${DATE_PIKER_FORMATS.parse.dateInput} - HH:mm`
    ),
    feedbackDescription: activity?.feedbackDescription,
  };
}

export function fromContactReportsToDataForTable(
  contactReport: IReport,
  ind: number
): IReportForTable {
  return {
    idOpportunity: contactReport?.idOpportunity,
    valueOfUnit: contactReport?.valueOfUnit,
    valueYear: contactReport?.valueYear,
    productDescription: contactReport?.productDescription,
    productEnglishDescription: contactReport?.productEnglishDescription,
    commerceSector: contactReport?.commerceSector,
    commerceSectorForTable: contactReport?.commerceSector?.name,
    productName: contactReport?.productName,
    quantity: contactReport?.quantity,
    quantityForTable: `${new Intl.NumberFormat('it-IT').format(
      contactReport?.quantity
    )}`,
    quantityYear: contactReport?.quantityYear,
    quantityYearForTable: `${new Intl.NumberFormat('it-IT').format(
      contactReport?.quantityYear
    )}`,
    unitOfMeasure: contactReport?.unitOfMeasure,
    unitOfMeasureForTable: contactReport?.unitOfMeasure?.name,
    valueOfUnitForTable: `€ ${new Intl.NumberFormat('it-IT').format(
      contactReport?.valueOfUnit
    )}`,
    valueYearForTable: `€ ${new Intl.NumberFormat('it-IT').format(
      contactReport?.valueYear
    )}`,
    userInsert: contactReport?.userInsert,
    dateInsert: contactReport?.dateInsert,
    dateInsertForTable: moment(contactReport?.dateInsert).format(
      DATE_PIKER_FORMATS.display.dateInput
    ),
    status: 'Report',
    index: ind,
    idContactReference: contactReport?.contactReference?.idContactReference,
  };
}

export function fromContactCompetitorsToDataForTable(
  contactCompetitor: any,
  ind: number
): any {
  return {
    idContactCompetitorCompany: contactCompetitor?.idContactCompetitorCompany,
    companyName: contactCompetitor?.companyName,
    country: contactCompetitor?.country?.country,
    valueRevenueYear: `€ ${new Intl.NumberFormat('it-IT').format(
      contactCompetitor?.valueRevenueYear
    )}`,
    product: `${new Intl.NumberFormat('it-IT').format(
      contactCompetitor?.product
    )}`,
    distribution: `${new Intl.NumberFormat('it-IT').format(
      contactCompetitor?.distribution
    )}`,
    priceSelling: `${new Intl.NumberFormat('it-IT').format(
      contactCompetitor?.priceSelling
    )}`,
    communicationMarketing: `${new Intl.NumberFormat('it-IT').format(
      contactCompetitor?.communicationMarketing
    )}`,
    index: ind,
  };
}

export function fromContactDealToDataForTable(
  deal: IDeal,
  ind: number
): IDealForTable {
  return {
    dateDeal: moment(deal?.dateDeal).format(
      DATE_PIKER_FORMATS.display.dateInput
    ),
    quantity: deal?.quantity,
    revenue: deal?.revenue,
    dealStateDesc: `DEAL_STATE_NAME.${DEAL_STATE_NAME[deal?.dealState?.name]}`,
    index: ind,
  };
}

export function fromOtherContactToDataForTable(
  contact: IOtherContact,
  ind: number
): IOtherContactForTable {
  return {
    businessName: contact?.businessName,
    buyerStatus: `BUYER_STATUS.${
      BUYER_STATUS[contact?.buyerStatus?.idBuyerStatus]
    }`,
    buyer: `${contact?.contactMainReference?.name} ${contact?.contactMainReference?.surname}`,
    country: contact?.country?.country,
    idCountry: contact?.country?.idCountry,
    email: contact?.contactMainReference?.email,
    idContact: contact?.idContact,
    province: contact?.province,
    telephoneNumber: contact?.contactMainReference?.tel,
    vip: contact?.vip,
    index: ind,
  };
}

export function fromProjectCustomerToDataForTable(
  project: IProjectCustomer,
  ind: number
): any {
  return {
    idProject: project?.idProject,
    businessName: project?.customer?.businessName,
    projectStatusCode: `${PS_VERSION[project?.projectStatusCode]}`,
    email: project?.customer?.email,
    code: project?.code,
    index: ind,
  };
}

export function fromDealOpportunityToDataForTable(deal: any, ind: number): any {
  return {
    commerceSector: deal?.commerceSector?.name,
    idCommerceSector: deal?.commerceSector?.idCommerceSector,
    productDescription: deal?.italianDescription,
    dateDeal: moment(deal?.dateDeal).format(
      DATE_PIKER_FORMATS.display.dateInput
    ),
    dealMatchedQuantity: `${new Intl.NumberFormat('it-IT').format(
      deal?.dealMatchedQuantity
    )}`,
    dealMatchedValue: `€ ${new Intl.NumberFormat('it-IT').format(
      deal?.dealMatchedValue
    )}`,
    dealStateDesc: `DEAL_STATE_NAME.${DEAL_STATE_NAME[deal?.dealState?.name]}`,
    dealDocuments: deal?.deal?.dealDocuments,
    note: deal.note,
    idDeal: deal.idDeal,
    dateInsert: deal.dateInsert,
    dateModify: deal.dateModify,
    index: ind,
    businessName: deal.contact.businessName,
    country: deal.contact.country.country,
    buyer: `${deal?.contact?.contactMainReference?.name || ''} ${
      deal?.contact?.contactMainReference?.surname || ''
    }
    <br>email: ${deal?.contact?.contactMainReference?.email || ''} <br>tel: ${
      deal?.contact?.contactMainReference?.tel || ''
    }`,
    owner: `${deal?.userByOwnerProjectIdMatched?.name || ''} ${
      deal?.userByOwnerProjectIdMatched?.surname || ''
    }`,
    sales: `${deal?.userManagerDealOpportunity?.name || ''} ${
      deal?.userManagerDealOpportunity?.surname || ''
    }
    <br>email: ${deal?.userManagerDealOpportunity?.email || ''} <br>tel: ${
      deal?.userManagerDealOpportunity?.telephone || ''
    }`,
    dealProductMatchedDesc: deal?.dealProductMatchedDesc,
    dealProductMatchedDescEn: deal?.dealProductMatchedDescEn,
    idDealOpportunityBusinessMatching: deal?.idDealOpportunityBusinessMatching,
    businessNameBuyer: `
      <strong>${deal?.contact?.businessName}</strong>
      <br>
      ${deal?.contact?.contactMainReference?.name || ''}
      ${deal?.contact?.contactMainReference?.surname || ''}
      <br>
      ${deal?.contact?.contactMainReference?.email || ''}
      <br>${deal?.contact?.contactMainReference?.tel || ''}
    `,
    manager: `
      ${deal?.userInfo?.name} ${deal?.userInfo?.surname}
      <br>
      ${deal?.userInfo?.telephone}
      <br>
      ${deal?.userInfo?.email}
    `,
  };
}

export function fromOpportunityToDataForTable(
  contactReport: any,
  ind: number
): any {
  return {
    idOpportunity: contactReport?.idOpportunity,
    commerceSector: contactReport?.commerceSector?.name,
    productName: contactReport?.productName,
    quantityYear: `${new Intl.NumberFormat('it-IT').format(
      contactReport?.opportunityMatchedQuantity
    )}`,
    unitOfMeasure: contactReport?.unitOfMeasure?.name,
    valueOfUnit: `€ ${new Intl.NumberFormat('it-IT').format(
      contactReport?.valueOfUnit
    )}`,
    valueYear: `€ ${new Intl.NumberFormat('it-IT').format(
      contactReport?.opportunityMatchedValue
    )}`,
    dateInsert: moment(contactReport?.dateInsert).format(
      DATE_PIKER_FORMATS.display.dateInput
    ),
    index: ind,
    businessName: contactReport.contact.businessName,
    country: contactReport.contact.country.country,
    buyer: `${contactReport?.contact?.contactMainReference?.name || ''} ${
      contactReport?.contact?.contactMainReference?.surname || ''
    }
    <br>email: ${
      contactReport?.contact?.contactMainReference?.email || ''
    } <br>tel: ${contactReport?.contact?.contactMainReference?.tel || ''}`,
    owner: `${contactReport?.userByOwnerProjectIdMatched?.name || ''} ${
      contactReport?.userByOwnerProjectIdMatched?.surname || ''
    }`,
    sales: `${contactReport?.userManagerOpportunity?.name || ''} ${
      contactReport?.userManagerOpportunity?.surname || ''
    }
    <br>email: ${contactReport?.userManagerOpportunity?.email || ''} <br>tel: ${
      contactReport?.userManagerOpportunity?.telephone || ''
    }`,
    opportunityProductMatchedDesc: contactReport?.opportunityProductMatchedDesc,
    opportunityProductMatchedDescEn:
      contactReport?.opportunityProductMatchedDescEn,
    idOpportunityBusinessMatching: contactReport?.idOpportunityBusinessMatching,
  };
}

export function fromBusinessMatchingToDataForTable(
  item: any,
  ind: number
): any {
  return {
    idBusinessMatching: item?.idBusinessMatching,
    businessNameMatched: item?.businessNameMatched,
    countryMatchedDesc: item?.countryMatchedDesc,
    hasContract: item?.hasContract ? 'Yes' : 'No',
    purchaseRef:
      `${item?.purchaseRefName || ''} ${item?.purchaseRefSurname || ''}` +
      `<br>tel: ${item?.purchaseRefTelephone || ''} <br>email: ${
        item?.purchaseRefEmail || ''
      }`,
    customerSellRef: `${item?.customerSellRefName || ''} ${
      item?.customerSellRefSurname || ''
    }
    <br>tel: ${item?.customerSellRefTelephone || ''} <br>email: ${
      item?.customerSellRefEmail || ''
    } `,
    index: ind,
    productDesc: item?.productDesc,
    productDescEn: item?.productDescEn,
    productMatchedQuantityYear: item?.productMatchedQuantityYear,
    productMatchedValueYear: item?.productMatchedValueYear,
  };
}

export function mappingServerDataToProjectsForTable(
  projects: IProjectInfo[],
  translations: any
): any {
  const newList: any = projects.map((project: IProjectInfo): any => ({
    ...project,
    lastWorkedDate: project?.lastModified,
    businessName: project?.customer?.businessName,
    telephone: project?.customer?.telephoneNumber,
    email: project?.customer?.email,
    address: `${project?.customer?.formattedAddress}, ${project?.customer?.city}`,
    version: PS_VERSION[project?.status],
    text: `${translations.WaitingSince} ${moment(
      project?.sentForApproval
    ).format('DD/MM/YYYY')}`,
    agent: `${project?.agent?.name} ${project?.agent?.surname}`,
    dlmanager: `${project?.manager?.name} ${project?.manager?.surname}`,
  }));
  return newList;
}
