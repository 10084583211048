import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { DocumentDTO } from 'src/app/models/contract';
import { ProjectDTO } from 'src/app/models/project';
import { IAgent, ILabManager } from 'src/app/models/roles';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { apiRootUrl, RI_STATUS } from 'src/app/config';
import { takeUntil } from 'rxjs/operators';
import { ICustomer, ICustomerContact } from 'src/app/models/customer';
import { LayoutService } from 'src/app/services/layout/layout.service';

@Component({
  selector: 'app-assign-project',
  templateUrl: './assign-project.component.html',
  styleUrls: ['./assign-project.component.scss'],
})
export class AssignProjectComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  translations: any;

  users: ILabManager[] | IAgent[] = [];

  form: FormGroup;
  project: ProjectDTO;
  labManagers: ILabManager[] = [];
  agents: IAgent[] = [];
  document: File;
  approvedBy: string = null;
  drawnUpBy: string = null;
  idApprovedBy: number;
  idDrawnUpBy: number;
  customer: ICustomer;
  customerAddress: string;
  customerImagePath: string;
  type: string;

  selectedLabManager: any;
  file: any;

  constructor(
    private irFormService: IrFormService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private layoutService: LayoutService
  ) {
    this.form = this.fb.group({
      idUser: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params: Params) => {
        this.project = { idProject: params.get('idProject') };
        this.type = params.get('type');
        this.subscriptions.add(
          this.irFormService
            .getProjectById(this.project.idProject)
            .subscribe((data: ProjectDTO) => {
              this.project = data;
              this.checkUser(this.project);
              this.subscriptions.add(
                this.irFormService
                  .getCustomerById(this.project.idCustomer)
                  .subscribe((customer: ICustomer) => {
                    this.customer = customer;
                    this.customerAddress =
                      customer?.address?.address +
                      ', ' +
                      customer?.address?.cap +
                      ' ' +
                      customer?.address?.city +
                      ', ' +
                      customer?.address?.province;
                    if (customer?.pathImage) {
                      this.customerImagePath = `${apiRootUrl}/customer/public?path=${customer.pathImage}`;
                    }
                    this.generateBreadCrumb();
                  })
              );
              if (this.type === 'assignDLManagerInternal') {
                this.subscriptions.add(
                  this.irFormService
                    .getLabManagerInternal()
                    .subscribe((dataInternal: ILabManager[]) => {
                      this.users = dataInternal;
                    })
                );
              }
              if (this.type === 'changeDLManager') {
                this.subscriptions.add(
                  this.irFormService
                    .getLabManager()
                    .subscribe((dataManager: ILabManager[]) => {
                      this.users = dataManager;
                      this.subscriptions.add(
                        this.irFormService
                          .getLabManagerInternal()
                          .subscribe((dataInternal: ILabManager[]) => {
                            this.users = this.users
                              .concat(dataInternal)
                              .filter(
                                (item) => item.idUser !== this.idDrawnUpBy
                              );
                            this.users.map((item) => {
                              item.roles.forEach((role) => {
                                if (role === 'LAB_MANAGER') {
                                  item.role = `(${this.translate.instant(
                                    'COMMON.DLManager'
                                  )})`;
                                }
                                if (role === 'LAB_MANAGER_INTERNAL') {
                                  item.role = `(${this.translate.instant(
                                    'COMMON.DLManagerInternal'
                                  )})`;
                                }
                              });
                            });
                          })
                      );
                    })
                );
              }
              if (this.type === 'changeAgent') {
                this.irFormService.getAgent().subscribe({
                  next: (data: IAgent[]) => {
                    this.users = data.filter((agent: IAgent) => {
                      return agent?.idUser !== this.idDrawnUpBy;
                    });
                  },
                });
              }
            })
        );
      })
    );
    this.subscriptions.add(
      this.translate.get('PROJECT_DETAIL').subscribe((translations) => {
        this.translations = translations;
      })
    );
  }

  generateBreadCrumb(): void {
    const breadcrumb: any[] = [
      {
        label: this.translate.instant('PAGES.customers'),
        path: '/customers',
        pageTitle: false,
      },
      {
        label: this.translate.instant('PAGES.customer'),
        path: '/customers/' + this.customer.idCustomer,
        pageTitle: false,
      },
    ];
    if (this.type === 'assignDLManagerInternal') {
      breadcrumb.push({
        label:
          this.translate.instant('COMMON.AssignDLManagerInternal') +
          ' - ' +
          this.customer?.businessName,
        path: null,
        pageTitle: true,
      });
    }
    if (this.type === 'changeDLManager') {
      breadcrumb.push({
        label:
          this.translate.instant('CUSTOMER.ChangeProjectDLManager') +
          ' - ' +
          this.customer?.businessName,
        path: null,
        pageTitle: true,
      });
    }
    if (this.type === 'changeAgent') {
      breadcrumb.push({
        label:
          this.translate.instant('CUSTOMER.ChangeProjectAgent') +
          ' - ' +
          this.customer?.businessName,
        path: null,
        pageTitle: true,
      });
    }
    this.subscriptions.add(this.layoutService.generateBreadcrumb(breadcrumb));
  }

  uploadDocument(document: File): void {
    this.document = document;
  }

  checkUser(project): void {
    if (
      [
        RI_STATUS.RiDelivered,
        RI_STATUS.RiApproved,
        RI_STATUS.IspDelivered,
        RI_STATUS.IspDelivered2,
        RI_STATUS.IspDelivered3,
        RI_STATUS.IspDelivered4,
        RI_STATUS.IspDelivered5,
        RI_STATUS.IspDelivered6,
      ].indexOf(project?.idProjectStatus) > -1
    ) {
      this.approvedBy = project?.user
        ? project?.user?.name + ' ' + project?.user?.surname
        : null;
      this.idApprovedBy = project?.user?.idUser;
      this.drawnUpBy = project?.previousUser
        ? project?.previousUser?.name + ' ' + project?.previousUser?.surname
        : null;
      this.idDrawnUpBy = project?.previousUser?.idUser;
    } else {
      this.drawnUpBy = project?.user
        ? project?.user?.name + ' ' + project?.user?.surname
        : null;
      this.idDrawnUpBy = project?.user?.idUser;
      this.approvedBy = project?.previousUser
        ? project?.previousUser?.name + ' ' + project?.previousUser?.surname
        : null;
      this.idApprovedBy = project?.previousUser?.idUser;
    }
  }

  save(): void {
    if (this.type === 'assignDLManagerInternal') {
      this.irFormService
        .createSubProject(this.project.idProject, this.form.get('idUser').value)
        .subscribe({
          complete: () => {
            this.router.navigate([`/customers/${this.customer.idCustomer}`]);
          },
        });
    }
    if (this.type === 'changeDLManager') {
      this.irFormService
        .changeOwner(this.project.idProject, this.form.get('idUser').value)
        .subscribe({
          complete: () => {
            this.router.navigate([`/customers/${this.customer.idCustomer}`]);
          },
        });
    }
    if (this.type === 'changeAgent') {
      this.irFormService
        .changeAgent(this.project.idProject, this.form.get('idUser').value)
        .subscribe({
          complete: () => {
            this.router.navigate([`/customers/${this.customer.idCustomer}`]);
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
