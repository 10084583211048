import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config, forkJoin, Observable, of } from 'rxjs';
import { ServiceExtension } from 'src/app/services/serviceExtension';
import { IChannel, IChannelMarket } from 'src/app/models/channels';
import { Contract, DocumentDTO } from 'src/app/models/contract';
import {
  ICustomer,
  ICustomerAndProject,
  ICustomerContact,
  ICustomers,
} from 'src/app/models/customer';
import { ICustomerInformation } from 'src/app/models/customer-information';
import {
  ICountryMarketObjective,
  ICustomIndex,
  IIndexMarketObjective,
  IMarkets,
  IMatrixMarketsAnalysis,
  MarketsAnalysisResponse,
} from 'src/app/models/markets';
import {
  ICnProductsService,
  IInterchangeData,
  IProductsServices,
  IProductWithoutBM,
  IRelevantRegistry,
} from 'src/app/models/products-services';
import {
  IAssignLabManager,
  IProjectCustomer,
  IProjectForTable,
  IProjectInfo,
  IProjectSections,
} from 'src/app/models/project';
import { ICountries } from 'src/app/models/countries';
import {
  IReferenceCompetitor,
  DistributionChannel,
  PresenceMarket,
  CompetitorProduct,
} from 'src/app/models/reference-competitor';
import { IAgent, ILabManager } from 'src/app/models/roles';
import { Note } from 'src/app/models/note';
import { Swot } from 'src/app/models/swot';
import { IReferentRole } from 'src/app/models/referent-role';
import { CustomerDescriptions } from 'src/app/models/customer-descriptions';
import { AuthService } from '../auth/auth.service';
import Config, {
  DOC_TYPE,
  RI_STATUS,
  RI_STATUS_DESCRIPTION,
} from 'src/app/config';
import { UnitsOfMeasure } from 'src/app/models/utility';
import { ExportResponse } from 'src/app/models/chart';
import {
  ICompanyLead,
  ILeadGeneration,
  ILeadGenerationActivity,
  ILeadProfile,
} from 'src/app/models/lead-generation';
import { ISectors } from 'src/app/models/sectors';
import { IProductMatching, KeyMatching } from 'src/app/models/product-matching';
import { Params } from '@angular/router';
import {
  ISearchProduct,
  ISearchProductDetail,
} from 'src/app/models/search-product';
import { IChannelsType } from 'src/app/models/channels-type';
import { ICustomerStrategy } from 'src/app/models/strategy';
import { IAppointment, IAppointmentReason } from 'src/app/models/appointment';
import * as moment from 'moment';
import { CommonService } from '../common/common.service';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/services/guard/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class IrFormService extends ServiceExtension {
  replacer: any = (key, value) => (value === '' ? null : value);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private common: CommonService,
    private errorHandler: ErrorHandlerService
  ) {
    super();
  }

  /**
   * @description Call PUT `/project/creation` API, to create Project from idCustomer
   * @param body the idCustomer who create project and project status(usually is 1 at creation)
   */
  createProject(body: IProjectInfo): Observable<IProjectInfo> {
    return this.http
      .post<IProjectInfo>(`${Config.apiRootUrl}/project/creation`, body)
      .pipe();
  }

  updateCustomer(body: ICustomer): Observable<ICustomer> {
    return this.http
      .put<ICustomer>(`${Config.apiRootUrl}/customer/${body.idCustomer}`, body)
      .pipe();
  }

  getAllCustomer(): Observable<ICustomer[]> {
    return this.http
      .get<ICustomer[]>(`${Config.apiRootUrl}/customer/all`)
      .pipe();
  }

  getAllCustomerByUser(
    idUserAgent: number,
    hideLoader: boolean = false
  ): Observable<ICustomers> {
    return this.http
      .get<ICustomers>(
        `${Config.apiRootUrl}/customer/allByUser` +
          `${idUserAgent ? '?idUserAgent=' + idUserAgent : ''}`,
        {
          headers: this._getHeaders(hideLoader),
        }
      )
      .pipe();
  }

  getCustomerById(idCustomer: number | string): Observable<ICustomer> {
    return this.http
      .get<ICustomer>(`${Config.apiRootUrl}/customer/${idCustomer}`)
      .pipe();
  }

  /**
   * @description Call GET `contract/project` API, to get Contract by idProject
   * @param idProject id of project associated to contract
   */
  getContractByIdProject(idProject: number | string): Observable<Contract> {
    return this.http
      .get<Contract>(`${Config.apiRootUrl}/contract/project/${idProject}`)
      .pipe();
  }

  createCustomerInformation(
    customerInformation: ICustomerInformation
  ): Observable<ICustomerInformation> {
    return this.http
      .post<ICustomerInformation>(
        `${Config.apiRootUrl}/customerInformation/create`,
        customerInformation
      )
      .pipe();
  }

  /**
   * @description Call `api/v1/getCustomerInformation` API to get CustomerInformation by idProject
   * @param idCustomer id of customer associated to customerInformation
   */
  getCustomerInformationByIdCustomer(
    idCustomer
  ): Observable<ICustomerInformation> {
    return this.http
      .get<ICustomerInformation>(
        `${Config.apiRootUrl}/customerInformation/getByIdCustomer/${idCustomer}`
      )
      .pipe();
  }

  /**
   * @description Call GET `api/v1/getCustomerInformation` API to get CustomerInformation by idProject
   * @param idCustomerInformation id associated to customerInformation
   */
  getCustomerInformationById(
    idCustomerInformation
  ): Observable<ICustomerInformation> {
    return this.http
      .get<ICustomerInformation>(
        `${Config.apiRootUrl}/customerInformation/${idCustomerInformation}`
      )
      .pipe();
  }

  /**
   * @description Call PUT `customerInformation/:idCustomer` API to update CustomerInformation
   * @param customerInformation body of customerInformation
   */
  updateCustomerInformation(
    customerInformation: ICustomerInformation
  ): Observable<void> {
    return this.http
      .put<void>(
        `${Config.apiRootUrl}/customerInformation/update/${customerInformation.idCustomerInformation}`,
        customerInformation
      )
      .pipe();
  }

  /**
   * @description API to delete a customerContact
   * @param idCustomerContact Id of the customerContact
   * @param hideLoader Set true to hide the full page loader
   */
  deleteCustomerContact(
    idCustomerContact: string | number,
    hideLoader: boolean = false
  ): Observable<ICustomerContact> {
    return this.http
      .delete<ICustomerContact>(
        `${Config.apiRootUrl}/customer/contact/${idCustomerContact}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description Call GET `document/download/:idDocument` API, to Download the document by Id
   * @param idDocument Id of document to download
   */
  downloadDocument(idDocument: number | string): Observable<Blob> {
    return this.http
      .get(`${Config.apiRootUrl}/document/download/${idDocument}`, {
        responseType: 'blob',
      })
      .pipe();
  }

  /**
   * @description Check the enabled functionalities of logged user and get all avaible entities of project
   * @param project the response of getProjectById( contains all necessary parameters )
   */
  getProjectEntities(
    project: IProjectInfo,
    hideLoader: boolean = false,
    isReadonly: boolean = false,
    isAdminOrDirection: boolean = false,
    isIsp: boolean = false
  ): Observable<IProjectSections> {
    let promises = {
      customer: this.getCustomerById(project.idCustomer),
      contract: this.getContractByIdProject(project.idProject),
      customerInformation: this.getCustomerInformationByIdCustomer(
        project.idCustomer
      ),
      productsServices: this.getProductsServicesByIdCustomer(
        project.idCustomer
      ),
      productsServicesWithoutBM: this.getProductsWithoutBM(project.idCustomer),
      markets: this.getMarketsById(project.idCustomer),
      channels: this.getChannelsByIdCustomer(project.idCustomer),
      referenceCompetitors: this.getReferenceCompetitorsDetailsByIdCustomer(
        project.idCustomer
      ),
      leadGeneration: this.getLeadGenerationByIdCustomer(project.idCustomer),
      notes: this.getNoteByProject(project.idProject),
      countries: this.getAllCountries(),
      unitsOfMeasure: this.getUnitsOfMeasure(false, true, project.idCustomer),
      commerceSector: this.getAllSectors(),
      // channelsType: this.getChannelsType(),
      channelsType: this.getChannelsTypeByIdCustomer(project.idCustomer),
      specificationsChannels: this.common.getSpecificationChannels(
        project.idCustomer
      ),
    };
    // If isp functionality is enabled add "getSwot Observable" to the forkJoin Object
    if (
      (this.authService.functionality.isp.enabled || isAdminOrDirection) &&
      isIsp
    ) {
      promises = {
        ...promises,
        ...{
          swot: this.getSwotByIdCustomer(project.idCustomer),
          competitorExportOrientation: this.getCompetitorExportOrientation(
            project.idCustomer
          ),
          customerDescription: this.getCustomerDescriptionByIdProject(
            project.idProject
          ),
          // evaluationConcurrency: this.getEvaluationConcurrency(
          //   project.idCustomer
          // ),
          marketAnalysis: this.getMarketsAnalysis(project.idProject),
          matrixMarketAnalysis: this.getMatrixMarketsAnalysis(
            project.idProject
          ),
          strategy: this.getStrategy(project.idProject, 9),
        },
      };
    }
    // if (!isReadonly) {
    //   promises = {
    //     ...promises,
    //     ...{ supervisorNotes: this.getSuperVisorNotes(project.idProject, true), }
    //   };
    // }
    return this.executeForkJoin(promises);
  }

  getProjectById(
    id: number | string,
    hideLoader: boolean = false
  ): Observable<IProjectInfo> {
    return this.http
      .get<IProjectInfo>(`${Config.apiRootUrl}/project/${id}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getAllProjectByUser(): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(`${Config.apiRootUrl}/project/working`)
      .pipe();
  }

  getAllProjectByCustomer(
    hideLoader: boolean = false,
    idCustomer: number | string
  ): Observable<ICustomerAndProject> {
    return this.http
      .get<ICustomerAndProject>(
        `${Config.apiRootUrl}/customer/allProjectByCustomer/${idCustomer}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description Call GET `contract/saveDocumentContract` API, to save the contract document
   * @param idStatus Id of status
   */
  getAllUserProjectByStatus(
    idStatus: RI_STATUS,
    hideLoader: boolean = false
  ): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(`${Config.apiRootUrl}/project/all/${idStatus}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  updateProject(project: IProjectInfo): Observable<IProjectInfo> {
    return this.http
      .put<IProjectInfo>(
        `${Config.apiRootUrl}/project/update/${project.idProject}`,
        project
      )
      .pipe();
  }

  sendIr(idProject: number | string): Observable<IProjectInfo> {
    return this.http
      .put<IProjectInfo>(
        `${Config.apiRootUrl}/project/delivery/${idProject}`,
        null
      )
      .pipe();
  }

  /**
   * @description Call POST `contract/saveDocumentContract` API, to save the contract document
   * @param document The document file to save on server
   */
  uploadDocument(document: File): Observable<DocumentDTO> {
    const formData: FormData = new FormData();
    formData.append('file', document, document.name);
    return this.http
      .post<DocumentDTO>(
        `${Config.apiRootUrl}/contract/saveDocumentContract`,
        formData
      )
      .pipe();
  }

  /**
   * @description Call POST `contract/creation` API, to create the contract
   * @param body The body of contract to save
   */
  saveContract(body: Contract): Observable<void> {
    return this.http
      .post<void>(`${Config.apiRootUrl}/contract/creation`, body)
      .pipe();
  }

  /**
   * @description Call PUT `contract/:idContract` API, to update the contract
   * @param body The body of contract to save
   */
  updateContract(body: Contract): Observable<any> {
    return this.http
      .put<any>(`${Config.apiRootUrl}/contract/${body.idContract}`, body)
      .pipe();
  }

  /**
   * @description Call GET `product/getProductByIdCustomer/:idCustomer` API to get all the productsServices of the customer
   * @param id id of Customer
   */
  getProductsServicesByIdCustomer(
    idCustomer: number | string
  ): Observable<IProductsServices[]> {
    return this.http
      .get<IProductsServices[]>(
        `${Config.apiRootUrl}/product/getByIdCustomer/${idCustomer}`
      )
      .pipe();
  }

  /**
   * @description Call PUT `product/update:idCustomer` API to update the productsServices
   * @param body body of ProductsServices form
   */
  updateProductsServices(
    body: IProductsServices[],
    idCustomer: number | string
  ): Observable<IProductsServices[]> {
    return this.http
      .put<IProductsServices[]>(
        `${Config.apiRootUrl}/product/${idCustomer}`,
        body
      )
      .pipe();
  }

  /**
   * @description Call GET 'product/idCustomer' API to get an array of products withot BM
   * @param idCustomer id of Customer
   */
  getProductsWithoutBM(
    idCustomer: number,
    hideLoader: boolean = false
  ): Observable<IProductWithoutBM[]> {
    return this.http
      .get<IProductWithoutBM[]>(`${Config.apiRootUrl}/product/${idCustomer}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description Call GET `/foreignMarket/getMarketByCustomer/:idCustomer` API to get all the markets of the customer
   * @param idCustomer id of the customer
   */
  getMarketsById(idCustomer: number | string): Observable<IMarkets[]> {
    return this.http
      .get<IMarkets[]>(
        `${Config.apiRootUrl}/foreignMarket/customer/${idCustomer}`
      )
      .pipe();
  }

  /**
   * @description Call PUT `foreignMarket/update/:idCutomer` API to update the markets array
   * @param body array of markets form
   * @param idCustomer id of the customer
   */
  updateMarkets(
    body: IMarkets[],
    idCustomer: number | string
  ): Observable<IMarkets[]> {
    return this.http
      .put<IMarkets[]>(
        `${Config.apiRootUrl}/foreignMarket/update/${idCustomer}`,
        body
      )
      .pipe();
  }

  /**
   * @description API to delete a Market
   * @param idForeignMarket Id of the Market
   */
  deleteMarket(idForeignMarket: number): Observable<IMarkets> {
    return this.http
      .delete<IMarkets>(`${Config.apiRootUrl}/foreignMarket/${idForeignMarket}`)
      .pipe();
  }

  /**
   * @description Call GET `foreignMarket/getAllCountry` API to get countries
   * @param hideLoader Set true to hide the loader
   */
  getAllCountries(
    hideLoader: boolean = false,
    toCache: boolean = true
  ): Observable<ICountries[]> {
    return this.http
      .get<ICountries[]>(`${Config.apiRootUrl}/foreignMarket/getAllCountry`, {
        headers: this._getHeaders(hideLoader, false, toCache),
      })
      .pipe();
  }

  /**
   * @description Call GET `distribution/getChannelByIdCustomer/:idCustomer` API to get all the channels of the customer
   * @param idCustomer id of the customer
   */
  getChannelsByIdCustomer(
    idCustomer: number | string
  ): Observable<IChannelMarket[]> {
    return this.http
      .get<IChannelMarket[]>(
        `${Config.apiRootUrl}/distribution/getChannelByIdCustomer/${idCustomer}`
      )
      .pipe();
  }

  /**
   * @description Call POST `channel/create` API to create che channels array
   * @param body array of channels
   */
  createChannels(body: IChannel[]): Observable<void> {
    return this.http
      .post<void>(`${Config.apiRootUrl}/channel/create`, body)
      .pipe();
  }

  /**
   * @description Call PUT `/distribution/update/:idCustomer` API to save the distribution channels array
   * @param body array of distribution channels
   * @param idCustomer id of the customer
   */
  saveChannelsOfCustomer(
    body: IChannel[],
    idCustomer: number | string
  ): Observable<void> {
    return this.http
      .put<void>(`${Config.apiRootUrl}/distribution/update/${idCustomer}`, body)
      .pipe();
  }

  /**
   * @description Call DELETE `/distribution/deleteCompanyById/:idCompany` API to delete the company in distribution channels
   * @param idCompany the id of capany to delete
   */
  deleteChannelCompany(idCompany: number | string): Observable<void> {
    return this.http
      .delete<void>(
        `${Config.apiRootUrl}/distribution/deleteCompanyById/${idCompany}`
      )
      .pipe();
  }

  /**
   * @description Call DELETE `/distribution/deleteDistributionById/:idChannel` API to delete the distribution channel
   * @param idChannel the id of distribution channel to delete
   */
  deleteChannel(idChannel: number | string): Observable<void> {
    return this.http
      .delete<void>(
        `${Config.apiRootUrl}/distribution/deleteDistributionById/${idChannel}`
      )
      .pipe();
  }

  /**
   * @description Call GET `/agentnote/getByIdProject/:idProject` API to the note associed to the project
   * @param idProject Id of the project
   */
  getNoteByProject(idProject: number | string): Observable<Note[]> {
    return this.http
      .get<Note[]>(`${Config.apiRootUrl}/agentnote/getByIdProject/${idProject}`)
      .pipe();
  }

  /**
   * @description Call PUT `/agentnote/save/:idProject` API to save the note array
   * @param body The note array to save
   */
  saveNotes(body: Note[], idProject: number | string): Observable<void> {
    return this.http
      .put<void>(`${Config.apiRootUrl}/agentnote/save/${idProject}`, body)
      .pipe();
  }

  /**
   * @description Call PUT `/agentnote/save/:idProject` send the IRDocument pdf file to server
   * @param document the pdf File of IR
   * @param idProject the idProject of IR
   */
  uploadIrDocument(
    document: File,
    idProject: number | string
  ): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', document, document.name);
    return this.http
      .post<void>(
        `${Config.apiRootUrl}/document/save/${idProject}/RI`,
        formData
      )
      .pipe();
  }

  uploadIspDocument(
    document: File,
    idProject: number | string
  ): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', document, document.name);
    return this.http
      .post<void>(
        `${Config.apiRootUrl}/document/save/${idProject}/ISP`,
        formData
      )
      .pipe();
  }

  getReferenceCompetitorsByIdCustomer(
    idCustomer: number
  ): Observable<IReferenceCompetitor[]> {
    return this.http
      .get<IReferenceCompetitor[]>(
        `${Config.apiRootUrl}/competitorCompany/getByIdCustomer/${idCustomer}`
      )
      .pipe();
  }

  getReferenceCompetitorsDetailsByIdCustomer(
    idCustomer: number
  ): Observable<IReferenceCompetitor[]> {
    return this.http
      .get<IReferenceCompetitor[]>(
        `${Config.apiRootUrl}/competitorCompany/getDetailsByIdCustomer/${idCustomer}`
      )
      .pipe();
  }

  saveReferenceCompetitors(
    body: IReferenceCompetitor[],
    idCustomer: number
  ): Observable<IReferenceCompetitor[]> {
    return this.http
      .put<IReferenceCompetitor[]>(
        `${Config.apiRootUrl}/competitorCompany/save/${idCustomer}`,
        body
      )
      .pipe();
  }

  deleteCustomerCompetitor(
    idCompetitor: number | string
  ): Observable<IReferenceCompetitor[]> {
    return this.http
      .delete<IReferenceCompetitor[]>(
        `${Config.apiRootUrl}/competitorCompany/delete/${idCompetitor}`
      )
      .pipe();
  }

  deleteCustomerCompetitorDistributionChannel(
    idCompetitorDistributionChannel: number | string
  ): Observable<DistributionChannel> {
    return this.http
      .delete<DistributionChannel>(
        `${Config.apiRootUrl}/competitorCompany/delete/channel/${idCompetitorDistributionChannel}`
      )
      .pipe();
  }

  deleteCustomerCompetitorMarketArea(
    idCompetitorMarketArea: number | string
  ): Observable<PresenceMarket> {
    return this.http
      .delete<PresenceMarket>(
        `${Config.apiRootUrl}/competitorCompany/delete/area/${idCompetitorMarketArea}`
      )
      .pipe();
  }

  deleteCustomerCompetitorProduct(
    idCustomerCompetitor: number | string
  ): Observable<CompetitorProduct> {
    return this.http
      .delete<CompetitorProduct>(
        `${Config.apiRootUrl}/competitorCompany/delete/product/${idCustomerCompetitor}`
      )
      .pipe();
  }

  /**
   * @description Call GET `/swotusp/save/:idCustomer` API to get USP & SWOT
   */
  getSwotByIdCustomer(idCustomer: number): Observable<Swot> {
    return this.http
      .get<Swot>(`${Config.apiRootUrl}/swotusp/getByIdCustomer/${idCustomer}`)
      .pipe();
  }

  /**
   * @description Call POST `/swotusp/create` API to create che channels array
   * @param body array of SWOTs
   */
  createSwot(body: Swot): Observable<void> {
    return this.http
      .post<void>(`${Config.apiRootUrl}/swotusp/create`, body)
      .pipe();
  }

  /**
   * @description Call PUT `/swotusp/save/:idProject` API to save the SWOT array
   * @param body The SWOT array to save
   */
  saveSwot(body: Swot, idProject: number | string): Observable<void> {
    return this.http
      .put<void>(`${Config.apiRootUrl}/swotusp/save/${idProject}`, body)
      .pipe();
  }

  getAllIrToAssign(
    idProjectStatus: number,
    hideLoader: boolean = false
  ): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(
        `${Config.apiRootUrl}/project/all/${idProjectStatus}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description Call `/project/approval/:idProject` API, to approve the project
   * @param idProject The id of project to approve
   */
  approveIr(idProject: number | string): Observable<IProjectInfo> {
    return this.http
      .put<IProjectInfo>(
        `${Config.apiRootUrl}/project/approval/${idProject}`,
        null
      )
      .pipe();
  }

  /**
   * @description Call `/project/reject/:idProject` API, to reject the project
   * @param idProject The id of project to reject
   */
  rejectIr(idProject: number | string): Observable<IProjectInfo> {
    return this.http
      .put<IProjectInfo>(
        `${Config.apiRootUrl}/project/reject/${idProject}`,
        null
      )
      .pipe();
  }

  /**
   * @description Call `/approval/isp/:idProject` API, to approve the ISP
   * @param idProject The id of project to approve
   */
  approveIsp(idProject: number | string): Observable<IProjectInfo> {
    return this.http
      .put<IProjectInfo>(
        `${Config.apiRootUrl}/project/approval/isp/${idProject}`,
        null
      )
      .pipe();
  }

  /**
   * @description Call `/reject/isp/:idProject` API, to reject the project
   * @param idProject The id of project to reject
   * @param projectNoteList the notes list
   */
  rejectIsp(idProject: number | string): Observable<IProjectInfo> {
    return this.http
      .put<IProjectInfo>(
        `${Config.apiRootUrl}/project/reject/isp/${idProject}`,
        null
      )
      .pipe();
  }

  getAllIrSent(): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(`${Config.apiRootUrl}/project/delivery/all`)
      .pipe();
  }

  /**
   * @description Call `/project/active/idProjectStatus` API, Get all project with passed status of the logged user
   * @param idProjectStatus The ID STATUS of project
   */
  getProjectByStatusByUser(
    idProjectStatus: RI_STATUS,
    hideLoader: boolean = false
  ): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(
        `${Config.apiRootUrl}/project/active/${idProjectStatus}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getProjectByStatusDescription(
    idProjectStatus: RI_STATUS_DESCRIPTION,
    hideLoader: boolean = false
  ): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(
        `${Config.apiRootUrl}/project/active/status/${idProjectStatus}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getProjectInternalByStatusDescription(
    idProjectStatus: RI_STATUS_DESCRIPTION,
    hideLoader: boolean = false
  ): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(
        `${Config.apiRootUrl}/project/internal/delivered/${idProjectStatus}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getProjectRejectedByStatusDescription(
    idProjectStatus: RI_STATUS_DESCRIPTION,
    hideLoader: boolean = false
  ): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(
        `${Config.apiRootUrl}/project/rejected/${idProjectStatus}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getProjectRejectedForSupervisorByStatusDescription(
    idProjectStatus: RI_STATUS_DESCRIPTION,
    hideLoader: boolean = false
  ): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(
        `${Config.apiRootUrl}/project/rejected/supervisor/${idProjectStatus}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getProjectApprovedByStatusDescription(
    idProjectStatus: RI_STATUS_DESCRIPTION,
    hideLoader: boolean = false
  ): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(
        `${Config.apiRootUrl}/project/approved/${idProjectStatus}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getLabManager(): Observable<ILabManager[]> {
    return this.http
      .get<ILabManager[]>(`${Config.apiRootUrl}/user/role/LAB_MANAGER`)
      .pipe();
  }

  getLabManagerInternal(): Observable<ILabManager[]> {
    return this.http
      .get<ILabManager[]>(`${Config.apiRootUrl}/user/role/LAB_MANAGER_INTERNAL`)
      .pipe();
  }

  getAgent(hideLoader: boolean = true): Observable<IAgent[]> {
    return this.http
      .get<IAgent[]>(`${Config.apiRootUrl}/user/role/AGENT`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description Call GET `/project/note/:idProject` API to get the supervisor notes
   * @param idProject Id of Project
   */
  getSuperVisorNotes(
    idProject: number | string,
    hideLoader: boolean = false
  ): Observable<Note[]> {
    return this.http
      .get<Note[]>(`${Config.apiRootUrl}/project/note/${idProject}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  assignLabManager(
    idProject: number | string,
    idLabManager: number | string
  ): Observable<IAssignLabManager> {
    return this.http
      .put<IAssignLabManager>(
        `${Config.apiRootUrl}/project/assignation/${idProject}/${idLabManager}`,
        idLabManager
      )
      .pipe();
  }

  /**
   * @description Call POST `/document/save/ve/:idProject` send the IRDocument pdf file to server
   * @param document the pdf File of IR
   * @param idProject the idProject of IR
   */
  uploadIrToAssignDocument(
    document: File,
    idProject: number | string
  ): Observable<DocumentDTO> {
    const formData: FormData = new FormData();
    formData.append('file', document, document.name);
    return this.http
      .post<DocumentDTO>(
        `${Config.apiRootUrl}/document/save/ve/${idProject}`,
        formData
      )
      .pipe();
  }

  /**
   * @description Call PUT `/project/delivery/isp/:idProject` to send the isp to supervisor
   * @param idProject The id of the project
   */
  sendIsp(idProject: string | number): Observable<IProjectInfo> {
    return this.http
      .put<IProjectInfo>(
        `${Config.apiRootUrl}/project/delivery/isp/${idProject}`,
        null
      )
      .pipe();
  }

  /**
   * @description Call GET `/customerInfoDescription/:idProject` to get the customer description by idProject
   * @param idProject The id of project
   * @param hideLoader the boolean to hide the loader
   */
  getCustomerDescriptionByIdProject(
    idProject: number | string,
    hideLoader: boolean = false
  ): Observable<CustomerDescriptions> {
    return this.http
      .get<CustomerDescriptions>(
        `${Config.apiRootUrl}/customer/description/project/${idProject}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description Call POST `/customerDescription/create/:idProject` to create the customer description on project
   * @param idProject The id of project associated to the customer description
   * @param customerDescription The customer description to save
   * @param hideLoader A boolean to hide the spinner loader
   * @param hideTaost A boolean to hide auto toast message
   */
  createCustomerDescription(
    idProject: number | string,
    customerDescription: CustomerDescriptions,
    hideLoader: boolean = false,
    hideTaost: boolean = false
  ): Observable<CustomerDescriptions> {
    return this.http
      .put<CustomerDescriptions>(
        `${Config.apiRootUrl}/customer/description/${idProject}`,
        customerDescription,
        { headers: this._getHeaders(hideLoader, hideTaost) }
      )
      .pipe();
  }

  /**
   * @description Call PUT `/customerDescription/create/:idProject` to create the customer description on project
   * @param idProject The id of project description to the customer description
   * @param customerDescription The customer description to save
   * @param hideLoader A boolean to hide the spinner loader
   * @param hideTaost A boolean to hide auto toast message
   */
  updateCustomerDescription(
    idProject: number | string,
    customerDescription: CustomerDescriptions,
    hideLoader: boolean = false,
    hideTaost: boolean = false
  ): Observable<CustomerDescriptions> {
    return this.http
      .put<CustomerDescriptions>(
        `${Config.apiRootUrl}/customer/description/${idProject}`,
        customerDescription,
        { headers: this._getHeaders(hideLoader, hideTaost) }
      )
      .pipe();
  }

  createUpdateSupervisorNotes(
    idProject: number | string,
    note: Note[],
    hideLoader: boolean = false
  ): Observable<Note[]> {
    return this.http
      .put<Note[]>(`${Config.apiRootUrl}/project/note/${idProject}`, note, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  deleteSupervisorNote(
    idProjectNote: number,
    hideLoader: boolean = false
  ): Observable<Note> {
    return this.http
      .delete<Note>(`${Config.apiRootUrl}/project/note/${idProjectNote}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  checkNote(
    idProject: number | string,
    note: Note[],
    hideLoader: boolean = false
  ): Observable<Note[]> {
    return this.http
      .put<Note[]>(`${Config.apiRootUrl}/project/${idProject}/note`, note, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getUnitsOfMeasure(
    hideLoader: boolean = false,
    toCache: boolean = true,
    idCustomer?: number
  ): Observable<UnitsOfMeasure[]> {
    let par: HttpParams = new HttpParams();
    if (idCustomer) {
      par = par.set('idCustomer', idCustomer.toString());
    }
    return this.http
      .get<UnitsOfMeasure[]>(`${Config.apiRootUrl}/common/unitofmeasure`, {
        headers: this._getHeaders(hideLoader, false, toCache),
        params: par,
      })
      .pipe();
  }

  updateUnitsOfMeasure(data: UnitsOfMeasure[]): Observable<UnitsOfMeasure> {
    return this.http
      .put(`${Config.apiRootUrl}/common/unitofmeasure`, data)
      .pipe();
  }

  deleteProduct(idProduct: number): Observable<IProductsServices> {
    return this.http
      .delete<IProductsServices>(`${Config.apiRootUrl}/product/${idProduct}`)
      .pipe();
  }

  deleteProductCompany(idCompany: number): Observable<IRelevantRegistry> {
    return this.http
      .delete<IRelevantRegistry>(
        `${Config.apiRootUrl}/product/registry/${idCompany}`
      )
      .pipe();
  }
  /**
   * @description Call GET `/competitorCompany/customer/exportorientation/:idCustomer` API to get the customers concurrency export Graphs
   * @param idCustomer Id of customer
   * @param hideLoader A boolean to hide the spinner loader
   */
  getCompetitorExportOrientation(
    idCustomer: number,
    hideLoader: boolean = false
  ): Observable<ExportResponse> {
    return this.http
      .get<ExportResponse>(
        `${Config.apiRootUrl}/competitorCompany/customer/exportorientation/${idCustomer}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getLeadGenerationByIdCustomer(
    idCustomer: number
  ): Observable<ILeadGeneration> {
    return this.http
      .get<ILeadGeneration>(
        `${Config.apiRootUrl}/leadgeneration/customer/${idCustomer}`
      )
      .pipe();
  }

  updateLeadGeneration(
    idCustomer: number,
    body: ILeadGeneration
  ): Observable<ILeadGeneration> {
    return this.http
      .put<ILeadGeneration>(
        `${Config.apiRootUrl}/leadgeneration/${idCustomer}`,
        body
      )
      .pipe();
  }

  deleteLeadGenerationActivity(
    idLeadGeneration: number
  ): Observable<ILeadGenerationActivity> {
    return this.http
      .delete<ILeadGenerationActivity>(
        `${Config.apiRootUrl}/leadgeneration/activity/${idLeadGeneration}`
      )
      .pipe();
  }

  deleteLeadGenerationCompany(idCompanyLead: number): Observable<ICompanyLead> {
    return this.http
      .delete<ICompanyLead>(
        `${Config.apiRootUrl}/leadgeneration/company/${idCompanyLead}`
      )
      .pipe();
  }

  deleteLeadGenerationProfile(idLeadProfile: number): Observable<ILeadProfile> {
    return this.http
      .delete<ILeadProfile>(
        `${Config.apiRootUrl}/leadgeneration/profile/${idLeadProfile}`
      )
      .pipe();
  }

  deleteLeadGenerationProfileActivity(
    idLeadProfileActivity: number
  ): Observable<ILeadProfile> {
    return this.http
      .delete<ILeadProfile>(
        `${Config.apiRootUrl}/leadgeneration/profileactivity/${idLeadProfileActivity}`
      )
      .pipe();
  }

  getReferentRoles(hideLoader: boolean = false): Observable<IReferentRole[]> {
    return this.http
      .get<IReferentRole[]>(`${Config.apiRootUrl}/common/roles`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description Call GET `foreignMarket/getAllCountry` API to get countries
   */
  getAllSectors(
    hideLoader: boolean = false,
    toCache: boolean = true
  ): Observable<ISectors[]> {
    return this.http.get<ISectors[]>(
      `${Config.apiRootUrl}/common/commercesector`,
      {
        headers: this._getHeaders(hideLoader, false),
      }
    );
  }

  handleError<T>(result?: T): any {
    return (err: any) => {
      console.error(err + ': ' + 'errororne!');
      console.log(result);
      return of(result as T);
    };
  }

  updateCommerceSectors(body: ISectors[]): Observable<ISectors[]> {
    return this.http.put<ISectors[]>(
      `${Config.apiRootUrl}/common/commercesector`,
      body
    );
  }

  deleteCommerceSector(idCommerceSector: number): Observable<ISectors> {
    return this.http.delete<ISectors>(
      `${Config.apiRootUrl}/common/commercesector/${idCommerceSector}`
    );
  }

  getCustomerDocuments(
    hideLoader: boolean = false,
    idCustomer: number
  ): Observable<DocumentDTO[]> {
    return this.http
      .get<DocumentDTO[]>(
        `${Config.apiRootUrl}/document/customer/${idCustomer}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  uploadCustomerDocument(
    hideLoader: boolean = false,
    idCustomer: number,
    document: File
  ): Observable<DocumentDTO> {
    const formData: FormData = new FormData();
    formData.append('file', document, document.name);
    return this.http
      .post<DocumentDTO>(
        `${Config.apiRootUrl}/document/customer/${idCustomer}/OTHER_DOC`,
        formData,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  deleteCustomerDocument(
    hideLoader: boolean = false,
    idDocument: string | number
  ): Observable<DocumentDTO> {
    return this.http
      .delete<DocumentDTO>(`${Config.apiRootUrl}/document/${idDocument}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getEvaluationConcurrency(idCustomer: number | string): Observable<any> {
    return this.http
      .get<any>(
        `${Config.apiRootUrl}/foreignMarket/evaluation/concurrency/customer/${idCustomer}`
      )
      .pipe();
  }

  enableDisableCustomer(
    idCustomer: number | string,
    customerStatus: boolean,
    hideLoader: boolean = false,
    hideTaost: boolean = false
  ): Observable<any> {
    return this.http
      .put<any>(
        `${Config.apiRootUrl}/customer/status/${idCustomer}/${customerStatus}`,
        { headers: this._getHeaders(hideLoader, hideTaost) }
      )
      .pipe();
  }

  getProductMatching(
    idProduct: number,
    hideLoader: boolean = false
  ): Observable<IProductMatching> {
    return this.http
      .get<IProductMatching>(
        `${Config.apiRootUrl}/productmatching/product/${idProduct}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  addProductMatchingServices(
    body: IProductMatching,
    hideLoader: boolean = false
  ): Observable<IProductMatching> {
    return this.http
      .put<IProductMatching>(`${Config.apiRootUrl}/productmatching/`, body, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  deleteProductMatchingEmitter(
    idProductMatching: string | number,
    hideLoader: boolean = false
  ): Observable<KeyMatching> {
    return this.http
      .delete<KeyMatching>(
        `${Config.apiRootUrl}/productmatching/${idProductMatching}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getCN8(
    description: string,
    language: string,
    page: number,
    pageSize: number,
    hideLoader: boolean = false
  ): Observable<ICnProductsService> {
    const par: Params = new HttpParams()
      .set('description', description)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http
      .get<ICnProductsService>(
        `${Config.apiRootUrl}/product/combinednomenclature/${language}/paginate`,
        { params: par, headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getInterchangeTypologyData(
    cnCode: any,
    interchangeTypology: number,
    idCustomer: number,
    yearEnd: number
  ): Observable<IInterchangeData[]> {
    const par: Params = new HttpParams().set('yearEnd', yearEnd?.toString());
    return this.http
      .get<IInterchangeData[]>(
        `${Config.apiRootUrl}/foreignMarket/evaluation/potential/commercial/interchange/${cnCode}/interchangetype/${interchangeTypology}/customer/${idCustomer}`,
        { params: par }
      )
      .pipe();
  }

  searchAllProducts(
    product: string,
    idProductType: number,
    hideLoader: boolean = false
  ): Observable<ISearchProduct> {
    return this.http
      .get<ISearchProduct>(
        `${Config.apiRootUrl}/product/matching/customeraggregation/${idProductType}?wordKey=${product}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  searchAllProductsDetails(
    product: string,
    idProductType: number,
    hideLoader: boolean = false
  ): Observable<ISearchProductDetail[]> {
    return this.http
      .get<ISearchProductDetail[]>(
        `${Config.apiRootUrl}/product/matching/customeraggregation/information/${idProductType}?wordKey=${product}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description Get Market Analysis
   */
  getMarketsAnalysis(
    idProject: number | string
  ): Observable<MarketsAnalysisResponse> {
    return this.http
      .get<MarketsAnalysisResponse>(
        `${Config.apiRootUrl}/marketanalysis/getmarketanalysis/${idProject}`
      )
      .pipe();
  }

  /**
   * @description Save Market Analysis
   */
  saveMarketsAnalysis(
    idProject: number | string,
    analysis: MarketsAnalysisResponse
  ): Observable<MarketsAnalysisResponse> {
    return this.http
      .put<MarketsAnalysisResponse>(
        `${Config.apiRootUrl}/marketanalysis/${idProject}`,
        analysis
      )
      .pipe();
  }

  saveMatrixMarketsAnalysis(
    idProject: number | string,
    body: any
  ): Observable<IMatrixMarketsAnalysis> {
    return this.http
      .put<IMatrixMarketsAnalysis>(
        `${Config.apiRootUrl}/marketanalysis/strategy/${idProject}`,
        body
      )
      .pipe();
  }

  getMatrixMarketsAnalysis(
    idProject: number | string
  ): Observable<IMatrixMarketsAnalysis> {
    return this.http
      .get<IMatrixMarketsAnalysis>(
        `${Config.apiRootUrl}/marketanalysis/indexmarketobjectivetable/project/${idProject}`
      )
      .pipe();
  }

  getCustomIndexList(): Observable<ICustomIndex[]> {
    return this.http
      .get<ICustomIndex[]>(
        `${Config.apiRootUrl}/marketanalysis/defaultindexmarketobjective`
      )
      .pipe();
  }

  deleteIndexMarketObjective(
    idProject: number | string,
    idIndexType: number
  ): Observable<IIndexMarketObjective> {
    return this.http
      .delete<IIndexMarketObjective>(
        `${Config.apiRootUrl}/marketanalysis/indexType/${idIndexType}/project/${idProject}`
      )
      .pipe();
  }

  getChannelsType(
    hideLoader: boolean = false,
    toCache: boolean = true
  ): Observable<IChannelsType[]> {
    return this.http
      .get<IChannelsType[]>(`${Config.apiRootUrl}/common/channeltype`, {
        headers: this._getHeaders(hideLoader, false, toCache),
      })
      .pipe();
  }

  getChannelsTypeByIdCustomer(
    idCustomer: number,
    hideLoader: boolean = true
  ): Observable<IChannelsType[]> {
    return this.http
      .get<IChannelsType[]>(
        `${Config.apiRootUrl}/common/channeltype/${idCustomer}`,
        {
          headers: this._getHeaders(hideLoader, false),
        }
      )
      .pipe();
  }

  createUpdateChannelType(channel: IChannelsType[]): Observable<IChannelsType> {
    return this.http
      .put<IChannelsType>(
        `${Config.apiRootUrl}/common/createupdate/channeltype`,
        channel
      )
      .pipe();
  }

  addChannelsType(
    channels: IChannelsType[],
    hideLoader: boolean = false
  ): Observable<IChannelsType[]> {
    return this.http
      .put<IChannelsType[]>(
        `${Config.apiRootUrl}/common/channeltype`,
        channels,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  deleteChannelType(
    idChannelType: number,
    hideLoader: boolean = false
  ): Observable<IChannelsType> {
    return this.http.delete<IChannelsType>(
      `${Config.apiRootUrl}/common/channeltype/${idChannelType}`,
      { headers: this._getHeaders(hideLoader) }
    );
  }

  /**
   * @description Get Strategy
   */
  getStrategy(
    idProject: number | string,
    idProjectStatus: number | string
  ): Observable<ICustomerStrategy> {
    return this.http
      .get<ICustomerStrategy>(
        `${Config.apiRootUrl}/projectobjective/${idProject}?idProjectStatus=${idProjectStatus}`
      )
      .pipe();
  }

  saveStrategy(
    idProject: number | string,
    body: ICustomerStrategy
  ): Observable<ICustomerStrategy> {
    return this.http
      .put<ICustomerStrategy>(
        `${Config.apiRootUrl}/projectobjective/${idProject}`,
        body
      )
      .pipe();
  }

  deleteStrategy(idProject: string | number): Observable<ICustomerStrategy> {
    return this.http
      .delete<ICustomerStrategy>(
        `${Config.apiRootUrl}/projectobjective/strategy/${idProject}`
      )
      .pipe();
  }

  createSubProject(
    idProject: number | string,
    idLabManagerInternal: number | string
  ): Observable<void> {
    return this.http
      .put<void>(
        `${Config.apiRootUrl}/project/subproject/${idProject}/user/${idLabManagerInternal}`,
        {}
      )
      .pipe();
  }

  changeOwner(
    idProject: number | string,
    idLabManager: number | string
  ): Observable<ICustomerStrategy> {
    return this.http
      .put<ICustomerStrategy>(
        `${Config.apiRootUrl}/project/assignation/${idProject}/${idLabManager}?isReplace=1`,
        {}
      )
      .pipe();
  }

  changeAgent(idProject: number | string, idAgent: number): Observable<any> {
    return this.http.put<any>(
      `${Config.apiRootUrl}/project/replaceAgent/${idProject}/${idAgent}`,
      null
    );
  }

  changeProductStrategy(
    idProject: string | number,
    labManager,
    hideLoader: boolean = true
  ): Observable<any> {
    const par: HttpParams = new HttpParams()
      .set('idProject', idProject.toString())
      .set('labManager', labManager.toString());
    return this.http
      .put<any>(
        `${Config.apiRootUrl}/project/changestrategy/${idProject}/user/${labManager}`,
        { params: par, headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getAgentAppointment(
    from: Date | string,
    to: Date | string,
    idUserAgent?: number,
    idAppointmentState: number = 1
  ): Observable<IAppointment[]> {
    return this.http
      .get<IAppointment[]>(
        `${Config.apiRootUrl}/appointment/?from=${from}&to=${to}` +
          `${
            idUserAgent
              ? `&idUserAgent=${idUserAgent}&idAppointmentState=${idAppointmentState}`
              : ``
          }`,
        { headers: this._getHeaders(true) }
      )
      .pipe();
  }

  updateAgentAppointment(
    idAppointment: number | string,
    body: IAppointment,
    hideLoader: boolean = true
  ): Observable<IAppointment> {
    return this.http
      .put<IAppointment>(
        `${Config.apiRootUrl}/appointment/agent/${idAppointment}`,
        body,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  deleteAgentAppointment(
    idAppointment: number | string,
    body: IAppointment
  ): Observable<IAppointment> {
    return this.http
      .put<IAppointment>(
        `${Config.apiRootUrl}/appointment/agent/cancel/${idAppointment}`,
        body,
        { headers: this._getHeaders(true) }
      )
      .pipe();
  }

  createProjectFromAppointment(
    idAppointment: number | string,
    body: any
  ): Observable<any> {
    return this.http
      .post<any>(
        `${Config.apiRootUrl}/project/creation/${idAppointment}`,
        body,
        { headers: this._getHeaders(true) }
      )
      .pipe();
  }

  uploadCustomerLogo(idCustomer: number | string, logo: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', logo, logo.name);
    return this.http
      .put<any>(`${Config.apiRootUrl}/customer/image/${idCustomer}`, formData)
      .pipe();
  }

  getCustomersHistory(hideLoader: boolean = true): Observable<any> {
    return this.http
      .get<any>(`${Config.apiRootUrl}/customer/history`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getUserStatistics(dateFrom: string, dateTo: string): Observable<any> {
    const par: Params = new HttpParams()
      .set('dateFrom', dateFrom.toString())
      .set('dateTo', dateTo.toString());
    return this.http
      .get<any>(`${Config.apiRootUrl}/customer-bo/statistics`, { params: par })
      .pipe();
  }

  // New API for ASSESSMENTS

  getMasterAndClonedProjects(): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(`${Config.apiRootUrl}/project/mastercloneproject`, {
        headers: this._getHeaders(true),
      })
      .pipe();
  }

  getRejectedAssessments(): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(`${Config.apiRootUrl}/project-bo/ass-rejected`, {
        headers: this._getHeaders(true),
      })
      .pipe();
  }

  getInProgressAssessments(): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(`${Config.apiRootUrl}/project-bo/ass-working`, {
        headers: this._getHeaders(true),
      })
      .pipe();
  }

  getToApproveAssessments(): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(
        `${Config.apiRootUrl}/project-bo/ass-project/${RI_STATUS_DESCRIPTION.RiDelivered}`,
        { headers: this._getHeaders(true) }
      )
      .pipe();
  }

  getBMAssessments(): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(
        `${Config.apiRootUrl}/project-bo/ass-project/${RI_STATUS_DESCRIPTION.NoContract}`,
        { headers: this._getHeaders(true) }
      )
      .pipe();
  }

  getApprovedAssessments(): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(
        `${Config.apiRootUrl}/project-bo/ass-project/${RI_STATUS_DESCRIPTION.RiApproved}`,
        { headers: this._getHeaders(true) }
      )
      .pipe();
  }

  getDashboardStatistics(
    dateFrom: string,
    dateTo: string,
    typeOutput: number
  ): Observable<any> {
    const par: Params = new HttpParams()
      .set('dateFrom', dateFrom)
      .set('dateTo', dateTo)
      .set('typeOutput', typeOutput.toString());
    return this.http
      .get<any>(`${Config.apiRootUrl}/dashboard/statistics/period`, {
        params: par,
        headers: this._getHeaders(true),
      })
      .pipe();
  }

  getSupervisorDashboardStatistics(
    dateFrom: string,
    dateTo: string,
    typeOutput: number
  ): Observable<any> {
    const par: Params = new HttpParams()
      .set('dateFrom', dateFrom)
      .set('dateTo', dateTo)
      .set('typeOutput', typeOutput.toString());
    return this.http
      .get<any>(`${Config.apiRootUrl}/dashboard/statistics/period/supervisor`, {
        params: par,
        headers: this._getHeaders(true),
      })
      .pipe();
  }

  getDirectionDashboardStatistics(
    dateFrom: string,
    dateTo: string,
    typeOutput: number
  ): Observable<any> {
    const par: Params = new HttpParams()
      .set('dateFrom', dateFrom)
      .set('dateTo', dateTo)
      .set('typeOutput', typeOutput.toString());
    return this.http
      .get<any>(`${Config.apiRootUrl}/dashboard/statistics/period/direction`, {
        params: par,
        headers: this._getHeaders(true),
      })
      .pipe();
  }

  getNoContractReasons(
    hideLoader: boolean = true
  ): Observable<IAppointmentReason[]> {
    return this.http.get<IAppointmentReason[]>(
      `${Config.apiRootUrl}/common/projectrefusalreason`,
      {
        headers: this._getHeaders(hideLoader),
      }
    );
  }

  createClonedProject(): Observable<any> {
    return this.http
      .put<any>(`${Config.apiRootUrl}/project/cloneproject`, {
        headers: this._getHeaders(true),
      })
      .pipe();
  }

  deleteClonedProject(idProject: number): Observable<any> {
    return this.http
      .delete<any>(`${Config.apiRootUrl}/project/${idProject}`, {
        headers: this._getHeaders(false),
      })
      .pipe();
  }

  getInterchangeYears(
    interchangeType: number,
    hideLoader: boolean = true
  ): Observable<number[]> {
    return this.http.get<number[]>(
      `${Config.apiRootUrl}/foreignMarket/yearavailable/${interchangeType}`,
      {
        headers: this._getHeaders(hideLoader),
      }
    );
  }
}
