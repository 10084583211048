<div class="mb-30" *ngIf="idProject && !isDirection">
  <app-customer-info-panel
    [idProject]="idProject"
    [isReadonly]="isReadonly"
    [path]="'crm'"
    [disabled]="disableManagedCustomers"
  ></app-customer-info-panel>
</div>
<mat-tab-group
  [(selectedIndex)]="matTabGroupIndex"
  (selectedTabChange)="resetDuplicateContactsSection()"
  dynamicHeight
  class="custom-mat-tab"
>
  <mat-tab>
    <ng-template matTabLabel>
      <span>{{ "CRM.Database" | translate }}</span>
    </ng-template>
    <div class="row">
      <div class="col-9">
        <div class="section-card h100">
          <div class="content">
            <app-table
              [loader]="projectActivitiesLoader"
              [data]="projectActivities"
              [columns]="projectActivitiesTableColumns"
              [columnsButtons]="projectActivitiesTableButtons"
              [searchValue]="searchValueActivities.value"
            ></app-table>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="section-card">
          <div *ngIf="!isReadonly || !isDirection" class="add-contact">
            <button
              type="button"
              class="rounded-button primary-bg-button"
              (click)="addContact()"
            >
              <span class="white-color">
                {{ "CRM.AddContact" | translate }}
              </span>
            </button>
          </div>
          <div class="header multi h70">
            <div class="title">
              <span>{{ "COMMON.Filters" | translate }}</span>
            </div>
            <div class="text-button">
              <span class="primary-color" (click)="resetFilters('activities')">
                {{ "COMMON.Reset" | translate | lowercase }}
              </span>
            </div>
          </div>
          <div class="content">
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "CRM.Research" | translate }}</mat-label>
                <input
                  matInput
                  name="research"
                  type="text"
                  #searchValueActivities
                />
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "CRM.Status2" | translate }}</mat-label>
                <mat-select
                  #activitiesStatusFilter
                  (selectionChange)="filterActivitiesTable('status', $event)"
                  [(value)]="defaultSelectContactsFilter"
                >
                  <mat-option
                    *ngFor="let status of buyerStatusDescription"
                    [value]="status.idBuyerStatus"
                  >
                    {{ "BUYER_STATUS_NAME." + status.name | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "CRM.Country" | translate }}</mat-label>
                <mat-select
                  #activitiesCountryFilter
                  (selectionChange)="filterActivitiesTable('country', $event)"
                >
                  <mat-option
                    *ngFor="let country of countries"
                    [value]="country"
                  >
                    {{ country }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template matTabLabel>
      <span>{{ "CRM.TotalContacts" | translate }}</span>
    </ng-template>
    <div *ngIf="!showDuplicateContactsSection" class="row">
      <div class="col-9">
        <div class="section-card">
          <div class="content">
            <app-table
              [loader]="contactsLoader"
              [data]="contacts"
              [columns]="contactsTableColumns"
              [columnsButtons]="contactsTableButtons"
              [searchValue]="searchValueContacts.value"
            ></app-table>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="section-card">
          <div *ngIf="!isReadonly || !isDirection" class="add-contact">
            <button
              type="button"
              class="rounded-button primary-bg-button"
              (click)="addContact()"
            >
              <span class="white-color">
                {{ "CRM.AddContact" | translate }}
              </span>
            </button>
          </div>
          <div class="header multi h70">
            <div class="title">
              <span>{{ "COMMON.Filters" | translate }}</span>
            </div>
            <div class="text-button">
              <span class="primary-color" (click)="resetFilters('contacts')">
                {{ "COMMON.Reset" | translate | lowercase }}
              </span>
            </div>
          </div>
          <div class="content">
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "CRM.Research" | translate }}</mat-label>
                <input
                  matInput
                  name="research"
                  type="text"
                  #searchValueContacts
                />
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "CRM_DETAIL.Channel2" | translate }}</mat-label>
                <mat-select
                  #contactsChannelFilter
                  (selectionChange)="filterContactsTable('channel', $event)"
                >
                  <mat-option
                    *ngFor="let channel of channels"
                    [value]="channel.idChannelType"
                  >
                    {{ channel.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "CRM.Status2" | translate }}</mat-label>
                <mat-select
                  #contactsStatusFilter
                  (selectionChange)="filterContactsTable('status', $event)"
                  [(value)]="defaultSelectContactsFilter"
                >
                  <mat-option
                    *ngFor="let status of buyerStatusDescription"
                    [value]="status.idBuyerStatus"
                  >
                    {{ "BUYER_STATUS_NAME." + status.name | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "CRM.Country" | translate }}</mat-label>
                <mat-select
                  #contactsCountryFilter
                  (selectionChange)="filterContactsTable('country', $event)"
                >
                  <mat-option
                    *ngFor="let country of countries"
                    [value]="country"
                  >
                    {{ country }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex justify-content-center mt-30">
              <button
                class="rounded-button primary-bg-button"
                style="margin-top: 20px !important"
                type="button"
                (click)="goToduplicateContactsSection()"
              >
                {{ "CRM_DETAIL.DuplicateContactsInOtherProject" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="showDuplicateContactsSection"
      class="duplicate-contacts-section"
    >
      <div class="section-card">
        <div class="stepper">
          <div
            class="step"
            [ngClass]="{
              enabled: stepperIndex === 0,
              completed: stepperIndex >= 1
            }"
          >
            <div class="circle">
              <span *ngIf="stepperIndex === 0">01</span>
              <svg-icon
                *ngIf="stepperIndex >= 1"
                name="accept_2_icon"
                class="w42 h42 white"
              ></svg-icon>
            </div>
            <div class="label">
              {{ "CRM_DETAIL.ProjectSelection" | translate }}
            </div>
          </div>
          <div class="horizontal-line"></div>
          <div
            class="step"
            [ngClass]="{
              enabled: stepperIndex === 1,
              completed: stepperIndex > 1
            }"
          >
            <div class="circle">
              <span *ngIf="stepperIndex < 2">02</span>
              <svg-icon
                *ngIf="stepperIndex >= 2"
                name="accept_2_icon"
                class="w42 h42 white"
              ></svg-icon>
            </div>
            <div class="label">
              {{ "CRM_DETAIL.ContactsSelection" | translate }}
            </div>
          </div>
          <div class="horizontal-line"></div>
          <div
            class="step"
            [ngClass]="{
              enabled: stepperIndex === 2
            }"
          >
            <div class="circle">
              <span>03</span>
            </div>
            <div class="label">
              {{ "CRM_DETAIL.ContactsDuplication" | translate }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="stepperIndex === 0" class="mt-30">
        <div class="section-card">
          <div class="header h70 multi no-after-element">
            <div class="description">
              {{
                "CRM_DETAIL.SelectProjectWherteToDuplicateContacts" | translate
              }}
            </div>
            <app-search-bar
              [disabled]="!managedCustomersForTable?.length"
              (emitSearchValue)="searchValue($event, 'projectWhereToDuplicate')"
            ></app-search-bar>
          </div>
          <div class="content">
            <app-table
              [data]="managedCustomersForTable"
              [columns]="managedCustomersTableColumns"
              [columnsButtons]="managedCustomersTableButtons"
              [loader]="managedCustomersTableLoader"
              [searchValue]="searchValueProjectWhereToDuplicate"
            ></app-table>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-30">
          <button
            class="rounded-button primary-border-button"
            type="button"
            (click)="goBackOnStepper(-1)"
          >
            {{ "COMMON.Cancel" | translate }}
          </button>
        </div>
      </div>
      <div *ngIf="stepperIndex === 1" class="mt-30">
        <div class="section-card">
          <div class="content">
            <div class="row">
              <div class="col-6">
                <div class="customer-contacts-title">
                  {{ projectInfo?.customer?.businessName }}
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <div>
                      <button
                        type="button"
                        class="only-text-button primary"
                        (click)="selectAllContactsToDuplicate()"
                      >
                        {{ "COMMON.SelectAll" | translate }}
                      </button>
                    </div>
                    <div>
                      <mat-form-field appearance="standard">
                        <mat-label>
                          {{ "CRM_DETAIL.SelectByCountry" | translate }}
                        </mat-label>
                        <mat-select
                          #contactsCountrySelection
                          (selectionChange)="selectContactsByCountry($event)"
                        >
                          <mat-option [value]="null">--</mat-option>
                          <mat-option
                            *ngFor="let country of countries"
                            [value]="country"
                          >
                            {{ country }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="search-bar-container">
                    <app-search-bar
                      [disabled]="!oldContacts?.length"
                      (emitSearchValue)="searchValue($event, 'oldContacts')"
                    ></app-search-bar>
                  </div>
                </div>
                <app-table
                  [data]="oldContacts"
                  [columns]="currentProjectContactsTableColumns"
                  [columnCheckbox]="currentProjectContactsTableCheckbox"
                  [searchValue]="searchValueOldContacts"
                ></app-table>
              </div>
              <div class="col-6">
                <div class="customer-contacts-title">
                  {{ selectedProjectForDuplication?.businessName }}
                </div>
                <div class="search-bar-container compensator">
                  <app-search-bar
                    [disabled]="!alreadyExistingContactForTable?.length"
                    (emitSearchValue)="searchValue($event, 'alreadyExisting')"
                  ></app-search-bar>
                </div>
                <app-table
                  [data]="alreadyExistingContactForTable"
                  [columns]="alreadyExistingContactTableColumns"
                  [loader]="alreadyExistingContactTableLoader"
                  [searchValue]="searchValueAlreadyExistingContacts"
                ></app-table>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-30">
          <button
            class="rounded-button primary-border-button"
            type="button"
            (click)="goBackOnStepper(0)"
          >
            {{ "COMMON.Back" | translate }}
          </button>
          <button
            class="ml-30 rounded-button primary-bg-button"
            type="button"
            [disabled]="alreadyExistingContactTableLoader"
            (click)="goNextOnStepper()"
          >
            {{ "COMMON.Next" | translate }}
          </button>
        </div>
      </div>
      <div *ngIf="stepperIndex === 2" class="mt-30">
        <div class="section-card">
          <div class="content">
            <div>
              <app-table
                [data]="selectedContactsToBeDuplicatedForTable"
                [columns]="alreadyExistingContactTableColumns"
              ></app-table>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-30">
          <button
            class="rounded-button primary-border-button"
            type="button"
            (click)="goBackOnStepper(1)"
          >
            {{ "COMMON.Back" | translate }}
          </button>
          <button
            class="ml-30 rounded-button primary-bg-button"
            type="button"
            [disabled]="alreadyExistingContactTableLoader"
            (click)="duplicateContacts()"
          >
            {{ "CRM_DETAIL.DuplicateContacts" | translate }}
          </button>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template matTabLabel>
      <span>{{ "CRM_DETAIL.ProspectBM" | translate }}</span>
    </ng-template>
    <div class="section-card">
      <div class="content">
        <app-table
          [columns]="exportedBMColumns"
          [columnsButtons]="exportedBMColumnButtons"
          [data]="exportedBMMappedForTable"
        ></app-table>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template matTabLabel>
      <span>{{ "CRM_DETAIL.ProspectLO" | translate }}</span>
    </ng-template>
    <div class="section-card">
      <div class="content">
        <app-table
          [columns]="exportedLostOffersColumns"
          [columnsButtons]="exportedLostOffersButtons"
          [data]="exportedLostOffersMappedForTable"
        ></app-table>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template matTabLabel>
      <span>{{ "CRM_DETAIL.ProspectReport" | translate }}</span>
    </ng-template>
    <div class="section-card">
      <div class="content">
        <app-table
          [columns]="exportedReportColumns"
          [data]="exportedReportMappedForTable"
          [columnsButtons]="exportedReportsButtons"
        ></app-table>
      </div>
    </div>
  </mat-tab>
  <ng-template #opportunityDetailDialog let-close="close" let-data>
    <div class="header h70 no-margin-top">
      <div class="title">
        <span *ngIf="data.opportunityType === 'bm'">
          {{ "BUSINESS_OPPORTUNITIES.BMDetail" | translate }}
        </span>
        <span *ngIf="data.opportunityType === 'lostOffer'">
          {{ "BUSINESS_OPPORTUNITIES.LostOfferDetail" | translate }}
        </span>
        <span *ngIf="data.opportunityType === 'report'">
          {{ "BUSINESS_OPPORTUNITIES.ReportDetail" | translate }}
        </span>
      </div>
      <button
        class="close-dialog"
        mat-dialog-close
        mat-icon-button
        [disabled]="avoidCloseDialog"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div *ngIf="data.opportunityType === 'bm'" class="content p-relative">
      <div class="d-flex justify-content-between ta-center">
        <div>
          <div style="width: 180px;">{{ "BUSINESS_OPPORTUNITIES.CommerceSector" | translate }}:</div>
          <div class="fw-700">
            {{ data.item?.commerceSector?.name }}
          </div>
        </div>
        <div>
          <div style="width: 180px;">{{ "BUSINESS_OPPORTUNITIES.Product" | translate }}:</div>
          <div class="fw-700">
            {{ data.item?.productName | titlecase }}
          </div>
        </div>
        <div>
          <div style="width: 180px;">{{ "BUSINESS_OPPORTUNITIES.ProductTypology" | translate }}</div>
          <div class="fw-700">
            {{
              data?.item?.isSold
                ? ("BUSINESS_OPPORTUNITIES.Sold" | translate)
                : ("BUSINESS_OPPORTUNITIES.Bought" | translate)
            }}
          </div>
        </div>
        <!-- <div>
          <div>{{ "BUSINESS_OPPORTUNITIES.Type" | translate }}</div>
          <div class="fw-700">
            {{
              data?.item?.isClient
                ? ("BUSINESS_OPPORTUNITIES.Client" | translate)
                : ("BUSINESS_OPPORTUNITIES.User" | translate)
            }}
          </div>
        </div> -->
      </div>
      <hr />
      <div class="d-flex justify-content-between ta-center">
        <div style="width: 180px;">
          <div>{{ "BUSINESS_OPPORTUNITIES.QuantityOrder" | translate }}:</div>
          <div class="fw-700 ml-05">
            {{ data.item?.productQuantityOrder | number: "1.0-2":undefined }}
          </div>
        </div>
        <div style="width: 180px;">
          <div>{{ "BUSINESS_OPPORTUNITIES.QuantityYear" | translate }}:</div>
          <div class="fw-700 ml-05">
            {{ data.item?.productQuantityYear | number: "1.0-2":undefined }}
          </div>
        </div>
        <div style="width: 180px;">
          <div>{{ "BUSINESS_OPPORTUNITIES.BoughtValueYear" | translate }}:</div>
          <div class="fw-700 ml-05">
            {{
              data.item?.productValueYear
                | currency: this._defaultCurrencyCode:"symbol":"1.0-2":undefined
            }}
          </div>
        </div>
      </div>
      <!-- <div>
        <div>{{ "BUSINESS_OPPORTUNITIES.ITADescription" | translate }}</div>
        <div class="fw-700">{{ data?.item?.productDesc }}</div>
      </div>
      <div class="mt-20">
        <div>{{ "BUSINESS_OPPORTUNITIES.ENGDescription" | translate }}</div>
        <div class="fw-700">{{ data?.item?.productDescEn }}</div>
      </div> -->
      <hr />
      <div
        class="customer-container d-flex"
        [ngClass]="data?.item?.isSold ? 'seller' : 'buyer'"
      >
        <div class="company pr-15">
          <div>
            {{
              (data?.item?.isSold
                ? "BUSINESS_OPPORTUNITIES.CompanyThatSell"
                : "BUSINESS_OPPORTUNITIES.CompanyThatBuy"
              ) | translate
            }}
          </div>
          <div>
            <strong>{{ data?.item?.businessName }}</strong>
            ({{
              (data?.item?.isClient ? "COMMON.Customer" : "COMMON.User")
                | translate
            }})
          </div>
          <div>
            {{
              (data?.item?.isSold
                ? "BUSINESS_OPPORTUNITIES.SellingBMReferent"
                : "BUSINESS_OPPORTUNITIES.BuyingBMReferent"
              ) | translate
            }}:
            <span class="fw-700">
              {{ data?.item?.customerRefName }}
              {{ data?.item?.customerRefSurname }}
            </span>
          </div>
          <div>
            {{ data?.item?.customerRefTelephone }}
          </div>
          <div>
            {{ data?.item.customerRefEmail }}
          </div>
        </div>
        <div class="manager pl-15">
          <div>
            {{
              (data?.item?.manager?.name ? "COMMON.DLManager" : "COMMON.Agent")
                | translate
            }}
          </div>
          <div class="fw-700">
            {{ data?.item?.manager?.name || data?.item?.agent?.name }}
            {{ data?.item?.manager?.surname || data?.item?.agent?.surname }}
          </div>
          <div>
            {{ data?.item?.manager?.telephone || data?.item?.agent?.telephone }}
          </div>
          <div>
            {{ data?.item?.manager?.email || data?.item?.agent?.email }}
          </div>
        </div>
      </div>
      <!-- <div
          class="next-container d-flex align-items-center justify-content-center"
        >
          <mat-icon>navigate_next</mat-icon>
        </div> -->
      <!-- <div
        *ngIf="!data?.item?.isSold"
        class="customer-container buyer d-flex align-items-center"
      >
        <div class="company pr-15">
          <div>{{ "BUSINESS_OPPORTUNITIES.CompanyThatBuy" | translate }}</div>
          <div class="fw-700">
            {{
              !data?.item?.isSold
                ? data?.item?.businessName
                : data?.item.businessNameMatched
            }}
          </div>
          <div>
            {{ "BUSINESS_OPPORTUNITIES.BuyingBMReferent" | translate }}:
            <span class="fw-700">
              {{
                !data?.item?.isSold
                  ? data?.item?.customerRefName
                  : data?.item.refNameMatched
              }}
              {{
                !data?.item?.isSold
                  ? data?.item?.customerRefSurname
                  : data?.item.refSurnameMatched
              }}
            </span>
          </div>
          <div>
            {{
              !data?.item?.isSold
                ? data?.item?.customerRefTelephone
                : data?.item.refTelephoneMatched
            }}
          </div>
          <div>
            {{
              !data?.item?.isSold
                ? data?.item.customerRefEmail
                : data?.item.refEmailMatched
            }}
          </div>
        </div>
        <div
          *ngIf="isDirection || (!isDirection && !data?.item?.isSold)"
          class="pl-15"
          [ngClass]="{
            'flex-basis-50': isDirection
              ? true
              : !data?.item?.isSold
              ? true
              : false
          }"
        >
          <div>
            {{
              data?.item?.manager?.name
                ? ("COMMON.DLManager" | translate)
                : ("COMMON.Agent" | translate)
            }}
          </div>
          <div class="fw-700">
            {{
              !data?.item?.isSold
                ? data?.item?.manager?.name || data?.item?.agent?.name
                : data?.item.managerMatched?.name ||
                  data?.item?.agentMatched?.name
            }}
            {{
              !data?.item?.isSold
                ? data?.item?.manager?.surname || data?.item?.agent?.surname
                : data?.item?.managerMatched?.surname ||
                  data?.item?.agentMatched?.surname
            }}
          </div>
          <div>
            {{
              !data?.item?.isSold
                ? data?.item?.manager?.telephone || data?.item?.agent?.telephone
                : data?.item?.managerMatched?.telephone ||
                  data?.item?.agentMatched?.telephone
            }}
          </div>
          <div>
            {{
              !data?.item?.isSold
                ? data?.item?.manager?.email || data?.item?.agent?.email
                : data?.item?.managerMatched?.email ||
                  data?.item?.agentMatched?.email
            }}
          </div>
        </div>
      </div> -->

      <div
        *ngIf="pageIsCustom"
        class="d-flex align-items-center justify-content-center mt-40"
      >
        <mat-slide-toggle
          color="primary"
          [formControl]="checkBMOpportunitiesStatus"
        >
          {{ "BUSINESS_OPPORTUNITIES.Visualized" | translate }}
        </mat-slide-toggle>
        <button
          [mat-dialog-close]="true"
          class="rounded-button primary-bg-button ml-30"
          [disabled]="checkBMOpportunitiesStatus.value !== true"
        >
          {{ "COMMON.Save" | translate }}
        </button>
      </div>
      <div *ngIf="pageIsCustom && !data?.item?.isSold" class="export-button">
        <a
          href="mailto:{{ data?.item?.refEmailMatched }}"
          class="rounded-button primary-bg-button send-email"
        >
          {{ "BUSINESS_OPPORTUNITIES.SendEmail" | translate }}
        </a>
      </div>
      <div *ngIf="pageIsCustom && data?.item?.isSold" class="export-button">
        <button
          class="rounded-button primary-bg-button"
          type="button"
          (click)="exportBM(data?.item)"
        >
          {{ "COMMON.Transfer" | translate }}
        </button>
      </div>
    </div>
    <div
      *ngIf="
        data.opportunityType === 'lostOffer' ||
        data.opportunityType === 'report'
      "
      class="content"
    >
      <div class="d-flex justify-content-between ta-center">
        <div *ngIf="!pageIsCustom">
          <div style="width: 180px;">{{ "BUSINESS_OPPORTUNITIES.CommerceSector" | translate }}</div>
          <div class="fw-700">
            {{ data.item?.commerceSector?.name }}
          </div>
        </div>
        <div style="width: 180px;">
          <div>{{ "BUSINESS_OPPORTUNITIES.Product" | translate }}</div>
          <div class="fw-700">
            {{ data.item?.productName | titlecase }}
          </div>
        </div>
        <div style="width: 180px;">
          <div>{{ "CRM_DETAIL.UnitOfMeasure" | translate }}</div>
          <div class="fw-700">
            {{
              data.item?.uom?.name || data?.item?.unitOfMeasure?.name
                | titlecase
            }}
          </div>
        </div>
        <!-- <div>
          <div>{{ "BUSINESS_OPPORTUNITIES.Country" | translate }}</div>
          <div class="fw-700">{{ data?.item?.country?.country }}</div>
        </div>
        <div>
          <div>{{ "CRM_DETAIL.DealQuantity" | translate }}</div>
          <div class="fw-700">{{ data.item?.quantity }}</div>
        </div>
        <div>
          <div>{{ "CRM_DETAIL.DealValue" | translate }}</div>
          <div class="fw-700">
            {{
              data.item?.revenue
                | currency: this._defaultCurrencyCode:"symbol":"1.0-2":undefined
            }}
          </div>
        </div> -->
      </div>
      <!-- <div class="mt-20">
        <div>{{ "BUSINESS_OPPORTUNITIES.Reason" | translate }}</div>
        <div class="fw-700">
          {{ data.item?.dealStateReason?.description }}
        </div>
      </div> -->
      <hr />
      <div class="d-flex justify-content-between ta-center">
        <div style="width: 180px;">
          <div>{{ "CRM_DETAIL.QuantityOrder" | translate }}</div>
          <div class="fw-700">
            {{
              (data?.opportunityType === "lostOffer"
                ? data?.item?.estimatedQuantityOrder
                : data?.item?.quantity) || "0"
            }}
          </div>
        </div>
        <div style="width: 180px;">
          <div>{{ "CRM_DETAIL.QuantityYear" | translate }}</div>
          <div class="fw-700">
            {{
              (data?.opportunityType === "lostOffer"
                ? data?.item?.estimatedQuantityYear
                : data?.item?.quantityYear) || "0"
            }}
          </div>
        </div>
        <div style="width: 180px;">
          <div>{{ "CRM_DETAIL.ValueYear" | translate }}</div>
          <div class="fw-700">
            {{
              (data?.opportunityType === "lostOffer"
                ? data?.item?.estimatedValueYear
                : data?.item.valueYear) || "0"
                | currency: this._defaultCurrencyCode:"symbol":"1.0-2":undefined
            }}
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div>
          {{ "CRM_DETAIL.ItalianDescription" | translate }}
        </div>
        <div class="fw-700">
          {{ data?.item?.italianDescription || data?.item?.productDescription }}
        </div>
      </div>
      <div class="mt-20">
        <div>
          {{ "CRM_DETAIL.EnglishDescription" | translate }}
        </div>
        <div class="fw-700">
          {{
            data?.item?.englishDescription ||
              data?.item?.productEnglishDescription
          }}
        </div>
      </div>
      <hr />
      <div class="customer-container buyer d-flex">
        <div class="company pr-15">
          <div>{{ "BUSINESS_OPPORTUNITIES.Company" | translate }}</div>
          <div class="fw-700">{{ data?.item?.contact?.businessName }}</div>
          <div>
            {{ data?.item?.contactReference?.name }}
            {{ data?.item?.contactReference?.surname }}
          </div>
          <div>{{ data?.item?.contactReference?.tel }}</div>
          <div>{{ data?.item?.contactReference?.email }}</div>
          <div>{{ data?.item?.contact?.country?.country }}</div>
        </div>
        <div class="manager pl-15">
          <div>{{ "BUSINESS_OPPORTUNITIES.DLManager" | translate }}</div>
          <div class="fw-700">
            {{ data?.item?.userInfo?.name || data?.item.userInsertDTO?.name }}
            {{
              data?.item?.userInfo?.surname || data?.item.userInsertDTO?.surname
            }}
          </div>
          <div>
            {{
              data?.item?.userInfo?.telephone ||
                data?.item.userInsertDTO?.telephone
            }}
          </div>
          <div>
            {{
              data?.item?.userInfo?.email || data?.item.userInsertDTO?.email
            }}
          </div>
        </div>
      </div>
      <!-- <div class="primary-container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 pl-30 vertical-bar">
            <div>{{ "BUSINESS_OPPORTUNITIES.Company" | translate }}</div>
            <div class="fw-700">{{ data?.item?.contact?.businessName }}</div>
            <div class="d-flex">
              <div>
                {{ data?.item?.contactReference?.name }}
                {{ data?.item?.contactReference?.surname }}
              </div>
              <span class="mx-10">|</span>
              <div>{{ data?.item?.contactReference?.tel }}</div>
              <span class="mx-10">|</span>
              <div>{{ data?.item?.contactReference?.email }}</div>
            </div>
          </div>
        </div>
      </div> -->
      <div
        *ngIf="data.opportunityType === 'lostOffer'"
        class="content p-relative"
      >
        <div
          *ngIf="pageIsCustom"
          class="d-flex align-items-center justify-content-center mt-40"
        >
          <mat-slide-toggle
            color="primary"
            [formControl]="checkLostOfferStatus"
          >
            {{ "BUSINESS_OPPORTUNITIES.Visualized" | translate }}
          </mat-slide-toggle>
          <button
            [mat-dialog-close]="true"
            class="rounded-button primary-bg-button ml-30"
            [disabled]="checkLostOfferStatus.value !== true"
          >
            {{ "COMMON.Save" | translate }}
          </button>
          <div *ngIf="pageIsCustom" class="export-button">
            <button
              class="rounded-button primary-bg-button"
              type="button"
              (click)="confirmExportLostOffer(data?.item)"
            >
              {{ "COMMON.Transfer" | translate }}
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="data.opportunityType === 'report'" class="content p-relative">
        <div
          *ngIf="pageIsCustom"
          class="d-flex align-items-center justify-content-center mt-40"
        >
          <mat-slide-toggle color="primary" [formControl]="checkReportStatus">
            {{ "BUSINESS_OPPORTUNITIES.Visualized" | translate }}
          </mat-slide-toggle>
          <button
            [mat-dialog-close]="true"
            class="rounded-button primary-bg-button ml-30"
            [disabled]="checkReportStatus.value !== true"
          >
            {{ "COMMON.Save" | translate }}
          </button>
        </div>
        <div *ngIf="pageIsCustom" class="export-button">
          <button
            class="rounded-button primary-bg-button"
            type="button"
            (click)="confirmExportReports(data?.item)"
          >
            {{ "COMMON.Transfer" | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="data.opportunityType === 'report'" class="content">
      <div class="d-flex justify-content-between mr-40 ta-center">
        <div>
          <div>{{ "BUSINESS_OPPORTUNITIES.Country" | translate }}</div>
          <div class="fw-700">
            {{ data?.item?.contact?.country?.country }}
          </div>
        </div>
        <div>
          <div>{{ "BUSINESS_OPPORTUNITIES.CommerceSector" | translate }}</div>
          <div class="fw-700">
            {{ data.item?.commerceSector?.name }}
          </div>
        </div>
        <div>
          <div>{{ "CRM_DETAIL.UnitOfMeasure" | translate }}</div>
          <div class="fw-700">{{ data.item?.unitOfMeasure?.name }}</div>
        </div>
      </div>
      <hr />
      <div class="d-flex justify-content-between mr-40 ta-center">
        <div>
          <div>{{ "CRM_DETAIL.EstimatedQuantityOrder" | translate }}</div>
          <div class="fw-700">
            {{ data?.item?.quantity || "0" }}
          </div>
        </div>
        <div>
          <div>{{ "CRM_DETAIL.EstimatedQuantityYear" | translate }}</div>
          <div class="fw-700 ml-05">
            {{ data?.item?.quantityYear || "0" }}
          </div>
        </div>
        <div>
          <div>{{ "CRM_DETAIL.EstimatedValueYear" | translate }}</div>
          <div class="fw-700 ml-05">
            {{
              data?.item?.valueYear || "0"
                | currency: this._defaultCurrencyCode:"symbol":"1.0-2":undefined
            }}
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="fw-700">
          {{ "BUSINESS_OPPORTUNITIES.ITADescription" | translate }}
        </div>
        <div>{{ data?.item?.productDescription }}</div>
      </div>
      <div class="mt-20">
        <div class="fw-700">
          {{ "BUSINESS_OPPORTUNITIES.ENGDescription" | translate }}
        </div>
        <div>{{ data?.item?.productEnglishDescription }}</div>
      </div>
      <hr />
      <div class="primary-container">
        <div class="row">
          <div class="col-6">
            <div>
              {{ "BUSINESS_OPPORTUNITIES.DLManager" | translate }}
            </div>
            <div class="fw-700">
              {{ data?.item?.userInsertDTO?.name }}
              {{ data?.item?.userInsertDTO?.surname }}
            </div>
            <div class="d-flex">
              <div>{{ data?.item?.userInsertDTO?.telephone }}</div>
              <span class="mx-10">|</span>
              <div>{{ data?.item?.userInsertDTO?.email }}</div>
            </div>
          </div>
          <div class="col-6 pl-30 vertical-bar">
            <div>{{ "BUSINESS_OPPORTUNITIES.Company" | translate }}</div>
            <div class="fw-700">{{ data?.item?.contact?.businessName }}</div>
            <div class="d-flex">
              <div>
                {{ data?.item?.contactReference?.name }}
                {{ data?.item?.contactReference?.surname }}
              </div>
              <span class="mx-10">|</span>
              <div>{{ data?.item?.contactReference?.tel }}</div>
              <span class="mx-10">|</span>
              <div>{{ data?.item?.contactReference?.email }}</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </ng-template>
  <mat-tab>
    <ng-template matTabLabel>
      <span>{{ "CRM.TotalCompetitors" | translate }}</span>
    </ng-template>
    <div class="row">
      <div class="col-9">
        <div class="section-card">
          <div class="content">
            <app-table
              [columns]="contactCompetitorsTableColumns"
              [columnsButtons]="contactCompetitorsTableButtons"
              [data]="contactCompetitors"
              [searchValue]="searchValueCompetitors.value"
            ></app-table>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="section-card">
          <div class="header multi h70">
            <div class="title">
              <span>{{ "COMMON.Filters" | translate }}</span>
            </div>
            <div class="text-button">
              <span class="primary-color" (click)="resetFilters('competitors')">
                {{ "COMMON.Reset" | translate | lowercase }}
              </span>
            </div>
          </div>
          <div class="content">
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "CRM.Research" | translate }}</mat-label>
                <input
                  matInput
                  name="research"
                  type="text"
                  #searchValueCompetitors
                />
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "CRM.ReferenceMarket" | translate }}</mat-label>
                <mat-select
                  #contactCompetitorsCountryFilter
                  (selectionChange)="
                    filterContactCompetitorsTable('country', $event)
                  "
                >
                  <mat-option
                    *ngFor="let country of filteredCountriesContactCompetitors"
                    [value]="country"
                  >
                    {{ country }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <ng-template #contactCompetitorDialog let-close="close" let-data>
    <div class="header h70 no-margin-top">
      <div class="title">
        <span>
          {{ "CRM_DETAIL.ContactDetails" | translate }}
        </span>
      </div>
      <button
        class="close-dialog"
        mat-dialog-close
        mat-icon-button
        [disabled]="avoidCloseDialog"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="content">
      <app-table
        [columns]="contactCompetitorsDetailTableColumns"
        [data]="data.competitorContacts"
      ></app-table>
    </div>
  </ng-template>
  <mat-tab>
    <ng-template matTabLabel>
      <span>{{ "CRM.CommercialSummary" | translate }}</span>
    </ng-template>
    <div class="row">
      <div class="col-9">
        <div class="section-card">
          <div class="content">
            <app-table
              [columns]="contactsCommercialSummaryTableColumns"
              [data]="contactsCommercialSummary"
              [searchValue]="searchValueCommercialSummary.value"
            ></app-table>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="section-card">
          <div class="header multi h70">
            <div class="title">
              <span>{{ "COMMON.Filters" | translate }}</span>
            </div>
            <div class="text-button">
              <span class="primary-color" (click)="resetFilters('commercialSummary')">
                {{ "COMMON.Reset" | translate | lowercase }}
              </span>
            </div>
          </div>
          <div class="content">
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "CRM.Research" | translate }}</mat-label>
                <input
                  matInput
                  name="research"
                  type="text"
                  #searchValueCommercialSummary
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Geolocation" *ngIf="false">
    <ng-template matTabLabel>
      <span>{{ "CRM_DETAIL.Geolocation" | translate }}</span>
    </ng-template>
    <div class="row">
      <div class="col-9">
        <div class="section-card h100">
          <div class="content h100">
            <div #mapDiv class="map-container p-relative">
              <div
                #popup
                class="popup"
                [ngClass]="showPopup ? 'show' : 'hide'"
              ></div>
              <div
                #lateralPopup
                class="lateral-popup"
                [ngClass]="showLateralPopup ? 'show' : 'hide'"
              ></div>
              <!-- <div *ngIf="dlmanagerInfoLoader" class="spinner-container-center">
                  <mat-spinner diameter="40"></mat-spinner>
                </div> -->
            </div>
            <div class="legend">
              <div class="legend-item">
                <svg-icon name="circle" class="w16 h16"></svg-icon>
                <div class="label">{{ "COMMON.Cluster" | translate }}</div>
              </div>
              <div class="legend-item">
                <svg-icon name="pin_pps" class="w20 h20"></svg-icon>
                <div class="label">
                  {{ "CRM_DETAIL.Active" | translate }}
                </div>
              </div>
              <div class="legend-item">
                <svg-icon name="pin_pps" class="w20 h20"></svg-icon>
                <div class="label">
                  {{ "CRM_DETAIL.Prospect" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="section-card">
          <div class="header multi h70">
            <div class="title">
              <span>{{ "COMMON.Filters" | translate }}</span>
            </div>
            <div class="text-button">
              <span class="primary-color" (click)="resetMapFilters()">
                {{ "COMMON.Reset" | translate | lowercase }}
              </span>
            </div>
          </div>
          <div class="content">
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "COMMON.Market" | translate }}</mat-label>
                <mat-select
                  disableOptionCentering
                  [formControl]="filterCountryMap"
                  (selectionChange)="filterContactsOnMap()"
                  (openedChange)="filter.focus(); filter.value = ''"
                >
                  <div class="select-global-filter">
                    <input
                      #filter
                      matInput
                      type="text"
                      [placeholder]="'COMMON.Search...' | translate"
                    />
                  </div>
                  <mat-option value="all">
                    {{ "COMMON.All" | translate }}
                  </mat-option>
                  <mat-option
                    *ngFor="
                      let country of countriesForMap
                        | filterSelect: filter.value
                    "
                    [value]="country"
                  >
                    {{ country }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "CRM.Status2" | translate }}</mat-label>
                <mat-select
                  [formControl]="filterStatusMap"
                  (selectionChange)="filterContactsOnMap()"
                >
                  <mat-option value="all">
                    {{ "COMMON.All" | translate }}
                  </mat-option>
                  <mat-option value="active">
                    {{ "BUYER_STATUS_NAME.ACTIVE" | translate }}
                  </mat-option>
                  <mat-option value="prospect">
                    {{ "BUYER_STATUS_NAME.PROSPECT" | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label>{{ "CRM.Channel" | translate }}</mat-label>
                <mat-select
                  [formControl]="filterChannelMap"
                  (selectionChange)="filterContactsOnMap()"
                >
                  <mat-option value="all">
                    {{ "COMMON.All" | translate }}
                  </mat-option>
                  <mat-option
                    *ngFor="let channel of channels"
                    [value]="channel.idChannelType"
                  >
                    {{ channel.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
