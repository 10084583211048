<div class="p-relative">
  <mat-tab-group
    mat-align-tabs="center"
    dynamicHeight
    class="custom-mat-tab"
    #matTabGroup
    [(selectedIndex)]="index"
    [ngClass]="isDirection ? 'reduced-width' : ''"
  >
    <mat-tab>
      <ng-template matTabLabel>
        <span *ngIf="!showBMBadge">
          {{ "BUSINESS_OPPORTUNITIES.BM" | translate }}
        </span>
        <span
          *ngIf="showBMBadge"
          matBadge="&#8288;"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "BUSINESS_OPPORTUNITIES.BM" | translate }}
        </span>
        <span *ngIf="isDirection" class="table-elems-counter">
          {{ bmOpportunitiesForTable?.length || "0" }}
        </span>
      </ng-template>
      <div class="d-flex">
        <div class="table-data section-card flex-basis-75 mr-15">
          <div class="content">
            <app-table
              [loader]="bmOpportunitiesLoader"
              [columns]="bmOpportunitiesTableColumns"
              [columnsButtons]="bmOpportunitiesTableButtons"
              [rowBadgeNewElements]="pageIsCustom"
              [data]="bmOpportunitiesForTable"
              [resetPaginator]="resetBMTablePaginator"
            ></app-table>
          </div>
        </div>
        <div
          class="filters-container section-card vertical flex-basis-25 ml-15"
        >
          <div class="header multi">
            <div class="title">
              <span>{{ "COMMON.Filters" | translate }}</span>
            </div>
            <div class="text-button">
              <span class="primary-color" (click)="resetBmFilters()">
                {{ "COMMON.Reset" | translate | lowercase }}
              </span>
            </div>
          </div>
          <div class="content d-flex justify-content-between flex-column h100">
            <form
              [formGroup]="bmFilters"
              (ngSubmit)="applyBmFilters('reset')"
              [ngClass]="pageIsCustom ? 'width50' : 'width20'"
            >
              <mat-form-field
                *ngIf="!pageIsCustom"
                appearance="standard"
                class="col"
              >
                <mat-label for="idCommerceSector">{{
                  "IR_FORM.CommerceSector" | translate
                }}</mat-label>
                <mat-select
                  formControlName="idCommerceSector"
                  name="idCommerceSector"
                >
                  <mat-option value="all">{{
                    "COMMON.All" | translate
                  }}</mat-option>
                  <mat-option
                    *ngFor="let sector of commerceSectors"
                    [value]="sector.idCommerceSector"
                  >
                    {{ sector.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                *ngIf="!pageIsCustom"
                appearance="standard"
                class="col"
              >
                <mat-label for="wordKey">
                  {{ "COMMON.Product" | translate }}
                </mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="wordKey"
                  name="wordKey"
                />
              </mat-form-field>
              <mat-form-field appearance="standard" class="col">
                <mat-label for="isSold">
                  {{ "BUSINESS_OPPORTUNITIES.Typology" | translate }}
                </mat-label>
                <mat-select formControlName="isSold" name="isSold">
                  <mat-option [value]="false">
                    {{ "COMMON.Bought" | translate }}
                  </mat-option>
                  <mat-option [value]="true">
                    {{ "COMMON.Sold" | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <div class="row">
              <mat-form-field appearance="standard" class="col">
                <mat-label for="isChecked">
                  {{ "BUSINESS_OPPORTUNITIES.Visualized" | translate }}
                </mat-label>
                <mat-select formControlName="isChecked" name="isChecked">
                  <mat-option value="all">
                    {{ "COMMON.All" | translate }}
                  </mat-option>
                  <mat-option [value]="true">
                    {{ "COMMON.Yes" | translate }}
                  </mat-option>
                  <mat-option [value]="false">
                    {{ "COMMON.No" | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
              <mat-form-field
                *ngIf="!pageIsCustom"
                appearance="standard"
                class="col"
              >
                <mat-label for="isClient">
                  {{ "COMMON.Type" | translate }}
                </mat-label>
                <mat-select formControlName="isClient" name="isClient">
                  <mat-option value="all">
                    {{ "COMMON.All" | translate }}
                  </mat-option>
                  <mat-option [value]="true">
                    {{ "COMMON.Customer" | translate }}
                  </mat-option>
                  <mat-option [value]="false">
                    {{ "COMMON.User" | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-12">
                <mat-label>{{ "CRM_DETAIL.Date" | translate }}</mat-label>
                <mat-date-range-input [rangePicker]="bmPicker">
                  <input
                    matStartDate
                    placeholder="{{ 'COMMON.StartDate' | translate }}"
                    formControlName="startDate"
                    readonly
                  />
                  <input
                    matEndDate
                    placeholder="{{ 'COMMON.EndDate' | translate }}"
                    formControlName="endDate"
                    readonly
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="bmPicker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #bmPicker></mat-date-range-picker>
              </mat-form-field>
              <button
                class="mt-20-auto rounded-button primary-bg-button"
                type="submit"
              >
                {{ "COMMON.Apply" | translate }}
              </button>
            </form>
            <button
              *ngIf="isDirection"
              class="rounded-button primary-border-button download-csv"
              type="button"
              [disabled]="bmOpportunitiesLoader"
              (click)="showCSVQuantityDialog('bm')"
            >
              {{ "BUSINESS_OPPORTUNITIES.DownloadResultsAsCSV" | translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template matTabLabel>
        <span *ngIf="!showLostOffersBadge">
          {{ "BUSINESS_OPPORTUNITIES.UnfulfilledOffers" | translate }}
        </span>
        <span
          *ngIf="showLostOffersBadge"
          matBadge="&#8288;"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "BUSINESS_OPPORTUNITIES.UnfulfilledOffers" | translate }}
        </span>
        <span *ngIf="isDirection" class="table-elems-counter">
          {{ lostOffersOpportunitiesForTable?.length || "0" }}
        </span>
      </ng-template>
      <div class="d-flex">
        <div class="table-data section-card flex-basis-75 mr-15">
          <div class="content">
            <div>
              <app-table
                [loader]="lostOffersOpportunitiesLoader"
                [columns]="lostOffersOpportunitiesTableColumns"
                [columnsButtons]="lostOffersOpportunitiesTableButtons"
                [data]="lostOffersOpportunitiesForTable"
                [rowBadgeNewElements]="pageIsCustom"
                [resetPaginator]="resetLostOffersTablePaginator"
              ></app-table>
            </div>
          </div>
        </div>
        <div
          class="filters-container section-card vertical flex-basis-25 ml-15"
        >
          <div class="header multi">
            <div class="title">
              <span>{{ "COMMON.Filters" | translate }}</span>
            </div>
            <div class="text-button">
              <span class="primary-color" (click)="resetLostOffersFilters()">
                {{ "COMMON.Reset" | translate | lowercase }}
              </span>
            </div>
          </div>
          <div class="content d-flex justify-content-between flex-column h100">
            <form
              [formGroup]="lostOffersFilters"
              (ngSubmit)="applyLostOffersFilters('reset')"
            >
              <mat-form-field appearance="standard" class="col">
                <mat-label for="idCommerceSector">{{
                  "IR_FORM.CommerceSector" | translate
                }}</mat-label>
                <mat-select
                  formControlName="idCommerceSector"
                  name="idCommerceSector"
                >
                  <mat-option value="all">{{
                    "COMMON.All" | translate
                  }}</mat-option>
                  <mat-option
                    *ngFor="let sector of commerceSectors"
                    [value]="sector.idCommerceSector"
                  >
                    {{ sector.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                *ngIf="!pageIsCustom"
                appearance="standard"
                class="col"
              >
                <mat-label for="productDescription">
                  {{ "COMMON.Product" | translate }}
                </mat-label>
                <input
                  matInput
                  name="productDescription"
                  formControlName="productDescription"
                  type="text"
                />
              </mat-form-field>
              <mat-form-field appearance="standard" class="col">
                <mat-label for="idCountry">{{
                  "BUSINESS_OPPORTUNITIES.Country" | translate
                }}</mat-label>
                <mat-select formControlName="idCountry" name="idCountry">
                  <mat-option value="all">
                    {{ "COMMON.All" | translate }}
                  </mat-option>
                  <mat-option
                    *ngFor="let country of countries"
                    [value]="country.idCountry"
                  >
                    {{ country.country }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-12">
                <mat-label>{{ "CRM_DETAIL.Date" | translate }}</mat-label>
                <mat-date-range-input [rangePicker]="lostOfferspicker">
                  <input
                    matStartDate
                    placeholder="{{ 'COMMON.StartDate' | translate }}"
                    formControlName="startDate"
                    readonly
                  />
                  <input
                    matEndDate
                    placeholder="{{ 'COMMON.EndDate' | translate }}"
                    formControlName="endDate"
                    readonly
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="lostOfferspicker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker
                  #lostOfferspicker
                ></mat-date-range-picker>
              </mat-form-field>
              <button
                class="mt-20-auto rounded-button primary-bg-button"
                type="submit"
              >
                {{ "COMMON.Apply" | translate }}
              </button>
            </form>
            <button
              *ngIf="isDirection"
              class="rounded-button primary-border-button download-csv"
              type="button"
              [disabled]="lostOffersOpportunitiesLoader"
              (click)="showCSVQuantityDialog('lostOffers')"
            >
              {{ "BUSINESS_OPPORTUNITIES.DownloadResultsAsCSV" | translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template matTabLabel>
        <span *ngIf="!showReportsBadge">
          {{ "BUSINESS_OPPORTUNITIES.Reports" | translate }}
        </span>
        <span
          *ngIf="showReportsBadge"
          matBadge="&#8288;"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "BUSINESS_OPPORTUNITIES.Reports" | translate }}
        </span>
        <span *ngIf="isDirection" class="table-elems-counter">
          {{ reportsOpportunitiesForTable?.length || "0" }}
        </span>
      </ng-template>
      <div class="d-flex">
        <div class="table-data section-card flex-basis-75 mr-15">
          <div class="content">
            <app-table
              [loader]="reportsOpportunitiesLoader"
              [columns]="reportsOpportunitiesTableColumns"
              [columnsButtons]="reportsOpportunitiesTableButtons"
              [data]="reportsOpportunitiesForTable"
              [rowBadgeNewElements]="pageIsCustom"
              [resetPaginator]="resetReportsTablePaginator"
            ></app-table>
          </div>
        </div>
        <div
          class="filters-container section-card vertical flex-basis-25 ml-15"
        >
          <div class="header multi">
            <div class="title">
              <span>{{ "COMMON.Filters" | translate }}</span>
            </div>
            <div class="text-button">
              <span class="primary-color" (click)="resetReportsFilters()">
                {{ "COMMON.Reset" | translate | lowercase }}
              </span>
            </div>
          </div>
          <div class="content d-flex justify-content-between flex-column h100">
            <form
              [formGroup]="reportsFilters"
              (ngSubmit)="applyReportsFilters('reset')"
            >
              <mat-form-field appearance="standard" class="col">
                <mat-label for="idCommerceSector">{{
                  "IR_FORM.CommerceSector" | translate
                }}</mat-label>
                <mat-select
                  formControlName="idCommerceSector"
                  name="idCommerceSector"
                >
                  <mat-option value="all">{{
                    "COMMON.All" | translate
                  }}</mat-option>
                  <mat-option
                    *ngFor="let sector of commerceSectors"
                    [value]="sector.idCommerceSector"
                  >
                    {{ sector.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                *ngIf="!pageIsCustom"
                appearance="standard"
                class="col"
              >
                <mat-label for="productDescription">
                  {{ "COMMON.ProductDescription" | translate }}
                </mat-label>
                <input
                  matInput
                  name="productDescription"
                  formControlName="productDescription"
                  type="text"
                />
              </mat-form-field>
              <mat-form-field appearance="standard" class="col">
                <mat-label for="idCountry">{{
                  "BUSINESS_OPPORTUNITIES.Country" | translate
                }}</mat-label>
                <mat-select formControlName="idCountry" name="idCountry">
                  <mat-option value="all">
                    {{ "COMMON.All" | translate }}
                  </mat-option>
                  <mat-option
                    *ngFor="let country of countries"
                    [value]="country.idCountry"
                  >
                    {{ country.country }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-12">
                <mat-label>{{ "CRM_DETAIL.Date" | translate }}</mat-label>
                <mat-date-range-input [rangePicker]="reportsPicker">
                  <input
                    matStartDate
                    placeholder="{{ 'COMMON.StartDate' | translate }}"
                    formControlName="startDate"
                    readonly
                  />
                  <input
                    matEndDate
                    placeholder="{{ 'COMMON.EndDate' | translate }}"
                    formControlName="endDate"
                    readonly
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="reportsPicker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #reportsPicker></mat-date-range-picker>
              </mat-form-field>
              <button
                class="mt-20-auto rounded-button primary-bg-button"
                type="submit"
              >
                {{ "COMMON.Apply" | translate }}
              </button>
            </form>
            <button
              *ngIf="isDirection"
              class="rounded-button primary-border-button download-csv"
              type="button"
              [disabled]="reportsOpportunitiesLoader"
              (click)="showCSVQuantityDialog('reports')"
            >
              {{ "BUSINESS_OPPORTUNITIES.DownloadResultsAsCSV" | translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <button
    *ngIf="isDirection"
    class="only-icon-button primary settings-email"
    type="button"
    (click)="showSettingsEmailDialog()"
  >
    <svg-icon name="iconSettingMail" class="w36 h36"></svg-icon>
  </button>
  <ng-template #confirmExportReportsDialog let-close="close" let-data>
    <div class="header h70 no-margin-top">
      <div class="title">
        <span>
          {{ "COMMON.ConfirmReportExport" | translate }}
        </span>
      </div>
      <button
        class="close-dialog"
        mat-dialog-close
        mat-icon-button
        [disabled]="avoidCloseDialog"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="content">
      <mat-form-field appearance="standard" class="col">
        <mat-label>{{ "CRM.Customer" | translate }}</mat-label>
        <mat-select (selectionChange)="selectCustomer($event)">
          <mat-option *ngFor="let customer of allCustomers" [value]="customer">
            {{ customer.businessName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="standard" class="col">
        <mat-label>{{ "COMMON.Project" | translate }}</mat-label>
        <mat-select
          [disabled]="!customerProjects?.length"
          (selectionChange)="selectProject($event)"
        >
          <mat-option
            *ngFor="let project of customerProjects"
            [value]="project"
          >
            PR-{{ project.idProject }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="buttons center">
      <button
        class="rounded-button primary-bg-button"
        type="button"
        [mat-dialog-close]="selectedProject"
      >
        {{ "COMMON.Transfer" | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template #confirmLostOffersExport let-close="close" let-data>
    <div class="header h70 no-margin-top">
      <div class="title">
        <span>
          {{ "COMMON.ConfirmReportExport" | translate }}
        </span>
      </div>
      <button
        class="close-dialog"
        mat-dialog-close
        mat-icon-button
        [disabled]="avoidCloseDialog"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="content">
      <mat-form-field appearance="standard" class="col">
        <mat-label>{{ "CRM.Customer" | translate }}</mat-label>
        <mat-select (selectionChange)="selectCustomer($event)">
          <mat-option *ngFor="let customer of allCustomers" [value]="customer">
            {{ customer.businessName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="standard" class="col">
        <mat-label>{{ "COMMON.Project" | translate }}</mat-label>
        <mat-select
          [disabled]="!customerProjects?.length"
          (selectionChange)="selectProject($event)"
        >
          <mat-option
            *ngFor="let project of customerProjects"
            [value]="project"
          >
            PR-{{ project.idProject }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="buttons center">
      <button
        class="rounded-button primary-bg-button"
        type="button"
        [mat-dialog-close]="selectedProject"
      >
        {{ "COMMON.Transfer" | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template #opportunityDetailDialog let-close="close" let-data>
    <div class="header h70 no-margin-top">
      <div class="title">
        <span *ngIf="data.opportunityType === 'bm'">
          {{ "BUSINESS_OPPORTUNITIES.BMDetail" | translate }}
        </span>
        <span *ngIf="data.opportunityType === 'lostOffer'">
          {{ "BUSINESS_OPPORTUNITIES.LostOfferDetail" | translate }}
        </span>
        <span *ngIf="data.opportunityType === 'report'">
          {{ "BUSINESS_OPPORTUNITIES.ReportDetail" | translate }}
        </span>
      </div>
      <button
        class="close-dialog"
        mat-dialog-close
        mat-icon-button
        [disabled]="avoidCloseDialog"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div *ngIf="data.opportunityType === 'bm'" class="content p-relative">
      <div class="d-flex justify-content-between ta-center">
        <div>
          <div style="width: 180px;">{{ "BUSINESS_OPPORTUNITIES.CommerceSector" | translate }}:</div>
          <div class="fw-700">
            {{ data.item?.commerceSector?.name }}
          </div>
        </div>
        <div>
          <div style="width: 180px;">{{ "BUSINESS_OPPORTUNITIES.Product" | translate }}:</div>
          <div class="fw-700">
            {{ data.item?.productName | titlecase }}
          </div>
        </div>
        <div>
          <div style="width: 180px;">{{ "BUSINESS_OPPORTUNITIES.ProductTypology" | translate }}:</div>
          <div class="fw-700">
            {{
              data?.item?.isSold
                ? ("BUSINESS_OPPORTUNITIES.Sold" | translate)
                : ("BUSINESS_OPPORTUNITIES.Bought" | translate)
            }}
          </div>
        </div>
        <!-- <div>
          <div>{{ "BUSINESS_OPPORTUNITIES.Type" | translate }}</div>
          <div class="fw-700">
            {{
              data?.item?.isClient
                ? ("BUSINESS_OPPORTUNITIES.Client" | translate)
                : ("BUSINESS_OPPORTUNITIES.User" | translate)
            }}
          </div>
        </div> -->
      </div>
      <hr />
      <div class="d-flex justify-content-between ta-center">
        <div style="width: 180px;">
          <div>{{ "BUSINESS_OPPORTUNITIES.QuantityOrder" | translate }}:</div>
          <div class="fw-700 ml-05">
            {{ data.item?.productQuantityOrder | number: "1.0-2":undefined }}
          </div>
        </div>
        <div style="width: 180px;">
          <div>{{ "BUSINESS_OPPORTUNITIES.QuantityYear" | translate }}:</div>
          <div class="fw-700 ml-05">
            {{ data.item?.productQuantityYear | number: "1.0-2":undefined }}
          </div>
        </div>
        <div style="width: 180px;">
          <div>{{ "BUSINESS_OPPORTUNITIES.BoughtValueYear" | translate }}:</div>
          <div class="fw-700 ml-05">
            {{
              data.item?.productValueYear | currency: this._defaultCurrencyCode:"symbol":"1.0-2":undefined
            }}
          </div>
        </div>
      </div>
      <!-- <div>
        <div>{{ "BUSINESS_OPPORTUNITIES.ITADescription" | translate }}</div>
        <div class="fw-700">{{ data?.item?.productDesc }}</div>
      </div>
      <div class="mt-20">
        <div>{{ "BUSINESS_OPPORTUNITIES.ENGDescription" | translate }}</div>
        <div class="fw-700">{{ data?.item?.productDescEn }}</div>
      </div> -->
      <hr />
      <div
        class="customer-container d-flex"
        [ngClass]="data?.item?.isSold ? 'seller' : 'buyer'"
      >
        <div class="company pr-15">
          <div>
            {{
              (data?.item?.isSold
                ? "BUSINESS_OPPORTUNITIES.CompanyThatSell"
                : "BUSINESS_OPPORTUNITIES.CompanyThatBuy"
              ) | translate
            }}
          </div>
          <div>
            <strong>{{ data?.item?.businessName }}</strong>
            ({{
              (data?.item?.isClient ? "COMMON.Customer" : "COMMON.User")
                | translate
            }})
          </div>
          <div>
            {{
              (data?.item?.isSold
                ? "BUSINESS_OPPORTUNITIES.SellingBMReferent"
                : "BUSINESS_OPPORTUNITIES.BuyingBMReferent"
              ) | translate
            }}:
            <span class="fw-700">
              {{ data?.item?.customerRefName }}
              {{ data?.item?.customerRefSurname }}
            </span>
          </div>
          <div>
            {{ data?.item?.customerRefTelephone }}
          </div>
          <div>
            {{ data?.item.customerRefEmail }}
          </div>
        </div>
        <div class="manager pl-15">
          <div>
            {{
              (data?.item?.manager?.name ? "COMMON.DLManager" : "COMMON.Agent")
                | translate
            }}
          </div>
          <div class="fw-700">
            {{ data?.item?.manager?.name || data?.item?.agent?.name }}
            {{ data?.item?.manager?.surname || data?.item?.agent?.surname }}
          </div>
          <div>
            {{ data?.item?.manager?.telephone || data?.item?.agent?.telephone }}
          </div>
          <div>
            {{ data?.item?.manager?.email || data?.item?.agent?.email }}
          </div>
        </div>
      </div>
      <!-- <div
          class="next-container d-flex align-items-center justify-content-center"
        >
          <mat-icon>navigate_next</mat-icon>
        </div> -->
      <!-- <div
        *ngIf="!data?.item?.isSold"
        class="customer-container buyer d-flex align-items-center"
      >
        <div class="company pr-15">
          <div>{{ "BUSINESS_OPPORTUNITIES.CompanyThatBuy" | translate }}</div>
          <div class="fw-700">
            {{
              !data?.item?.isSold
                ? data?.item?.businessName
                : data?.item.businessNameMatched
            }}
          </div>
          <div>
            {{ "BUSINESS_OPPORTUNITIES.BuyingBMReferent" | translate }}:
            <span class="fw-700">
              {{
                !data?.item?.isSold
                  ? data?.item?.customerRefName
                  : data?.item.refNameMatched
              }}
              {{
                !data?.item?.isSold
                  ? data?.item?.customerRefSurname
                  : data?.item.refSurnameMatched
              }}
            </span>
          </div>
          <div>
            {{
              !data?.item?.isSold
                ? data?.item?.customerRefTelephone
                : data?.item.refTelephoneMatched
            }}
          </div>
          <div>
            {{
              !data?.item?.isSold
                ? data?.item.customerRefEmail
                : data?.item.refEmailMatched
            }}
          </div>
        </div>
        <div
          *ngIf="isDirection || (!isDirection && !data?.item?.isSold)"
          class="pl-15"
          [ngClass]="{
            'flex-basis-50': isDirection
              ? true
              : !data?.item?.isSold
              ? true
              : false
          }"
        >
          <div>
            {{
              data?.item?.manager?.name
                ? ("COMMON.DLManager" | translate)
                : ("COMMON.Agent" | translate)
            }}
          </div>
          <div class="fw-700">
            {{
              !data?.item?.isSold
                ? data?.item?.manager?.name || data?.item?.agent?.name
                : data?.item.managerMatched?.name ||
                  data?.item?.agentMatched?.name
            }}
            {{
              !data?.item?.isSold
                ? data?.item?.manager?.surname || data?.item?.agent?.surname
                : data?.item?.managerMatched?.surname ||
                  data?.item?.agentMatched?.surname
            }}
          </div>
          <div>
            {{
              !data?.item?.isSold
                ? data?.item?.manager?.telephone || data?.item?.agent?.telephone
                : data?.item?.managerMatched?.telephone ||
                  data?.item?.agentMatched?.telephone
            }}
          </div>
          <div>
            {{
              !data?.item?.isSold
                ? data?.item?.manager?.email || data?.item?.agent?.email
                : data?.item?.managerMatched?.email ||
                  data?.item?.agentMatched?.email
            }}
          </div>
        </div>
      </div> -->

      <div
        *ngIf="pageIsCustom"
        class="d-flex align-items-center justify-content-center mt-40"
      >
        <mat-slide-toggle
          color="primary"
          [formControl]="checkBMOpportunitiesStatus"
        >
          {{ "BUSINESS_OPPORTUNITIES.Visualized" | translate }}
        </mat-slide-toggle>
        <button
          [mat-dialog-close]="true"
          class="rounded-button primary-bg-button ml-30"
          [disabled]="checkBMOpportunitiesStatus.value !== true"
        >
          {{ "COMMON.Save" | translate }}
        </button>
      </div>
      <div *ngIf="pageIsCustom && !data?.item?.isSold" class="export-button">
        <a
          href="mailto:{{ data?.item?.refEmailMatched }}"
          class="rounded-button primary-bg-button send-email"
        >
          {{ "BUSINESS_OPPORTUNITIES.SendEmail" | translate }}
        </a>
      </div>
      <div *ngIf="pageIsCustom && data?.item?.isSold" class="export-button">
        <button
          class="rounded-button primary-bg-button"
          type="button"
          (click)="exportBM(data?.item)"
        >
          {{ "COMMON.Transfer" | translate }}
        </button>
      </div>
    </div>
    <div
      *ngIf="
        data.opportunityType === 'lostOffer' ||
        data.opportunityType === 'report'
      "
      class="content"
    >
      <div class="d-flex justify-content-between ta-center">
        <div>
          <div style="width: 180px;">{{ "BUSINESS_OPPORTUNITIES.CommerceSector" | translate }}</div>
          <div class="fw-700">
            {{ data.item?.commerceSector?.name }}
          </div>
        </div>
        <div>
          <div style="width: 180px;">{{ "BUSINESS_OPPORTUNITIES.Product" | translate }}</div>
          <div class="fw-700">
            {{ data.item?.productName | titlecase }}
          </div>
        </div>
        <div>
          <div style="width: 180px;">{{ "CRM_DETAIL.UnitOfMeasure" | translate }}</div>
          <div class="fw-700">
            {{
              data.item?.uom?.name || data?.item?.unitOfMeasure?.name
                | titlecase
            }}
          </div>
        </div>
        <!-- <div>
          <div>{{ "BUSINESS_OPPORTUNITIES.Country" | translate }}</div>
          <div class="fw-700">{{ data?.item?.country?.country }}</div>
        </div>
        <div>
          <div>{{ "CRM_DETAIL.DealQuantity" | translate }}</div>
          <div class="fw-700">{{ data.item?.quantity }}</div>
        </div>
        <div>
          <div>{{ "CRM_DETAIL.DealValue" | translate }}</div>
          <div class="fw-700">
            {{
              data.item?.revenue
                | currency: this._defaultCurrencyCode:"symbol":"1.0-2":undefined
            }}
          </div>
        </div> -->
      </div>
      <!-- <div class="mt-20">
        <div>{{ "BUSINESS_OPPORTUNITIES.Reason" | translate }}</div>
        <div class="fw-700">
          {{ data.item?.dealStateReason?.description }}
        </div>
      </div> -->
      <hr />
      <div class="d-flex justify-content-between ta-center">
        <div>
          <div style="width: 180px;">{{ "CRM_DETAIL.QuantityOrder" | translate }}</div>
          <div class="fw-700">
            {{
              (data?.opportunityType === "lostOffer"
                ? data?.item?.estimatedQuantityOrder
                : data?.item?.quantity) || "0"
            }}
          </div>
        </div>
        <div>
          <div style="width: 180px;">{{ "CRM_DETAIL.QuantityYear" | translate }}</div>
          <div class="fw-700">
            {{
              (data?.opportunityType === "lostOffer"
                ? data?.item?.estimatedQuantityYear
                : data?.item?.quantityYear) || "0"
            }}
          </div>
        </div>
        <div>
          <div style="width: 180px;">{{ "CRM_DETAIL.ValueYear" | translate }}</div>
          <div class="fw-700">
            {{
              (data?.opportunityType === "lostOffer"
                ? data?.item?.estimatedValueYear
                : data?.item.valueYear) || "0"
                | currency: this._defaultCurrencyCode:"symbol":"1.0-2":undefined
            }}
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div>
          {{ "CRM_DETAIL.ItalianDescription" | translate }}
        </div>
        <div class="fw-700">
          {{ data?.item?.italianDescription || data?.item?.productDescription }}
        </div>
      </div>
      <div class="mt-20">
        <div>
          {{ "CRM_DETAIL.EnglishDescription" | translate }}
        </div>
        <div class="fw-700">
          {{
            data?.item?.englishDescription ||
              data?.item?.productEnglishDescription
          }}
        </div>
      </div>
      <hr />
      <div class="customer-container buyer d-flex">
        <div class="company pr-15">
          <div>{{ "BUSINESS_OPPORTUNITIES.Company" | translate }}</div>
          <div class="fw-700">{{ data?.item?.contact?.businessName }}</div>
          <div>
            {{ data?.item?.contactReference?.name }}
            {{ data?.item?.contactReference?.surname }}
          </div>
          <div>{{ data?.item?.contactReference?.tel }}</div>
          <div>{{ data?.item?.contactReference?.email }}</div>
          <div>{{ data?.item?.contact?.country?.country }}</div>
        </div>
        <div class="manager pl-15">
          <div>{{ "BUSINESS_OPPORTUNITIES.DLManager" | translate }}</div>
          <div class="fw-700">
            {{ data?.item?.userInfo?.name || data?.item.userInsertDTO?.name }}
            {{
              data?.item?.userInfo?.surname || data?.item.userInsertDTO?.surname
            }}
          </div>
          <div>
            {{
              data?.item?.userInfo?.telephone ||
                data?.item.userInsertDTO?.telephone
            }}
          </div>
          <div>
            {{
              data?.item?.userInfo?.email || data?.item.userInsertDTO?.email
            }}
          </div>
        </div>
      </div>
      <!-- <div class="primary-container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 pl-30 vertical-bar">
            <div>{{ "BUSINESS_OPPORTUNITIES.Company" | translate }}</div>
            <div class="fw-700">{{ data?.item?.contact?.businessName }}</div>
            <div class="d-flex">
              <div>
                {{ data?.item?.contactReference?.name }}
                {{ data?.item?.contactReference?.surname }}
              </div>
              <span class="mx-10">|</span>
              <div>{{ data?.item?.contactReference?.tel }}</div>
              <span class="mx-10">|</span>
              <div>{{ data?.item?.contactReference?.email }}</div>
            </div>
          </div>
        </div>
      </div> -->
      <div
        *ngIf="data.opportunityType === 'lostOffer'"
        class="content p-relative"
      >
        <div
          *ngIf="pageIsCustom"
          class="d-flex align-items-center justify-content-center mt-40"
        >
          <mat-slide-toggle
            color="primary"
            [formControl]="checkLostOfferStatus"
          >
            {{ "BUSINESS_OPPORTUNITIES.Visualized" | translate }}
          </mat-slide-toggle>
          <button
            [mat-dialog-close]="true"
            class="rounded-button primary-bg-button ml-30"
            [disabled]="checkLostOfferStatus.value !== true"
          >
            {{ "COMMON.Save" | translate }}
          </button>
          <div *ngIf="pageIsCustom" class="export-button">
            <button
              class="rounded-button primary-bg-button"
              type="button"
              (click)="confirmExportLostOffer(data?.item)"
            >
              {{ "COMMON.Transfer" | translate }}
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="data.opportunityType === 'report'" class="content p-relative">
        <div
          *ngIf="pageIsCustom"
          class="d-flex align-items-center justify-content-center mt-40"
        >
          <mat-slide-toggle color="primary" [formControl]="checkReportStatus">
            {{ "BUSINESS_OPPORTUNITIES.Visualized" | translate }}
          </mat-slide-toggle>
          <button
            [mat-dialog-close]="true"
            class="rounded-button primary-bg-button ml-30"
            [disabled]="checkReportStatus.value !== true"
          >
            {{ "COMMON.Save" | translate }}
          </button>
        </div>
        <div *ngIf="pageIsCustom" class="export-button">
          <button
            class="rounded-button primary-bg-button"
            type="button"
            (click)="confirmExportReports(data?.item)"
          >
            {{ "COMMON.Transfer" | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="data.opportunityType === 'report'" class="content">
      <div class="d-flex justify-content-between mr-40 ta-center">
        <div>
          <div>{{ "BUSINESS_OPPORTUNITIES.Country" | translate }}</div>
          <div class="fw-700">
            {{ data?.item?.contact?.country?.country }}
          </div>
        </div>
        <div>
          <div>{{ "BUSINESS_OPPORTUNITIES.CommerceSector" | translate }}</div>
          <div class="fw-700">
            {{ data.item?.commerceSector?.name }}
          </div>
        </div>
        <div>
          <div>{{ "CRM_DETAIL.UnitOfMeasure" | translate }}</div>
          <div class="fw-700">{{ data.item?.unitOfMeasure?.name }}</div>
        </div>
      </div>
      <hr />
      <div class="d-flex justify-content-between mr-40 ta-center">
        <div>
          <div>{{ "CRM_DETAIL.EstimatedQuantityOrder" | translate }}</div>
          <div class="fw-700">
            {{ data?.item?.quantity || "0" }}
          </div>
        </div>
        <div>
          <div>{{ "CRM_DETAIL.EstimatedQuantityYear" | translate }}</div>
          <div class="fw-700 ml-05">
            {{ data?.item?.quantityYear || "0" }}
          </div>
        </div>
        <div>
          <div>{{ "CRM_DETAIL.EstimatedValueYear" | translate }}</div>
          <div class="fw-700 ml-05">
            {{
              data?.item?.valueYear || "0"
                | currency: this._defaultCurrencyCode:"symbol":"1.0-2":undefined
            }}
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="fw-700">
          {{ "BUSINESS_OPPORTUNITIES.ITADescription" | translate }}
        </div>
        <div>{{ data?.item?.productDescription }}</div>
      </div>
      <div class="mt-20">
        <div class="fw-700">
          {{ "BUSINESS_OPPORTUNITIES.ENGDescription" | translate }}
        </div>
        <div>{{ data?.item?.productEnglishDescription }}</div>
      </div>
      <hr />
      <div class="primary-container">
        <div class="row">
          <div class="col-6">
            <div>
              {{ "BUSINESS_OPPORTUNITIES.DLManager" | translate }}
            </div>
            <div class="fw-700">
              {{ data?.item?.userInsertDTO?.name }}
              {{ data?.item?.userInsertDTO?.surname }}
            </div>
            <div class="d-flex">
              <div>{{ data?.item?.userInsertDTO?.telephone }}</div>
              <span class="mx-10">|</span>
              <div>{{ data?.item?.userInsertDTO?.email }}</div>
            </div>
          </div>
          <div class="col-6 pl-30 vertical-bar">
            <div>{{ "BUSINESS_OPPORTUNITIES.Company" | translate }}</div>
            <div class="fw-700">{{ data?.item?.contact?.businessName }}</div>
            <div class="d-flex">
              <div>
                {{ data?.item?.contactReference?.name }}
                {{ data?.item?.contactReference?.surname }}
              </div>
              <span class="mx-10">|</span>
              <div>{{ data?.item?.contactReference?.tel }}</div>
              <span class="mx-10">|</span>
              <div>{{ data?.item?.contactReference?.email }}</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </ng-template>
  <ng-template #CSVDownloadQuantityDialog let-close="close" let-data>
    <div class="header h70 no-margin-top">
      <div class="title">
        <span>{{ "BUSINESS_OPPORTUNITIES.DownloadResults" | translate }}</span>
      </div>
      <button
        class="close-dialog"
        mat-dialog-close
        mat-icon-button
        (click)="resetDownloadCSVOptions()"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="content">
      <div class="d-flex">
        <mat-radio-group
          class="quantity-group"
          (change)="checkChoiceForDownloadCSV($event)"
          color="primary"
        >
          <mat-radio-button [checked]="true" value="all" color="primary">
            {{ "BUSINESS_OPPORTUNITIES.All" | translate }}
          </mat-radio-button>
          <mat-radio-button class="mt-20" value="specific" color="primary">
            {{ "CRM_DETAIL.Date" | translate }}
          </mat-radio-button>
        </mat-radio-group>
        <div class="p-relative">
          <mat-form-field appearance="standard" class="col-12 quantity-field">
            <mat-date-range-input [rangePicker]="csvPicker">
              <input
                matStartDate
                placeholder="{{ 'COMMON.StartDate' | translate }}"
                [formControl]="downloadCSVDateFrom"
                readonly
              />
              <input
                matEndDate
                placeholder="{{ 'COMMON.EndDate' | translate }}"
                [formControl]="downloadCSVDateTo"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="csvPicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #csvPicker></mat-date-range-picker>
          </mat-form-field>
          <!-- <mat-form-field class="quantity-field">
            <input
              matInput
              type="number"
              name="quantity"
              min="0"
              max="1000"
              [formControl]="csvQuantity"
            />
          </mat-form-field> -->
        </div>
      </div>
    </div>
    <div class="buttons center">
      <button
        type="button"
        class="primary-bg-button rounded-button"
        (click)="downloadCSV()"
      >
        {{ "BUSINESS_OPPORTUNITIES.DownloadResults" | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template #settingsEmailDialog let-close="close" let-data>
    <div class="header h70 no-margin-top">
      <div class="title">
        <span>
          {{ "BUSINESS_OPPORTUNITIES.DataToShowOnEmail" | translate }}
        </span>
      </div>
      <button class="close-dialog" mat-dialog-close mat-icon-button>
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="content email-formatter">
      <form [formGroup]="emailSettingsForm">
        <div>
          <mat-checkbox formControlName="productName" color="primary">
            {{ "BUSINESS_OPPORTUNITIES.Product" | translate }}
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="soldPurchase" color="primary">
            {{ "BUSINESS_OPPORTUNITIES.ProductTypology" | translate }}
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="orderQuantity" color="primary">
            {{ "BUSINESS_OPPORTUNITIES.QuantityOrder" | translate }}
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="quantityYear" color="primary">
            {{ "BUSINESS_OPPORTUNITIES.QuantityYear" | translate }}
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="priceYear" color="primary">
            {{ "BUSINESS_OPPORTUNITIES.BoughtValueYear" | translate }}
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="nameCustomer" color="primary">
            {{ "BUSINESS_OPPORTUNITIES.Company" | translate }}
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="referenceName" color="primary">
            {{ "COMMON.Referent" | translate }}
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="referencePhone" color="primary">
            {{ "COMMON.ReferentPhone" | translate }}
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="referenceEmail" color="primary">
            {{ "COMMON.ReferentEmail" | translate }}
          </mat-checkbox>
        </div>
        <!-- <div>
          <mat-checkbox formControlName="dlName" color="primary">
            {{ "COMMON.TeamLeader" | translate }}
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="dlPhone" color="primary">
            {{ "COMMON.TeamLeaderPhone" | translate }}
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="dlEmail" color="primary">
            {{ "COMMON.TeamLeaderEmail" | translate }}
          </mat-checkbox>
        </div> -->
      </form>
    </div>
    <div class="buttons center">
      <button
        type="button"
        class="primary-bg-button rounded-button"
        (click)="saveEmailSettings()"
      >
        {{ "COMMON.Save" | translate }}
      </button>
    </div>
  </ng-template>
  <div class="sticky-container" [ngClass]="showFilters ? 'opened' : ''">
    <div class="sticky-header">
      <div
        class="sticky-label"
        [ngClass]="checkDataForStickyLabels() ? '' : 'not-ready'"
        (click)="showFilters = !showFilters; showCsv = false"
      >
        <svg-icon
          *ngIf="!showFilters"
          name="filterIcon"
          class="w24 h24 primary"
        ></svg-icon>
        <mat-icon *ngIf="showFilters">clear</mat-icon>
      </div>
      <div class="title">{{ "COMMON.Filters" | translate }}</div>
      <button
        *ngIf="index === 0"
        type="button"
        class="only-text-button primary"
        (click)="resetBmFilters()"
      >
        {{ "COMMON.Reset" | translate | lowercase }}
      </button>
      <button
        *ngIf="index === 1"
        type="button"
        class="only-text-button primary"
        (click)="resetLostOffersFilters()"
      >
        {{ "COMMON.Reset" | translate | lowercase }}
      </button>
      <button
        *ngIf="index === 2"
        type="button"
        class="only-text-button primary"
        (click)="resetReportsFilters()"
      >
        {{ "COMMON.Reset" | translate | lowercase }}
      </button>
    </div>
    <div class="sticky-content">
      <form *ngIf="index === 0" [formGroup]="bmFilters">
        <mat-form-field *ngIf="!pageIsCustom" appearance="standard" class="col">
          <mat-label for="idCommerceSector">{{
            "IR_FORM.CommerceSector" | translate
          }}</mat-label>
          <mat-select
            formControlName="idCommerceSector"
            name="idCommerceSector"
          >
            <mat-option value="all">{{ "COMMON.All" | translate }}</mat-option>
            <mat-option
              *ngFor="let sector of commerceSectors"
              [value]="sector.idCommerceSector"
            >
              {{ sector.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!pageIsCustom" appearance="standard" class="col">
          <mat-label for="wordKey">
            {{ "COMMON.Product" | translate }}
          </mat-label>
          <input
            matInput
            type="text"
            formControlName="wordKey"
            name="wordKey"
          />
        </mat-form-field>
        <mat-form-field appearance="standard" class="col">
          <mat-label for="isSold">
            {{ "BUSINESS_OPPORTUNITIES.Typology" | translate }}
          </mat-label>
          <mat-select formControlName="isSold" name="isSold">
            <mat-option [value]="false">
              {{ "COMMON.Bought" | translate }}
            </mat-option>
            <mat-option [value]="true">
              {{ "COMMON.Sold" | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <div class="row">
          <mat-form-field appearance="standard" class="col">
            <mat-label for="isChecked">
              {{ "BUSINESS_OPPORTUNITIES.Visualized" | translate }}
            </mat-label>
            <mat-select formControlName="isChecked" name="isChecked">
              <mat-option value="all">
                {{ "COMMON.All" | translate }}
              </mat-option>
              <mat-option [value]="true">
                {{ "COMMON.Yes" | translate }}
              </mat-option>
              <mat-option [value]="false">
                {{ "COMMON.No" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <mat-form-field *ngIf="!pageIsCustom" appearance="standard" class="col">
          <mat-label for="isClient">
            {{ "COMMON.Type" | translate }}
          </mat-label>
          <mat-select formControlName="isClient" name="isClient">
            <mat-option value="all">
              {{ "COMMON.All" | translate }}
            </mat-option>
            <mat-option [value]="true">
              {{ "COMMON.Customer" | translate }}
            </mat-option>
            <mat-option [value]="false">
              {{ "COMMON.User" | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-12">
          <mat-label>{{ "CRM_DETAIL.Date" | translate }}</mat-label>
          <mat-date-range-input [rangePicker]="bmPickerSticky">
            <input
              matStartDate
              placeholder="{{ 'COMMON.StartDate' | translate }}"
              formControlName="startDate"
              readonly
            />
            <input
              matEndDate
              placeholder="{{ 'COMMON.EndDate' | translate }}"
              formControlName="endDate"
              readonly
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="bmPickerSticky"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #bmPickerSticky></mat-date-range-picker>
        </mat-form-field>
        <button
          class="mt-20-auto rounded-button primary-bg-button"
          type="button"
          (click)="showFilters = false; applyBmFilters('reset')"
        >
          {{ "COMMON.Apply" | translate }}
        </button>
      </form>
      <form *ngIf="index === 1" [formGroup]="lostOffersFilters">
        <mat-form-field appearance="standard" class="col">
          <mat-label for="idCommerceSector">{{
            "IR_FORM.CommerceSector" | translate
          }}</mat-label>
          <mat-select
            formControlName="idCommerceSector"
            name="idCommerceSector"
          >
            <mat-option value="all">{{ "COMMON.All" | translate }}</mat-option>
            <mat-option
              *ngFor="let sector of commerceSectors"
              [value]="sector.idCommerceSector"
            >
              {{ sector.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!pageIsCustom" appearance="standard" class="col">
          <mat-label for="productDescription">
            {{ "COMMON.Product" | translate }}
          </mat-label>
          <input
            matInput
            name="productDescription"
            formControlName="productDescription"
            type="text"
          />
        </mat-form-field>
        <mat-form-field appearance="standard" class="col">
          <mat-label for="idCountry">{{
            "BUSINESS_OPPORTUNITIES.Country" | translate
          }}</mat-label>
          <mat-select formControlName="idCountry" name="idCountry">
            <mat-option value="all">
              {{ "COMMON.All" | translate }}
            </mat-option>
            <mat-option
              *ngFor="let country of countries"
              [value]="country.idCountry"
            >
              {{ country.country }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-12">
          <mat-label>{{ "CRM_DETAIL.Date" | translate }}</mat-label>
          <mat-date-range-input [rangePicker]="lostOfferspicker">
            <input
              matStartDate
              placeholder="{{ 'COMMON.StartDate' | translate }}"
              formControlName="startDate"
              readonly
            />
            <input
              matEndDate
              placeholder="{{ 'COMMON.EndDate' | translate }}"
              formControlName="endDate"
              readonly
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="lostOfferspicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #lostOfferspicker></mat-date-range-picker>
        </mat-form-field>
        <button
          class="mt-20-auto rounded-button primary-bg-button"
          type="button"
          (click)="showFilters = false; applyLostOffersFilters('reset')"
        >
          {{ "COMMON.Apply" | translate }}
        </button>
      </form>
      <form *ngIf="index === 2" [formGroup]="reportsFilters">
        <mat-form-field appearance="standard" class="col">
          <mat-label for="idCommerceSector">{{
            "IR_FORM.CommerceSector" | translate
          }}</mat-label>
          <mat-select
            formControlName="idCommerceSector"
            name="idCommerceSector"
          >
            <mat-option value="all">{{ "COMMON.All" | translate }}</mat-option>
            <mat-option
              *ngFor="let sector of commerceSectors"
              [value]="sector.idCommerceSector"
            >
              {{ sector.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!pageIsCustom" appearance="standard" class="col">
          <mat-label for="productDescription">
            {{ "COMMON.ProductDescription" | translate }}
          </mat-label>
          <input
            matInput
            name="productDescription"
            formControlName="productDescription"
            type="text"
          />
        </mat-form-field>
        <mat-form-field appearance="standard" class="col">
          <mat-label for="idCountry">{{
            "BUSINESS_OPPORTUNITIES.Country" | translate
          }}</mat-label>
          <mat-select formControlName="idCountry" name="idCountry">
            <mat-option value="all">
              {{ "COMMON.All" | translate }}
            </mat-option>
            <mat-option
              *ngFor="let country of countries"
              [value]="country.idCountry"
            >
              {{ country.country }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-12">
          <mat-label>{{ "CRM_DETAIL.Date" | translate }}</mat-label>
          <mat-date-range-input [rangePicker]="reportsPicker">
            <input
              matStartDate
              placeholder="{{ 'COMMON.StartDate' | translate }}"
              formControlName="startDate"
              readonly
            />
            <input
              matEndDate
              placeholder="{{ 'COMMON.EndDate' | translate }}"
              formControlName="endDate"
              readonly
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="reportsPicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #reportsPicker></mat-date-range-picker>
        </mat-form-field>
        <button
          class="mt-20-auto rounded-button primary-bg-button"
          type="button"
          (click)="showFilters = false; applyReportsFilters('reset')"
        >
          {{ "COMMON.Apply" | translate }}
        </button>
      </form>
    </div>
  </div>
  <div
    *ngIf="isDirection"
    class="sticky-container csv"
    [ngClass]="showCsv ? 'opened' : ''"
  >
    <div class="sticky-header">
      <div
        class="sticky-label"
        [ngClass]="checkDataForStickyLabels() ? '' : 'not-ready'"
        (click)="showCsv = !showCsv"
      >
        <svg-icon
          *ngIf="!showCsv"
          name="downloadIcon"
          class="w24 h24 primary"
        ></svg-icon>
        <mat-icon *ngIf="showCsv">clear</mat-icon>
      </div>
      <div class="title">{{ "COMMON.Filters" | translate }}</div>
    </div>
    <div class="sticky-content">
      <div class="d-flex">
        <mat-radio-group
          class="quantity-group"
          (change)="checkChoiceForDownloadCSV($event)"
          color="primary"
        >
          <mat-radio-button [checked]="true" value="all">
            {{ "BUSINESS_OPPORTUNITIES.All" | translate }}
          </mat-radio-button>
          <mat-radio-button class="mt-20" value="specific">
            {{ "CRM_DETAIL.Date" | translate }}
          </mat-radio-button>
        </mat-radio-group>
        <div class="p-relative">
          <mat-form-field appearance="standard" class="col-12 quantity-field">
            <mat-date-range-input [rangePicker]="csvPicker">
              <input
                matStartDate
                placeholder="{{ 'COMMON.StartDate' | translate }}"
                [formControl]="downloadCSVDateFrom"
                readonly
              />
              <input
                matEndDate
                placeholder="{{ 'COMMON.EndDate' | translate }}"
                [formControl]="downloadCSVDateTo"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="csvPicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #csvPicker></mat-date-range-picker>
          </mat-form-field>
          <!-- <mat-form-field class="quantity-field">
            <input
              matInput
              type="number"
              name="quantity"
              min="0"
              max="1000"
              [formControl]="csvQuantity"
            />
          </mat-form-field> -->
        </div>
      </div>
      <button
        type="button"
        class="primary-bg-button rounded-button download"
        (click)="downloadCSV()"
      >
        {{ "BUSINESS_OPPORTUNITIES.DownloadResults" | translate }}
      </button>
    </div>
  </div>
  <div class="shadow" [ngClass]="showFilters || showCsv ? 'on' : ''"></div>
</div>
