import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config, forkJoin, Observable } from 'rxjs';
import { IProjectInfo, Project, ProjectDTO } from 'src/app/models/project';
import { ServiceExtension } from 'src/app/services/serviceExtension';
import { AuthService } from '../auth/auth.service';
import Config from 'src/app/config';
import { ICountries } from 'src/app/models/countries';
import {
  IChannelSpecification,
  IChannelsType,
} from 'src/app/models/channels-type';
import { IProductsServices } from 'src/app/models/products-services';
import { PsSwot } from 'src/app/models/swot';
import {
  ICompetitorPositioning,
  ICompetitorPositioningData,
} from 'src/app/models/competitor-positioning';
import { Note } from 'src/app/models/note';
import { Params } from '@angular/router';
import { IrFormService } from '../ir-form/ir-form.service';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class PsFormService extends ServiceExtension {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private common: CommonService
  ) {
    super();
  }

  getProjectById(id: number | string): Observable<ProjectDTO> {
    return this.http
      .get<ProjectDTO>(`${Config.apiRootUrl}/project/${id}`)
      .pipe();
  }

  getProjectEntities(
    project: ProjectDTO,
    idProjectStatus?: number
  ): Observable<Project> {
    const promises = {
      productsServices: this.getProductsServicesByIdCustomer(
        project.idCustomer
      ),
      countries: this.getAllCountries(),
      channelsType: this.getChannelsType(),
      specificationsChannels: this.common.getSpecificationChannels(
        project.idCustomer
      ),
      psStrategy: this.getStrategy(project.idProject, idProjectStatus),
      psSwot: this.getSwotByIdCustomer(project.idProject, idProjectStatus),
      psCompetitor: this.getCompetitorPositioning(
        project.idProject,
        idProjectStatus ? idProjectStatus : project?.idProjectStatus
      ),
    };
    return this.executeForkJoin(promises);
  }

  getProductsServicesByIdCustomer(
    idCustomer: number | string,
    toCache: boolean = true
  ): Observable<IProductsServices[]> {
    return this.http
      .get<IProductsServices[]>(
        `${Config.apiRootUrl}/product/getByIdCustomer/${idCustomer}`,
        { headers: this._getHeaders(false, false, toCache) }
      )
      .pipe();
  }

  getAllCountries(toCache: boolean = true): Observable<ICountries[]> {
    return this.http
      .get<ICountries[]>(`${Config.apiRootUrl}/foreignMarket/getAllCountry`, {
        headers: this._getHeaders(false, false, toCache),
      })
      .pipe();
  }

  getChannelsType(toCache: boolean = true): Observable<IChannelsType[]> {
    return this.http
      .get<IChannelsType[]>(`${Config.apiRootUrl}/common/channeltype`, {
        headers: this._getHeaders(false, false, toCache),
      })
      .pipe();
  }

  getStrategy(
    idProject: number | string,
    idProjectStatus?: number
  ): Observable<any> {
    return this.http
      .get<any>(
        `${Config.apiRootUrl}/crm/${
          idProjectStatus ? 'prev' : ''
        }strategy/statistics/project/${idProject}${
          idProjectStatus ? '/status/' + idProjectStatus : ''
        }`,
        { headers: this._getHeaders(true) }
      )
      .pipe();
  }

  saveStrategy(
    idProject: number | string,
    body: any,
    sendToApprove?: number
  ): Observable<any> {
    if (sendToApprove) {
      return this.http
        .put<any>(
          `${Config.apiRootUrl}/crm/strategy/project/${idProject}?sendtoapprove=${sendToApprove}`,
          body
        )
        .pipe();
    }
    return this.http
      .put<any>(`${Config.apiRootUrl}/crm/strategy/project/${idProject}`, body)
      .pipe();
  }

  getSwotByIdCustomer(
    idProject: number | string,
    idProjectStatus: number | string
  ): Observable<PsSwot> {
    return this.http.get<PsSwot>(
      idProjectStatus
        ? `${Config.apiRootUrl}/swatuspprojectanalysis/project/${idProject}/status/${idProjectStatus}`
        : `${Config.apiRootUrl}/swatuspprojectanalysis/project/${idProject}`
    );
  }

  createSwot(body: PsSwot, idProject: number | string): Observable<void> {
    return this.http
      .put<void>(
        `${Config.apiRootUrl}/swatuspprojectanalysis/project/${idProject}`,
        body
      )
      .pipe();
  }

  saveSwot(
    body: PsSwot,
    idProject: number | string,
    idProjectStatus: number | string
  ): Observable<void> {
    return this.http
      .put<void>(
        `${Config.apiRootUrl}/swatuspprojectanalysis/project/${idProject}?idProjectStatus=${idProjectStatus}`,
        body
      )
      .pipe();
  }

  getCompetitorPositioning(
    idProject: number | string,
    idProjectStatus: number
  ): Observable<ICompetitorPositioningData> {
    return this.http
      .get<ICompetitorPositioningData>(
        `${Config.apiRootUrl}/contactcompetitorcompany/project/${idProject}?idProjectStatus=${idProjectStatus}`
      )
      .pipe();
  }

  saveCompetitorPositioning(
    idProject: number | string,
    idProjectStatus: number,
    body: ICompetitorPositioningData
  ): Observable<void> {
    return this.http
      .put<void>(
        // `${Config.apiRootUrl}/contactcompetitorcompany/competitorcompanycrm/list`,
        `${Config.apiRootUrl}/contactcompetitorcompany/competitorcompanycrm/list/${idProject}/${idProjectStatus}`,
        body
      )
      .pipe();
  }

  approveIsp(idProject: number | string): Observable<ProjectDTO> {
    return this.http
      .put<ProjectDTO>(
        `${Config.apiRootUrl}/project/approval/isp/${idProject}`,
        null
      )
      .pipe();
  }

  rejectIsp(idProject: number | string): Observable<ProjectDTO> {
    return this.http
      .put<ProjectDTO>(
        `${Config.apiRootUrl}/project/reject/isp/${idProject}`,
        null
      )
      .pipe();
  }

  /**
   * @description API to get all supervisor notes
   * @param idProject Id of the project
   * @param hideLoader Set true to hide full screen laoder
   */
  getSupervisorNotes(
    idProject,
    hideLoader: boolean = false
  ): Observable<Note[]> {
    return this.http
      .get<Note[]>(`${Config.apiRootUrl}/project/note/${idProject}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  createUpdateSupervisorNotes(
    idProject: number | string,
    note: Note[],
    hideLoader: boolean = false
  ): Observable<Note[]> {
    return this.http
      .put<Note[]>(`${Config.apiRootUrl}/project/note/${idProject}`, note, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  deleteSupervisorNote(
    idProjectNote: number,
    hideLoader: boolean = false
  ): Observable<Note> {
    return this.http
      .delete<Note>(`${Config.apiRootUrl}/project/note/${idProjectNote}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  checkNote(
    idProject: number | string,
    note: Note[],
    hideLoader: boolean = false
  ): Observable<Note[]> {
    return this.http
      .put<Note[]>(`${Config.apiRootUrl}/project/${idProject}/note`, note, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  publishProject(
    idProject: string | number,
    idCustomer: string | number,
    idProjectStatus: number,
    hideLoader: boolean = false
  ): Observable<any> {
    const par: Params = new HttpParams().set(
      'idProjectStatus',
      idProjectStatus.toString()
    );
    return this.http
      .put<any>(
        `${Config.apiRootUrl}/project/publish/${idProject}/customer/${idCustomer}`,
        {},
        { params: par, headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  projectsHistory(
    idProject: string | number,
    idCustomer: string | number,
    hideLoader: boolean = false
  ): Observable<ProjectDTO> {
    return this.http
      .get<ProjectDTO>(
        `${Config.apiRootUrl}/project/${idProject}/customer/${idCustomer}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getDeliveredPs(): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(`${Config.apiRootUrl}/project-bo/ps-delivered`, {
        headers: this._getHeaders(true),
      })
      .pipe();
  }

  getInProgressPs(): Observable<IProjectInfo[]> {
    return this.http
      .get<IProjectInfo[]>(`${Config.apiRootUrl}/project-bo/ps-working`, {
        headers: this._getHeaders(true),
      })
      .pipe();
  }
}
