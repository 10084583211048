import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {PROJECT_TYPES, PS_VERSION} from 'src/app/config';
import { IProjectCustomer } from 'src/app/models/project';
import { IMarketStrategy } from 'src/app/models/strategy';
import { CrmService } from 'src/app/services/crm/crm.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-customer-info-panel',
  templateUrl: './customer-info-panel.component.html',
  styleUrls: ['./customer-info-panel.component.scss'],
})
export class CustomerInfoPanelComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  @Input() idProject: number;
  @Input() isReadonly: boolean;
  @Input() path: string;
  @Input() disabled: boolean;
  customers: IProjectCustomer[];
  filteredCustomers: IProjectCustomer[] = [];
  filteredProjects: IProjectCustomer[] = [];
  selectedProject: IProjectCustomer;
  psVersion: any = PS_VERSION;
  selectedIdCustomer: number;
  marketsInStrategy: string[] = [];
  translations: any = {};

  constructor(private crmService: CrmService, private router: Router, private translate: TranslateService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.translate.get('PROJECT_TYPES').subscribe((translations: any) => {
        this.translations = translations;
      })
    );
    this.getFollowedCustomers();
  }

  getFollowedCustomers(): void {
    this.subscriptions.add(
      this.crmService.getFollowedCustomers(false).subscribe({
        next: (customers: IProjectCustomer[]) => {
          this.customers = customers;
          this.filterCustomers();
          this.filterProjects();
        },
      })
    );
  }

  filterCustomers(): void {
    const customersIds = [];
    this.filteredCustomers = [];
    this.customers.forEach((customer: IProjectCustomer) => {
      if (customer.idProject === this.idProject) {
        this.selectedIdCustomer = customer.idCustomer;
        this.selectedProject = customer;
        this.selectedProject.reason = this.translations[PROJECT_TYPES[customer.reason || 'ME']];
        customer?.marketProjectObjectiveDTO?.forEach(
          (market: IMarketStrategy) => {
            this.marketsInStrategy.push(market?.country);
          }
        );
        this.crmService.triggerIdCommerceSector(customer?.idCommerceSector);
      }
      if (!customersIds.includes(customer.idCustomer)) {
        customersIds.push(customer.idCustomer);
        this.filteredCustomers.push(customer);
      }
    });
  }

  filterProjects(): void {
    this.filteredProjects = [];
    this.customers.forEach((customer: IProjectCustomer) => {
      if (customer.idCustomer === this.selectedIdCustomer) {
        this.filteredProjects.push(customer);
      }
    });
  }

  resetSelectedProject(event: MatSelectChange): void {
    this.selectedIdCustomer = event.value;
    this.filterProjects();
    this.idProject = null;
    this.selectedProject = null;
    this.marketsInStrategy = [];
  }

  refreshProject(event: MatSelectChange): void {
    this.filteredProjects.forEach((project: IProjectCustomer) => {
      if (project.idProject === event.value) {
        this.selectedProject = project;
        this.marketsInStrategy = [];
        project?.marketProjectObjectiveDTO?.forEach(
          (market: IMarketStrategy) => {
            this.marketsInStrategy.push(market?.country);
          }
        );
      }
    });
    if (this.path === 'ps-form') {
      this.router.navigate([`/${this.path}/${event.value}`]);
    } else {
      this.router.navigate([
        `/${this.path}/customer/${this.selectedIdCustomer}/project/${event.value}`,
      ]);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
