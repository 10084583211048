import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { fromProjectDtoToProjectTable } from 'src/app/utility/elaborations';
import { IColumnsName, ITableButtons, ITableOptions, ToastStatus } from 'src/app/models/utility';
import { IProjectForTable, Project, ProjectDTO } from 'src/app/models/project';
import { Note } from 'src/app/models/note';
import { DOC_TYPE, PR_STATUS_PS_VERSION, RI_STATUS } from 'src/app/config';
import { checkIrDocument, checkIrStatus, checkIspDocument, checkIspStatus, checkModify, checkModifyCRM, checkIspWorking1, checkVisualizePs1 } from 'src/app/utility/table';
import { NotesDialogComponent } from 'src/app/components/notes-dialog/notes-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { IProductsServices } from 'src/app/models/products-services';
import { ICustomerInformation } from 'src/app/models/customer-information';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-ir-in-progress-table',
  templateUrl: './ir-in-progress-table.component.html',
  styleUrls: ['./ir-in-progress.component.scss']
})
export class IrInProgressTableComponent implements OnInit, OnChanges, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  idUser: number;

  projectDataTable: MatTableDataSource<IProjectForTable> = new MatTableDataSource<IProjectForTable>();

  /**
   * @TODO Sistemamare, il dialog dovrebbe essere un componente non un template
   */

  @ViewChild('sendIrDialog') sendIrDialog: TemplateRef<ViewChild>;
  @ViewChild('visualizeIrNotesDialog') visualizeIrNotesDialog: TemplateRef<ViewChild>;

  @Input() translations: any;
  @Input() showNote: boolean;
  @Input() showVersion: boolean;

  @Input() projectData: ProjectDTO;
  @Input() displayedColumns: string[] = [
    'lastModified',
    'businessName',
    'telephoneNumber',
    'email',
    'address',
    'menuButtons'
  ];
  @Input() columnsName: IColumnsName[] = [
    {value: 'businessName', name: 'Ragione Sociale'},
    {value: 'telephoneNumber', name: 'Telefono'},
    {value: 'email', name: 'E-Mail'},
    {value: 'address', name: 'Indirizzo'},
    {value: 'lastModified', name: 'Ultima lavorazione'},
    {value: 'psVersion', name: 'Versione'}
  ];
  @Input() tableButtons: ITableButtons[] = [
    {
      name: 'Note',
      disabled: (original: IProjectForTable): boolean => !this.presentNotes(original),
      click: (original: IProjectForTable) => { this.visualizeNotes(original); },
      icon: 'comment'
    }
  ];
  @Input() tableMenuButtons: ITableButtons[] = [
    {
      name: 'Modifica',
      conditions: checkModify,
      click: (original: IProjectForTable) => { this.modifyIr(original); },
      icon: 'edit',
    },
    {
      name: 'Apri CRM',
      conditions: checkModifyCRM,
      click: (original: IProjectForTable) => { this.modifyCRM(original); },
      icon: 'edit',
    },
    // ↓ DEPRECATED ↓ : non si ha più la possibilità di vedere, salvare e scaricare il documento (PDF)
    /*{
      name: 'DownloadIR',
      disabled: (project: IProjectForTable) => !checkIrDocument(project),
      conditions: checkIrStatus,
      click: (original: IProjectForTable) => { this.downloadIr(original); },
      icon: 'get_app'
    },*/
    {
      name: 'Invia',
      // ↓ DEPRECATED ↓ : non si ha più la possibilità di vedere, salvare e scaricare il documento (PDF)
      // disabled: (project: IProjectForTable) => !checkIrDocument(project),
      conditions: checkIrStatus,
      click: (original: Project) => { this.sendIr(original); },
      icon: 'send'
    },
    {
      name: 'Invia',
      // ↓ DEPRECATED ↓ : non si ha più la possibilità di vedere, salvare e scaricare il documento (PDF)
      // disabled: (project: IProjectForTable) => !checkIspDocument(project),
      conditions: checkIspWorking1,
      click: (original: Project) => { this.sendIsp(original); },
      icon: 'send'
    },
    {
      name: 'Visualizza PS1',
      conditions: checkVisualizePs1,
      click: (original: IProjectForTable) => { this.modifyIr(original); },
      icon: 'preview'
    },
    {
      name: 'Consegna PS corrente',
      conditions: checkVisualizePs1,
      click: (original: IProjectForTable) => { this.goToSendCrm(original); },
      icon: 'read_more'
    },
  ];
  @Input() tableOptions: ITableOptions = {
    showFilter: true,
    showSort: true,
    showPaginator: true,
    showButtons: true
  };
  @Input() loader: boolean = false;

  @Output() reloadDataEmitter: EventEmitter<void> = new EventEmitter();

  constructor(
    private common: CommonService,
    private router: Router,
    private irFormService: IrFormService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.showVersion) {
      this.displayedColumns.splice(5, 0, 'psVersion');
    }
    if (this.showNote) {
      this.displayedColumns.splice(6, 0, 'buttons');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const projectData: ProjectDTO[]  = changes.projectData?.currentValue || [];
    if (projectData?.length || projectData.length === 0) {
      this.projectDataTable.data = projectData.map(fromProjectDtoToProjectTable);
    }
  }

  presentNotes(project: IProjectForTable): boolean {
    let presentNotes: boolean;
    project.projectNoteList.forEach((note: Note) => {
      if (note.checked === false) {
        presentNotes = true;
      }
    });
    return presentNotes;
  }

  modifyIr(project: IProjectForTable): void {
    this.router.navigate(['/assessment', project.idProject]);
  }

  sendIr(project: Project): void {
    const soldProducts: IProductsServices[] = [];
    let hasExport: boolean;
    this.subscriptions.add(
      this.irFormService.getCustomerInformationByIdCustomer(project.customer.idCustomer).subscribe((data: ICustomerInformation) => {
        hasExport = data?.hasExport;
        this.irFormService.getProductsServicesByIdCustomer(project.customer.idCustomer).subscribe((products: IProductsServices[]) => {
          products?.forEach((product) => {
            if (product.idProductType === 1) {
              if (product.productCode) {
                soldProducts.push(product);
              }
            }
          });
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = false;
          dialogConfig.data = {
            title: this.translations?.Attention,
            message: this.translations?.SendIrMessageConfirmation,
            centerTextMessage: true
          };
          if (hasExport === true) {
            if (soldProducts.length >= 1) {
              const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.subscriptions.add(this.irFormService.sendIr(project.idProject).subscribe({
                    complete: () => {
                      this.reloadDataEmitter.emit();
                    }
                  }));
                }
              });
            } else {
              this.common.showToast(this.translations.SoldProductError, ToastStatus.error, 4000);
            }
          } else {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.subscriptions.add(this.irFormService.sendIr(project.idProject).subscribe({
                  complete: () => {
                    this.reloadDataEmitter.emit();
                  }
                }));
              }
            });
          }
        });
      })
    );
  }

  downloadIr(project: IProjectForTable): void {
    let idDocument: number | string;
    if (project?.documents?.length > 0) {
      project.documents.forEach(data => {
        if (project.idProjectStatus >= RI_STATUS.IspWorking) {
          if (data.type === DOC_TYPE.ISP) {
            idDocument = data.idDocument;
          }
        } else {
          if (data.type === DOC_TYPE.RI || data.type === DOC_TYPE.CONTRACT) {
            idDocument = data.idDocument;
          }
        }
      });
      this.subscriptions.add(this.irFormService.downloadDocument(idDocument).subscribe());
    }
  }

  visualizeNotes(project: IProjectForTable): void {
    const irNotes: Note[] = [];
    project.projectNoteList.forEach((note: Note) => {
      if (note.checked === false) {
        irNotes.push(note);
      }
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      translations: this.translate.instant('IR_IN_PROGRESS'),
      message: this.translate.instant('IR_IN_PROGRESS.Notes'),
      notes: irNotes
    };
    this.dialog.open(NotesDialogComponent, dialogConfig);
  }

  sendIsp(project: Project): void {
    const soldProducts: IProductsServices[] = [];
    let hasExport: boolean;
    this.subscriptions.add(
      this.irFormService.getCustomerInformationByIdCustomer(project.customer.idCustomer).subscribe((data: ICustomerInformation) => {
        hasExport = data?.hasExport;
        this.irFormService.getProductsServicesByIdCustomer(project.customer.idCustomer).subscribe((products: IProductsServices[]) => {
          products?.forEach((product) => {
            if (product.idProductType === 1) {
              if (product.productCode) {
                soldProducts.push(product);
              }
            }
          });
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = false;
          dialogConfig.data = {
            title: this.translations?.Attention,
            message: this.translations?.SendIspMessageConfirmation,
            centerTextMessage: true
          };
          if (hasExport === true) {
            if (soldProducts.length >= 1) {
              const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  this.subscriptions.add(this.irFormService.sendIsp(project.idProject).subscribe({
                    complete: () => {
                      this.reloadDataEmitter.emit();
                    }
                  }));
                }
              });
            } else {
              this.common.showToast(this.translations.SoldProductError, ToastStatus.error, 4000);
            }
          } else {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.subscriptions.add(this.irFormService.sendIsp(project.idProject).subscribe({
                  complete: () => {
                    this.reloadDataEmitter.emit();
                  }
                }));
              }
            });
          }
        });
      })
    );
  }

  modifyCRM(project: IProjectForTable): void {
    this.router.navigate(['/crm-detail', project.idProject, project.customer.idCustomer]);
  }

  goToSendCrm(project: IProjectForTable): void {
    this.router.navigate(['/ps-form', project.idProject, project.customer.idCustomer]);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
