<div *ngIf="translations" class="upload-button-wrapper">
  <div *ngIf="sizeTypeFileError">
    <mat-error *ngIf="sizeTypeFileError">
      {{
        "UPLOAD_FILE.UnableToUploadDocument"
          | translate: { size: maxSize, types: fileTypes }
      }}</mat-error
    >
  </div>
  <div *ngIf="showDocumentName && namePosition === 'top'">
    <div
      *ngIf="
        !downloadFileFromName && (fileToHandle?.name || fileToUpload?.name)
      "
      class="not-clickable-name-file"
    >
      <mat-icon class="icon-color icon-file-name"> description </mat-icon>
      <mat-icon class="icon-color icon-file-download"> get_app </mat-icon>
      <span class="light-text-color file-name">
        {{ fileToHandle?.name || fileToUpload?.name }}
      </span>
    </div>
    <div
      *ngIf="downloadFileFromName && (fileToHandle?.name || fileToUpload?.name)"
      class="clickable-name-file"
    >
      <button
        type="button"
        class="only-icon-button primary mr-20"
        (click)="downloadFile()"
      >
        <svg-icon name="download_icon" class="w24 h24"></svg-icon>
      </button>
      <button
        type="button"
        class="only-text-button primary"
        (click)="downloadFile()"
      >
        <span class="bold fs-18 truncate-text">
          {{ fileToHandle?.name || fileToUpload?.name }}
        </span>
      </button>
    </div>
    <!-- Code for having mat menu -->
    <!-- <button type="button" mat-button [matMenuTriggerFor]="menu">
      <mat-icon *ngIf="fileToUpload" class="icon-color">description</mat-icon>
      <span class="light-text-color file-name">
        {{ fileToUpload?.name }}
      </span>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-button type="button" (click)="removeFile()">
        <mat-icon>remove_circle</mat-icon>
        {{ 'IR_FORM.RemoveFile' | translate }}
      </button>
    </mat-menu> -->
  </div>
  <button
    *ngIf="!hideButton"
    type="button"
    id="upload-document-button"
    [ngClass]="
      buttonClass === 'primary' ? 'primary-bg-button' : 'primary-border-button'
    "
    class="rounded-button upload-button"
    [disabled]="disableButton || uploadingFile"
  >
    <label class="upload-label" for="{{ idInputFile }}">
      <mat-spinner
        *ngIf="uploadingFile"
        diameter="20"
        class="mr-10"
      ></mat-spinner>
      <svg-icon
        *ngIf="uploadingFileIcon"
        [name]="uploadingFileIcon"
        class="w20 h20"
      ></svg-icon>
      <span [ngClass]="buttonClass === 'primary' ? 'white-color' : ''">
        {{ changeLabel() }}
      </span>
    </label>
    <input
      type="file"
      id="{{ idInputFile }}"
      #file
      style="display: none"
      (change)="handleFileInput($event.target?.files)"
      [disabled]="disableButton"
    />
  </button>
  <div *ngIf="showError" class="ta-center error-color">
    {{ "IR_FORM.UploadTheContract" | translate }}
  </div>
  <div *ngIf="showDocumentName && namePosition === 'bottom'">
    <div
      *ngIf="
        !downloadFileFromName && (fileToHandle?.name || fileToUpload?.name)
      "
      class="not-clickable-name-file"
    >
      <!-- <mat-icon class="icon-color icon-file-name"> description </mat-icon>
      <mat-icon class="icon-color icon-file-download"> get_app </mat-icon> -->
      <span class="light-text-color file-name">
        {{ fileToHandle?.name || fileToUpload?.name }}
      </span>
    </div>
    <div
      *ngIf="downloadFileFromName && (fileToHandle?.name || fileToUpload?.name)"
      class="clickable-name-file"
    >
      <button
        type="button"
        class="only-icon-button primary mr-20"
        (click)="downloadFile()"
      >
        <svg-icon name="download_icon" class="w24 h24"></svg-icon>
      </button>
      <button
        type="button"
        class="only-text-button primary"
        (click)="downloadFile()"
      >
        <span class="bold fs-18">
          {{ fileToHandle?.name || fileToUpload?.name }}
        </span>
      </button>
    </div>
    <!-- Code for having mat menu -->
    <!-- <button type="button" mat-button [matMenuTriggerFor]="menu">
      <mat-icon *ngIf="fileToUpload" class="icon-color">description</mat-icon>
      <span class="light-text-color file-name">
        {{ fileToUpload?.name }}
      </span>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-button type="button" (click)="removeFile()">
        <mat-icon>remove_circle</mat-icon>
        {{ 'IR_FORM.RemoveFile' | translate }}
      </button>
    </mat-menu> -->
  </div>
</div>

<!-- <div *ngIf="translations" class="upload-button-wrapper">
  <button type="button" id="upload-document-button" class="upload-button" mat-raised-button color="primary" [disabled]="disableButton || uploadingFile">
    <label class="upload-label" for="upload-document">
      <mat-spinner *ngIf="uploadingFile" diameter="20" class="mr-10"></mat-spinner>
      <mat-icon *ngIf="!uploadingFile">{{ changeIcon() }}</mat-icon>
      {{ changeLabel() }}
    </label>
    <input type="file" id="upload-document" #file style="display: none;" (change)="handleFileInput($event.target?.files)" [disabled]="disableButton">
  </button>
  <div *ngIf="sizeTypeFileError"><mat-error *ngIf="sizeTypeFileError">
  {{ 'UPLOAD_FILE.UnableToUploadDocument' | translate:{ size: maxSize, types: fileTypes } }}</mat-error></div>
  <div *ngIf="showDocumentName">
    <div
      *ngIf="!downloadFileFromName && (fileToHandle?.name || fileToUpload?.name)"
      class="not-clickable-name-file"
    >
      <mat-icon class="icon-color icon-file-name">
        description
      </mat-icon>
      <mat-icon class="icon-color icon-file-download">
        get_app
      </mat-icon>
      <span class="light-text-color file-name">
        {{ fileToHandle?.name || fileToUpload?.name}}
      </span>
    </div>
    <div
      *ngIf="downloadFileFromName && (fileToHandle?.name || fileToUpload?.name)"
      class="clickable-name-file"
    >
      <button
        class="clickable-name-file"
        type="button"
        mat-button
        (click)="downloadFile()"
      >
        <mat-icon class="icon-color icon-file-name">
          description
        </mat-icon>
        <mat-icon class="icon-color icon-file-download">
          get_app
        </mat-icon>
        <span class="light-text-color file-name">
          {{ fileToHandle?.name || fileToUpload?.name}}
        </span>
      </button>
    </div>

    
    BELOW ALREADY COMMENTED CODE

    Code for having mat menu
    <button type="button" mat-button [matMenuTriggerFor]="menu">
      <mat-icon *ngIf="fileToUpload" class="icon-color">description</mat-icon>
      <span class="light-text-color file-name">
        {{ fileToUpload?.name }}
      </span>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-button type="button" (click)="removeFile()">
        <mat-icon>remove_circle</mat-icon>
        {{ 'IR_FORM.RemoveFile' | translate }}
      </button>
    </mat-menu>


  </div>
</div> -->
