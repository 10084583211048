import {Component, Input, OnInit} from "@angular/core";
import {IColumnsName} from "../../../models/utility";

@Component({
  selector: 'app-table-row-details',
  templateUrl: './table-row-details.component.html',
  styleUrls: ['./table-row-details.component.scss']
})
export class TableRowDetailsComponent implements OnInit{
  @Input() element: any;
  @Input() columnsName: IColumnsName[];
  @Input() columnsToShow: number;
  detailsColumnsToShowEnd: number;
  detailsColumnsToShowStart: number;

  ngOnInit(): void {
    this.detailsColumnsToShowEnd = this.columnsName.length;
    this.detailsColumnsToShowStart = this.columnsToShow;
  }
}
