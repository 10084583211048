<div class="dialog-wrapper">
  <h2 mat-dialog-title class="ta-center">
    {{ "CUSTOMER.ProjectsHistory" | translate }} - ID {{ project.idProject }}
  </h2>
  <mat-dialog-content>
    <div class="light-text-color">
      <div style="display: inline-block; width: 12%">
        <span>{{ "CUSTOMER.Version" | translate }}</span>
      </div>
      <div style="display: inline-block; width: 23%" class="ta-center">
        <span>{{ "CUSTOMER.StartDate" | translate }}</span>
      </div>
      <div style="display: inline-block; width: 23%" class="ta-center">
        <span>{{ "CUSTOMER.EndDate" | translate }}</span>
      </div>
      <div style="display: inline-block; width: 23%" class="ta-center">
        <span>{{ "CUSTOMER.PublishDate" | translate }}</span>
      </div>
    </div>
    <div
      *ngIf="projectLoader"
      style="height: 100px"
      class="d-flex align-items-center"
    >
      <mat-spinner class="m-0-auto" diameter="40"></mat-spinner>
    </div>
    <div *ngIf="projectHistoryList && !projectLoader" class="mb-20">
      <div
        *ngFor="
          let projectVersion of projectHistoryList?.projectHistoryList;
          let i = index
        "
      >
        <hr />
        <div style="display: inline-block; width: 12%">
          <span class="bold-text-color">
            {{
              prStatusPsVersion["PR_STATUS_" + projectVersion.idProjectStatus]
            }}
          </span>
        </div>
        <div style="display: inline-block; width: 23%" class="ta-center">
          <span class="fw-700">
            {{ projectVersion?.dateEffectiveStart | date: "dd/MM/yyyy HH:mm" }}
          </span>
        </div>
        <div style="display: inline-block; width: 23%" class="ta-center">
          <span class="fw-700">
            {{ projectVersion?.dateEffectiveEnd | date: "dd/MM/yyyy HH:mm" }}
          </span>
        </div>
        <div style="display: inline-block; width: 23%" class="ta-center">
          <span class="fw-700">
            {{
              projectVersion?.publishedDate | date: "dd/MM/yyyy HH:mm" || "--"
            }}
          </span>
        </div>
        <div
          *ngIf="!showPublishConfirmationButtons[i]"
          style="display: inline-block; width: 19%; vertical-align: middle"
          class="ta-center"
        >
          <button
            *ngIf="!publishLoaderList[i]"
            mat-button
            color="primary"
            (click)="showPublishConfirmationButtons[i] = true"
            [disabled]="
              checkIfAtLeastOnePublishLoaderIsTrue() ||
              (!showPublishConfirmationButtons[i] &&
                checkIfOthersShowPublicConfirmationButtonsAreOpen()) ||
              projectVersion?.isPublished ||
              !projectVersion?.dateEffectiveEnd
            "
          >
            {{
              projectVersion?.isPublished
                ? ("CUSTOMER.AlreadyPublished" | translate)
                : ("CUSTOMER.Publish" | translate)
            }}
          </button>
          <div
            *ngIf="publishLoaderList[i]"
            style="height: 36px"
            class="d-flex align-items-center"
          >
            <mat-spinner class="m-0-auto" diameter="20"></mat-spinner>
          </div>
        </div>
        <div
          *ngIf="showPublishConfirmationButtons[i]"
          style="display: inline-block; width: 19%"
          class="ta-center"
        >
          <button
            mat-button
            color="primary"
            (click)="publishProjectVersion(projectVersion, i)"
          >
            <mat-icon>done</mat-icon>
          </button>
          <button
            mat-button
            color="warn"
            (click)="showPublishConfirmationButtons[i] = false"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button
      mat-button
      mat-dialog-close
      [disabled]="checkIfAtLeastOnePublishLoaderIsTrue()"
    >
      {{ "COMMON.Close" | translate }}
    </button>
  </mat-dialog-actions>
</div>
