import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import Config, { horizontalPosition, verticalPosition } from 'src/app/config';
import {
  IChannelSpecification,
  IChannelsType,
} from 'src/app/models/channels-type';
import { IProvince } from 'src/app/models/user';
import { INominatimCoordinates, ToastStatus } from 'src/app/models/utility';
import { ServiceExtension } from '../serviceExtension';

@Injectable({
  providedIn: 'root',
})
export class CommonService extends ServiceExtension {
  constructor(
    private snackBar: MatSnackBar,
    private zone: NgZone,
    public routes: Router,
    private http: HttpClient
  ) {
    super();
  }

  /**
   * Open an allert message
   * @param message the message to show
   * @param status the status of message (success, info, error, warning)
   * @param duration the duration of toast message(millisencond)
   * @param action the string to show
   * @param actionMethod the method to after action
   */
  showToast(
    message: string,
    status: ToastStatus = ToastStatus.info,
    duration: number = 2500,
    action?: string | null,
    actionMethod: () => {} = null
  ): void {
    this.zone.run((): void => {
      this.snackBar
        .open(message, action, {
          horizontalPosition,
          verticalPosition,
          panelClass: status,
          duration,
        })
        .onAction()
        .subscribe({
          next: () => {
            if (action && actionMethod) {
              actionMethod();
            }
            if (action.toLocaleLowerCase() === 'close') {
              this.snackBar.dismiss();
            }
          },
        });
    });
  }

  getSpecificationChannels(
    idCustomer: number,
    hideLoader: boolean = true
  ): Observable<any> {
    return this.http
      .get<any[]>(
        `${Config.apiRootUrl}/common/specificationchanneltype/${idCustomer}`,
        {
          headers: this._getHeaders(hideLoader),
        }
      )
      .pipe();
  }

  saveChannelsSpecifications(
    channelsSpec: IChannelSpecification[]
  ): Observable<IChannelSpecification[]> {
    return this.http
      .put<IChannelSpecification[]>(
        `${Config.apiRootUrl}/common/specificationchanneltype`,
        channelsSpec
      )
      .pipe();
  }

  deleteChannelSpecification(
    idSpecificationChannelType: number
  ): Observable<any> {
    return this.http.delete<any>(
      `${Config.apiRootUrl}/common/specificationchanneltype/${idSpecificationChannelType}`
    );
  }

  getTop3Managers(
    dateFrom: string,
    dateTo: string,
    hideLoader: boolean
  ): Observable<any> {
    const par: Params = new HttpParams()
      .set('dateFrom', dateFrom.toString())
      .set('dateTo', dateTo.toString());
    return this.http
      .get<any>(`${Config.apiRootUrl}/dashboard/statistics/deals`, {
        headers: this._getHeaders(hideLoader),
        params: par,
      })
      .pipe();
  }

  getAllProvince(hideLoader: boolean = true): Observable<IProvince[]> {
    return this.http
      .get<IProvince[]>(`${Config.apiRootUrl}/common/province`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getAddressFromCoordinates(
    address: string
  ): Observable<INominatimCoordinates[]> {
    return this.http.get<INominatimCoordinates[]>(
      `https://nominatim.openstreetmap.org/search?q=${address}&format=json`
    );
  }

  getCityFromCoordinates(city: string): Observable<INominatimCoordinates[]> {
    return this.http.get<INominatimCoordinates[]>(
      `https://nominatim.openstreetmap.org/search?city=${city}&format=json`
    );
  }

  getEmailFormatter(): Observable<any> {
    return this.http.get<any>(`${Config.apiRootUrl}/notice/emailformatter`);
  }

  updateEmailFormatter(emailFormatter: any): Observable<any> {
    return this.http.put<any>(
      `${Config.apiRootUrl}/notice/emailformatter`,
      emailFormatter
    );
  }
}
