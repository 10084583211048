import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { FileLimit, Roles } from 'src/app/config';
import { INotice, INoticeDocument } from 'src/app/models/note';
import { IFileToHandle, ToastStatus } from 'src/app/models/utility';
import { CommonService } from 'src/app/services/common/common.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { LayoutService } from 'src/app/services/layout/layout.service';

@Component({
  selector: 'app-notices-board',
  templateUrl: './notices-board.component.html',
  styleUrls: ['./notices-board.component.scss'],
})
export class NoticesBoardComponent implements OnInit, OnDestroy {
  notices: INotice[] = [];
  oldNotices: INotice[];
  selectedNotice: INotice;
  isDirection: boolean = false;
  noticeForm: FormGroup;
  lockButtons: boolean = false;
  lockFilters: boolean = false;
  preselectedNoticeId: number;
  uploadedAttachment: IFileToHandle;
  attachmentList: File[] = [];

  private subscriptions: Subscription = new Subscription();

  descriptionFilter: FormControl = new FormControl(null);
  dateFilter: FormControl = new FormControl(null);

  @ViewChild('noticeDialog') noticeDialog: TemplateRef<any>;
  @ViewChild('attachment', { static: false }) attachment: ElementRef;

  constructor(
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private location: Location,
    private common: CommonService,
    private irFormService: IrFormService
  ) {
    this.route.queryParams.subscribe({
      next: (params: Params) => {
        if (params.id) {
          this.preselectedNoticeId = parseInt(params.id, 10);
          setTimeout(() => {
            this.location.replaceState('/notices-board');
          }, 1000);
        }
      },
    });
    this.noticeForm = this.fb.group({
      idServiceNotice: [null],
      title: [null, [Validators.required, Validators.maxLength(55)]],
      description: [null, [Validators.required, Validators.maxLength(255)]],
    });
  }

  ngOnInit(): void {
    if (
      JSON.parse(localStorage.getItem('session_user')).activeRole ===
      Roles.Direction
    ) {
      this.isDirection = true;
    }
    this.generateBreadcrumb();
    this.getNotices();
  }

  generateBreadcrumb(): void {
    this.subscriptions.add(
      this.translate.get('PAGES').subscribe({
        next: (data: any) => {
          this.layoutService.generateBreadcrumb([
            {
              label: data['notices-board'],
              path: '/notices-board',
              pageTitle: true,
            },
          ]);
        },
      })
    );
  }

  getNotices(): void {
    this.lockFilters = true;
    this.descriptionFilter.disable();
    this.dateFilter.disable();
    this.layoutService.getNotices().subscribe({
      next: (notices: INotice[]) => {
        this.notices = notices;
        this.oldNotices = notices;
        this.descriptionFilter.enable();
        this.dateFilter.enable();
        this.lockFilters = false;
        this.filterNotices();
        notices.forEach((notice: INotice) => {
          if (notice.idServiceNotice === this.preselectedNoticeId) {
            this.selectedNotice = notice;
          }
        });
      },
    });
  }

  selectNotice(notice: INotice): void {
    this.selectedNotice = notice;
    // this.noticeForm.patchValue(notice);
  }

  openNoticeDialog(type: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      noticeDialogType: type,
    };
    if (type === 'Edit') {
      this.noticeForm.patchValue(this.selectedNotice);
    }
    this.dialog.open(this.noticeDialog, dialogConfig);
  }

  closeNoticeDialog(): void {
    this.noticeForm.reset();
    this.attachmentList = [];
  }

  saveNotice(): void {
    if (this.attachmentList.length) {
      this.noticeForm.markAsDirty();
    }
    if (this.noticeForm.dirty && this.noticeForm.valid) {
      this.lockButtons = true;
      this.noticeForm.disable();
      this.layoutService.saveNotice([this.noticeForm.getRawValue()]).subscribe({
        next: (idNotice: number) => {
          if (this.attachmentList.length) {
            this.layoutService
              .uploadNoticeAttachments(idNotice, this.attachmentList)
              .subscribe({
                complete: () => {
                  this.noticeForm.reset();
                  this.noticeForm.enable();
                  this.getNotices();
                  this.lockButtons = false;
                  this.dialog.closeAll();
                },
              });
          } else {
            this.noticeForm.reset();
            this.noticeForm.enable();
            this.getNotices();
            this.lockButtons = false;
            this.dialog.closeAll();
          }
        },
      });
    } else if (this.noticeForm.valid && !this.noticeForm.dirty) {
      this.dialog.closeAll();
    }
  }

  handleFileInput(files: FileList): void {
    if (files && files.item(0)) {
      const typeSpit = files.item(0).type.split('/');
      if (
        files.item(0).size <= FileLimit.maxSize * 1024 * 1024 &&
        FileLimit.fileTypesSplit.includes(typeSpit[1])
      ) {
        this.attachmentList.push(files.item(0));
        this.attachment.nativeElement.value = null;
      } else {
        if (files.item(0).size >= FileLimit.maxSize * 1024 * 1024) {
          this.common.showToast(
            this.translate.instant('COMMON.FileExceedsMaximumDimension'),
            ToastStatus.error,
            3000
          );
        }
        if (!FileLimit.fileTypesSplit.includes(typeSpit[1])) {
          this.common.showToast(
            this.translate.instant('COMMON.FileNotSupported'),
            ToastStatus.error,
            3000
          );
        }
      }
    }
  }

  deleteNotice(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translate.instant('COMMON.Attention'),
      message: this.translate.instant('COMMON.DeleteNotice?'),
      buttonTrue: this.translate.instant('COMMON.Yes'),
      buttonFalse: this.translate.instant('COMMON.No'),
    };
    const dialogRef: MatDialogRef<any> = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.layoutService
          .deleteNotice(this.selectedNotice.idServiceNotice)
          .subscribe({
            complete: () => {
              this.noticeForm.reset();
              this.selectedNotice = null;
              this.getNotices();
            },
          });
      }
    });
  }

  downloadAttachment(notice: INoticeDocument): void {
    this.layoutService
      .downloadAttachment(notice.idServiceNoticesDocument)
      .subscribe();
  }

  filterNotices(): void {
    this.notices = this.oldNotices;
    if (
      this.descriptionFilter.value !== null &&
      this.descriptionFilter.value !== ''
    ) {
      this.notices = this.oldNotices.filter((notice: INotice) => {
        return (
          notice.description.toLowerCase().includes(this.descriptionFilter.value.toLowerCase()) ||
          notice.title.toLowerCase().includes(this.descriptionFilter.value.toLowerCase())
        );
      });
    }
    if (this.dateFilter.value !== null && this.dateFilter.value !== '') {
      this.notices = this.notices.filter((notice: INotice) => {
        return (
          moment(notice.creationDate).format('DD/MM/YYYY') ===
          moment(this.dateFilter.value).format('DD/MM/YYYY')
        );
      });
    }
  }

  resetFilters(): void {
    this.descriptionFilter.setValue(null);
    this.dateFilter.setValue(null);
    this.notices = this.oldNotices;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
