<form appSrollOnError [formGroup]="noteForm" (ngSubmit)="onSubmit()">
  <div class="section" formArrayName="notes">
    <div class="header">
      <div>
        <div class="title">
          <span>{{ "IR_FORM.Note" | translate }}</span>
        </div>
        <div class="description">
          <span>{{ "IR_FORM.NoteDescription" | translate }}</span>
        </div>
      </div>
    </div>
    <div class="content mt-20">
      <div class="section-card">
        <div class="p-relative">
          <div class="content with-left-element">
            <div class="row" [formGroupName]="0">
              <mat-form-field appearance="standard" class="col-12">
                <mat-label for="description">{{
                  "IR_FORM.ReferentNote" | translate
                }}</mat-label>
                <textarea
                  rows="3"
                  matInput
                  formControlName="description"
                  name="description"
                  type="text"
                ></textarea>
                <mat-error>
                  {{ notes.at(0).get('description').errors
                      | getErrorMessage: translations.ReferentNote
                  }}
                </mat-error>
              </mat-form-field>
              <span 
                class="col-12 text-color custom-hint fs-italic"
                style="margin-top: 0"
              >{{
                "IR_FORM.ReferenceNoteDescr" | translate
              }}</span>
            </div>
          </div>
        </div>
        <div class="p-relative">
          <div class="content with-left-element">
            <div class="row" [formGroupName]="1">
              <mat-form-field appearance="standard" class="col-12">
                <mat-label for="description"
                  >{{ "IR_FORM.CustomerNote" | translate }}
                </mat-label>
                <textarea
                  rows="3"
                  matInput
                  formControlName="description"
                  name="description"
                  type="text"
                ></textarea>
                <mat-error>
                  {{ notes.at(1).get('description').errors
                      | getErrorMessage: translations.CustomerNote
                  }}
                </mat-error>
              </mat-form-field>
              <span 
                class="col-12 text-color custom-hint fs-italic"
                style="margin-top: 0"
              >{{
                "IR_FORM.CustomerNoteDescr" | translate
              }}</span>
            </div>
          </div>
        </div>
        <div class="p-relative">
          <div class="content with-left-element">
            <div class="row" [formGroupName]="2">
              <mat-form-field appearance="standard" class="col-12">
                <mat-label for="description">{{
                  "IR_FORM.ProductNote" | translate
                }}</mat-label>
                <textarea
                  rows="3"
                  matInput
                  formControlName="description"
                  name="description"
                  type="text"
                ></textarea>
              </mat-form-field>
              <span class="col-12 text-color custom-hint fs-italic">{{
                "IR_FORM.ProductNoteDescr" | translate
              }}</span>
            </div>
          </div>
        </div>
        <div class="p-relative">
          <div class="content with-left-element">
            <div class="row" [formGroupName]="3">
              <mat-form-field appearance="standard" class="col-12">
                <mat-label for="description">{{
                  "IR_FORM.ManagerNote" | translate
                }}</mat-label>
                <textarea
                  rows="3"
                  matInput
                  formControlName="description"
                  name="description"
                  type="text"
                ></textarea>
              </mat-form-field>
              <span class="col-12 text-color custom-hint fs-italic">{{
                "IR_FORM.ManagerNoteDescr" | translate
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center-buttons mt-30">
      <button
        *ngIf="!isReadOnly"
        type="submit"
        class="rounded-button primary-bg-button"
      >
        {{ "COMMON.SaveChanges" | translate }}
      </button>
    </div>
  </div>
</form>
