<div *ngIf="contentsList" class="row">
  <div class="col-9">
    <div class="section-card">
      <div class="content">
        <app-table
          [loader]="contentsLoader"
          [columns]="contentsTableColumns"
          [columnsButtons]="contentsTableColumnsButtons"
          [data]="contentsData"
        ></app-table>
      </div>
    </div>
    <div class="section-without-card mt-20">
      <div class="description">
        <p>
          {{
            "IR_FORM.SubTitleSoldProductsServicesDetailedDescription"
              | translate
          }}
        </p>
      </div>
    </div>
    <div *ngIf="isIsp" class="row mt-60">
      <mat-form-field
        class="col-9"
        [ngClass]="
          !customerDescriptionForm.get('currentProdDesc')?.value && isIsp
            ? 'red-field-for-lab-manager'
            : ''
        "
      >
        <mat-label for="currentProdDesc">
          {{ "IR_FORM.CommentForPS" | translate }}
        </mat-label>
        <textarea
          rows="2"
          matInput
          type="text"
          name="currentProdDesc"
          [formControl]="customerDescriptionForm.get('currentProdDesc')"
        ></textarea>
        <mat-error
          *ngIf="customerDescriptionForm.get('currentProdDesc').invalid"
        >
          {{
            customerDescriptionForm.get("currentProdDesc").errors
              | getErrorMessage: translations.CommentForPS
          }}
        </mat-error>
      </mat-form-field>
      <div class="col-3" *ngIf="!isReadOnly && !isLabManagerInternal">
        <div class="center-buttons">
          <button
            type="button"
            class="rounded-button primary-border-button"
            (click)="submitComment()"
          >
            {{ "IR_FORM.SaveComment" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="section-card">
      <div
        *ngIf="!isReadOnly && !this.isLabManagerInternal"
        class="add-content"
      >
        <button
          type="button"
          class="rounded-button primary-bg-button"
          (click)="showContentDetail()"
        >
          <span class="white-color">
            {{ "IR_FORM.AddContent" | translate }}
          </span>
        </button>
      </div>
      <div class="header multi">
        <div class="title">
          <span>{{ "COMMON.Filters" | translate }}</span>
        </div>
        <div class="text-button">
          <span class="primary-color" (click)="resetFilters()">
            {{ "COMMON.Reset" | translate | lowercase }}
          </span>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <mat-form-field appearance="standard" class="col">
            <mat-label>{{ "IR_FORM.Typology" | translate }}</mat-label>
            <mat-select
              #typologyFilter
              (selectionChange)="filterTable('typology', $event)"
            >
              <mat-option value="Prodotto">
                {{ "IR_FORM.Product" | translate }}
              </mat-option>
              <mat-option value="Servizio">
                {{ "IR_FORM.Service" | translate }}
              </mat-option>
              <mat-option value="Subfornitura">
                {{ "IR_FORM.Subcontracting" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="standard" class="col">
            <mat-label>{{ "IR_FORM.Type" | translate }}</mat-label>
            <mat-select
              #typeFilter
              (selectionChange)="filterTable('type', $event)"
            >
              <mat-option value="Acquistato">
                {{ "IR_FORM.Bought" | translate }}
              </mat-option>
              <mat-option value="Venduto">
                {{ "IR_FORM.Sold" | translate }}
              </mat-option>
              <mat-option value="Corr / Comp">
                {{ "IR_FORM.RelatedComplementar" | translate }}
              </mat-option>
              <mat-option value="Concorrenza indiretta">
                {{ "IR_FORM.IndirectCompetition" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="standard" class="col">
            <mat-label>{{ "IR_FORM.ImportanceLevel" | translate }}</mat-label>
            <mat-select
              #importanceLevelFilter
              (selectionChange)="filterTable('importanceLevel', $event)"
            >
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">6</mat-option>
              <mat-option value="7">7</mat-option>
              <mat-option value="8">8</mat-option>
              <mat-option value="9">9</mat-option>
              <mat-option value="10">10</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>

<form
  *ngIf="contentDetail"
  appSrollOnError
  [formGroup]="contentForm"
  (ngSubmit)="saveContent()"
  class="ir-form"
>
  <div class="section-card">
    <div class="header h70">
      {{ contentForm?.get("name")?.value || "IR_FORM.Content" | translate }}
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-2">
          <mat-label for="type">
            {{ "IR_FORM.Tipology" | translate }}
          </mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="type"
            name="type"
          >
            <mat-option value="PRODOTTO_FINITO">{{
              "IR_FORM.FinalProduct" | translate
            }}</mat-option>
            <mat-option value="SERVIZIO">{{
              "IR_FORM.Service" | translate
            }}</mat-option>
            <mat-option value="SUBFORNITURA">{{
              "IR_FORM.Subcontracting" | translate
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="contentForm.get('type').invalid">
            {{
              contentForm.get("type").errors
                | getErrorMessage: translations.Tipology
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-2">
          <mat-label for="idProductType">
            {{ "IR_FORM.Type" | translate }}
          </mat-label>
          <mat-select
            formControlName="idProductType"
            disableOptionCentering
            panelClass="custom-panel-class"
            name="idProductType"
            (selectionChange)="resetContentFormOnTypeChange($event)"
          >
            <mat-option [value]="1">{{
              "IR_FORM.Sold2" | translate
            }}</mat-option>
            <mat-option [value]="2">{{
              "IR_FORM.Bought2" | translate
            }}</mat-option>
            <!-- <mat-option [value]="3">{{
              "IR_FORM.RelatedComplementar2" | translate
            }}</mat-option>
            <mat-option [value]="4">{{
              "IR_FORM.IndirectCompetition" | translate
            }}</mat-option> -->
          </mat-select>
          <mat-error *ngIf="contentForm.get('idProductType').invalid">
            {{
              contentForm.get("idProductType").errors
                | getErrorMessage: translations.Type
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3" formGroupName="commerceSector">
          <mat-label for="idCommerceSector">
            {{ "IR_FORM.CommerceSector" | translate }}
          </mat-label>
          <mat-select formControlName="idCommerceSector">
            <mat-option
              *ngFor="let commerceSector of commerceSectors"
              [value]="commerceSector.idCommerceSector"
            >
              {{ commerceSector.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{
              contentForm.get("commerceSector.idCommerceSector").errors
                | getErrorMessage: translations.CommerceSector
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label for="name">
            {{ "IR_FORM.Name" | translate }}
          </mat-label>
          <input
            #productName
            matInput
            formControlName="name"
            type="text"
            name="name"
          />
          <mat-error *ngIf="contentForm.get('name').invalid">
            {{
              contentForm.get("name").errors
                | getErrorMessage: translations.Name
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          *ngIf="contentForm.get('idProductType').value !== 2"
          class="col-2"
        >
          <mat-label for="productCode">
            {{ "IR_FORM.CustomsCode" | translate }}
          </mat-label>
          <input
            #searchCodeInput
            matInput
            formControlName="productCode"
            type="text"
            name="productCode"
          />
          <button
            type="button"
            matSuffix
            [matMenuTriggerFor]="customsCodeDescription"
            class="only-icon-button primary"
            (click)="$event.stopPropagation()"
            style="margin-right: 35px"
          >
            <mat-icon>info</mat-icon>
          </button>
          <mat-menu #customsCodeDescription="matMenu">
            <div style="padding: 0 10px">
              {{ "IR_FORM.CustomsCodeDescription" | translate }}
            </div>
          </mat-menu>
          <button
            *ngIf="!isReadOnly && !isLabManagerInternal"
            class="only-icon-button search-code primary"
            type="button"
            matSuffix
            (click)="searchCode(searchCodeInput.value)"
          >
            <svg-icon name="search_icon" class="w32 h32"></svg-icon>
          </button>
          <mat-error *ngIf="contentForm.get('productCode').invalid">
            {{
              contentForm.get("productCode").errors
                | getErrorMessage: translations.CustomsCode
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-12">
          <mat-label for="description">
            {{ "IR_FORM.ExtendedDescription" | translate }}
          </mat-label>
          <input
            matInput
            formControlName="description"
            type="text"
            name="description"
          />
          <mat-error *ngIf="contentForm.get('description').invalid">
            {{
              contentForm.get("description").errors
                | getErrorMessage: translations.ExtendedDescription
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-12">
          <mat-label for="englishDescription">
            {{ "IR_FORM.EnglishExtendedDescription" | translate }}
          </mat-label>
          <input
            matInput
            formControlName="englishDescription"
            type="text"
            name="englishDescription"
          />
          <mat-error *ngIf="contentForm.get('englishDescription').invalid">
            {{
              contentForm.get("englishDescription").errors
                | getErrorMessage: translations.EnglishExtendedDescription
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="contentForm.get('idProductType').value < 3" class="row">
        <mat-form-field
          *ngIf="contentForm.get('idProductType').value === 1"
          class="col-3"
        >
          <mat-label for="revenuePercentage">
            {{ "IR_FORM.TurnoverQuote" | translate }}
          </mat-label>
          <input
            matInput
            formControlName="revenuePercentage"
            type="number"
            min="0"
            max="100"
            name="revenuePercentage"
            onKeyPress="this.value.length === 3 ? false : true"
            (keyup)="totalRevenuePercentageContents()"
          />
          <span class="custom-suffix" matSuffix>%</span>
          <mat-error *ngIf="contentForm.get('revenuePercentage').invalid">
            {{
              contentForm.get("revenuePercentage").errors
                | getErrorMessage: translations.TurnoverQuote
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3" formGroupName="unitOfMeasure">
          <mat-label for="idUom">
            {{ "IR_FORM.UnitOfMeasure" | translate }}
          </mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="idUom"
            name="idUom"
          >
            <mat-option
              *ngFor="let unit of unitsOfMeasure"
              [value]="unit.idUom"
            >
              {{ unit.name }}
            </mat-option>
          </mat-select>
          <button
            *ngIf="
              !isReadOnly &&
              !isLabManagerInternal &&
              contentForm.get('idProduct')?.value !== idMarketAnalysisProduct
            "
            type="button"
            class="only-icon-button primary"
            matSuffix
            (click)="showUomManagementDialog($event)"
          >
            <svg-icon name="list_icon_2" class="w20 h20"></svg-icon>
          </button>
          <mat-error
            *ngIf="contentForm.controls.unitOfMeasure.get('idUom').invalid"
          >
            {{
              contentForm.controls.unitOfMeasure.get("idUom").errors
                | getErrorMessage: translations.UnitOfMeasure
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label for="quantity">
            {{ "IR_FORM.TargetQuantity" | translate }}
          </mat-label>
          <input
            currencyMask
            matInput
            formControlName="quantity"
            autocomplete="off"
            [options]="currencyQuantityConfigWithoutDecimal"
            name="quantity"
          />
          <mat-error *ngIf="contentForm.get('quantity').invalid">
            {{
              contentForm.get("quantity").errors
                | getErrorMessage: translations.TargetQuantity
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label for="quantityYear">
            {{ "IR_FORM.TargetQuantityYear" | translate }}
          </mat-label>
          <input
            currencyMask
            matInput
            formControlName="quantityYear"
            autocomplete="off"
            [options]="currencyQuantityConfigWithoutDecimal"
            name="quantityYear"
            (keyup)="updateValueYear()"
          />
          <mat-error *ngIf="contentForm.get('quantityYear').invalid">
            {{
              contentForm.get("quantityYear").errors
                | getErrorMessage: translations.TargetQuantityYear
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-3">
          <mat-label for="valueOfUnit">
            {{ "IR_FORM.ValueOfUnit" | translate }}
          </mat-label>
          <input
            matInput
            name="valueOfUnit"
            currencyMask
            formControlName="valueOfUnit"
            autocomplete="off"
            [options]="currencyDefaultConfig"
            (keyup)="updateValueYear()"
          />
          <mat-error *ngIf="contentForm.get('valueOfUnit').invalid">
            {{
              contentForm.get("valueOfUnit").errors
                | getErrorMessage: translations.ValueOfUnit
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label for="valueYear">
            {{
              (contentForm.get("idProductType").value !== 2
                ? "IR_FORM.ValueSoldYear"
                : "IR_FORM.ValueYear"
              ) | translate
            }}
          </mat-label>
          <input
            matInput
            formControlName="valueYear"
            readonly
            name="valueYear"
            autocomplete="off"
            [options]="currencyDefaultConfig"
            currencyMask
          />
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label for="importedExported">
            {{
              contentForm.get("idProductType").value === 2
                ? ("IR_FORM.ImportedProduct" | translate)
                : ("IR_FORM.ExportedProduct" | translate)
            }}
          </mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="importedExported"
            name="importedExported"
          >
            <mat-option [value]="true">{{
              "IR_FORM.Yes" | translate
            }}</mat-option>
            <mat-option [value]="false">{{
              "IR_FORM.No" | translate
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="contentForm.get('importedExported').invalid">
            {{
              contentForm.get("importedExported").errors
                | getErrorMessage: translations.ExportedProduct
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          *ngIf="contentForm.get('idProductType').value !== 2"
          class="col-3"
        >
          <mat-label
            *ngIf="
              !contentForm.get('idProductType').value ||
              contentForm.get('idProductType').value < 3
            "
            for="importanceLevel"
          >
            {{ "IR_FORM.ImportanceLevelLong" | translate }}
          </mat-label>
          <mat-label
            *ngIf="contentForm.get('idProductType').value === 3"
            for="importanceLevel"
          >
            {{ "IR_FORM.CorrelationLevel" | translate }}
          </mat-label>
          <mat-label
            *ngIf="contentForm.get('idProductType').value === 4"
            for="importanceLevel"
          >
            {{ "IR_FORM.CompetitiveLevel" | translate }}
          </mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="importanceLevel"
            name="importanceLevel"
          >
            <mat-option [value]="1">1</mat-option>
            <mat-option [value]="2">2</mat-option>
            <mat-option [value]="3">3</mat-option>
            <mat-option [value]="4">4</mat-option>
            <mat-option [value]="5">5</mat-option>
            <mat-option [value]="6">6</mat-option>
            <mat-option [value]="7">7</mat-option>
            <mat-option [value]="8">8</mat-option>
            <mat-option [value]="9">9</mat-option>
            <mat-option [value]="10">10</mat-option>
          </mat-select>
          <mat-error *ngIf="contentForm.get('importanceLevel').invalid">
            {{
              contentForm.get("importanceLevel").errors
                | getErrorMessage: translations.Level
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div
        *ngIf="
          contentForm.get('idProductType').value < 3 &&
          contentForm.get('idProductType').value !== 2
        "
        class="row"
      >
        <mat-form-field class="col-12">
          <mat-label for="reason">
            {{ "IR_FORM.Reason" | translate }}
          </mat-label>
          <input matInput formControlName="reason" type="text" name="reason" />
          <mat-error *ngIf="contentForm.get('reason').invalid">
            {{
              contentForm.get("reason").errors
                | getErrorMessage: translations.Reason
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="word-key-section mt-20">
        <div formGroupName="wordKey" class="mb-20">
          <div class="d-flex align-items-center">
            <div class="fw-700 d-inline-block">
              {{ "IR_FORM.WordsKey" | translate }}
            </div>
            <button
              type="button"
              matSuffix
              [matMenuTriggerFor]="wordKeyDescription"
              class="only-icon-button primary ml-20"
            >
              <mat-icon>info</mat-icon>
            </button>
            <mat-menu #wordKeyDescription="matMenu">
              <div style="padding: 0 10px">
                {{ "IR_FORM.WordsKeyDescriptionInformations" | translate }}
              </div>
            </mat-menu>
            <div
              *ngIf="
                !productMatching?.controls?.length &&
                !isReadOnly &&
                !isLabManagerInternal
              "
              class="ml-20 error-color"
            >
              {{ "IR_FORM.InsertAtLeastOneWordKey" | translate }}
            </div>
          </div>
          <div formArrayName="productMatching" class="wordkey-container">
            <div
              class="item-wordkey"
              *ngFor="let prodMatch of productMatching.controls; let i = index"
            >
              <div class="row">
                <mat-form-field [formGroupName]="i" class="col">
                  <mat-label for="wordKey">{{
                    "IR_FORM.WordKey" | translate
                  }}</mat-label>
                  <input
                    matInput
                    name="wordKey"
                    formControlName="wordKey"
                    type="text"
                  />
                  <mat-error *ngIf="prodMatch.get('wordKey').invalid">
                    {{
                      prodMatch.get("wordKey").errors
                        | getErrorMessage: translations.WordKey
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field [formGroupName]="i" class="col">
                  <mat-label for="englishWordKey">{{
                    "IR_FORM.EnglishWordKey" | translate
                  }}</mat-label>
                  <input
                    matInput
                    name="englishWordKey"
                    formControlName="englishWordKey"
                    type="text"
                  />
                  <mat-error *ngIf="prodMatch.get('englishWordKey').invalid">
                    {{
                      prodMatch.get("englishWordKey").errors
                        | getErrorMessage: translations.EnglishWordKey
                    }}
                  </mat-error>
                </mat-form-field>
                <div class="col-1 d-flex col-without-padding">
                  <button
                    [disabled]="isReadOnly || isLabManagerInternal"
                    class="only-icon-button primary"
                    type="button"
                    (click)="removeProductMatching(i)"
                  >
                    <svg-icon name="trash_icon" class="w20 h20"></svg-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="!isReadOnly && !isLabManagerInternal"
            class="ta-center mt-20"
          >
            <button
              class="small-rounded-button primary-bg-button"
              type="button"
              [disabled]="productMatching.controls.length > 4"
              (click)="addProductMatching()"
            >
              {{ "COMMON.Add" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="contentForm?.get('idProductType')?.value === 1">
      <div class="header">
        <div class="title">
          {{ "IR_FORM.RelatedComplementaryProducts" | translate }}
        </div>
        <button
          type="button"
          [matMenuTriggerFor]="relatedComplementaryPrductsInfo"
          class="only-icon-button primary ml-20"
        >
          <mat-icon>info</mat-icon>
        </button>
        <mat-menu #relatedComplementaryPrductsInfo="matMenu">
          <div style="padding: 0 10px">
            {{
              "IR_FORM.RelatedComplementaryProductsDescriptionInformation"
                | translate
            }}
          </div>
        </mat-menu>
      </div>

      <!-- PRODOTTI CORRELATI/COMPLEMENTARI  -->
      <div class="content" formArrayName="relatedComplementary">
        <div
          class="sub-product-container"
          *ngFor="
            let subProduct of relatedComplementaryProducts.controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <div class="row">
            <mat-form-field appearance="standard" class="col-9">
              <mat-label for="name">{{ "IR_FORM.Name" | translate }}</mat-label>
              <input matInput formControlName="name" type="text" name="name" />
              <mat-error>
                {{
                  subProduct.get("description").errors
                    | getErrorMessage: translations.Name
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-3">
              <mat-label for="importanceLevel">
                {{ "IR_FORM.CorrelationLevel" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="importanceLevel"
                name="importanceLevel"
              >
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="6">6</mat-option>
                <mat-option [value]="7">7</mat-option>
                <mat-option [value]="8">8</mat-option>
                <mat-option [value]="9">9</mat-option>
                <mat-option [value]="10">10</mat-option>
              </mat-select>
              <mat-error>
                {{
                  subProduct.get("importanceLevel").errors
                    | getErrorMessage: translations.Level
                }}
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field appearance="standard" class="col-2">
              <mat-label for="productCode">
                {{ "IR_FORM.CustomsCode" | translate }}
              </mat-label>
              <input
                #searchCodeInput
                matInput
                formControlName="productCode"
                type="text"
                name="productCode"
              />
              <button
                type="button"
                matSuffix
                [matMenuTriggerFor]="customsCodeDescription"
                class="only-icon-button primary"
                (click)="$event.stopPropagation()"
                style="margin-right: 35px"
              >
                <mat-icon>info</mat-icon>
              </button>
              <mat-menu #customsCodeDescription="matMenu">
                <div style="padding: 0 10px">
                  {{ "IR_FORM.CustomsCodeDescription" | translate }}
                </div>
              </mat-menu>
              <button
                *ngIf="
                  !isReadOnly &&
                  !isLabManagerInternal
                "
                class="only-icon-button search-code primary"
                type="button"
                matSuffix
                (click)="searchCode(searchCodeInput.value, subProduct)"
              >
                <svg-icon name="search_icon" class="w32"></svg-icon>
              </button>
              <mat-error *ngIf="subProduct.get('productCode').invalid">
                {{
                  subProduct.get("productCode").errors
                    | getErrorMessage: translations.CustomsCode
                }}
              </mat-error>
            </mat-form-field> -->
          </div>
          <div class="row">
            <mat-form-field appearance="standard" class="col-12">
              <mat-label for="description">
                {{ "IR_FORM.ExtendedDescription" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="description"
                type="text"
                name="description"
              />
              <mat-error>
                {{
                  subProduct.get("description").errors
                    | getErrorMessage: translations.Description
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="standard" class="col-12">
              <mat-label for="englishDescription">
                {{ "IR_FORM.EnglishExtendedDescription" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="englishDescription"
                type="text"
                name="englishDescription"
              />
              <mat-error>
                {{
                  subProduct.get("englishDescription").errors
                    | getErrorMessage: translations.EnglishExtendedDescription
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="word-key-section my-20">
            <div class="d-flex align-items-center">
              <div class="fw-700 d-inline-block">
                {{ "IR_FORM.ReferenceAnagrafics" | translate }}
              </div>
              <button
                *ngIf="isAgent"
                type="button"
                [matMenuTriggerFor]="relatedComplementaryRegistryInfo"
                class="only-icon-button primary ml-20"
              >
                <mat-icon>info</mat-icon>
              </button>
              <mat-menu #relatedComplementaryRegistryInfo="matMenu">
                <div style="padding: 0 10px">
                  {{
                    "IR_FORM.RelatedComplementaryProductRegistryInfo"
                      | translate
                  }}
                </div>
              </mat-menu>
            </div>
            <div formArrayName="relevantRegistry" class="wordkey-container">
              <div
                class="item-wordkey"
                *ngFor="
                  let relReg of subProduct.get('relevantRegistry').controls;
                  let regInd = index
                "
                [formGroupName]="regInd"
              >
                <div class="row">
                  <mat-form-field class="col">
                    <mat-label for="companyName">{{
                      "IR_FORM.CompanyName" | translate
                    }}</mat-label>
                    <input
                      matInput
                      name="companyName"
                      formControlName="companyName"
                      type="text"
                    />
                    <mat-error *ngIf="relReg.get('companyName').invalid">
                      {{
                        relReg.get("companyName").errors
                          | getErrorMessage: translations.CompanyName
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col">
                    <mat-label for="idCountry">{{
                      "IR_FORM.Nationality" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idCountry"
                      name="idCountry"
                      (closed)="filter.value = ''"
                    >
                      <div class="select-global-filter">
                        <input
                          #filter
                          matInput
                          type="text"
                          [placeholder]="'COMMON.Search...' | translate"
                        />
                        <button
                          matSuffix
                          *ngIf="filter.value !== ''"
                          class="only-icon-button"
                          type="button"
                          (click)="filter.value = null"
                        >
                          <mat-icon>clear</mat-icon>
                        </button>
                      </div>
                      <mat-option
                        [ngStyle]="{
                          display:
                            (
                              countries | filterSelect: filter.value:'country'
                            ).includes(country) || filter.value === ''
                              ? 'flex'
                              : 'none'
                        }"
                        *ngFor="let country of countries"
                        [value]="country.idCountry"
                      >
                        {{ country.country }}
                      </mat-option>
                      <div
                        *ngIf="
                          !(countries | filterSelect: filter.value:'country')
                            ?.length
                        "
                        class="select-global-filter-no-result"
                      >
                        {{ "COMMON.NoResult" | translate }}
                      </div>
                    </mat-select>
                    <mat-error *ngIf="relReg.get('idCountry').invalid">
                      {{
                        relReg.get("idCountry").errors
                          | getErrorMessage: translations.Nationality
                      }}
                    </mat-error>
                  </mat-form-field>
                  <div class="col-1 d-flex col-without-padding">
                    <button
                      *ngIf="!isReadOnly && !isLabManagerInternal"
                      class="only-icon-button primary"
                      type="button"
                      (click)="removeRelevantRegistry(regInd, subProduct)"
                    >
                      <svg-icon name="trash_icon" class="w20 h20"></svg-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="!isReadOnly && !isLabManagerInternal"
              class="ta-center mt-20"
            >
              <button
                *ngIf="!isReadOnly && !isLabManagerInternal"
                class="small-rounded-button primary-bg-button"
                type="button"
                [disabled]="relevantRegistryList.controls.length > 4"
                (click)="addRelevantRegistry(subProduct)"
              >
                {{ "COMMON.Add" | translate }}
              </button>
            </div>
          </div>
          <button
            *ngIf="!isReadOnly && !isLabManagerInternal"
            type="button"
            class="rounded-button primary-border-button m-0-auto"
            (click)="removeSubProduct(subProduct, productType.related, i)"
          >
            {{ "COMMON.Delete" | translate }}
          </button>
        </div>
        <button
          *ngIf="!isReadOnly && !isLabManagerInternal"
          type="button"
          class="rounded-button primary-bg-button ml-auto"
          (click)="addSubProduct(null, productType.related)"
        >
          {{ "IR_FORM.AddRelatedComplementaryProduct" | translate }}
        </button>
      </div>

      <!-- PRODOTTI CONCORRENZA INDIRETTA  -->
      <div class="header">
        <div class="title">
          {{ "IR_FORM.IndirectCompetitionProducts" | translate }}
        </div>
        <button
          type="button"
          matSuffix
          [matMenuTriggerFor]="indirectCompetitionProductsInfo"
          class="only-icon-button primary ml-20"
        >
          <mat-icon>info</mat-icon>
        </button>
        <mat-menu #indirectCompetitionProductsInfo="matMenu">
          <div style="padding: 0 10px">
            {{
              "IR_FORM.IndirectCompetitionProductsDescriptionInformation"
                | translate
            }}
          </div>
        </mat-menu>
      </div>
      <div class="content" formArrayName="indirectCompetition">
        <div
          class="sub-product-container"
          *ngFor="
            let subProduct of indirectCompetitionProducts.controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <div class="row">
            <mat-form-field appearance="standard" class="col-5">
              <mat-label for="name">{{ "IR_FORM.Name" | translate }}</mat-label>
              <input matInput formControlName="name" type="text" name="name" />
              <mat-error>
                {{
                  subProduct.get("description").errors
                    | getErrorMessage: translations.Name
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              appearance="standard"
              class="col-4"
              formGroupName="commerceSector"
            >
              <mat-label for="idCommerceSector">
                {{ "IR_FORM.CommerceSector" | translate }}
              </mat-label>
              <mat-select formControlName="idCommerceSector">
                <mat-option
                  *ngFor="let commerceSector of commerceSectors"
                  [value]="commerceSector.idCommerceSector"
                >
                  {{ commerceSector.name }}
                </mat-option>
              </mat-select>
              <mat-error>
                {{
                  subProduct.get("commerceSector.idCommerceSector").errors
                    | getErrorMessage: translations.CommerceSector
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-3">
              <mat-label for="importanceLevel">
                {{ "IR_FORM.CompetitiveLevel" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="importanceLevel"
                name="importanceLevel"
              >
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="6">6</mat-option>
                <mat-option [value]="7">7</mat-option>
                <mat-option [value]="8">8</mat-option>
                <mat-option [value]="9">9</mat-option>
                <mat-option [value]="10">10</mat-option>
              </mat-select>
              <mat-error>
                {{
                  subProduct.get("importanceLevel").errors
                    | getErrorMessage: translations.Level
                }}
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field appearance="standard" class="col-2">
              <mat-label for="productCode">
                {{ "IR_FORM.CustomsCode" | translate }}
              </mat-label>
              <input
                #searchCodeInput
                matInput
                formControlName="productCode"
                type="text"
                name="productCode"
              />
              <button
                *ngIf="
                  !isReadOnly &&
                  !isLabManagerInternal &&
                  contentForm.get('idProduct')?.value !==
                    this.idMarketAnalysisProduct
                "
                type="button"
                matSuffix
                [matMenuTriggerFor]="customsCodeDescription"
                class="only-icon-button primary"
                (click)="$event.stopPropagation()"
                style="margin-right: 35px"
              >
                <mat-icon>info</mat-icon>
              </button>
              <mat-menu #customsCodeDescription="matMenu">
                <div style="padding: 0 10px">
                  {{ "IR_FORM.CustomsCodeDescription" | translate }}
                </div>
              </mat-menu>
              <button
                *ngIf="
                  !isReadOnly &&
                  !isLabManagerInternal
                "
                class="only-icon-button search-code primary"
                type="button"
                matSuffix
                (click)="searchCode(searchCodeInput.value, subProduct)"
              >
                <svg-icon name="search_icon" class="w32"></svg-icon>
              </button>
              <mat-error *ngIf="subProduct.get('productCode').invalid">
                {{
                  subProduct.get("productCode").errors
                    | getErrorMessage: translations.CustomsCode
                }}
              </mat-error>
            </mat-form-field> -->
          </div>
          <div class="row">
            <mat-form-field appearance="standard" class="col-12">
              <mat-label for="description">
                {{ "IR_FORM.ExtendedDescription" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="description"
                type="text"
                name="description"
              />
              <mat-error>
                {{
                  subProduct.get("description").errors
                    | getErrorMessage: translations.Description
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="standard" class="col-12">
              <mat-label for="englishDescription">
                {{ "IR_FORM.EnglishExtendedDescription" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="englishDescription"
                type="text"
                name="englishDescription"
              />
              <mat-error>
                {{
                  subProduct.get("englishDescription").errors
                    | getErrorMessage: translations.EnglishExtendedDescription
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="word-key-section my-20">
            <div class="d-flex align-items-center">
              <div class="fw-700 d-inline-block">
                {{ "IR_FORM.ReferenceAnagrafics" | translate }}
              </div>
              <button
                *ngIf="isAgent"
                type="button"
                [matMenuTriggerFor]="competitionRegistryInfo"
                class="only-icon-button primary ml-20"
              >
                <mat-icon>info</mat-icon>
              </button>
              <mat-menu #competitionRegistryInfo="matMenu">
                <div style="padding: 0 10px">
                  {{
                    "IR_FORM.CompetitionProductRegistryInfo"
                      | translate
                  }}
                </div>
              </mat-menu>
            </div>
            <div formArrayName="relevantRegistry" class="wordkey-container">
              <div
                class="item-wordkey"
                *ngFor="
                  let relReg of subProduct.get('relevantRegistry').controls;
                  let regInd = index
                "
                [formGroupName]="regInd"
              >
                <div class="row">
                  <mat-form-field class="col">
                    <mat-label for="companyName">{{
                      "IR_FORM.CompanyName" | translate
                    }}</mat-label>
                    <input
                      matInput
                      name="companyName"
                      formControlName="companyName"
                      type="text"
                    />
                    <mat-error *ngIf="relReg.get('companyName').invalid">
                      {{
                        relReg.get("companyName").errors
                          | getErrorMessage: translations.CompanyName
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col">
                    <mat-label for="idCountry">{{
                      "IR_FORM.Nationality" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idCountry"
                      name="idCountry"
                      (closed)="filter.value = ''"
                    >
                      <div class="select-global-filter">
                        <input
                          #filter
                          matInput
                          type="text"
                          [placeholder]="'COMMON.Search...' | translate"
                        />
                        <button
                          matSuffix
                          *ngIf="filter.value !== ''"
                          class="only-icon-button"
                          type="button"
                          (click)="filter.value = null"
                        >
                          <mat-icon>clear</mat-icon>
                        </button>
                      </div>
                      <mat-option
                        [ngStyle]="{
                          display:
                            (
                              countries | filterSelect: filter.value:'country'
                            ).includes(country) || filter.value === ''
                              ? 'flex'
                              : 'none'
                        }"
                        *ngFor="let country of countries"
                        [value]="country.idCountry"
                      >
                        {{ country.country }}
                      </mat-option>
                      <div
                        *ngIf="
                          !(countries | filterSelect: filter.value:'country')
                            ?.length
                        "
                        class="select-global-filter-no-result"
                      >
                        {{ "COMMON.NoResult" | translate }}
                      </div>
                    </mat-select>
                    <mat-error *ngIf="relReg.get('idCountry').invalid">
                      {{
                        relReg.get("idCountry").errors
                          | getErrorMessage: translations.Nationality
                      }}
                    </mat-error>
                  </mat-form-field>
                  <div class="col-1 d-flex col-without-padding">
                    <button
                      *ngIf="!isReadOnly && !isLabManagerInternal"
                      class="only-icon-button primary"
                      type="button"
                      (click)="removeRelevantRegistry(regInd, subProduct)"
                    >
                      <svg-icon name="trash_icon" class="w20 h20"></svg-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="!isReadOnly && !isLabManagerInternal"
              class="ta-center mt-20"
            >
              <button
                class="small-rounded-button primary-bg-button"
                type="button"
                [disabled]="relevantRegistryList.controls.length > 4"
                (click)="addRelevantRegistry(subProduct)"
              >
                {{ "COMMON.Add" | translate }}
              </button>
            </div>
          </div>
          <button
            *ngIf="!isReadOnly && !isLabManagerInternal"
            type="button"
            class="rounded-button primary-border-button m-0-auto"
            (click)="removeSubProduct(subProduct, productType.competition, i)"
          >
            {{ "COMMON.Delete" | translate }}
          </button>
        </div>
        <button
          *ngIf="!isReadOnly && !isLabManagerInternal"
          type="button"
          class="rounded-button primary-bg-button ml-auto"
          (click)="addSubProduct(null, productType.competition)"
        >
          {{ "IR_FORM.AddIndirectCompetitionProduct" | translate }}
        </button>
      </div>
    </ng-container>
    <div *ngIf="!isReadOnly && !isLabManagerInternal" class="buttons center">
      <button
        type="button"
        class="rounded-button error-border-button mr-20"
        (click)="closeContentDetail()"
        [disabled]="lockButtons"
      >
        {{ "COMMON.Back" | translate }}
      </button>
      <button
        *ngIf="
          contentForm?.get('idProduct')?.value &&
          contentForm.get('idProduct')?.value !== this.idMarketAnalysisProduct
        "
        type="button"
        class="rounded-button error-border-button mr-20"
        (click)="deleteContentFromDetail()"
        [disabled]="lockButtons"
      >
        {{ "COMMON.Delete" | translate }}
      </button>
      <button
        type="submit"
        class="rounded-button primary-bg-button"
        [disabled]="lockButtons"
      >
        {{ "COMMON.Save" | translate }}
      </button>
    </div>
  </div>
</form>

<!-- <div *ngIf="contentForm.get('relatedProducts').value">
  <div class="d-flex align-items-center">
    <div class="fw-700 d-inline-block">
      {{ "IR_FORM.ReferenceAnagrafics" | translate }}
    </div>
  </div>
  <div formArrayName="relevantRegistry" class="wordkey-container">
    <div
      class="item"
      *ngFor="
        let relReg of relevantRegistryList.controls;
        let i = index
      "
    >
      <div class="row">
        <mat-form-field [formGroupName]="i" class="col">
          <mat-label for="companyName">{{
            "IR_FORM.CompanyName" | translate
          }}</mat-label>
          <input
            matInput
            name="companyName"
            formControlName="companyName"
            type="text"
          />
          <mat-error *ngIf="relReg.get('companyName').invalid">
            {{
              relReg.get("companyName").errors
                | getErrorMessage: translations.CompanyName
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field [formGroupName]="i" class="col">
          <mat-label for="idCountry">{{
            "IR_FORM.Nationality" | translate
          }}</mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="idCountry"
            name="idCountry"
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="entityFilterCtrl"
                [placeholderLabel]="'COMMON.Search...' | translate"
                [noEntriesFoundLabel]="'Nessun risultato'"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              [ngStyle]="{
                display: filteredCountries.includes(country)
                  ? 'flex'
                  : 'none'
              }"
              *ngFor="let country of countries"
              [value]="country.idCountry"
            >
              {{ country.country }}
            </mat-option>
            <mat-option
              *ngIf="!filteredCountries?.length"
              style="pointer-events: none"
            >
              {{ "COMMON.NoResult" | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="relReg.get('idCountry').invalid">
            {{
              relReg.get("idCountry").errors
                | getErrorMessage: translations.Nationality
            }}
          </mat-error>
        </mat-form-field>
        <div class="col-1 d-flex col-without-padding">
          <button
            [disabled]="isReadOnly || isLabManagerInternal"
            class="only-icon-button primary"
            type="button"
            (click)="removeRelevantRegistry(i)"
          >
            <svg-icon name="trash_icon" class="w20"></svg-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="!isReadOnly && !isLabManagerInternal"
    class="ta-center mt-20"
  >
    <button
      class="small-rounded-button primary-bg-button"
      type="button"
      [disabled]="relevantRegistryList.controls.length > 4"
      (click)="addRelevantRegistry()"
    >
      {{ "COMMON.Add" | translate }}
    </button>
  </div>
</div> -->
