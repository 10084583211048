import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DocumentDTO } from 'src/app/models/contract';
import { ProjectDTO } from 'src/app/models/project';
import { ILabManager } from 'src/app/models/roles';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { apiRootUrl, CONTACT_REFERENCE_TYPE, RI_STATUS } from 'src/app/config';
import { ICustomer, ICustomerContact } from 'src/app/models/customer';
import { LayoutService } from 'src/app/services/layout/layout.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ITableButton, ITableColumn } from 'src/app/models/utility';
import { UsersService } from 'src/app/services/users/users.service';
import { Feature, Map, MapBrowserEvent, Overlay, View } from 'ol';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import { Style, Fill, Icon, Text } from 'ol/style';
import Geometry from 'ol/geom/Geometry';
import Cluster from 'ol/source/Cluster';
import { FeatureLike } from 'ol/Feature';
import { fromDocumentDtoToDocumentForTable } from 'src/app/utility/elaborations';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
})
export class ProjectDetailComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  dlmanagerSubscriptions: Subscription;
  translations: any;

  customer: ICustomer;
  project: ProjectDTO;
  labManagers: any[];
  document: File;
  approvedBy: string = null;
  approvedByPhone: string = null;
  approvedByEmail: string = null;
  drawnUpBy: string = null;
  drawnUpByPhone: string = null;
  drawnUpByEmail: string = null;
  idApprovedBy: number;
  idDrawnUpBy: number;
  customerAddress: string;
  projectReferent: ICustomerContact;
  administrativeReferent: ICustomerContact;
  customerImagePath: string;
  selectedDLManager: any;
  selectedDLManagerForProject: any;
  file: any;
  dlManagersLoader: boolean = false;
  dlManagersForTable: any[];
  dlManagersTableColumns: ITableColumn[];
  dlManagersTableButtons: ITableButton[];
  dlManagerExpertiseInfo: any;
  dlManagerExpertiseInfoLoading: boolean;
  map: Map;
  view: View = new View({
    center: fromLonLat([12.496366, 41.902782]),
    zoom: 5,
  });
  coords = [
    fromLonLat([9.18854, 45.464664]),
    fromLonLat([12.496366, 41.902782]),
    fromLonLat([14.305573, 40.853294]),
  ];
  dlmanagerInfoLoader: boolean;
  showPopup: boolean = false;
  showLateralPopup: boolean = false;
  loaderDocuments: boolean = false;
  documentsDataSource: DocumentDTO[];
  documentsTableColumnsButtons: ITableButton[];
  documentsTableColumns: ITableColumn[];
  searchValueDocuments: string;

  @ViewChild('assignDLManagerDialogTemplate')
  assignDLManagerDialogTemplate: TemplateRef<any>;
  @ViewChild('mapDiv') mapDiv: ElementRef;
  @ViewChild('popup') popup: ElementRef;
  @ViewChild('lateralPopup') lateralPopup: ElementRef;

  constructor(
    private irFormService: IrFormService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private userService: UsersService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params: Params) => {
        this.project = { idProject: params.get('idProject') };
        this.route.queryParams.subscribe((queryParam: any) => {
          this.customer = { idCustomer: queryParam.idCustomer };
        });
      })
    );
    this.subscriptions.add(
      this.irFormService
        .getCustomerById(this.customer.idCustomer)
        .subscribe((customer: ICustomer) => {
          this.customer = customer;
          this.customerAddress =
            customer?.address?.address +
            ', ' +
            customer?.address?.cap +
            ' ' +
            customer?.address?.city +
            ', ' +
            customer?.address?.province;

          customer?.customerContact?.forEach((contact: ICustomerContact) => {
            if (
              contact.customerReferenceType.idCustomerReferenceType ===
              CONTACT_REFERENCE_TYPE.ProjectReferent
            ) {
              this.projectReferent = contact;
            }
            if (
              contact.customerReferenceType.idCustomerReferenceType ===
              CONTACT_REFERENCE_TYPE.AdministrativeReferent
            ) {
              this.administrativeReferent = contact;
            }
          });
          if (customer?.pathImage) {
            this.customerImagePath = `${apiRootUrl}/customer/public?path=${customer.pathImage}`;
          }
          this.getAllDocuments();
        })
    );
    this.subscriptions.add(
      this.irFormService
        .getProjectById(this.project.idProject)
        .subscribe((data: ProjectDTO) => {
          this.project = data;
          this.checkUser(this.project);
        })
    );
    this.getDLManagers();
    this.subscriptions.add(
      this.translate.get('PROJECT_DETAIL').subscribe((translations) => {
        this.translations = translations;
        this.generateBreadCrumb();
      })
    );
  }

  getDLManagers(): void {
    this.dlManagersLoader = true;
    this.subscriptions.add(
      this.irFormService.getLabManager().subscribe({
        next: (data: any[]) => {
          this.labManagers = data;
          this.dlManagersForTable = data.map((dlm: any, ind: number) => ({
            idUser: dlm.idUser,
            dlManager: `
              <strong>${dlm.name} ${dlm.surname}</strong>
              <br>
              ${dlm.email}
            `,
            address: `${dlm?.city} ${dlm?.cap}, ${dlm?.province}`,
            index: ind,
          }));
          this.dlManagersLoader = false;
          this.dlManagersTableColumns = [
            {
              name: 'dlManager',
              translatedName: this.translate.instant('COMMON.DLManager'),
              type: 'innerHTML',
              addClass: (row): string => {
                if (row.idUser === this.selectedDLManager?.idUser) {
                  return 'selected-dlmanager';
                } else {
                  return '';
                }
              },
            },
            {
              name: 'address',
              translatedName: this.translate.instant('COMMON.Residence'),
              addClass: (row): string => {
                if (row.idUser === this.selectedDLManager?.idUser) {
                  return 'selected-dlmanager';
                } else {
                  return '';
                }
              },
            },
            {
              name: 'buttons',
              disableSort: true,
              addClass: (row): string => {
                if (row.idUser === this.selectedDLManager?.idUser) {
                  return 'selected-dlmanager';
                } else {
                  return '';
                }
              },
            },
          ];
          this.dlManagersTableButtons = [
            {
              icon: 'arrow_green_3_icon',
              class: 'primary',
              clickFunction: (dlManager: any) => {
                return this.showDLManagerInfo(
                  this.labManagers[dlManager.index]
                );
              },
            },
          ];
        },
      })
    );
  }

  addClass(dlmanager: any): string {
    if (dlmanager.idUser === this.selectedDLManager?.idUser) {
      return 'background-color: red';
    } else {
      return '';
    }
  }

  generateBreadCrumb(): void {
    this.subscriptions.add(
      this.layoutService.generateBreadcrumb([
        {
          label: this.translate.instant('PAGES.projects'),
          path: '/projects',
          pageTitle: false,
        },
        {
          label: `${this.translate.instant('PAGES.project')} ${
            this.project.idProject
          }`,
          path: null,
          pageTitle: true,
        },
      ])
    );
  }

  uploadDocument(document: File): void {
    this.document = document;
  }

  checkUser(project): void {
    if (
      [RI_STATUS.RiDelivered, RI_STATUS.RiApproved].indexOf(
        project?.idProjectStatus
      ) > -1
    ) {
      this.approvedBy = project?.user
        ? project?.user?.name + ' ' + project?.user?.surname
        : null;
      this.approvedByPhone = project?.user ? project?.user?.telephone : '---';
      this.approvedByEmail = project?.user ? project?.user?.email : '---';
      this.idApprovedBy = project?.user?.idUser;
      this.drawnUpBy = project?.previousUser
        ? project?.previousUser?.name + ' ' + project?.previousUser?.surname
        : null;
      this.drawnUpByPhone = project?.previousUser
        ? project?.previousUser?.telephone
        : '---';
      this.drawnUpByEmail = project?.previousUser
        ? project?.previousUser?.email
        : '---';
      this.idDrawnUpBy = project?.previousUser?.idUser;
    } else {
      this.drawnUpBy = project?.user
        ? project?.user?.name + ' ' + project?.user?.surname
        : null;
      this.drawnUpByPhone = project?.user ? project?.user?.telephone : '---';
      this.drawnUpByEmail = project?.user ? project?.user?.email : '---';
      this.idDrawnUpBy = project?.user?.idUser;
      this.approvedBy = project?.previousUser
        ? project?.previousUser?.name + ' ' + project?.previousUser?.surname
        : null;
      this.approvedByPhone = project?.previousUser
        ? project?.previousUser?.telephone
        : '---';
      this.approvedByEmail = project?.previousUser
        ? project?.previousUser?.email
        : '---';
      this.idApprovedBy = project?.previousUser?.idUser;
    }
  }

  sendProject(): void {
    if (this.document) {
      this.subscriptions.add(
        this.irFormService
          .uploadIrToAssignDocument(this.document, this.project.idProject)
          .subscribe({
            next: (resp: DocumentDTO) => {
              this.assignManager();
            },
            error: (err: HttpErrorResponse) => {
              throw err;
            },
          })
      );
    } else {
      this.assignManager();
    }
  }

  assignManager() {
    this.subscriptions.add(
      this.irFormService
        .assignLabManager(
          this.project.idProject,
          this.selectedDLManagerForProject.idUser
        )
        .subscribe({
          next: () => {
            this.router.navigate(['/projects']);
          },
          error: (err: HttpErrorResponse) => {
            throw err;
          },
        })
    );
  }

  openAssignDLManagerDialog(): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig<any>();
    dialogConfig.width = '900px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      labManagers: this.labManagers,
      customer: this.customer,
    };
    const dialogRef: MatDialogRef<any> = this.dialog.open(
      this.assignDLManagerDialogTemplate,
      dialogConfig
    );
    setTimeout(() => {
      this.map = new Map({
        view: this.view,
        target: this.mapDiv.nativeElement,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          new VectorLayer({
            source: new VectorSource({
              features: [
                new Feature({
                  geometry: new Point(
                    fromLonLat(this.customer?.address?.position)
                  ),
                  features: [
                    new Feature({
                      properties: {
                        name: this.customer?.businessName,
                        address: this.customer.address.city,
                      },
                    }),
                  ],
                }),
              ],
            }),
            style: new Style({
              image: new Icon({
                anchor: [0.5, 1],
                src:
                  `data:image/svg+xml;utf8,` +
                  escape(
                    `
                      <svg width="40" height="40" version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <style type="text/css">.st0{fill:#e69138;}</style>
                        <path class="st0" d="M255,0c101.1,0,183.1,82,183.1,183.1c0,101.1-183.1,327-183.1,327S71.9,284.3,71.9,183.1C71.9,82,153.9,0,255,0z"/>
                      </svg>
                    `
                  ),
              }),
              text: new Text({
                text: '1',
                scale: 1.5,
                fill: new Fill({
                  color: '#ffffff',
                }),
                offsetY: -25,
              }),
            }),
          }),
        ],
        controls: [],
      });
      const popup: Overlay = new Overlay({
        element: this.popup.nativeElement,
        stopEvent: false,
      });
      const lateralPopup: Overlay = new Overlay({
        element: this.lateralPopup.nativeElement,
        stopEvent: false,
      });
      this.map.addOverlay(popup);
      this.map.addOverlay(lateralPopup);
      this.map.on('pointermove', (evt: MapBrowserEvent) => {
        const pixel = this.map.getEventPixel(evt.originalEvent);
        const hit = this.map.hasFeatureAtPixel(pixel);
        (this.map.getTarget() as HTMLElement).style.cursor = hit
          ? 'pointer'
          : '';
        const feature: FeatureLike = this.map.forEachFeatureAtPixel(
          evt.pixel,
          (feat) => {
            return feat;
          }
        );
        if (feature && feature?.getProperties()?.features?.length === 1) {
          popup.setPosition(evt.coordinate);
          this.showPopup = true;
          this.showLateralPopup = false;
          this.lateralPopup.nativeElement.innerHTML = ``;
          this.popup.nativeElement.innerHTML = `
            <strong style="font-size: 14px">
              ${
                feature?.getProperties()?.features[0]?.getProperties()
                  ?.properties?.name
              }
            </strong>
            <br>
            <span style="font-size: 12px">
              ${
                feature?.getProperties()?.features[0]?.getProperties()
                  ?.properties?.address
              }
            </span>
          `;
        } else if (feature && this.view.getZoom() > 17.5) {
          lateralPopup.setPosition(evt.coordinate);
          this.showLateralPopup = true;
          this.showPopup = false;
          let innerHTMLString = '';
          this.popup.nativeElement.innerHTML = ``;
          if (feature?.getProperties()?.features?.length <= 6) {
            feature?.getProperties()?.features?.forEach((feat: any) => {
              innerHTMLString =
                innerHTMLString +
                `
                <strong style="font-size: 14px">
                  ${feat?.getProperties()?.properties?.name}
                </strong>
                <br>
              `;
            });
          } else {
            feature?.getProperties()?.features?.forEach((feat: any) => {
              innerHTMLString =
                innerHTMLString +
                `
                <strong style="font-size: 11px; display: block">
                  ${feat?.getProperties()?.properties?.name}
                </strong>
              `;
            });
          }
          this.lateralPopup.nativeElement.innerHTML =
            innerHTMLString +
            `
            <br>
            <span style="font-size: 12px">
              ${
                feature?.getProperties()?.features[0]?.getProperties()
                  ?.properties?.address
              }
            </span>
          `;
        } else {
          this.showPopup = false;
          this.showLateralPopup = false;
          this.popup.nativeElement.innerHTML = ``;
          this.lateralPopup.nativeElement.innerHTML = ``;
        }
      });
    }, 100);
    dialogRef.afterClosed().subscribe({
      next: (result: boolean) => {
        if (result) {
          this.selectedDLManagerForProject = this.selectedDLManager;
        }
        this.selectedDLManager = null;
      },
      complete: () => {
        this.view.setCenter(fromLonLat([12.496366, 41.902782]));
        this.view.setZoom(5);
        this.dlManagerExpertiseInfo = null;
      },
    });
  }

  showDLManagerInfo(dlManager: any): void {
    if (this.dlmanagerSubscriptions) {
      this.dlmanagerSubscriptions.unsubscribe();
    }
    this.dlmanagerInfoLoader = true;
    let layersToRemove: any[] = [];
    this.map.getLayers().forEach((layer: TileLayer | VectorLayer) => {
      if (layer?.getClassName() !== 'ol-layer') {
        layersToRemove.push(layer);
      }
    });
    layersToRemove.forEach((layer: TileLayer | VectorLayer) => {
      this.map.removeLayer(layer);
    });
    // this.map.addLayer(
    //   new TileLayer({
    //     source: new OSM(),
    //   })
    // );
    this.selectedDLManager = dlManager;
    this.dlManagerExpertiseInfoLoading = true;
    this.dlmanagerSubscriptions = new Subscription();
    this.dlmanagerSubscriptions.add(
      this.userService.getDLManagerExpertiseInfo(dlManager.idUser).subscribe({
        next: (data: any) => {
          this.dlManagerExpertiseInfo = data;
          this.dlManagerExpertiseInfoLoading = false;
          // this.view.animate({
          //   center: fromLonLat([9.18854, 45.464664]),
          //   duration: 500,
          //   zoom: 10,
          // });
          const onlyCoordinates: any[] = [];
          onlyCoordinates.push(fromLonLat(this.customer.address.position));
          /** Add DLManager residence layer */
          if (dlManager?.residencePosition) {
            onlyCoordinates.push(fromLonLat(dlManager.residencePosition));
            this.map.addLayer(
              new VectorLayer({
                className: 'dlmanager-position',
                source: new VectorSource({
                  features: [
                    new Feature({
                      geometry: new Point(
                        fromLonLat(dlManager?.residencePosition)
                      ),
                      features: [
                        new Feature({
                          properties: {
                            name: `${dlManager?.name} ${dlManager?.surname}`,
                            address: dlManager?.city,
                          },
                        }),
                      ],
                    }),
                  ],
                }),
                style: new Style({
                  image: new Icon({
                    anchor: [0.5, 1],
                    src:
                      `data:image/svg+xml;utf8,` +
                      escape(
                        `
                          <svg width="40" height="40" version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                            <style type="text/css">.st0{fill:#f4d726;}</style>
                            <path class="st0" d="M255,0c101.1,0,183.1,82,183.1,183.1c0,101.1-183.1,327-183.1,327S71.9,284.3,71.9,183.1C71.9,82,153.9,0,255,0z"/>
                          </svg>
                        `
                      ),
                  }),
                  text: new Text({
                    text: '1',
                    scale: 1.5,
                    fill: new Fill({
                      color: '#ffffff',
                    }),
                    offsetY: -25,
                  }),
                }),
              })
            );
          }
          /** Add managed customers positions */
          if (this.dlManagerExpertiseInfo?.customer?.length) {
            this.dlManagerExpertiseInfo.customer.forEach((item: any) => {
              onlyCoordinates.push(fromLonLat(item.address.position));
            });
            const featuresData: any = new Array(
              this.dlManagerExpertiseInfo.customer
            );
            this.dlManagerExpertiseInfo.customer.forEach(
              (item: any, index: number) => {
                featuresData[index] = new Feature({
                  geometry: new Point(fromLonLat(item.address.position)),
                  properties: {
                    name: item.businessName,
                    address: item.address.city,
                  },
                });
              }
            );
            const styleCache: any = {};
            this.map.addLayer(
              new VectorLayer({
                className: 'customers-position',
                source: new Cluster({
                  distance: 40,
                  source: new VectorSource({
                    features: featuresData,
                  }),
                }),
                style: (feat: any) => {
                  const size = feat.get('features').length;
                  if (size > 1) {
                    let style = styleCache[size];
                    if (!style) {
                      style = new Style({
                        image: new Icon({
                          anchor: [0.5, 0.5],
                          src:
                            `data:image/svg+xml;utf8,` +
                            escape(
                              `
                                <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve">
                                  <circle fill="#006B61" cx="256" cy="256" r="256"/>
                                </svg>
                              `
                            ),
                        }),
                        text: new Text({
                          text: size.toString(),
                          // text: item.companyCount.toString(),
                          scale: 1.5,
                          fill: new Fill({
                            color: '#ffffff',
                          }),
                          offsetY: 1,
                        }),
                      });
                      styleCache[size] = style;
                    }
                    return style;
                  } else {
                    const originalFeature = feat.get('features')[0];
                    return this.clusterMemberStyle(originalFeature);
                  }
                },
              })
            );
          }
          const feature: Feature<Geometry> = new Feature({
            geometry: new Polygon([onlyCoordinates]),
            name: 'Test',
          });
          const poly = feature.getGeometry();
          this.view.fit(poly.getExtent(), {
            padding: [60, 30, 30, 30],
            maxZoom: 10,
          });
          // else {
          //   this.view.setCenter(fromLonLat([12.496366, 41.902782]));
          //   this.view.setZoom(5);
          // }
          this.dlmanagerInfoLoader = false;
        },
      })
    );
  }

  clusterMemberStyle(clusterMember: any): Style {
    return new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src:
          `data:image/svg+xml;utf8,` +
          escape(
            `
              <svg width="40" height="40" version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                <style type="text/css">.st0{fill:#61BAAE;}</style>
                <path class="st0" d="M255,0c101.1,0,183.1,82,183.1,183.1c0,101.1-183.1,327-183.1,327S71.9,284.3,71.9,183.1C71.9,82,153.9,0,255,0z"/>
              </svg>
            `
          ),
      }),
      text: new Text({
        text: '1',
        // text: item.companyCount.toString(),
        scale: 1.5,
        fill: new Fill({
          color: '#ffffff',
        }),
        offsetY: -25,
      }),
    });
  }

  getCoords(): any {
    const arr = [];
    this.coords.forEach((item: any) => {
      arr.push(new Feature(new Point(item)));
    });
    return arr;
  }

  getAllDocuments(): void {
    this.loaderDocuments = true;
    this.subscriptions.add(
      this.irFormService
        .getCustomerDocuments(this.loaderDocuments, this.customer.idCustomer)
        .subscribe((data: DocumentDTO[]) => {
          this.documentsDataSource = data.map(
            fromDocumentDtoToDocumentForTable
          );
          this.documentsTableColumns = [
            {
              name: 'name',
              translatedName: this.translate.instant('CUSTOMER.Name'),
            },
            {
              name: 'type',
              translatedName: this.translate.instant('CUSTOMER.Type'),
            },
            {
              name: 'dateInsert',
              translatedName: this.translate.instant('CUSTOMER.DateInsert'),
              type: 'date',
            },
            {
              name: 'userName',
              translatedName: this.translate.instant('CUSTOMER.User'),
            },
            {
              name: 'buttons',
              disableSort: true,
            },
          ];
          this.documentsTableColumnsButtons = [
            {
              icon: 'download_icon',
              class: 'primary',
              clickFunction: (item: any) =>
                this.visualizeDocument(item.idDocument),
            },
          ];
          this.loaderDocuments = false;
        })
    );
  }

  visualizeDocument(idDocument: number | string): void {
    this.irFormService.downloadDocument(idDocument).subscribe();
  }

  searchValue(value: string): void {
    this.searchValueDocuments = value;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.dlmanagerSubscriptions) {
      this.dlmanagerSubscriptions.unsubscribe();
    }
  }
}
