import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {PR_STATUS_PS_VERSION, PS_VERSION} from 'src/app/config';
import {IProjectCustomer, ProjectDTO} from 'src/app/models/project';
import {CrmService} from 'src/app/services/crm/crm.service';
import {NguCarousel, NguCarouselConfig} from "@ngu/carousel";
import {slider} from "./slide-animation";
import {IDeal} from "../../models/contact";

@Component({
  selector: 'app-pmi-carousel',
  templateUrl: './pmi-carousel.component.html',
  styleUrls: ['./pmi-carousel.component.scss'],
  animations: [slider]
})
export class PmiCarouselComponent implements OnInit, OnDestroy, AfterContentChecked {

  @Input() public followedPMIs: IProjectCustomer[];
  followedPMIsBM: IProjectCustomer[] = [];

  private subscriptions: Subscription = new Subscription();
  psVersion: any = PS_VERSION;

  /* Followed PMI cards */
  followedPMIDataSource: MatTableDataSource<IProjectCustomer> = new MatTableDataSource<IProjectCustomer>();
  followedPMIsLoader: boolean;

  // customerInfoData: any;

  @ViewChild("myCarousel") myCarousel: NguCarousel<IProjectCustomer>;
  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
    slide: 3,
    speed: 250,
    point: {
      visible: false
    },
    touch: true,
    loop: true,
    load: 2,
    easing: 'cubic-bezier(0, 0, 0.2, 1)',
    animation: "lazy"

  };

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private crmService: CrmService,
    private route: ActivatedRoute
  ) {
    this.followedPMIDataSource.filterPredicate = (data: IProjectCustomer, filter: string) => {
      return data.code.toLocaleLowerCase().includes(filter) ||
      data.customer.businessName.toLocaleLowerCase().includes(filter) ||
      data.productName.toLocaleLowerCase().includes(filter) ||
      PR_STATUS_PS_VERSION[`PR_STATUS_${data.idProjectStatus}`].toLocaleLowerCase().includes(filter);
    };
  }

  ngOnInit(): void {
    for (let i = 0; i < this.followedPMIs.length; i++) {
      let hasBm = false;
      this.subscriptions.add(
        this.crmService.checkCrmBusinessMatchingExist(this.followedPMIs[i].idProject).subscribe((result: any) => {
          hasBm = result;
          const data = {...this.followedPMIs[i], hasBm};
          this.followedPMIsBM[i] = data;
          if(!this.followedPMIDataSource.data){
            this.followedPMIDataSource.data = [];
          }
          this.followedPMIDataSource.data.push(data);
        })

      );
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  viewContactCrmDetail(customer: any): void {
    this.router.navigate([ `/crm-detail`, customer?.idProject, customer?.idCustomer ]);
  }

  viewContactCrmDetailPMI(project: any): void {
    this.router.navigate([ `/crm-detail`, project?.idProject, project?.idCustomer ]);
  }

  sendCurrentPS(project: any): void {
    // this.setCustomerInfoData(project);
    this.router.navigate([ `/ps-form`, project?.idProject, project?.idCustomer ]);
  }

  sendToDeals(project: any): void {
    // this.setCustomerInfoData(project);
    this.router.navigate([ `/deals`, project?.idProject, project?.idCustomer ]);
  }

  cloneProject(project: any): void {
    this.router.navigate([ `/clone-project`, project?.idProject ]);
  }

  applyFilter(event): void {
    const filter = event.target.value;
    this.followedPMIDataSource.filter = filter.trim().toLowerCase();
    this.followedPMIsBM = [];
    this.followedPMIsBM = [...this.followedPMIDataSource.filteredData];
    this.cdRef.detectChanges();
  }

  goToCrmBm(project: any): void {
    this.router.navigate(['/crm-business-matching', project?.idProject]);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
