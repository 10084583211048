import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { config, Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import {
  IProjectForTabl,
  IProjectForTable,
  IProjectInfo,
} from 'src/app/models/project';
import {
  Roles,
  RI_TAB,
  RI_STATUS,
  RI_STATUS_DESCRIPTION,
  RI_STATUS_STRING,
  PS_TYPE,
  PR_STATUS_PS_VERSION,
} from 'src/app/config';
import { TranslateService } from '@ngx-translate/core';
import { ITableButton, ITableColumn, Select } from 'src/app/models/utility';
import * as moment from 'moment';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Note } from 'src/app/models/note';
import {
  fromProjectDtoToProjectTable,
  mappingServerDataToProjectsForTable,
} from 'src/app/utility/elaborations';
import { PsFormService } from 'src/app/services/ps-form/ps-form.service';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { PublishProjectDialogComponent } from 'src/app/components/publish-project-dialog/publish-project-dialog.component';
import { Storage } from 'src/app/utility/storage';

@Component({
  selector: 'app-ir-in-progress',
  templateUrl: './ir-in-progress.component.html',
  styleUrls: ['./ir-in-progress.component.scss'],
})
export class IrInProgressComponent implements OnInit, OnDestroy {
  // Various
  subscriptions: Subscription = new Subscription();
  idLoggedUser: number;
  translations: any;
  statusSelect: Select[] = [
    { label: RI_STATUS_STRING.RI_STATUS_1, value: RI_STATUS.RiWorking },
    { label: RI_STATUS_STRING.RI_STATUS_2, value: RI_STATUS.RiDelivered },
    { label: RI_STATUS_STRING.RI_STATUS_3, value: RI_STATUS.RiApproved },
    { label: RI_STATUS_STRING.RI_STATUS_4, value: RI_STATUS.NoContract },
    { label: 'PsWorking', value: RI_STATUS.IspWorking },
    { label: 'PsToApprove', value: RI_STATUS.IspDelivered },
    { label: RI_STATUS_STRING.RI_STATUS_8, value: RI_STATUS.Closed },
  ];
  workingVersionSelect: Select[] = [
    { label: PR_STATUS_PS_VERSION.PR_STATUS_6, value: RI_STATUS.IspWorking },
    { label: PR_STATUS_PS_VERSION.PR_STATUS_9, value: RI_STATUS.IspWorking2 },
    { label: PR_STATUS_PS_VERSION.PR_STATUS_10, value: RI_STATUS.IspWorking3 },
    { label: PR_STATUS_PS_VERSION.PR_STATUS_11, value: RI_STATUS.IspWorking4 },
    { label: PR_STATUS_PS_VERSION.PR_STATUS_12, value: RI_STATUS.IspWorking5 },
    { label: PR_STATUS_PS_VERSION.PR_STATUS_13, value: RI_STATUS.IspWorking6 },
  ];
  toApproveVersionSelect: Select[] = [
    { label: PR_STATUS_PS_VERSION.PR_STATUS_6, value: RI_STATUS.IspDelivered },
    { label: PR_STATUS_PS_VERSION.PR_STATUS_9, value: RI_STATUS.IspDelivered2 },
    {
      label: PR_STATUS_PS_VERSION.PR_STATUS_10,
      value: RI_STATUS.IspDelivered3,
    },
    {
      label: PR_STATUS_PS_VERSION.PR_STATUS_11,
      value: RI_STATUS.IspDelivered4,
    },
    {
      label: PR_STATUS_PS_VERSION.PR_STATUS_12,
      value: RI_STATUS.IspDelivered5,
    },
    {
      label: PR_STATUS_PS_VERSION.PR_STATUS_13,
      value: RI_STATUS.IspDelivered6,
    },
  ];
  statusSelected: RI_STATUS = 1;
  workingVersionSelected: number = RI_STATUS.IspWorking;
  toApproveVersionSelected: number = RI_STATUS.IspDelivered;
  showWorkingVersionSelect: boolean = false;
  showToApproveVersionSelect: boolean = false;
  // User role
  userRole: string;
  // Role conditions
  isAdmin: boolean = false;
  isAgent: boolean = false;
  isAgentSupervisor: boolean = false;
  isLabManager: boolean = false;
  isLabManagerInternal: boolean = false;
  isLabManagerSupervisor: boolean = false;
  isSupervisor: boolean = false;
  isDirection: boolean = false;
  isDemonstrator: boolean = false;
  role: any = Roles;
  // Tables columns
  masterAndClonedProjectsTableColumns: ITableColumn[];
  rejectedProjectsTableColumns: ITableColumn[];
  inProgressProjectsTableColumns: ITableColumn[];
  sendToApproveProjectsTableColumns: ITableColumn[];
  toApproveProjectsTableColumns: ITableColumn[];
  approvedProjectsTableColumns: ITableColumn[];
  bmProjectsTableColumns: ITableColumn[];
  projectsToAssignTableColumns: ITableColumn[];
  projectsAssignedTableColumns: ITableColumn[];
  projectsTableColumns: ITableColumn[];
  // Tables columns buttons
  masterProjectTableColumnsButtons: ITableButton[];
  clonedProjectsTableColumnsButtons: ITableButton[];
  rejectedProjectsTableColumnsButtons: ITableButton[];
  inProgressProjectsTableColumnsButtons: ITableButton[];
  sendToApproveProjectsTableColumnsButtons: ITableButton[];
  toApproveProjectsTableColumnsButtons: ITableButton[];
  approvedProjectsTableColumnsButtons: ITableButton[];
  bmProjectsTableColumnsButtons: ITableButton[];
  projectsToAssignTableColumnsButtons: ITableButton[];
  projectsAssignedTableColumnsButtons: ITableButton[];
  projectsTableColumnsButtons: ITableButton[];
  // Projects for tables
  masterProject: IProjectForTable[];
  clonedProjects: IProjectForTable[];
  rejectedProjects: IProjectForTable[];
  inProgressProjects: IProjectForTable[];
  sendToApproveProjects: IProjectForTable[];
  toApproveProjects: IProjectForTable[];
  approvedProjects: IProjectForTable[];
  bmProjects: IProjectForTable[];
  projectsToAssign: IProjectForTable[];
  projectsAssigned: IProjectForTable[];
  projects: IProjectForTable[];
  toApproveInternalPs: IProjectForTable[];
  // Loaders
  masterAndClonedProjectLoader: boolean;
  rejectedProjectsLoader: boolean;
  inProgressProjectsLoader: boolean;
  sendToApproveProjectsLoader: boolean;
  toApproveProjectsLoader: boolean;
  approvedProjectsLoader: boolean;
  bmProjectsLoader: boolean;
  projectsToAssignLoader: boolean;
  projectsAssignedLoader: boolean;
  projectsLoader: boolean;
  // Tables search value
  searchValueMasterAndClonedProjects: string;
  searchValueRejectedProjects: string;
  searchValueInProgressProjects: string;
  searchValueSendToApproveProjects: string;
  searchValueToApproveProjects: string;
  searchValueApprovedProjects: string;
  searchValueBmProjects: string;
  searchValueProjectsToAssign: string;
  searchValueProjectsAssigned: string;
  searchValueProjects: string;
  // Revision notes dialog
  @ViewChild('revisionNotesDialog') revisionNotesDialog: TemplateRef<any>;
  riTab: any = RI_TAB;
  locale: string = navigator.language;
  projectSections: string[] = [
    'Customer',
    'Contract',
    'CustomerInformation',
    'Products',
    'Markets',
    'Distribution',
    'ReferenceCompetitors',
    'LeadGeneration',
    'SWOT',
    'MarketsStrategyAnalysis',
    'Notes',
    'Strategy',
  ];

  selectedProject: any = [];
  psSupervision: boolean = false;

  constructor(
    private tranlsate: TranslateService,
    public route: ActivatedRoute,
    private irFormService: IrFormService,
    private psFormService: PsFormService,
    private layoutService: LayoutService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (window.location.href.includes('/ps-supervision')) {
      this.psSupervision = true;
    }
    this.idLoggedUser = JSON.parse(localStorage.getItem('session_user')).idUser;
    this.subscriptions.add(
      this.tranlsate.get('IR_IN_PROGRESS').subscribe((data: any) => {
        this.translations = data;
        // Check role
        this.userRole = JSON.parse(
          localStorage.getItem('session_user')
        ).activeRole;
        if (this.userRole === Roles.Agent) {
          this.isAgent = true;
        }
        if (this.userRole === Roles.AgentSupervisor) {
          this.isAgentSupervisor = true;
        }
        if (this.userRole === Roles.LabManager) {
          this.isLabManager = true;
        }
        if (this.userRole === Roles.LabManagerInternal) {
          this.isLabManagerInternal = true;
        }
        if (this.userRole === Roles.LabManagerSupervisor) {
          this.isLabManagerSupervisor = true;
        }
        if (this.userRole === Roles.Direction) {
          this.isDirection = true;
        }
        if (this.userRole === Roles.Admin) {
          this.isAdmin = true;
        }
        if (Storage.get('session_user').demonstrator) {
          this.isDemonstrator = true;
        }
        // Fetch data
        this.getMasterAndClonedProjects();
        this.getRejectedProjects();
        this.getInProgressProjects();
        this.getSendToApproveProjects();
        this.getToApproveProjects();
        this.getApprovedProjects();
        this.getBMProjects();
        if (this.isDirection) {
          this.getProjectsToAssign();
          this.getProjectsAssigned();
          this.getProjects();
          this.initializeProjectsToAssignTableColumns();
          this.initializeProjectsAssignedTableColumns();
          this.initializeProjectsTableColumns();
          this.initializeProjectsTableButtons();
        }
        // Initialize tables
        this.initializeMasterAndClonedProjectsTableColumns();
        this.initializeMasterProjectTableButtons();
        this.initializeClonedProjectsTableButtons();
        this.initializeRejectedProjectsTableColumns();
        this.initializeRejectedProjectsTableButtons();
        this.initializeInProgressProjectsTableColumns();
        this.initializeInProgressProjectsTableButtons();
        this.initializeSendToApproveProjectsTableColumns();
        this.initializeSendToApproveProjectsTableButtons();
        this.initializeToApproveProjectsTableColumns();
        this.initializeToApproveProjectsTableButtons();
        this.initializeApprovedProjectsTableColumns();
        this.initializeApprovedProjectsTableButtons();
        this.initializeBMAssTableColumns();
        this.initializeBMAssTableButtons();

        this.generateBreadCrumb();
      })
    );
  }

  generateBreadCrumb(): void {
    if (this.isAgent || this.isAgentSupervisor) {
      this.subscriptions.add(
        this.layoutService.generateBreadcrumb([
          {
            label: this.tranlsate.instant('PAGES.assessment'),
            path: null,
            pageTitle: true,
          },
        ])
      );
    }
    if (
      this.isLabManager ||
      this.isLabManagerInternal ||
      this.isLabManagerSupervisor
    ) {
      this.subscriptions.add(
        this.layoutService.generateBreadcrumb([
          {
            label:
              window?.location?.pathname === '/ps-supervision'
                ? this.tranlsate.instant('PAGES.ps-supervision')
                : this.tranlsate.instant('PAGES.ps'),
            path: null,
            pageTitle: true,
          },
        ])
      );
    }
    if (this.isDirection) {
      this.subscriptions.add(
        this.layoutService.generateBreadcrumb([
          {
            label: this.tranlsate.instant('PAGES.projects'),
            path: null,
            pageTitle: true,
          },
        ])
      );
    }
  }

  initializeMasterAndClonedProjectsTableColumns(): void {
    this.masterAndClonedProjectsTableColumns = [
      {
        name: 'lastWorkedDate',
        translatedName: this.translations.LastWorking,
        type: 'date',
      },
      {
        name: 'businessName',
        translatedName: this.translations.BusinessName,
      },
      {
        name: 'telephone',
        translatedName: this.translations.Telephone,
      },
      {
        name: 'email',
        translatedName: this.translations.Email,
      },
      {
        name: 'address',
        translatedName: this.translations.Address,
      },
      // {
      //   name: 'version',
      //   translatedName: this.translations.Version,
      // },
    ];

    // if (this.isAgentSupervisor) {
    //   this.masterAndClonedProjectsTableColumns.push({
    //     name: 'agent',
    //     translatedName: this.translations.Agent,
    //   });
    // }
    // if (this.isLabManagerSupervisor) {
    //   this.masterAndClonedProjectsTableColumns.push({
    //     name: 'dlmanager',
    //     translatedName: this.translations.DLManager,
    //   });
    // }
    this.masterAndClonedProjectsTableColumns.push({
      name: 'buttons',
      disableSort: true,
      width: 10,
    });
  }

  initializeRejectedProjectsTableColumns(): void {
    this.rejectedProjectsTableColumns = [
      {
        name: 'lastWorkedDate',
        translatedName: this.translations.LastWorking,
        type: 'date',
      },
      {
        name: 'businessName',
        translatedName: this.translations.BusinessName,
      },
      {
        name: 'telephone',
        translatedName: this.translations.Telephone,
      },
      {
        name: 'email',
        translatedName: this.translations.Email,
      },
      {
        name: 'address',
        translatedName: this.translations.Address,
      },
    ];
    if (
      this.isLabManager ||
      this.isLabManagerInternal ||
      this.isLabManagerSupervisor
    ) {
      this.rejectedProjectsTableColumns.push({
        name: 'version',
        translatedName: this.translations.Version,
      });
    }
    if (this.isAgentSupervisor) {
      this.rejectedProjectsTableColumns.push({
        name: 'agent',
        translatedName: this.translations.Agent,
      });
    }
    if (this.isLabManagerSupervisor) {
      this.rejectedProjectsTableColumns.push({
        name: 'dlmanager',
        translatedName: this.translations.DLManager,
      });
    }
    this.rejectedProjectsTableColumns.push({
      name: 'buttons',
      disableSort: true,
      width: 10,
    });
  }

  initializeRejectedProjectsTableButtons(): void {
    this.rejectedProjectsTableColumnsButtons = [
      {
        icon: 'document_icon',
        class: 'primary',
        disabled: (item: IProjectInfo) =>
          !item.projectNoteList?.some((note: Note) => !note.checked),
        conditions: () => this.isAgent || this.isLabManager,
        clickFunction: (item: IProjectInfo) => {
          this.showRevisionNotes(item);
        },
      },
      {
        icon: 'edit_icon',
        class: 'primary',
        tooltip: (project: IProjectForTable) => {
          if (
            project.idProjectStatus === RI_STATUS.RiWorking ||
            project.idProjectStatus === RI_STATUS.IspWorking
          ) {
            return this.translations.Edit;
          } else if (project.idProjectStatus >= RI_STATUS.IspWorking2) {
            return this.translations.DeliveryPS;
          }
        },
        disabled: () => false,
        conditions: (project: IProjectInfo) =>
          this.isAgent || this.isLabManager,
        clickFunction: (item: IProjectInfo) => {
          if (this.isAgent) {
            this.router.navigate([`/assessment/${item.idProject}`]);
          }
          if (this.isLabManager) {
            if (item.idProjectStatus >= RI_STATUS.IspWorking2) {
              this.router.navigate([`/ps-form/${item.idProject}`]);
            } else if (item.idProjectStatus === RI_STATUS.IspWorking) {
              this.router.navigate([`/ps/${item.idProject}`]);
            }
          }
        },
      },
      {
        icon: 'more_option_2_icon',
        class: 'primary',
        subMenu: {
          buttons: [
            {
              text: this.tranlsate.instant('COMMON.Visualize'),
              conditions: (project: IProjectForTable) => {
                return project.idProjectStatus >= RI_STATUS.IspWorking2;
              },
              subMenu: {
                buttons: [
                  {
                    text: this.tranlsate.instant('PS_VERSION.PS1'),
                    conditions: (project: IProjectForTable) => {
                      return project.idProjectStatus >= RI_STATUS.IspWorking2;
                    },
                    clickFunction: (project: IProjectForTable) => {
                      this.router.navigate([`/ps-readonly`, project.idProject]);
                    },
                  },
                  {
                    text: this.tranlsate.instant('PS_VERSION.PS2'),
                    conditions: (project: IProjectForTable) => {
                      return project.idProjectStatus >= RI_STATUS.IspWorking3;
                    },
                    clickFunction: (project: IProjectForTable) => {
                      this.router.navigate([
                        `/ps-form-readonly`,
                        project.idProject,
                        project.customer.idCustomer,
                        RI_STATUS.IspWorking2,
                      ]);
                    },
                  },
                  {
                    text: this.tranlsate.instant('PS_VERSION.PS3'),
                    conditions: (project: IProjectForTable) => {
                      return project.idProjectStatus >= RI_STATUS.IspWorking4;
                    },
                    clickFunction: (project: IProjectForTable) => {
                      this.router.navigate([
                        `/ps-form-readonly`,
                        project.idProject,
                        project.customer.idCustomer,
                        RI_STATUS.IspWorking3,
                      ]);
                    },
                  },
                  {
                    text: this.tranlsate.instant('PS_VERSION.PS4'),
                    conditions: (project: IProjectForTable) => {
                      return project.idProjectStatus >= RI_STATUS.IspWorking5;
                    },
                    clickFunction: (project: IProjectForTable) => {
                      this.router.navigate([
                        `/ps-form-readonly`,
                        project.idProject,
                        project.customer.idCustomer,
                        RI_STATUS.IspWorking4,
                      ]);
                    },
                  },
                  {
                    text: this.tranlsate.instant('PS_VERSION.PS5'),
                    conditions: (project: IProjectForTable) => {
                      return project.idProjectStatus >= RI_STATUS.IspWorking6;
                    },
                    clickFunction: (project: IProjectForTable) => {
                      this.router.navigate([
                        `/ps-form-readonly`,
                        project.idProject,
                        project.customer.idCustomer,
                        RI_STATUS.IspWorking5,
                      ]);
                    },
                  },
                  {
                    text: this.tranlsate.instant('PS_VERSION.PS6'),
                    conditions: (project: IProjectForTable) => {
                      return project.idProjectStatus >= RI_STATUS.IspDelivered6;
                    },
                    clickFunction: (project: IProjectForTable) => {
                      this.router.navigate([
                        `/ps-form-readonly`,
                        project.idProject,
                        project.customer.idCustomer,
                        RI_STATUS.IspWorking6,
                      ]);
                    },
                  },
                ],
              },
            },
            // {
            //   text: this.translations.VisualizeCurrentPS,
            //   conditions: (project: IProjectInfo) => {
            //     return (
            //       project.idProjectStatus === RI_STATUS.IspDelivered ||
            //       project.idProjectStatus >= RI_STATUS.IspDelivered2
            //     );
            //   },
            //   clickFunction: (project: IProjectInfo) => {
            //     if (project.idProjectStatus === RI_STATUS.IspDelivered) {
            //       this.router.navigate([`/ps-readonly/${project.idProject}`]);
            //     }
            //     if (project.idProjectStatus > RI_STATUS.IspDelivered) {
            //       this.router.navigate([
            //         `/ps-form-readonly/${project.idProject}/${project.customer.idCustomer}`,
            //       ]);
            //     }
            //   },
            // },
            // {
            //   text: this.translations.VisualizePS1,
            //   conditions: (project: IProjectForTable) => {
            //     return project.idProjectStatus >= RI_STATUS.IspWorking2;
            //   },
            //   clickFunction: (project: IProjectForTable) => {
            //     this.router.navigate([`/ps-readonly`, project.idProject]);
            //   },
            // },
            {
              text: this.translations.GoToCRM,
              conditions: (project: IProjectForTable): boolean => {
                return project.idProjectStatus >= RI_STATUS.IspWorking2;
              },
              clickFunction: (project: IProjectForTable) => {
                this.router.navigate([
                  `/crm/customer/${project.customer.idCustomer}/project/${project.idProject}`,
                ]);
              },
            },
            {
              text: this.translations.PublishProject,
              conditions: (project: IProjectForTable): boolean => {
                return (
                  project?.idManager === this.idLoggedUser &&
                  project?.idProjectStatus >= RI_STATUS.IspWorking2
                );
              },
              clickFunction: (project: IProjectForTable) => {
                this.publishProjectDialog(project);
              },
            },
          ],
        },
      },
      // {
      //   icon: 'arrow_green_3_icon',
      //   class: 'primary',
      //   conditions: (project: IProjectInfo) =>
      //     this.isAgentSupervisor ||
      //     this.isLabManagerSupervisor ||
      //     project.idProjectStatus >= RI_STATUS.IspWorking2,
      //   clickFunction: (project: IProjectInfo) => {
      //     if (this.isAgentSupervisor) {
      //       this.router.navigate([`/assessment-readonly/${project.idProject}`]);
      //     }
      //     if (this.isLabManager || this.isLabManagerSupervisor) {
      //       this.router.navigate([`/ps-readonly/${project.idProject}`]);
      //     }
      //   },
      // },
    ];

    // TODO: Chiedere a Maurizio publish project va aggiunto anche ai progetti rejected
  }

  initializeInProgressProjectsTableColumns(): void {
    this.inProgressProjectsTableColumns = [
      {
        name: 'lastWorkedDate',
        translatedName: this.translations.LastWorking,
        type: 'date',
      },
      {
        name: 'businessName',
        translatedName: this.translations.BusinessName,
      },
      {
        name: 'telephone',
        translatedName: this.translations.Telephone,
        sortType: 'number',
      },
      {
        name: 'email',
        translatedName: this.translations.Email,
      },
      {
        name: 'address',
        translatedName: this.translations.Address,
      },
    ];
    if (this.isLabManager || this.isLabManagerInternal) {
      this.inProgressProjectsTableColumns.push({
        name: 'version',
        translatedName: this.translations.Version,
      });
    }
    this.inProgressProjectsTableColumns.push({
      name: 'buttons',
      disableSort: true,
      width: 10,
    });
  }

  initializeInProgressProjectsTableButtons(): void {
    if (this.isAgent || this.isLabManager || this.isLabManagerInternal) {
      this.inProgressProjectsTableColumnsButtons = [
        {
          icon: 'document_icon',
          class: 'primary',
          disabled: (item: IProjectInfo) =>
            !item.projectNoteList?.some((note: Note) => !note.checked),
          clickFunction: (item: IProjectInfo) => {
            this.showRevisionNotes(item);
          },
        },
        {
          icon: 'edit_icon',
          class: 'primary',
          tooltip: (project: IProjectForTable) => {
            if (
              project.idProjectStatus === RI_STATUS.RiWorking ||
              project.idProjectStatus === RI_STATUS.IspWorking
            ) {
              return this.translations.Edit;
            } else if (project.idProjectStatus >= RI_STATUS.IspWorking2) {
              return this.translations.DeliveryPS;
            }
          },
          disabled: () => false,
          clickFunction: (item: IProjectInfo) => {
            if (this.isAgent) {
              this.router.navigate([`/assessment/${item.idProject}`]);
            }
            if (this.isLabManager || this.isLabManagerInternal) {
              if (item.idProjectStatus >= RI_STATUS.IspWorking2) {
                this.router.navigate([`/ps-form/${item.idProject}`]);
              } else if (item.idProjectStatus === RI_STATUS.IspWorking) {
                this.router.navigate([`/ps/${item.idProject}`]);
              }
            }
          },
        },
        {
          icon: 'more_option_2_icon',
          class: 'primary',
          subMenu: {
            buttons: [
              {
                text: this.tranlsate.instant('COMMON.Visualize'),
                conditions: (project: IProjectForTable) => {
                  return project.idProjectStatus >= RI_STATUS.IspWorking2;
                },
                subMenu: {
                  buttons: [
                    {
                      text: this.tranlsate.instant('PS_VERSION.PS1'),
                      conditions: (project: IProjectForTable) => {
                        return project.idProjectStatus >= RI_STATUS.IspWorking2;
                      },
                      clickFunction: (project: IProjectForTable) => {
                        this.router.navigate([
                          `/ps-readonly`,
                          project.idProject,
                        ]);
                      },
                    },
                    {
                      text: this.tranlsate.instant('PS_VERSION.PS2'),
                      conditions: (project: IProjectForTable) => {
                        return project.idProjectStatus >= RI_STATUS.IspWorking3;
                      },
                      clickFunction: (project: IProjectForTable) => {
                        this.router.navigate([
                          `/ps-form-readonly`,
                          project.idProject,
                          project.customer.idCustomer,
                          RI_STATUS.IspWorking2,
                        ]);
                      },
                    },
                    {
                      text: this.tranlsate.instant('PS_VERSION.PS3'),
                      conditions: (project: IProjectForTable) => {
                        return project.idProjectStatus >= RI_STATUS.IspWorking4;
                      },
                      clickFunction: (project: IProjectForTable) => {
                        this.router.navigate([
                          `/ps-form-readonly`,
                          project.idProject,
                          project.customer.idCustomer,
                          RI_STATUS.IspWorking3,
                        ]);
                      },
                    },
                    {
                      text: this.tranlsate.instant('PS_VERSION.PS4'),
                      conditions: (project: IProjectForTable) => {
                        return project.idProjectStatus >= RI_STATUS.IspWorking5;
                      },
                      clickFunction: (project: IProjectForTable) => {
                        this.router.navigate([
                          `/ps-form-readonly`,
                          project.idProject,
                          project.customer.idCustomer,
                          RI_STATUS.IspWorking4,
                        ]);
                      },
                    },
                    {
                      text: this.tranlsate.instant('PS_VERSION.PS5'),
                      conditions: (project: IProjectForTable) => {
                        return project.idProjectStatus >= RI_STATUS.IspWorking6;
                      },
                      clickFunction: (project: IProjectForTable) => {
                        this.router.navigate([
                          `/ps-form-readonly`,
                          project.idProject,
                          project.customer.idCustomer,
                          RI_STATUS.IspWorking5,
                        ]);
                      },
                    },
                    {
                      text: this.tranlsate.instant('PS_VERSION.PS6'),
                      conditions: (project: IProjectForTable) => {
                        return (
                          project.idProjectStatus >= RI_STATUS.IspDelivered6
                        );
                      },
                      clickFunction: (project: IProjectForTable) => {
                        this.router.navigate([
                          `/ps-form-readonly`,
                          project.idProject,
                          project.customer.idCustomer,
                          RI_STATUS.IspWorking6,
                        ]);
                      },
                    },
                  ],
                },
              },
              {
                text: this.translations.GoToCRM,
                conditions: (project: IProjectForTable): boolean => {
                  return project.idProjectStatus >= RI_STATUS.IspWorking2;
                },
                clickFunction: (project: IProjectForTable) => {
                  this.router.navigate([
                    `/crm/customer/${project.customer.idCustomer}/project/${project.idProject}`,
                  ]);
                },
              },
              {
                text: this.translations.PublishProject,
                conditions: (project: IProjectForTable): boolean => {
                  return (
                    project?.idManager === this.idLoggedUser &&
                    project?.idProjectStatus >= RI_STATUS.IspWorking2
                  );
                },
                clickFunction: (project: IProjectForTable) => {
                  this.publishProjectDialog(project);
                },
              },
            ],
          },
        },
      ];
    }
  }

  initializeMasterProjectTableButtons(): void {
    if (this.isLabManager && this.isDemonstrator) {
      this.masterProjectTableColumnsButtons = [
        {
          icon: 'edit_icon',
          class: 'primary',
          tooltip: this.translations.Edit,
          clickFunction: (item: IProjectInfo) => {
            if (item.idProjectStatus >= RI_STATUS.IspWorking2) {
              this.router.navigate([`/ps-form/${item.idProject}`]);
            } else if (item.idProjectStatus === RI_STATUS.IspWorking) {
              this.router.navigate([`/ps/${item.idProject}`]);
            }
          },
        },
        {
          icon: 'more_option_2_icon',
          class: 'primary',
          subMenu: {
            buttons: [
              {
                text: this.translations.CloneProject,
                clickFunction: () => {
                  this.cloneMasterProject();
                },
              },
            ],
          },
        },
      ];
    }
  }

  initializeClonedProjectsTableButtons(): void {
    if (this.isLabManager && this.isDemonstrator) {
      this.clonedProjectsTableColumnsButtons = [
        {
          icon: 'edit_icon',
          class: 'primary',
          tooltip: this.translations.Edit,
          clickFunction: (item: IProjectInfo) => {
            if (item.idProjectStatus >= RI_STATUS.IspWorking2) {
              this.router.navigate([`/ps-form/${item.idProject}`]);
            } else if (item.idProjectStatus === RI_STATUS.IspWorking) {
              this.router.navigate([`/ps/${item.idProject}`]);
            }
          },
        },
        // TEMP until we add the deletion functionality
        {
          icon: 'more_option_2_icon',
          class: 'primary',
          subMenu: {
            buttons: [
              {
                text: this.translations.DeleteClone,
                clickFunction: (project: IProjectForTable) => {
                  this.deleteClonedProject(+project?.idProject);
                },
              },
              // {
              //   text: this.translations.PublishProject,
              //   clickFunction: (project: IProjectForTable) => {
              //     this.publishProjectDialog(project);
              //   },
              // },
            ],
          },
        },
      ];
    }
  }

  initializeSendToApproveProjectsTableColumns(): void {
    this.sendToApproveProjectsTableColumns = [
      {
        name: 'lastWorkedDate',
        translatedName: this.translations.LastWorking,
        type: 'date',
      },
      {
        name: 'businessName',
        translatedName: this.translations.BusinessName,
      },
      {
        name: 'telephone',
        translatedName: this.translations.Telephone,
      },
      {
        name: 'email',
        translatedName: this.translations.Email,
      },
      {
        name: 'address',
        translatedName: this.translations.Address,
      },
    ];
    if (this.isLabManager || this.isLabManagerInternal) {
      this.sendToApproveProjectsTableColumns.push({
        name: 'version',
        translatedName: this.translations.Version,
      });
    }
    this.sendToApproveProjectsTableColumns.push(
      {
        name: 'text',
        class: 'error-color fw-700',
      },
      {
        name: 'buttons',
        disableSort: true,
      }
    );
  }

  initializeSendToApproveProjectsTableButtons(): void {
    this.sendToApproveProjectsTableColumnsButtons = [
      {
        icon: 'more_option_2_icon',
        class: 'primary',
        subMenu: {
          buttons: [
            {
              text: this.translations.VisualizeASS,
              conditions: (project: IProjectInfo) => {
                return (
                  project.idProjectStatus === RI_STATUS.RiDelivered &&
                  !this.isAgentSupervisor
                );
              },
              clickFunction: (project: IProjectInfo) => {
                if (project.idProjectStatus === RI_STATUS.RiDelivered) {
                  this.router.navigate([
                    `/assessment-readonly/${project.idProject}`,
                  ]);
                }
                if (project.idProjectStatus === RI_STATUS.IspDelivered) {
                  this.router.navigate([`/ps-readonly/${project.idProject}`]);
                }
                if (project.idProjectStatus > RI_STATUS.IspDelivered) {
                  this.router.navigate([
                    `/ps-form-readonly/${project.idProject}/${project.customer.idCustomer}`,
                  ]);
                }
              },
            },
            {
              text: this.tranlsate.instant('COMMON.Visualize'),
              conditions: (project: IProjectForTable) => {
                return project.idProjectStatus >= RI_STATUS.IspDelivered;
              },
              subMenu: {
                buttons: [
                  {
                    text: this.tranlsate.instant('PS_VERSION.PS1'),
                    conditions: (project: IProjectForTable) => {
                      return project.idProjectStatus >= RI_STATUS.IspDelivered;
                    },
                    clickFunction: (project: IProjectForTable) => {
                      this.router.navigate([`/ps-readonly`, project.idProject]);
                    },
                  },
                  {
                    text: this.tranlsate.instant('PS_VERSION.PS2'),
                    conditions: (project: IProjectForTable) => {
                      return project.idProjectStatus >= RI_STATUS.IspDelivered2;
                    },
                    clickFunction: (project: IProjectForTable) => {
                      this.router.navigate([
                        `/ps-form-readonly`,
                        project.idProject,
                        project.customer.idCustomer,
                        RI_STATUS.IspWorking2,
                      ]);
                    },
                  },
                  {
                    text: this.tranlsate.instant('PS_VERSION.PS3'),
                    conditions: (project: IProjectForTable) => {
                      return project.idProjectStatus >= RI_STATUS.IspDelivered3;
                    },
                    clickFunction: (project: IProjectForTable) => {
                      this.router.navigate([
                        `/ps-form-readonly`,
                        project.idProject,
                        project.customer.idCustomer,
                        RI_STATUS.IspWorking3,
                      ]);
                    },
                  },
                  {
                    text: this.tranlsate.instant('PS_VERSION.PS4'),
                    conditions: (project: IProjectForTable) => {
                      return project.idProjectStatus >= RI_STATUS.IspDelivered4;
                    },
                    clickFunction: (project: IProjectForTable) => {
                      this.router.navigate([
                        `/ps-form-readonly`,
                        project.idProject,
                        project.customer.idCustomer,
                        RI_STATUS.IspWorking4,
                      ]);
                    },
                  },
                  {
                    text: this.tranlsate.instant('PS_VERSION.PS5'),
                    conditions: (project: IProjectForTable) => {
                      return project.idProjectStatus >= RI_STATUS.IspDelivered5;
                    },
                    clickFunction: (project: IProjectForTable) => {
                      this.router.navigate([
                        `/ps-form-readonly`,
                        project.idProject,
                        project.customer.idCustomer,
                        RI_STATUS.IspWorking5,
                      ]);
                    },
                  },
                  {
                    text: this.tranlsate.instant('PS_VERSION.PS6'),
                    conditions: (project: IProjectForTable) => {
                      return project.idProjectStatus >= RI_STATUS.IspDelivered6;
                    },
                    clickFunction: (project: IProjectForTable) => {
                      this.router.navigate([
                        `/ps-form-readonly`,
                        project.idProject,
                        project.customer.idCustomer,
                        RI_STATUS.IspDelivered6,
                      ]);
                    },
                  },
                ],
              },
            },
            // {
            //   text: this.translations.VisualizeCurrentPS,
            //   conditions: (project: IProjectInfo) => {
            //     return project.idProjectStatus >= RI_STATUS.IspDelivered;
            //   },
            //   clickFunction: (project: IProjectInfo) => {
            //     if (project.idProjectStatus === RI_STATUS.IspDelivered) {
            //       this.router.navigate([`/ps-readonly/${project.idProject}`]);
            //     }
            //     if (project.idProjectStatus > RI_STATUS.IspDelivered) {
            //       this.router.navigate([
            //         `/ps-form-readonly/${project.idProject}/${project.customer.idCustomer}`,
            //       ]);
            //     }
            //   },
            // },
            // {
            //   text: this.translations.VisualizePS1,
            //   conditions: (project: IProjectInfo) => {
            //     return project.idProjectStatus > RI_STATUS.IspDelivered;
            //   },
            //   clickFunction: (project: IProjectInfo) => {
            //     this.router.navigate([`/ps-readonly/${project.idProject}`]);
            //   },
            // },
          ],
        },
      },
    ];
  }

  initializeToApproveProjectsTableColumns(): void {
    this.toApproveProjectsTableColumns = [
      {
        name: 'lastWorkedDate',
        translatedName: this.translations.LastWorking,
        type: 'date',
      },
      {
        name: 'businessName',
        translatedName: this.translations.BusinessName,
      },
      {
        name: 'telephone',
        translatedName: this.translations.Telephone,
      },
      {
        name: 'email',
        translatedName: this.translations.Email,
      },
      {
        name: 'address',
        translatedName: this.translations.Address,
      },
    ];
    if (this.isLabManager || this.isLabManagerSupervisor) {
      this.toApproveProjectsTableColumns.push({
        name: 'version',
        translatedName: this.translations.Version,
      });
    }
    if (this.isAgentSupervisor) {
      this.toApproveProjectsTableColumns.push({
        name: 'agent',
        translatedName: this.translations.Agent,
      });
    }
    if (this.isLabManager || this.isLabManagerSupervisor) {
      this.toApproveProjectsTableColumns.push({
        name: 'dlmanager',
        translatedName: this.translations.DLManager,
      });
    }
    this.toApproveProjectsTableColumns.push({
      name: 'buttons',
      disableSort: true,
    });
  }

  initializeToApproveProjectsTableButtons(): void {
    this.toApproveProjectsTableColumnsButtons = [
      {
        icon: 'accept_2_icon',
        class: 'primary',
        tooltip: (project: IProjectForTable): string => {
          if (project?.projectNoteList?.some((note: Note) => !note.checked)) {
            return this.tranlsate.instant(
              'IR_FORM.ThereAreSupervisorNotesOnProject'
            );
          } else {
            if (project.idProjectStatus === RI_STATUS.RiDelivered) {
              return this.translations.ApproveASS;
            }
            if (project.idProjectStatus >= RI_STATUS.IspDelivered) {
              return this.translations.ApprovePS;
            }
          }
        },
        disabled: (project: IProjectForTable) => {
          let result: boolean = false;
          if (project?.projectNoteList?.length) {
            project.projectNoteList.some((note: Note) => {
              if (!note.checked) {
                result = true;
                return true;
              } else {
                return false;
              }
            });
          }
          return result;
        },
        clickFunction: (project: IProjectForTable) => {
          this.approveProject(project.idProject);
        },
      },
      {
        icon: 'decline_2_icon',
        class: 'primary',
        tooltip: (project: IProjectForTable) => {
          if (project.idProjectStatus === RI_STATUS.RiDelivered) {
            return this.translations.RejectASS;
          }
          if (project.idProjectStatus >= RI_STATUS.IspDelivered) {
            return this.translations.RejectPS;
          }
        },
        clickFunction: (project: IProjectForTable) => {
          this.rejectProject(project.idProject);
        },
      },
      {
        icon: 'edit_icon',
        class: 'primary',
        tooltip: (project: IProjectForTable) => {
          if (project.idProjectStatus === RI_STATUS.RiDelivered) {
            return this.translations.EditOrAssignRevisionNotes;
          }
          if (project.idProjectStatus >= RI_STATUS.IspDelivered) {
            return this.translations.AssignRevisionNotes;
          }
        },
        clickFunction: (project: IProjectForTable) => {
          if (project.idProjectStatus === RI_STATUS.RiDelivered) {
            this.router.navigate([`/assessment/${project.idProject}`]);
          }
          if (project.idProjectStatus === RI_STATUS.IspDelivered) {
            this.router.navigate([`/ps/${project.idProject}`]);
          }
          if (project.idProjectStatus >= RI_STATUS.IspDelivered2) {
            this.router.navigate([`/ps-form/${project.idProject}`]);
          }
        },
      },
    ];
  }

  initializeApprovedProjectsTableColumns(): void {
    this.approvedProjectsTableColumns = [
      {
        name: 'lastWorkedDate',
        translatedName: this.translations.LastWorking,
        type: 'date',
      },
      {
        name: 'businessName',
        translatedName: this.translations.BusinessName,
      },
      {
        name: 'telephone',
        translatedName: this.translations.Telephone,
      },
      {
        name: 'email',
        translatedName: this.translations.Email,
      },
      {
        name: 'address',
        translatedName: this.translations.Address,
      },
      // {
      //   name: 'buttons',
      //   disableSort: true,
      //   width: 10,
      // },
    ];
    if (
      this.isLabManager ||
      this.isLabManagerInternal ||
      this.isLabManagerSupervisor
    ) {
      this.approvedProjectsTableColumns.push({
        name: 'version',
        translatedName: this.translations.Version,
      });
    }
    if (this.isAgentSupervisor) {
      this.approvedProjectsTableColumns.push({
        name: 'agent',
        translatedName: this.translations.Agent,
      });
    }
    if (this.isLabManagerSupervisor) {
      this.approvedProjectsTableColumns.push({
        name: 'dlmanager',
        translatedName: this.translations.DLManager,
      });
    }
  }

  initializeApprovedProjectsTableButtons(): void {
    this.approvedProjectsTableColumnsButtons = [
      {
        icon: 'arrow_green_3_icon',
        class: 'primary',
        disabled: () => false,
        clickFunction: (project: IProjectInfo) => {
          if (this.isAgentSupervisor) {
            this.router.navigate([`/assessment-readonly/${project.idProject}`]);
          }
          if (this.isLabManager || this.isLabManagerSupervisor) {
            this.router.navigate([`/ps-readonly/${project.idProject}`]);
          }
        },
      },
    ];
  }

  initializeBMAssTableColumns(): void {
    this.bmProjectsTableColumns = [
      {
        name: 'lastWorkedDate',
        translatedName: this.translations.LastWorking,
        type: 'date',
      },
      {
        name: 'businessName',
        translatedName: this.translations.BusinessName,
      },
      {
        name: 'telephone',
        translatedName: this.translations.Telephone,
      },
      {
        name: 'email',
        translatedName: this.translations.Email,
      },
      {
        name: 'address',
        translatedName: this.translations.Address,
      },
      {
        name: 'buttons',
        width: 10,
        disableSort: true,
      },
    ];
  }

  initializeBMAssTableButtons(): void {
    this.bmProjectsTableColumnsButtons = [
      {
        icon: 'edit_icon',
        class: 'primary',
        clickFunction: (project: IProjectInfo) => {
          this.router.navigate([`/assessment/${project.idProject}`]);
        },
      },
    ];
  }

  approveProject(idProject: string | number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.isAgentSupervisor
        ? this.tranlsate.instant('IR_FORM.ApproveIrMessageConfirmation')
        : this.tranlsate.instant('IR_FORM.ApproveIspMessageConfirmation'),
      buttonTrue: this.tranlsate.instant('COMMON.Yes'),
      buttonFalse: this.tranlsate.instant('COMMON.No'),
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.isAgentSupervisor) {
          this.subscriptions.add(
            this.irFormService.approveIr(idProject).subscribe({
              complete: () => {
                this.getToApproveProjects();
                this.getApprovedProjects();
              },
            })
          );
        }
        if (this.isLabManagerSupervisor || this.isLabManager) {
          this.subscriptions.add(
            this.irFormService.approveIsp(idProject).subscribe({
              complete: () => {
                this.getToApproveProjects();
                this.getApprovedProjects();
              },
            })
          );
        }
      }
    });
  }

  rejectProject(idProject: string | number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.isAgentSupervisor
        ? this.tranlsate.instant('IR_FORM.RejectIrMessageConfirmation')
        : this.tranlsate.instant('IR_FORM.RejectIspMessageConfirmation'),
      buttonTrue: this.tranlsate.instant('COMMON.Yes'),
      buttonFalse: this.tranlsate.instant('COMMON.No'),
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.isAgentSupervisor) {
          this.subscriptions.add(
            this.irFormService.rejectIr(idProject).subscribe({
              complete: () => {
                this.getToApproveProjects();
                this.getRejectedProjects();
              },
            })
          );
        }
        if (this.isLabManagerSupervisor || this.isLabManager) {
          this.subscriptions.add(
            this.irFormService.rejectIsp(idProject).subscribe({
              complete: () => {
                this.getToApproveProjects();
                this.getRejectedProjects();
              },
            })
          );
        }
      }
    });
  }

  getMasterAndClonedProjects(): void {
    this.masterAndClonedProjectLoader = true;
    if (this.isLabManager && this.isDemonstrator) {
      this.subscriptions.add(
        this.irFormService
          .getMasterAndClonedProjects()
          .subscribe((projects: IProjectInfo[]) => {
            let master: IProjectInfo[] = [],
              clones: IProjectInfo[] = [];

            projects.forEach((project) => {
              if (project.type === PS_TYPE.Master) {
                master.push(project);
              } else if (project.type === PS_TYPE.Clone) {
                clones.push(project);
              }
            });

            this.masterProject = mappingServerDataToProjectsForTable(
              master,
              this.translations
            );
            this.clonedProjects = mappingServerDataToProjectsForTable(
              clones,
              this.translations
            );
            this.masterAndClonedProjectLoader = false;
          })
      );
    }
  }

  getRejectedProjects(): void {
    this.rejectedProjectsLoader = true;
    if (this.isAgent || this.isAgentSupervisor) {
      this.subscriptions.add(
        this.irFormService
          .getRejectedAssessments()
          .subscribe((projects: IProjectInfo[]) => {
            this.rejectedProjects = mappingServerDataToProjectsForTable(
              projects,
              this.translations
            );
            this.rejectedProjectsLoader = false;
          })
      );
    }
    if (this.isLabManager || this.isLabManagerInternal) {
      this.subscriptions.add(
        this.irFormService
          .getProjectRejectedByStatusDescription(
            RI_STATUS_DESCRIPTION.IspWorking,
            true
          )
          .subscribe({
            next: (projects: IProjectInfo[]) => {
              this.rejectedProjects = mappingServerDataToProjectsForTable(
                projects,
                this.translations
              );
              this.rejectedProjectsLoader = false;
            },
          })
      );
    }
    if (this.isLabManagerSupervisor) {
      this.subscriptions.add(
        this.irFormService
          .getProjectRejectedForSupervisorByStatusDescription(
            RI_STATUS_DESCRIPTION.IspWorking,
            true
          )
          .subscribe({
            next: (projects: IProjectInfo[]) => {
              this.rejectedProjects = mappingServerDataToProjectsForTable(
                projects,
                this.translations
              );
              this.rejectedProjectsLoader = false;
            },
          })
      );
    }
  }

  getInProgressProjects(): void {
    this.inProgressProjectsLoader = true;
    if (this.isAgent) {
      this.subscriptions.add(
        this.irFormService.getInProgressAssessments().subscribe({
          next: (projects: IProjectInfo[]) => {
            this.inProgressProjects = mappingServerDataToProjectsForTable(
              projects,
              this.translations
            );
            this.inProgressProjectsLoader = false;
          },
        })
      );
    }
    if (this.isLabManager || this.isLabManagerInternal) {
      this.subscriptions.add(
        this.psFormService.getInProgressPs().subscribe({
          next: (projects: IProjectInfo[]) => {
            this.inProgressProjects = mappingServerDataToProjectsForTable(
              projects,
              this.translations
            );
            this.inProgressProjectsLoader = false;
          },
        })
      );
    }
  }

  getSendToApproveProjects(): void {
    this.sendToApproveProjectsLoader = true;
    if (this.isAgent) {
      this.subscriptions.add(
        this.irFormService.getToApproveAssessments().subscribe({
          next: (projects: IProjectInfo[]) => {
            this.sendToApproveProjects = mappingServerDataToProjectsForTable(
              projects,
              this.translations
            );
            this.sendToApproveProjectsLoader = false;
          },
        })
      );
    }
    if (this.isLabManager || this.isLabManagerInternal) {
      // this.subscriptions.add(
      //   this.psFormService.getDeliveredPs().subscribe({
      //     next: (projects: any) => {
      //       const toApproveInternalPsUnmapped = [];
      //       const toApproveProjectsUnmapped = [];
      //       projects.forEach((project: IProjectForTable) => {
      //         if (project.idUser === this.idLoggedUser) {
      //           toApproveInternalPsUnmapped.push(project);
      //         } else {
      //           toApproveProjectsUnmapped.push(project);
      //         }
      //       });
      //       this.toApproveInternalPs = mappingServerDataToProjectsForTable(
      //         toApproveInternalPsUnmapped,
      //         this.translations
      //       );
      //       this.toApproveProjects = mappingServerDataToProjectsForTable(
      //         toApproveProjectsUnmapped,
      //         this.translations
      //       );
      //       this.toApproveProjectsLoader = false;
      //     },
      //   })
      // );
      this.subscriptions.add(
        this.irFormService
          .getProjectByStatusDescription(
            RI_STATUS_DESCRIPTION.IspDelivered,
            true
          )
          .subscribe({
            next: (projects: IProjectInfo[]) => {
              this.sendToApproveProjects = mappingServerDataToProjectsForTable(
                projects,
                this.translations
              );
              this.sendToApproveProjectsLoader = false;
            },
          })
      );
    }
    if (this.isLabManager || this.isLabManagerInternal) {
    }
  }

  getToApproveProjects(): void {
    this.toApproveProjectsLoader = true;
    if (this.isAgentSupervisor) {
      this.subscriptions.add(
        this.irFormService.getToApproveAssessments().subscribe({
          next: (projects: IProjectInfo[]) => {
            this.toApproveProjects = mappingServerDataToProjectsForTable(
              projects,
              this.translations
            );
            this.toApproveProjectsLoader = false;
          },
        })
      );
    }
    if (this.isLabManager) {
      this.subscriptions.add(
        this.psFormService.getDeliveredPs().subscribe({
          next: (projects: any) => {
            const toApproveInternalPsUnmapped = [];
            const toApproveProjectsUnmapped = [];
            projects.forEach((project: IProjectForTable) => {
              if (project.idUser === this.idLoggedUser) {
                toApproveInternalPsUnmapped.push(project);
              } else {
                toApproveProjectsUnmapped.push(project);
              }
            });
            this.toApproveInternalPs = mappingServerDataToProjectsForTable(
              toApproveInternalPsUnmapped,
              this.translations
            );
            this.toApproveProjects = mappingServerDataToProjectsForTable(
              toApproveProjectsUnmapped,
              this.translations
            );
            this.toApproveProjectsLoader = false;
          },
        })
      );
      this.subscriptions.add(
        this.irFormService
          .getProjectByStatusDescription(
            RI_STATUS_DESCRIPTION.IspDelivered,
            true
          )
          .subscribe({
            next: (projects: any) => {
              // console.log(projects);
            },
          })
      );
    }
    if (this.isLabManagerSupervisor) {
      this.subscriptions.add(
        this.psFormService.getDeliveredPs().subscribe({
          next: (projects: IProjectInfo[]) => {
            this.toApproveProjects = mappingServerDataToProjectsForTable(
              projects,
              this.translations
            );
            this.toApproveProjectsLoader = false;
          },
        })
      );
    }
  }

  getApprovedProjects(): void {
    this.approvedProjectsLoader = true;
    if (this.isAgentSupervisor) {
      this.subscriptions.add(
        this.irFormService.getApprovedAssessments().subscribe({
          next: (projects: IProjectInfo[]) => {
            this.approvedProjects = mappingServerDataToProjectsForTable(
              projects,
              this.translations
            );
            this.approvedProjectsLoader = false;
          },
        })
      );
    }
    if (this.isLabManager) {
      this.subscriptions.add(
        this.irFormService
          .getProjectApprovedByStatusDescription(
            RI_STATUS_DESCRIPTION.IspWorking,
            true
          )
          .subscribe({
            next: (projects: IProjectInfo[]) => {
              this.approvedProjects = mappingServerDataToProjectsForTable(
                projects,
                this.translations
              );
              this.approvedProjectsLoader = false;
            },
          })
      );
    }
    if (this.isLabManagerSupervisor) {
      this.subscriptions.add(
        this.irFormService
          .getProjectApprovedByStatusDescription(
            RI_STATUS_DESCRIPTION.IspWorking,
            true
          )
          .subscribe({
            next: (projects: IProjectInfo[]) => {
              this.approvedProjects = mappingServerDataToProjectsForTable(
                projects,
                this.translations
              );
              this.approvedProjectsLoader = false;
            },
          })
      );
    }
  }

  getBMProjects(): void {
    this.bmProjectsLoader = true;
    if (this.isAgent) {
      this.subscriptions.add(
        this.irFormService
          .getBMAssessments()
          .subscribe((assessments: IProjectInfo[]) => {
            this.bmProjects = mappingServerDataToProjectsForTable(
              assessments,
              this.translations
            );
            this.bmProjectsLoader = false;
          })
      );
    }
  }

  showRevisionNotes(item: IProjectInfo): void {
    const projectSectionCustomerNotes: Note[] = [];
    const projectSectionContractNotes: Note[] = [];
    const projectSectionCustomerInformationNotes: Note[] = [];
    const projectSectionProductsNotes: Note[] = [];
    const projectSectionMarketsNotes: Note[] = [];
    const projectSectionDistributionNotes: Note[] = [];
    const projectSectionCompetitorsNotes: Note[] = [];
    const projectSectionLeadGenerationNotes: Note[] = [];
    const projectSectionSWOTNotes: Note[] = [];
    const projectSectionMarketsStrategyAnalysisNotes: Note[] = [];
    const projectSectionNotesNotes: Note[] = [];
    const projectSectionStrategyNotes: Note[] = [];
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      idProjectStatus: item.idProjectStatus,
      businessName: item.customer.businessName,
      projectSections: {
        Customer: projectSectionCustomerNotes,
        Contract: projectSectionContractNotes,
        CustomerInformation: projectSectionCustomerInformationNotes,
        Products: projectSectionProductsNotes,
        Markets: projectSectionMarketsNotes,
        Distribution: projectSectionDistributionNotes,
        ReferenceCompetitors: projectSectionCompetitorsNotes,
        LeadGeneration: projectSectionLeadGenerationNotes,
        SWOT: projectSectionSWOTNotes,
        MarketsStrategyAnalysis: projectSectionMarketsStrategyAnalysisNotes,
        Notes: projectSectionNotesNotes,
        Strategy: projectSectionStrategyNotes,
      },
    };
    // Filter notes by section
    item.projectNoteList.forEach((note: Note) => {
      if (!note.checked) {
        if (note.riTab === this.riTab.Customer) {
          projectSectionCustomerNotes.push(note);
        }
        if (note.riTab === this.riTab.Contract) {
          projectSectionContractNotes.push(note);
        }
        if (note.riTab === this.riTab.CustomerInformation) {
          projectSectionCustomerInformationNotes.push(note);
        }
        if (note.riTab === this.riTab.Products) {
          projectSectionProductsNotes.push(note);
        }
        if (note.riTab === this.riTab.Markets) {
          projectSectionMarketsNotes.push(note);
        }
        if (note.riTab === this.riTab.Distribution) {
          projectSectionDistributionNotes.push(note);
        }
        if (note.riTab === this.riTab.ReferenceCompetitors) {
          projectSectionCompetitorsNotes.push(note);
        }
        if (note.riTab === this.riTab.LeadGeneration) {
          projectSectionLeadGenerationNotes.push(note);
        }
        if (note.riTab === this.riTab.SWOT) {
          projectSectionSWOTNotes.push(note);
        }
        if (note.riTab === this.riTab.MarketsStrategyAnalysis) {
          projectSectionMarketsStrategyAnalysisNotes.push(note);
        }
        if (note.riTab === this.riTab.Notes) {
          projectSectionNotesNotes.push(note);
        }
        if (note.riTab === this.riTab.Strategy) {
          projectSectionStrategyNotes.push(note);
        }
      }
    });
    this.dialog.open(this.revisionNotesDialog, dialogConfig);
  }

  getProjectsToAssign(): void {
    this.projectsToAssignLoader = true;
    this.subscriptions.add(
      this.irFormService
        .getAllIrToAssign(RI_STATUS.RiApproved, true)
        .subscribe((projects: IProjectInfo[]) => {
          this.projectsToAssign = projects.map(fromProjectDtoToProjectTable);
          this.initializeProjectsToAssignTableButtons();
          this.projectsToAssignLoader = false;
        })
    );
  }

  getProjectsAssigned(): void {
    this.projectsAssignedLoader = true;
    this.subscriptions.add(
      this.irFormService
        .getProjectByStatusDescription(RI_STATUS_DESCRIPTION.IspWorking, true)
        .subscribe((projects: IProjectInfo[]) => {
          this.projectsAssigned = projects.map(fromProjectDtoToProjectTable);
          this.projectsAssignedLoader = false;
        })
    );
  }

  getProjects(status?: number): void {
    this.projectsLoader = true;
    this.subscriptions.add(
      this.irFormService
        .getAllUserProjectByStatus(status || this.statusSelected, true)
        .subscribe((projects: IProjectInfo[]) => {
          this.projects = projects.map(fromProjectDtoToProjectTable);
          this.projectsLoader = false;
        })
    );
  }

  publishProject(project: IProjectInfo): void {
    const configData: MatDialogConfig = new MatDialogConfig();
    configData.disableClose = true;
    configData.autoFocus = false;
    configData.panelClass = 'custom-dialog-container';
    configData.width = '400px';
    configData.data = {
      title: this.translations.Attention,
      message: this.translations.PublishProject,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, configData);
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.subscriptions.add(
          this.psFormService
            .publishProject(
              project.idProject,
              project.customer?.idCustomer,
              project.idProjectStatus,
              true
            )
            .subscribe({
              next: () => {},
            })
        );
      }
    });
  }

  setSelectedStatus(): void {
    if (
      this.statusSelected === RI_STATUS.IspWorking ||
      (this.statusSelected >= RI_STATUS.IspWorking2 &&
        this.statusSelected <= RI_STATUS.IspWorking6) ||
      this.statusSelected === RI_STATUS.IspDelivered ||
      (this.statusSelected >= RI_STATUS.IspDelivered2 &&
        this.statusSelected >= RI_STATUS.IspDelivered6)
    ) {
      if (
        this.statusSelected === RI_STATUS.IspWorking ||
        (this.statusSelected >= RI_STATUS.IspWorking2 &&
          this.statusSelected <= RI_STATUS.IspWorking6)
      ) {
        this.showWorkingVersionSelect = true;
        this.showToApproveVersionSelect = false;
        console.log(this.workingVersionSelected);
        this.getProjects(this.workingVersionSelected);
      }
      if (
        this.statusSelected === RI_STATUS.IspDelivered ||
        (this.statusSelected >= RI_STATUS.IspDelivered2 &&
          this.statusSelected <= RI_STATUS.IspDelivered6)
      ) {
        this.showWorkingVersionSelect = false;
        this.showToApproveVersionSelect = true;
        console.log(this.toApproveVersionSelected);
        this.getProjects(this.toApproveVersionSelected);
      }
    } else {
      this.workingVersionSelected = RI_STATUS.IspWorking;
      this.toApproveVersionSelected = RI_STATUS.IspDelivered;
      this.showWorkingVersionSelect = false;
      this.showToApproveVersionSelect = false;
      this.getProjects();
    }
  }

  initializeProjectsToAssignTableColumns(): void {
    this.projectsToAssignTableColumns = [
      {
        name: 'lastModified',
        translatedName: this.translations.LastWorking,
        type: 'date',
      },
      {
        name: 'businessName',
        translatedName: this.translations.BusinessName,
      },
      {
        name: 'telephoneNumber',
        translatedName: this.translations.Telephone,
      },
      {
        name: 'email',
        translatedName: this.translations.Email,
      },
      {
        name: 'address',
        translatedName: this.translations.Address,
      },
      {
        name: 'buttons',
        width: 10,
        disableSort: true,
      },
    ];
  }

  initializeProjectsAssignedTableColumns(): void {
    this.projectsAssignedTableColumns = [
      {
        name: 'lastModified',
        translatedName: this.translations.LastWorking,
        type: 'date',
      },
      {
        name: 'businessName',
        translatedName: this.translations.BusinessName,
      },
      {
        name: 'telephoneNumber',
        translatedName: this.translations.Telephone,
      },
      {
        name: 'email',
        translatedName: this.translations.Email,
      },
      {
        name: 'address',
        translatedName: this.translations.Address,
      },
      {
        name: 'manager',
        translatedName: this.translations.DLManager,
      },
    ];
  }

  initializeProjectsTableColumns(): void {
    this.projectsTableColumns = [
      {
        name: 'lastModified',
        translatedName: this.translations.LastWorking,
        type: 'date',
      },
      {
        name: 'businessName',
        translatedName: this.translations.BusinessName,
      },
      {
        name: 'telephoneNumber',
        translatedName: this.translations.Telephone,
      },
      {
        name: 'email',
        translatedName: this.translations.Email,
      },
      {
        name: 'address',
        translatedName: this.translations.Address,
      },
      {
        name: 'agent',
        translatedName: this.translations.AgentDLike,
      },
      {
        name: 'buttons',
      },
    ];
  }

  initializeProjectsTableButtons(): void {
    this.projectsTableColumnsButtons = [
      {
        icon: 'arrow_green_3_icon',
        class: 'primary',
        conditions: (project: IProjectForTable) => {
          return (
            project.idProjectStatus === RI_STATUS.RiDelivered ||
            project.idProjectStatus === RI_STATUS.RiApproved ||
            project.idProjectStatus === RI_STATUS.IspDelivered ||
            project.idProjectStatus === RI_STATUS.IspApproved ||
            project.idProjectStatus >= RI_STATUS.IspDelivered2
          );
        },
        clickFunction: (project: IProjectForTable) => {
          if (
            project.idProjectStatus === RI_STATUS.RiDelivered ||
            project.idProjectStatus === RI_STATUS.RiApproved
          ) {
            this.router.navigate([`/assessment-readonly/${project.idProject}`]);
          }
          if (
            project.idProjectStatus === RI_STATUS.IspDelivered ||
            project.idProjectStatus === RI_STATUS.IspApproved
          ) {
            this.router.navigate([`/ps-readonly/${project.idProject}`]);
          }
          if (project.idProjectStatus >= RI_STATUS.IspDelivered2) {
            this.router.navigate([
              `/ps-form-readonly/${project.idProject}/${project.customer.idCustomer}`,
            ]);
          }
        },
      },
    ];
  }

  initializeProjectsToAssignTableButtons(): void {
    this.projectsToAssignTableColumnsButtons = [
      {
        icon: 'arrow_green_3_icon',
        class: 'primary',
        clickFunction: (project: IProjectForTable) => {
          this.assignProject(project);
        },
      },
    ];
  }

  assignProject(project: IProjectForTable): void {
    // this.router.navigate([
    //   `/projects`,
    //   project.idProject,
    //   project.customer.idCustomer,
    // ]);
    this.router.navigate([`/projects/${project.idProject}`], {
      queryParams: {
        idCustomer: project.customer.idCustomer,
      },
    });
  }

  publishProjectDialog(project: IProjectForTable): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      projectData: project,
    };
    const dialogRef: MatDialogRef<PublishProjectDialogComponent> =
      this.dialog.open(PublishProjectDialogComponent, dialogConfig);
    // this.dialogSubscriptions = dialogRef
    //   .afterClosed()
    //   .subscribe((result: any) => {
    //     if (result) {

    //     }
    //   });
  }

  cloneMasterProject(): void {
    this.subscriptions.add(
      this.irFormService.createClonedProject().subscribe({
        next: () => {
          this.getMasterAndClonedProjects();
        },
      })
    );
  }

  deleteClonedProject(idProject: number): void {
    this.subscriptions.add(
      this.irFormService.deleteClonedProject(idProject).subscribe({
        next: () => {
          this.getMasterAndClonedProjects();
        },
      })
    );
  }

  searchValue(value: string, table: string): void {
    switch (table) {
      case 'masterAndCloned':
        this.searchValueMasterAndClonedProjects = value;
        break;
      case 'rejected':
        this.searchValueRejectedProjects = value;
        break;
      case 'approved':
        this.searchValueApprovedProjects = value;
        break;
      case 'in progress':
        this.searchValueInProgressProjects = value;
        break;
      case 'send to approve':
        this.searchValueSendToApproveProjects = value;
        break;
      case 'to approve':
        this.searchValueToApproveProjects = value;
        break;
      case 'bm':
        this.searchValueBmProjects = value;
        break;
      case 'projectsToAssign':
        this.searchValueProjectsToAssign = value;
        break;
      case 'projectsAssigned':
        this.searchValueProjectsAssigned = value;
        break;
      case 'Projects':
        this.searchValueProjects = value;
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
