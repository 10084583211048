import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Project } from 'src/app/models/project';
import { Note } from 'src/app/models/note';
import { DOC_TYPE, NOTE_TYPE } from 'src/app/config';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { DocumentDTO } from 'src/app/models/contract';

@Component({
  selector: 'app-note-form',
  templateUrl: './note-form.component.html',
  styleUrls: ['./note-form.component.scss'],
})
export class NoteFormComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  @Input() initialValues: Observable<Project>;
  @Input() translations: any;
  @Input() isSupervisor: boolean;
  @Input() isIsp: boolean;
  @Input() isReadOnly: boolean;
  @Input() isUserAndPreviousUser: boolean;

  @Output() submitFuncEmitter: EventEmitter<Note[]> = new EventEmitter();
  @Output() sendIrEmitter: EventEmitter<any> = new EventEmitter();
  @Output() rejectIrEmitter: EventEmitter<any> = new EventEmitter();
  @Output() approveIrEmitter: EventEmitter<any> = new EventEmitter();

  idProject: number | string;
  noteForm: FormGroup;
  haveDocuments: boolean = false;

  constructor(private fb: FormBuilder, private layoutService: LayoutService) {
    this.noteForm = fb.group({
      notes: fb.array([
        fb.group({
          idAgentNote: [null],
          description: [null, [Validators.required]],
          idAgentNoteType: [NOTE_TYPE.ReferentNote],
          idProject: [null],
        }),
        fb.group({
          idAgentNote: [null],
          description: [null, [Validators.required]],
          idAgentNoteType: [NOTE_TYPE.CustomNote],
          idProject: [null],
        }),
        fb.group({
          idAgentNote: [null],
          description: [null],
          idAgentNoteType: [NOTE_TYPE.ProductNote],
          idProject: [null],
        }),
        fb.group({
          idAgentNote: [null],
          description: [null],
          idAgentNoteType: [NOTE_TYPE.ManagerNote],
          idProject: [null],
        }),
      ]),
    });

    this.subscriptions.add(
      this.noteForm.valueChanges.subscribe(() => {
        this.checkDirtyForm();
      })
    );
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialValues) => {
          this.idProject = initialValues.idProject;
          this.notes.controls.forEach((noteForm) =>
            noteForm.get('idProject').setValue(this.idProject)
          );
          initialValues.notes?.forEach((note: Note): void =>
            this.initilizaNoteForm(note.idAgentNoteType - 1, note)
          );

          this.noteForm.markAsPristine();
          this.noteForm.markAsUntouched();

          this.checkDirtyForm();

          if (this.isReadOnly) {
            this.noteForm.disable();
          }
          if (
            initialValues.documents.filter(
              (doc: DocumentDTO) =>
                doc.type === DOC_TYPE.RI || doc.type === DOC_TYPE.CONTRACT
            ).length > 0
          ) {
            this.haveDocuments = true;
          }
        },
      })
    );
  }

  /**
   * @description Get the notes formArray in FormGroup for easy access
   */
  get notes(): FormArray {
    return this.noteForm.get('notes') as FormArray;
  }

  /**
   * @description Initialize the form in `idxNoteth` item of formArray
   * @param idxNote index of noteform in array to inizialize
   * @param note the value which initialize the form
   */
  initilizaNoteForm(idxNote: number, note: Note): void {
    this.notes.at(idxNote).patchValue(note);
  }

  /**
   * @description Check Validity form and Emit submit function after check if and how many item in array are changed or new
   */
  onSubmit(): void {
    if (this.noteForm.valid && this.noteForm.dirty) {
      // const dirtyNotes: FormArray = this.fb.array(
      //   this.notes.controls.filter((note: FormGroup) => note.dirty)
      // );
      const body: Note[] = this.notes.getRawValue();
      this.submitFuncEmitter.emit(body);
      // this.noteForm.markAsPristine();
      // this.noteForm.markAsUntouched();
    }
  }

  checkDirtyForm(): boolean {
    return this.noteForm?.dirty;
  }

  checkInvalidForm(): boolean {
    this.noteForm.markAllAsTouched();
    return this.noteForm?.invalid;
  }

  sendIr(): void {
    this.sendIrEmitter.emit();
  }

  /*viewIr(): void {
    this.viewIrEmitter.emit();
  }*/

  rejectIr(): void {
    this.rejectIrEmitter.emit();
  }

  approveIr(): void {
    if (this.isUserAndPreviousUser) {
      this.sendIrEmitter.emit();
    } else {
      this.approveIrEmitter.emit();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
