<div class="section-card">
  <div class="header">
    <div class="title">
      <span>{{ "COMMON.Project" | translate }}: {{ project?.idProject }}</span>
    </div>
  </div>
  <div class="content">
    <div class="row">
      <mat-form-field class="col-6">
        <mat-label>{{
          "PROJECT_DETAIL.AssignLabManager" | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          [value]="
            selectedDLManagerForProject?.name
              ? selectedDLManagerForProject?.name +
                ' ' +
                selectedDLManagerForProject?.surname
              : ''
          "
          name="labManager"
          readonly
        />
        <button
          matSuffix
          class="only-icon-button primary"
          type="button"
          (click)="openAssignDLManagerDialog()"
        >
          <svg-icon name="icon_add_manager" class="w20 h20"></svg-icon>
        </button>
        <!-- <mat-select
          disableOptionCentering
          panelClass="custom-panel-class"
          name="labManager"
          formControlName="labManager"
        >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="entityFilterCtrl"
              [placeholderLabel]="'COMMON.Search...' | translate"
              [noEntriesFoundLabel]="'Nessun risultato'"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let labManager of filteredLabManagers"
            [value]="labManager"
          >
            {{ labManager?.name }} {{ labManager?.surname }} -
            {{ labManager?.email }}
          </mat-option>
        </mat-select> -->
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.DrawnUpBy" | translate }}</mat-label>
        <input matInput [value]="drawnUpBy" readonly />
        <div
          matSuffix
          class="app-color"
          [ngbPopover]="popDrawnUpBy"
          placement="left"
          container="body"
          triggers="mouseenter:mouseleave"
        >
          <svg-icon name="tooltip_icon" class="w16 h16"></svg-icon>
        </div>
      </mat-form-field>
      <ng-template #popDrawnUpBy>
        <div>
          <span>Tel: {{ drawnUpByPhone }}</span>
        </div>
        <div>
          <span>Email: {{ drawnUpByEmail }}</span>
        </div>
      </ng-template>
      <mat-form-field class="col-3">
        <mat-label>{{
          "PROJECT_DETAIL.ContractSigningDate" | translate
        }}</mat-label>
        <input
          matInput
          [value]="
            (project?.expectedContractStartDate | date: 'dd/MM/yyyy') || null
          "
          readonly
        />
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.LastUpdateDate" | translate }}</mat-label>
        <input
          matInput
          [value]="(project?.lastModified | date: 'dd/MM/yyyy') || null"
          readonly
        />
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.ApprovedBy" | translate }}</mat-label>
        <input matInput [value]="approvedBy" readonly />
        <div
          matSuffix
          class="app-color"
          [ngbPopover]="popApprovedBy"
          placement="left"
          container="body"
          triggers="mouseenter:mouseleave"
        >
          <svg-icon name="tooltip_icon" class="w16 h16"></svg-icon>
        </div>
      </mat-form-field>
      <ng-template #popApprovedBy>
        <div>
          <span>Tel: {{ approvedByPhone }}</span>
        </div>
        <div>
          <span>Email: {{ approvedByEmail }}</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<div class="section-card mt-30">
  <div class="header">
    <div class="title">
      <span>{{ "PROJECT_DETAIL.CustomerInformation" | translate }}</span>
    </div>
  </div>
  <div class="content">
    <div class="row">
      <div class="customer-image col-1">
        <img src="{{ customerImagePath }}" />
      </div>
      <mat-form-field class="col-3">
        <mat-label>{{ "IR_FORM.BusinessName" | translate }}</mat-label>
        <input matInput [value]="customer?.businessName || ''" readonly />
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>{{ "COMMON.Address" | translate }}</mat-label>
        <input matInput [value]="customerAddress || ''" readonly />
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.Email" | translate }}</mat-label>
        <input matInput [value]="customer?.email || ''" readonly />
      </mat-form-field>
      <mat-form-field class="col-2">
        <mat-label>{{ "PROJECT_DETAIL.Phone" | translate }}</mat-label>
        <input matInput [value]="customer?.telephoneNumber || ''" readonly />
      </mat-form-field>
    </div>
  </div>
</div>
<div class="section-card mt-30">
  <div class="header">
    <div class="title">
      <span>{{ "PROJECT_DETAIL.ProjectReferent" | translate }}</span>
    </div>
  </div>
  <div class="content">
    <div class="row">
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.Name" | translate }}</mat-label>
        <input matInput [value]="projectReferent?.name || ''" readonly />
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.Surname" | translate }}</mat-label>
        <input matInput [value]="projectReferent?.surname || ''" readonly />
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.Email" | translate }}</mat-label>
        <input matInput [value]="projectReferent?.email || ''" readonly />
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.Phone" | translate }}</mat-label>
        <input
          matInput
          [value]="projectReferent?.telephoneNumber || ''"
          readonly
        />
      </mat-form-field>
    </div>
  </div>
</div>
<div class="section-card mt-30">
  <div class="header">
    <div class="title">
      <span>{{ "PROJECT_DETAIL.AdministrativeReferent" | translate }}</span>
    </div>
  </div>
  <div class="content">
    <div class="row">
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.Name" | translate }}</mat-label>
        <input matInput [value]="administrativeReferent?.name || ''" readonly />
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.Surname" | translate }}</mat-label>
        <input
          matInput
          [value]="administrativeReferent?.surname || ''"
          readonly
        />
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.Email" | translate }}</mat-label>
        <input
          matInput
          [value]="administrativeReferent?.email || ''"
          readonly
        />
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.Phone" | translate }}</mat-label>
        <input
          matInput
          [value]="administrativeReferent?.telephoneNumber || ''"
          readonly
        />
      </mat-form-field>
    </div>
  </div>
</div>
<div class="section-card mt-30">
  <div class="header multi">
    <div class="title">
      <span>{{ "CUSTOMER.Documents" | translate }}</span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!documentsDataSource?.length"
        (emitSearchValue)="searchValue($event)"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="loaderDocuments"
      [columns]="documentsTableColumns"
      [columnsButtons]="documentsTableColumnsButtons"
      [data]="documentsDataSource"
      [searchValue]="searchValueDocuments"
    >
    </app-table>
  </div>
</div>
<div class="d-flex justify-content-center mt-30">
  <div>
    <app-upload-file
      [uploadDirectType]="false"
      [fileType]="translations?.Certificate"
      [showDocumentName]="true"
      [namePosition]="'bottom'"
      (uploadFileEmitter)="uploadDocument($event)"
    ></app-upload-file>
  </div>
  <button
    type="button"
    class="rounded-button primary-bg-button ml-30"
    [disabled]="!selectedDLManagerForProject?.idUser"
    (click)="sendProject()"
  >
    {{ "PROJECT_DETAIL.AssignProject" | translate }}
  </button>
</div>
<ng-template #assignDLManagerDialogTemplate let-close="close">
  <div class="header">
    <div class="title">
      <span>
        {{ "COMMON.AssignDLManagerFor" | translate }}
        <strong>{{ customer.businessName }}</strong>
        {{ customer?.address?.address }}, {{ customer?.address?.city }},
        {{ customer?.address?.province }}
      </span>
    </div>
    <button class="close-dialog" mat-dialog-close mat-icon-button>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="content">
    <div class="row">
      <div class="col-6 test">
        <app-table
          [loader]="dlManagersLoader"
          [columns]="dlManagersTableColumns"
          [columnsButtons]="dlManagersTableButtons"
          [data]="dlManagersForTable"
        ></app-table>
      </div>
      <div class="col-6 d-flex flex-column">
        <div *ngIf="selectedDLManager" class="d-flex">
          <div>{{ "COMMON.ExpertiseAreas" | translate }}:</div>
          <div
            class="fw-700"
            [ngClass]="i === 0 ? 'ml-05' : null"
            *ngFor="
              let area of dlManagerExpertiseInfo?.expertiseArea;
              let i = index
            "
          >
            {{ i === 0 ? null : "," }} {{ area?.name }}
          </div>
          <div
            *ngIf="!dlManagerExpertiseInfo?.expertiseArea?.length"
            class="ml-05"
          >
            {{ "COMMON.NoneFemale" | translate }}
          </div>
        </div>
        <div *ngIf="!selectedDLManager">
          {{ "PROJECT_DETAIL.SelectADLManagerForDetails" | translate }}
        </div>
        <div class="mt-20">
          {{ "PROJECT_DETAIL.CompaniesUnderManagement" | translate }}
        </div>
        <div
          #mapDiv
          class="map-container p-relative"
          [ngStyle]="{ opacity: dlmanagerInfoLoader ? '50%' : '100%' }"
        >
          <div
            #popup
            class="popup"
            [ngClass]="showPopup ? 'show' : 'hide'"
          ></div>
          <div
            #lateralPopup
            class="lateral-popup"
            [ngClass]="showLateralPopup ? 'show' : 'hide'"
          ></div>
          <div *ngIf="dlmanagerInfoLoader" class="spinner-container-center">
            <mat-spinner diameter="40"></mat-spinner>
          </div>
        </div>
        <div class="legend">
          <div class="d-flex">
            <div class="item">
              <svg-icon name="pin_pps" class="w20 h20"></svg-icon>
              <div class="label">{{ "COMMON.DLManager" | translate }}</div>
            </div>
            <div class="item">
              <svg-icon name="pin_pps" class="w20 h20"></svg-icon>
              <div class="label">
                {{ "COMMON.ManagedCompanies" | translate }}
                ({{ dlManagerExpertiseInfo?.customer?.length || 0 }})
              </div>
            </div>
            <div class="item">
              <svg-icon name="pin_pps" class="w20 h20"></svg-icon>
              <div class="label">{{ "COMMON.ProjectCompany" | translate }}</div>
            </div>
          </div>
          <div class="item separate mt-10" style="margin-left: 2px">
            <svg-icon name="circle" class="w16 h16"></svg-icon>
            <div class="label">
              {{ "COMMON.ClusterForManagedCompanies" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons center">
    <button
      type="button"
      [mat-dialog-close]="true"
      class="rounded-button primary-bg-button"
      [disabled]="dlManagerExpertiseInfoLoading || !selectedDLManager?.name"
    >
      {{ "PROJECT_DETAIL.AssignLabManager" | translate }}
    </button>
  </div>
</ng-template>
