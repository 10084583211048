import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LoggedUser } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MustMatch } from 'src/app/utility/validators';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { DomSanitizer } from '@angular/platform-browser';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  accountForm: FormGroup;

  translations: any;
  disableEditAccount: boolean = true;
  spinner: boolean = false;
  load;
  user: LoggedUser;
  mandatoryPassword: boolean = false;
  hide: boolean = true;
  hideTwo: boolean = true;
  userImage: any;
  loading: boolean;

  @ViewChild('editUserImageDialog') editUserImageDialog: TemplateRef<any>;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.user = this.authService.userProfile;
    this.subscriptions.add(
      this.translate.get('USERS_MANAGEMENT').subscribe((translations) => {
        this.translations = translations;
        this.generateBreadCrumb();
      })
    );
    this.accountForm = this.fb.group({
      name: [this.user?.name || null, Validators.required],
      surname: [this.user?.surname || null, Validators.required],
      telephone: [this.user?.telephone || null, Validators.required],
      email: [this.user?.email || null, Validators.required],
      password: [
        null,
        [
          Validators.pattern(/(?=^.{4,128}$)^[a-zA-Z0-9._@&$#!?-]+$/),
          ...this.getPasswordValidators(),
        ],
      ],
      confirmPassword: [
        null,
        [MustMatch('password'), ...this.getPasswordValidators()],
      ],
    });
    this.getUserImage();
  }

  get controls(): { [key: string]: AbstractControl } {
    return this.accountForm.controls;
  }

  editAccount(message, action): void {
    if (!this.disableEditAccount) {
      this.spinner = true;
      this.load = setInterval(() => {
        this.spinner = false;
        this.disableEditAccount = true;
        clearInterval(this.load);
      }, 2000);
    }
    if (this.disableEditAccount) {
      this.disableEditAccount = false;
    }
  }

  private getPasswordValidators(): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if (this.mandatoryPassword) {
      validators.push(Validators.required);
    }
    return validators;
  }

  submit(): void {
    // const body: any = {
    //   name: this.accountForm.get('name').value,
    //   surname: this.accountForm.get('surname').value,
    //   telephone: this.accountForm.get('telephone').value,
    // };
    // this.accountForm.disable();
    // this.subscriptions.add(
    //   this.authService.updateAccount(this.user.idUser, body).subscribe({
    //     next: () => {
    //       this.accountForm.enable();
    //     }
    //   })
    // );
  }

  checkImageType(file: any): void {
    if (file.includes('assets')) {
      this.http.get(file, { responseType: 'blob' as 'json' }).subscribe({
        next: (image: any) => {
          this.uploadImage(image);
        },
      });
    } else {
      this.uploadImage(file);
    }

  }

  uploadImage(image: any): void {
    this.loading = true;
    this.subscriptions.add(
      this.layoutService.updateUserImage(image).subscribe({
        complete: () => {
          this.getUserImage();
          this.layoutService.refreshUserImage(image);
          this.loading = false;
          this.dialog.closeAll();
        },
        error: (err) => {
          console.log(err);
          this.loading = false;
        },
      })
    );
  }

  getUserImage(): void {
    this.subscriptions.add(
      this.layoutService.getUserImage().subscribe({
        next: (data: Blob) => {
          if (data.size > 0) {
            const unsafeImage = URL.createObjectURL(data);
            this.userImage = this.sanitizer.bypassSecurityTrustUrl(unsafeImage);
          } else {
            this.userImage = null;
          }
        },
      })
    );
  }

  generateBreadCrumb(): void {
    this.layoutService.generateBreadcrumb([
      {
        label: this.translate.instant('COMMON.PersonalAccount'),
        path: '/account',
        pageTitle: true,
      },
    ]);
  }

  changeUserImage(): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '800px';
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef: MatDialogRef<any> = this.dialog.open(
      this.editUserImageDialog,
      dialogConfig
    );
  }

  closeEditImageDialog(): void {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
