class Storage {
  static set(key: string, payload: any): void {
    localStorage.setItem(key, JSON.stringify(payload));
  }

  static get(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  static remove(key: string | string[]): void {
    if (Array.isArray(key)) {
      key.forEach(item => {
        localStorage.removeItem(item);
      });
    } else {
      localStorage.removeItem(key);
    }
  }

  static cleanAll(): void {
    Object.keys(localStorage).forEach((key) => {
      if (key !== "domain" && key !== "apiContext" && key !== "protocol")
        this.remove(key);
   });
    // localStorage.clear();
  }
}

class Session {
  static set(key: string, payload: any): void {
    sessionStorage.setItem(key, JSON.stringify(payload));
  }

  static get(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  static remove(key: string): void {
    if (Array.isArray(key)) {
      key.forEach(item => {
        sessionStorage.removeItem(item);
      });
    } else {
      sessionStorage.removeItem(key);
    }
  }
}

export { Storage, Session };
