import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerDescriptions } from 'src/app/models/customer-descriptions';
import { UserFunctionality } from 'src/app/models/user';

@Component({
  template: ''
})
export abstract class IrFormComponent {

  customerDescriptionForm: FormGroup;

  @Input() functionalities: UserFunctionality;

  @Output() customerDescrSubmitEmitter: EventEmitter<CustomerDescriptions> = new EventEmitter<CustomerDescriptions>();

  constructor(
    fb: FormBuilder
  ) {
    this.customerDescriptionForm = fb.group({
      idCustomerInfoDescription: [],
      currentProdDesc: [null, Validators.required],
      complementaryProdDesc: [],
      indirectCompetitionProdDesc: [null, Validators.required],
      marketDesc: [null, Validators.required],
      channelDesc: [null, Validators.required],
      ext1: [null, Validators.required]
    });
  }

  get customerDescrControls(): {[key: string]: AbstractControl}  {
    return this.customerDescriptionForm.controls;
  }

  protected updateIdCustomerInfoDescription(customerInfoDescription: CustomerDescriptions = {}): void {
    this.customerDescriptionForm.patchValue(customerInfoDescription);
  }

  protected customerDescrSubmit(description: string): void {
    if ((this.functionalities.isp.create || this.functionalities.isp.edit) && this.customerDescriptionForm.get(description).dirty) {
      // this.customerDescriptionForm.get(description).valid && this.customerDescriptionForm.get(description).dirty ) {
        this.customerDescrSubmitEmitter.emit(this.customerDescriptionForm.getRawValue());
        this.customerDescriptionForm.markAsPristine();
        this.customerDescriptionForm.markAsUntouched();
    }
  }
}
